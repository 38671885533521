import React, {useState} from 'react'
import MaterialTable, { MTableToolbar } from "material-table";
import { Typography } from '@material-ui/core';

//custom components
import ViewItem from './ViewItem'
// Utils
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from "@material-ui/pickers";

  //material-ui
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup';

function VehiclesTable(props){

 return (
     <> 
    <MaterialTable
    columns={[
      {
        title: "Veículo",
        field: "board",
        sorting: false,
        cellStyle: {  textAlign: "center", border: "#fff" },
      },
      {
        title: "Tempo Médio",
        field: "tempo_medio",
        cellStyle: {  textAlign: "center", border: "#fff" },
        render: (rowData) => moment(rowData.tempo_medio, "HH:mm:ss.SSS").format("HH:mm:ss")
      },
      {
        title: "Tempo Maior",
        field: "maior_tempo",
        cellStyle: {  textAlign: "center", border: "#fff" },
        render: (rowData) => moment(rowData.maior_tempo, "HH:mm:ss.SSS").format("HH:mm:ss")
      },
      {
        field: "actions",
        title: "Detalhes",
        cellStyle: { padding: 0, paddingLeft: 40, textAlign: "right", border: "#fff", width: 20, maxWidth: 20 },
        render: (rowData) => (
          <>
            <FormGroup row>
             <ViewItem board={rowData.board} startDate={props.startDate} endDate={props.endDate} vehicle_id={rowData.veiculoid}   />
            </FormGroup>
          </>
        ),
      },
    ]}
    data={props.data}        
    options={{
      minBodyHeight: 580,
      pageSize: 10,
      actionsColumnIndex: -1,
      search: false,
      showTitle: false,
      headerStyle: {
        paddingLeft: 20,
        textAlign: "center",
        backgroundColor: "#e8ebef",
        border: "#fff",
      },
      rowStyle: (x) => {
        if (x.tableData.id % 2) {
          return { backgroundColor: "#f3f5f8" };
        }
      },
    }}
    components={{
      Toolbar: (props) => (
        <div style={{ display: "none" }}>
          <MTableToolbar {...props} />
        </div>
      ),
    }}
    style={{
      borderRadius: 5,
    }}
  /></>
 )
}

export default VehiclesTable;