import React, {useState, useEffect} from 'react';
import api from '../../../../../core/services/api'
//@material-ui
import Grid from '@material-ui/core/Grid'


//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";


//custom components
import AverageTimes from './AverageTimes';
import PresenteeismTurn from './PresenteeismTurn'
import moment from 'moment';

function PresenteeismPanel(){
    const [state, setState] = useState({
        start_day: moment().format("YYYY-MM-DD"),
        end_day: moment().add(1, 'days').format("YYYY-MM-DD"),
        shift: 10,
        presenteeismShift1: [],
        presenteeismShift2: [],
        presenteeismShift3: [],
        setup1: 0,
        setup2: 0,
        setup3: 0,
        checklist1: 0,
        checklist2: 0,
        checklist3: 0,
        lanche1: 0,
        lanche2: 0,
        lanche3: 0,
        end1: 0,
        end2: 0,
        end3: 0,
    })
  
    useEffect(() => {
    getPresenteeism()
    }, [state.start_day])


    async function getPresenteeism() {
    try {
        const data1 = await api.get(`presenteeism-shift?start_day=${state.start_day}&end_day=${state.end_day}&shift=10`);
        const data2 = await api.get(`presenteeism-shift?start_day=${state.start_day}&end_day=${state.end_day}&shift=16`);
        const data3 = await api.get(`presenteeism-shift?start_day=${state.start_day}&end_day=${state.end_day}&shift=12`);
        const data4 = await api.get(`presenteeism-shift?start_day=${state.start_day}&end_day=${state.end_day}&shift=13`);
        
        let setup1 = 0;
        let averageSetup1 = 0;
        let checklist1 = 0;
        let averageChecklist1 = 0;
        let lanche1 = 0;
        let averageLanche1 = 0;
        let end1 = 0;
        let averageEnd1 = 0;


        let setup2 = 0;
        let averageSetup2 = 0;
        let checklist2 = 0;
        let averageChecklist2 = 0;
        let lanche2 = 0;
        let averageLanche2 = 0;
        let end2 = 0;
        let averageEnd2 = 0;

        let setup3 = 0;
        let averageSetup3 = 0;
        let checklist3 = 0;
        let averageChecklist3 = 0;
        let lanche3 = 0;
        let averageLanche3 = 0;
        let end3 = 0;
        let averageEnd3 = 0;

        let setup4 = 0;
        let averageSetup4 = 0;
        let checklist4 = 0;
        let averageChecklist4 = 0;
        let lanche4 = 0;
        let averageLanche4 = 0;
        let end4 = 0;
        let averageEnd4 = 0;

        data1.data.map((item) => {

            

            if(item.setup_time && item.setup_time > 0){
                setup1 += item.setup_time;
                averageSetup1 += 1;
            }
            if(item.lanche && item.lanche > 0){
                lanche1 += item.lanche;
                averageLanche1 += 1;
            }
            if(item.checklist && item.checklist > 0){
                checklist1 += item.checklist;
                averageChecklist1 += 1;
            }
            item.journey.tasks.forEach((item2, index) => {

                if(item2.activity_type == 12){
                    const duration = moment(item2.end_real,"YYYY-MM-DD HH:mm:ss").diff(moment(item2.start_real, "YYYY-MM-DD HH:mm:ss"),"seconds");
                    end1 += duration;
                    averageEnd1 += 1;
                }
            })
        })

        data2.data.map((item) => {
            if(item.setup_time && item.setup_time > 0){
                setup2 += item.setup_time;
                averageSetup2 += 1;
            }
            if(item.lanche && item.lanche > 0){
                lanche2 += item.lanche;
                averageLanche2 += 1;
            }
            if(item.checklist && item.checklist > 0){
                checklist2 += item.checklist;
                averageChecklist2 += 1;
            }
            item.journey.tasks.forEach((item2, index) => {

                 //ajustando END SHIFT
                 if(item2.activity_type == 3){
                    let date = moment(state.end_day, "YYYY-MM-DD HH:mm:ss").add(4140, 'seconds').format("YYYY-MM-DD HH:mm:ss");
                    item.end_shift = moment(date,"YYYY-MM-DD HH:mm:ss").diff(moment(item2.start_real, "YYYY-MM-DD HH:mm:ss"),"seconds");
                }

                if(item2.activity_type == 12){
                    const duration = moment(item2.end_real,"YYYY-MM-DD HH:mm:ss").diff(moment(item2.start_real, "YYYY-MM-DD HH:mm:ss"),"seconds");
                    end2 += duration;
                    averageEnd2 += 1;
                }
            })
        })
        data3.data.map((item) => {
            if(item.setup_time && item.setup_time > 0){
                setup3 += item.setup_time;
                averageSetup3 += 1;
            }
            if(item.lanche && item.lanche > 0){
                lanche3 += item.lanche;
                averageLanche3 += 1;
            }
            if(item.checklist && item.checklist > 0){
                checklist3 += item.checklist;
                averageChecklist3 += 1;
            }
            item.journey.tasks.forEach((item2, index) => {

       


                if(item2.activity_type == 12){
                    const duration = moment(item2.end_real,"YYYY-MM-DD HH:mm:ss").diff(moment(item2.start_real, "YYYY-MM-DD HH:mm:ss"),"seconds");
                    end3 += duration;
                    averageEnd3 += 1;
                }
            })
        })

        data4.data.map((item) => {
           
            if(item.lanche && item.lanche > 0){
                lanche4 += item.lanche;
                averageLanche4 += 1;
            }
            if(item.checklist && item.checklist > 0){
                checklist4 += item.checklist;
                averageChecklist4 += 1;
            }
            item.journey.tasks.forEach((item2, index) => {

                  //ajustando END SHIFT
                  if(item2.activity_type == 3){
                    let date = moment(state.end_day, "YYYY-MM-DD HH:mm:ss").add(21600, 'seconds').format("YYYY-MM-DD HH:mm:ss");
                    item.end_shift = moment(date,"YYYY-MM-DD HH:mm:ss").diff(moment(item2.start_real, "YYYY-MM-DD HH:mm:ss"),"seconds");
                }

                if(item2.activity_type == 11){
                    const duration = moment(item2.start_real,"YYYY-MM-DD HH:mm:ss").diff(moment(item2.start_prevision, "YYYY-MM-DD HH:mm:ss"),"seconds");
                    setup4 += duration;
                    item.setup_time = duration;
                    averageSetup4 += 1;
                }

                if(item2.activity_type == 12){
                    const duration = moment(item2.end_real,"YYYY-MM-DD HH:mm:ss").diff(moment(item2.start_real, "YYYY-MM-DD HH:mm:ss"),"seconds");
                    end4 += duration;
                    averageEnd4 += 1;
                }
            })
       
        })


        //console.log('Tirando media, setup: ', setup2, 'Average: ', averageSetup2)
        setup1 = setup1 == 0 ? 0 : (setup1/averageSetup1).toFixed(0);
        setup2 = setup2 == 0 ? 0 : (setup2/averageSetup2).toFixed(0);
        setup3 = setup3 == 0 ? 0 : (setup3/averageSetup3).toFixed(0);
        setup4 = setup4 == 0 ? 0 : (setup4/averageSetup4).toFixed(0);
        checklist1 = checklist1 == 0 ? 0 : (checklist1/averageChecklist1).toFixed(0);
        checklist2 = checklist2 == 0 ? 0 : (checklist2/averageChecklist2).toFixed(0);
        checklist3 = checklist3 == 0 ? 0 : (checklist3/averageChecklist3).toFixed(0);
        checklist4 = checklist4 == 0 ? 0 : (checklist4/averageChecklist4).toFixed(0);
        lanche1 = lanche1 == 0 ? 0 : (lanche1/averageLanche1).toFixed(0);
        lanche2 = lanche2 == 0 ? 0 : (lanche2/averageLanche2).toFixed(0);
        lanche3 = lanche3 == 0 ? 0 : (lanche3/averageLanche3).toFixed(0);
        lanche4 = lanche4 == 0 ? 0 : (lanche4/averageLanche4).toFixed(0);
        end1 = end1 == 0 ? 0 : (end1/averageEnd1).toFixed(0);
        end2 = end2 == 0 ? 0 : (end2/averageEnd2).toFixed(0);
        end3 = end3 == 0 ? 0 : (end3/averageEnd3).toFixed(0);
        end4 = end4 == 0 ? 0 : (end4/averageEnd4).toFixed(0);
        //console.log('Media setup turno1 : ', setup2)
        setState((prev) => ({
        ...prev,
        presenteeismShift1: data1.data || [],
        presenteeismShift2: data2.data || [],
        presenteeismShift3: data3.data || [],
        presenteeismShift4: data4.data || [],

        setup1: setup1,
        setup2: setup2,
        setup3: setup3,
        setup4: setup4,
        checklist1: checklist1,
        checklist2: checklist2,
        checklist3: checklist3,
        checklist4: checklist4,
        lanche1: lanche1,
        lanche2: lanche2,
        lanche3: lanche3,
        lanche4: lanche4,
        end1: end1,
        end2: end2,
        end3: end3,
        end4: end4,
        }))
    } catch (error) {
        console.warn(error)
    }
    }

    const handleDate = (date) => {
        setState((prev) => ({
          ...prev,
          start_day: moment(date).format("YYYY-MM-DD"),
          end_day: moment(date).add(1, 'days').format("YYYY-MM-DD")
        }));
      };
    return (
    <Grid container spacing={3}>
        <Grid item>
            <div style={{ marginTop: 10, marginBottom:  0}}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
                <ThemeProvider theme={fcaTheme}>
                  <KeyboardDatePicker
                    label="Filtro de Data"
                    disableFuture={true}
                    inputVariant="outlined"
                    disableToolbar
                    clearable="true"
                    autoOk={true}
                    style={{ paddingBottom: 12 }}
                    format="dd/MM/yyyy"
                    variant="inline"
                    value={moment(state.start_day, "YYYY-MM-DD")}
                    onChange={handleDate}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </div>
        </Grid>
        <Grid item md={12} lg={12} sm={12} xs={12}>
        <AverageTimes 
        setup1={state.setup1} 
        setup2={state.setup2} 
        setup3={state.setup3} 
        setup4={state.setup4} 
        checklist1={state.checklist1} 
        checklist2={state.checklist2} 
        checklist3={state.checklist3} 
        checklist4={state.checklist4} 
        lanche1={state.lanche1}
        lanche2={state.lanche2}
        lanche3={state.lanche3}
        lanche4={state.lanche4}
        end1={state.end1}
        end2={state.end2}
        end3={state.end3}
        end4={state.end4}
        date={state.start_day}
        />
        </Grid>
        {state.start_day == moment(state.start_day, "YYYY-MM-DD").day(0).format("YYYY-MM-DD") ? (<>
            <Grid item md={12} lg={12} sm={12} xs={12}>
            <PresenteeismTurn title="Presenteísmo Turno 3 - Domingo" presenteeism={state.presenteeismShift4} setup_time="06:00:00" end="15:48:00" />
            </Grid></>
        ) : (<>
        
            <Grid item md={12} lg={12} sm={12} xs={12}>
            <PresenteeismTurn title="Presenteísmo Turno 1" presenteeism={state.presenteeismShift1} setup_time="06:00:00" end="15:48:00" />
            </Grid>
            <Grid item md={12} lg={12} sm={12} xs={12}>
            <PresenteeismTurn title="Presenteísmo Turno 2" presenteeism={state.presenteeismShift2} setup_time="15:48:00" end="01:09:00" />
            </Grid>
            <Grid item md={12} lg={12} sm={12} xs={12}>
            <PresenteeismTurn title="Presenteísmo Turno 3" presenteeism={state.presenteeismShift3} setup_time="01:09:00" end="06:00:00" />
            </Grid></>
        )}
       



    </Grid> 
    )
}

export default PresenteeismPanel;