import React, { Component } from "react";
import api from "../../../../core/services/api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Cold from "@material-ui/icons/AcUnit";
import Hot from "@material-ui/icons/Whatshot";
import CheckCircle from "@material-ui/icons/CheckCircle";
import FMap from "@material-ui/icons/Map";
import TableContainer from "@material-ui/core/TableContainer";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Main from "../../components/Main";
import Container from "@material-ui/core/Container";
import moment from "moment";
import Modal from "./modal";
import {Link} from 'react-router-dom';
import MomentUtils from "@date-io/moment";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker
  } from "@material-ui/pickers";
  import "moment/locale/pt-br";

export class Evidences extends Component {
  state = {
    alerts: [],
    carrier: "all",
    carriers: [],
    today: this.props.match.params.date
  };

  componentDidMount() {
    this.getAlerts();
    this.getCarriers();
  }

  async getAlerts() {
    const {data} = await api.get(`/alerts/list?day=${moment(this.state.today).format('YYYY-MM-DD')}&carrier=${this.state.carrier}`);
    this.setState({ ...this.state, alerts: data });
  }

  async getCarriers() {
    const {data} = await api.get(`/carriers`);
    this.setState({ ...this.state, carriers: data.data ||[] });
  }

  handleDateChange = date => {
    this.setState({ ...this.state, today: date }, () => {
      this.getAlerts();
    });
  };

  tempIcon(temperature) {  
      if (temperature < 12) {
        return (
          <Cold style={{fontSize: 17, color: 'white'}} />
          )
      } else if(temperature > 16) {
        return (
          <Hot style={{fontSize: 17, color: 'white'}} />
        )
      }
      return (
          <CheckCircle style={{fontSize: 17, color: 'white'}} />
      )
  }

  filtraTemperatura(temp) {
    if (temp < 12) {
      return "#2196F3"; // blue
    } else if (temp > 16) {
      return "#EC273B"; // red
    } else {
      return "#5ED237"; // green
    }
  }

  filterCarrier(value){
    this.setState({...this.state, carrier: value}, () => this.getAlerts());
  }

  render() {
    return (
      <>
        <Main>
          <Container maxWidth="lg">
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '70px', alignItems: 'flex-end'}}>
                <h1 style={{margin: 0}}>Listagem de Alertas</h1>
                <Select 
                  style={{winWidth: 200, maxWidth: 200, width: 200, marginTop: 16, marginBottom: 8}}
                  value={this.setState.carrier}
                  onChange={(e) => this.filterCarrier(e.target.value)}>
                  <MenuItem value="all" >Todas</MenuItem>
                  {this.state.carriers.map(({id, name}) => <MenuItem value={id}>{name}</MenuItem>)}
                </Select>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Escolha o dia"
                        format="DD-MM-YYYY"
                        value={this.state.today}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                        "aria-label": "change date"
                        }}
                    />
                </MuiPickersUtilsProvider>
            </div>   
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Horário</TableCell>
                    <TableCell align="left">Motorista</TableCell>
                    <TableCell align="left">Transportadora</TableCell>
                    <TableCell align="left">Placa</TableCell>
                    <TableCell align="left">Temperatura</TableCell>
                    <TableCell align="left">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.alerts.length === 0 ? null : this.state.alerts.map(alert => {
                    return (
                      <TableRow key={alert.id}>
                        <TableCell align="left">
                          {moment(alert.created_at).format("HH:mm")}
                        </TableCell>
                        <TableCell align="left">{alert.driver_name}</TableCell>
                        <TableCell align="left">{alert.carrier}</TableCell>
                        <TableCell align="left">{alert.plate_number}</TableCell>
                        <TableCell align="left">
                            <div style={{
                                borderRadius: '30px',
                                color: 'white',
                                width: 55,
                                maxWidth: 55,
                                padding: '4px 2px',
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                background: this.filtraTemperatura(alert.temperature)
                            }}>
                            {this.tempIcon(alert.temperature)}
                            <h4 style={{margin: 0}}>{alert.temperature}</h4>
                            </div>
                        </TableCell>
                        <TableCell align="left">
                          <Link style={{color: 'black', textDecoration: 'none'}} to={`/routes/${alert.journey_id}`}><FMap style={{fontSize: 17}}/></Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </Main>
      </>
    );
  }
}

export default Evidences;