import React, { useEffect, useState } from "react";
import api from '../../../../../core/services/api'
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Grid from '@material-ui/core/Grid'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'


function EditForm(props) {
  const [state, setState] = React.useState({
    error: false,
    disabled: false,
    active: true
  });

  const [name, setName] = useState("");
  const [func, setFunction] = useState("");
  const [email, setEmail] = useState("");
  const [matricula, setMatricula] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [turn, setTurn] = useState("");
  const [local, setLocal] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setState({
      disabled: false,
      check: false,
      error: false,
      showPassword: ''
    })
    setName(props.collaborator.name);
    setMatricula(props.collaborator.matricula);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const handleSubmit = () => {
    setState((prev) => ({
      ...prev,
      disabled: true
    }))
    if(state.check){
      //SE ATIVAR CHECKBOX DE MUDANÇA DE SENHA
      if(password != password2){
        setState((prev) => ({
          ...prev,
          disabled: false,
          error: true
        }))
      } else if (password === '' && password2 === '' && name === ''){
        setState((prev) => ({
          ...prev,
          disabled: false,
          error: true
        }))
      } else {
      postDriver(state.check);
      setOpen(false)
      }
    } else {
      //SEM TROCA DE SENHA
      if (name === ''){
        setState((prev) => ({
          ...prev,
          disabled: false,
          error: true
        }))
      } else {
      postDriver(state.check);
      setOpen(false)
      }
    } 
  }

  async function postDriver(check){
    try {
      if(check){
        const driver = {
          name: name,
          email: "",
          password: password,
          matricula: matricula,
          resource: {
            cpf: "",
            cnh: "",
            active: true,
          },
          carrier_id: state.carrier_id,
        }
        await api.put(`drivers/`+props.collaborator.driver_id,driver);
        props.getData();
      } else {
        const driver = {
          name: name,
          email: "",
          matricula: matricula,
          resource: {
            cpf: "",
            cnh: "",
            active: true,
          },
          carrier_id: state.carrier_id,
        }
        await api.put(`drivers/`+props.collaborator.driver_id,driver);
        props.getData();
      }
    } catch (error) {
      console.warn(error)  
    }
  }

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };




  return (
    <div>
       <IconButton onClick={handleClickOpen}>
        <EditIcon style={{ color: "#fcc326" }} className="icon-small" />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>Editar Motorista</DialogTitle>
        <DialogContent>
        <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                  id="name"
                  name="name"
                  label="Nome do Motorista"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                />{" "}
              </div>
             
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                  id="matricula"
                  name="matricula"
                  label="Login"
                  type="text"
                  value={matricula}
                  onChange={(e) => setMatricula(e.target.value)}
                  fullWidth
                />{" "}
              </div>
              <div style={{ marginTop: 12, marginBottom: 12, display: state.check ? 'block' : 'none'  }}>
             <FormControl fullWidth>
              <InputLabel>Senha</InputLabel>
              <Input
                  error={state.error ? true : false}
                  fullWidth
                  id="password"
                  name="password"
                  label="Senha"
                  type={state.showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                       
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                /></FormControl>
              </div>
              <div style={{ marginTop: 12, marginBottom: 12, display: state.check ? 'block' : 'none'  }} >
              <FormControl fullWidth>
                <InputLabel>Confirmar senha</InputLabel>
                <Input
                  error={state.error ? true : false}
                  fullWidth
                  id="password2"
                  name="password2"
                  label="Confirme sua Senha"
                  type={state.showPassword ? 'text' : 'password'}
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />{" "}</FormControl>
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.check}
                    onChange={handleChange}
                    name="check"
                    color="primary"
                  />
                }
                label="Alterar senha"
              /> 
                </div>
        </DialogContent>
        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor:state.disabled ? "" : "#5ed237", color:state.disabled ? "" : "#fff" }}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditForm;
