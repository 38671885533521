import React, {useState, useEffect} from 'react'
import api from '../../../../../../core/services/api'
import moment from 'moment'
import Chart from "react-apexcharts";
import options from "./options";
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";


//material ui
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import StopIcon from '@material-ui/icons/Stop'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'


//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// pick a date util library
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";


//custom components
import CustomSelect from '../../../../components/CustomSelect'

function GraphCard(props){
    const usuario = JSON.parse(localStorage.getItem("usuario"));
    const [state, setState] = useState({          
        series: [{
          name: 'Realizado',
          data: [0,0,0,0,0]
        },
        {
          name: 'Programado',
          data: [0,0,0,0,0]
        }
      ],
      series2: [{
        name: 'Realizado',
        data: [0,0,0,0,0]
      },
      {
        name: 'Programado',
        data: [0,0,0,0,0]
      }
    ],
    series3: [{
      name: 'Realizado',
      data: [0,0,0,0,0]
    },
    {
      name: 'Programado',
      data: [0,0,0,0,0]
    }
  ],
        legends: [],
        target: [],
        lesstarget: [],
        biggertarget: [],
        start_day: moment().format("YYYY-MM-DD"),
        end_day: moment().add(1, 'days').format("YYYY-MM-DD"),
        local_list: [],
        shifts_list: [],
        shifts_id: null,
        local_users: usuario.person.resource.local_id || [60],
        local_id: usuario.person.resource.local_id[0] || 60,
        local_name: '',
    });

    useEffect(() => {
      getData();
    },[props.local_id, props.shifts_id, props.start_day])

    useEffect(() => {
      getShift()
    }, [])
  
    async function getShift() {
      try {
        const data = await api.get(`shifts?active=all`);
        const shifts = []
        data.data.data.map((item) => shifts.push(item))
        setState((prev) => ({
          ...prev,
          shifts_list: data.data.data || []
        }))
      } catch (error) {
        console.warn(error)
      }
    }
  async function getData(){
      try {
        let shift = ``;
        if(props.shifts_id != null) shift = `&shift=${props.shifts_id}`
        const data = await api.get(`task-local?start_day=${props.start_day}&end_day=${props.end_day}&local=${props.local_id}`+shift);
        const data2 = await api.get(`place?perPage=1000`);
        let places = data2.data.data;
        if(!state.local_users.includes(60)){
          places = data2.data.data.filter((item) => {if(state.local_users.includes(item.id)){ return item}})
        }
        const data3 = await api.get(`routes`);
        let local_name = data.data[0] ? data.data[0].tasks.title : ''
        let series = [{
          name: 'Realizado',
          data: [0,0,0,0,0]
        }]
        let series2 = [{
          name: 'Realizado',
          data: [0,0,0,0,0]
        }]
        let series3 = [{
          name: 'Realizado',
          data: [0,0,0,0,0]
        }]
        let programado = {
          name: 'Programado',
          data: [3,12,70,12,3]
        }
        series.push(programado)
        series2.push(programado)
        series3.push(programado)
        let average = [0,0,0]
        let target = [0,0,0]
        let targetaverage = [0,0,0]
        let lesstarget = [9999,9999,9999]
        let biggertarget = [0,0,0]

        
        

    //pegando target das rotas cadastradas
    data3.data.map((item) => {
      item.tasks.map((item) => {
        if(item.local == props.local_id){
          const aux2 = moment(item.target_time, "HH:mm:ss").diff(moment(item.target_time, "HH:mm:ss").clone().startOf('day'), 'seconds');
          if(item.shift == 10){
          //ativdade turno 1 
          if(lesstarget[0] > aux2) lesstarget[0] = aux2;
          if(biggertarget[0] < aux2) biggertarget[0] = aux2;
          target[0] += aux2;
          targetaverage[0] += 1; 
          } else if (item.shift == 16){
            //atividade turno 2
            if(lesstarget[1] > aux2) lesstarget[1] = aux2;
            if(biggertarget[1] < aux2) biggertarget[1] = aux2;
            target[1] += aux2;
            targetaverage[1] += 1;
          } else if (item.shift == 12){
            //atividade turno 3
            if(lesstarget[2] > aux2) lesstarget[2] = aux2;
            if(biggertarget[2] < aux2) biggertarget[2] = aux2;
            target[2] += aux2;
            targetaverage[2] += 1;
          }
        }
      })
    })

    target.forEach((item, index) => {
      if(item != 0 ){
        target[index] = parseInt(((item/targetaverage[index])/60).toFixed(0))
      }
    })
    lesstarget.forEach((item, index) => {
      lesstarget[index] = parseInt((item/60).toFixed(0))
    })
    biggertarget.forEach((item, index) => {
      biggertarget[index] = parseInt((item/60).toFixed(0))
    })

    let t1 = [lesstarget[0], target[0]-1, target[0], target[0]+1, biggertarget[0]]
    let t2 = [lesstarget[1], target[1]-1, target[1], target[1]+1, biggertarget[1]]
    let t3 = [lesstarget[2], target[2]-1, target[2], target[2]+1, biggertarget[2]]


      //criando series
      data.data.map((item) =>{
        if(item.tasks.local == props.local_id){
           const aux = (moment(item.tasks.finish_time, "DD/MM/YYYY HH:mm:ss").diff(moment(item.tasks.finish_time, "DD/MM/YYYY HH:mm:ss").clone().startOf('day'), 'seconds')/60);
            if(item.tasks.shift == 10){
            //ativdade turno 1 
            
            if(aux < t1[0]){
              series[0].data[0] += 1;
              average[0] += 1;
            } else if (aux > t1[0] && aux < t1[1]) {
              series[0].data[1] += 1;
              average[0] += 1;
            } else if (aux > t1[1] && aux < t1[2]) {
              series[0].data[2] += 1;
              average[0] += 1;
            } else if (aux > t1[2] && aux < t1[3]) {
              series[0].data[2] += 1;
              average[0] += 1;
            } else if (aux > t1[3] && aux < t1[4]) {
              series[0].data[3] += 1;
              average[0] += 1;
            } else if (aux > t1[4]) {
              series[0].data[4] += 1;
              average[0] += 1;
            }
            
            } else if (item.tasks.shift == 16){
             
              //atividade turno 2
              if(aux < t2[0]){
                series2[0].data[0] += 1;
                average[1] += 1;
              } else if (aux > t2[0] && aux < t2[1]) {
                series2[0].data[1] += 1;
                average[1] += 1;
              } else if (aux > t2[1] && aux < t2[2]) {
                series2[0].data[2] += 1;
                average[1] += 1;
              } else if (aux > t2[2] && aux < t2[3]) {
                series2[0].data[2] += 1;
                average[1] += 1;
              } else if (aux > t2[3] && aux < t2[4]) {
                series2[0].data[3] += 1;
                average[1] += 1;
              } else if (aux > t2[4]) {
                series2[0].data[4] += 1;
                average[1] += 1;
              }
              
            } else if (item.tasks.shift == 12){
              //atividade turno 3
              if(aux < t3[0]){
                series3[0].data[0] += 1;
                average[2] += 1;
              } else if (aux > t3[0] && aux < t3[1]) {
                series3[0].data[1] += 1;
                average[2] += 1;
              } else if (aux > t3[1] && aux < t3[2]) {
                series3[0].data[2] += 1;
                average[2] += 1;
              } else if (aux > t3[2] && aux < t3[3]) {
                series3[0].data[2] += 1;
                average[2] += 1;
              } else if (aux > t3[3] && aux < t3[4]) {
                series3[0].data[3] += 1;
                average[2] += 1;
              } else if (aux > t3[4]) {
                series3[0].data[4] += 1;
                average[2] += 1;
              }
              
            }
          
        }
      })
      series[0].data.forEach((item, index) => {
        if(item != 0){
          series[0].data[index] = ((item*100)/average[0]).toFixed(1)
        }
      })
      series2[0].data.forEach((item, index) => {
        if(item != 0){
          series2[0].data[index] = ((item*100)/average[1]).toFixed(1)
        }
      })
      series3[0].data.forEach((item, index) => {
        if(item != 0){
          series3[0].data[index] = ((item*100)/average[2]).toFixed(1)
        }
      })
      setState((prev) => ({
        ...prev,
        local_list: places || [],
        series: series,
        series2: series2,
        series3: series3,
        local_name: local_name,
        target: target,
        lesstarget: lesstarget,
        biggertarget: biggertarget
      }))
      } catch (error) {
        console.warn(error)
      }
    }



    return(
      <Grid container justify="center" alignItems="center" spacing={3}>
        <Grid item lg={6} sm={6} md={6} xs={6}>
        <Typography style={{fontSize: 14, textAlign: 'left'}} color="textSecondary" gutterBottom>
        <b>Dispersão por Turno: {state.local_name}</b> - Período {moment(props.start_day, "YYYY-MM-DD").format("DD/MM/YYYY")} 
        </Typography>
        </Grid>
        <Grid item lg={6} sm={6} md={6} xs={6}></Grid>
        <Grid item lg={4} sm={4} md={4} xs={4} style={{marginBottom: '-40px'}}>
        <Typography style={{fontSize: 14, textAlign: 'center'}} color="textSecondary">T1</Typography>
        </Grid>
        <Grid item lg={4} sm={4} md={4} xs={4} style={{marginBottom: '-40px'}}>
        <Typography style={{fontSize: 14, textAlign: 'center'}} color="textSecondary">T2</Typography>
        </Grid>
        <Grid item lg={4} sm={4} md={4} xs={4} style={{marginBottom: '-40px'}}>
        <Typography style={{fontSize: 14, textAlign: 'center'}} color="textSecondary">T3</Typography>
        </Grid>
        <Grid item lg={4} sm={4} md={4} xs={4}>
        <Chart
              options={options(state.target[0], state.lesstarget[0], state.biggertarget[0])}
              series={state.series}
              type="line"
              height={350}
            />
        </Grid>
        <Grid item lg={4} sm={4} md={4} xs={4}>
        <Chart
              options={options(state.target[1], state.lesstarget[1], state.biggertarget[1])}
              series={state.series2}
              type="line"
              height={350}
            />
        </Grid>
        <Grid item lg={4} sm={4} md={4} xs={4}>
        <Chart
              options={options(state.target[2], state.lesstarget[2], state.biggertarget[2])}
              series={state.series3}
              type="line"
              height={350}
            />
        </Grid>
      </Grid>
        
    )
}

export default GraphCard;