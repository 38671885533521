import React from "react";
const template = document.createElement('template');
template.innerHTML =  `
<svg 
    version="1.1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlns:xlink="http://www.w3.org/1999/xlink"
 	viewBox="-10 -10 240 240"
    style="width:140%; height:100%;transform: scale(-1,1)"
    xml:space="preserve">
	<style>
		.guia 	{ fill: transparent; stroke: #888; stroke-width: 0.5  }
		.medida { fill: #ffcc0066;  	stroke: #888; stroke-width: 0.5 }
	</style>
	<g class="guia" id="guia">
        <!-- face traseira -->
        <path id="g0" />

        <!--face dianteira--> 
        <path id="g1"/>
        
        <!-- tampa -->
        <path id="g2"/>

        <!-- base -->
        <path id="g3"/>

        <!-- esquerda -->
        <path id="g4"/>
        
        <!-- direita -->
        <path id="g5"/>
	</g>

    <g class="medida" id="medida">
        <!-- face traseira -->
        <path id="m0" />

        <!--face dianteira--> 
        <path id="m1"/>
        
        <!-- tampa -->
        <path id="m2"/>

        <!-- base -->
        <path id="m3"/>

        <!-- esquerda -->
        <path id="m4"/>
        
        <!-- direita -->
        <path id="m5"/>
    </g>


</svg>` 
export default class CubeSVG extends HTMLElement
{
    static get observedAttributes(){
        return ['x-max', 'y-max', 'z-max', 'x-value', 'y-value', 'z-value'];
    }

    constructor(){
        super();
        const shadowRoot = this.attachShadow({mode:'closed'});
        shadowRoot.appendChild(template.content.cloneNode(true));
        this.element = shadowRoot.querySelector('svg');
    }

    attributeChangedCallback(name, oldVal, newVal) {
        if (oldVal !== newVal)
        { this.render(); }
    }

    connectedCallback(){
        if(!this.xMax)
        { this.xMax = 100; }

        if(!this.yMax)
        { this.yMax = 100; }

        if(!this.zMax)
        { this.zMax = 100; }

        if(!this.xValue)
        { this.xValue = 0; }

        if(!this.yValue)
        { this.yValue = 0; }

        if(!this.zValue)
        { this.zValue = 0; }
        
        this.render();
    }

    get xMax(){ return +this.getAttribute('x-max'); }
    set xMax(v){ this.setAttribute('x-max', v); }

    get yMax(){ return +this.getAttribute('y-max'); }
    set yMax(v){ this.setAttribute('y-max', v); }

    get zMax(){ return +this.getAttribute('z-max'); }
    set zMax(v){ this.setAttribute('z-max', v); }

    get xValue(){ return +this.getAttribute('x-value'); }
    set xValue(v){ this.setAttribute('x-value', v); }

    get yValue(){ return +this.getAttribute('y-value'); }
    set yValue(v){ this.setAttribute('y-value', v); }

    get zValue(){ return +this.getAttribute('z-value'); }
    set zValue(v){ this.setAttribute('z-value', v); }

    fill(obj, start, x, y, z)
    {
        obj.traseira.setAttribute('d', `m${start[0]},${start[1]} h${x} v${y} h${-x}z`);
        obj.dianteira.setAttribute('d', `m${start[0]+z},${start[1]+z} h${x} v${y} h${-x}z`);
        obj.tampa.setAttribute('d', `m${start[0]},${start[1]} h${x} l${z},${z} h${-x}z`);       
        obj.base.setAttribute('d', `m${start[0]},${start[1]+y} h${x} l${z},${z} h${-x}z`);
        obj.esquerda.setAttribute('d', `m${start[0]},${start[1]} l${z},${z}  v${y} l-${z}-${z}z`);
        obj.direita.setAttribute('d', `m${start[0]+x},${start[1]} l${z},${z}  v${y} l-${z}-${z}z`);

    }

    render(){
        let guia = {
            traseira:   this.element.getElementById('g0'),
            dianteira:  this.element.getElementById('g1'),
            tampa:      this.element.getElementById('g2'),
            base:       this.element.getElementById('g3'),
            esquerda:   this.element.getElementById('g4'),
            direita:    this.element.getElementById('g5')
        };

        let medida = {
            traseira:   this.element.getElementById('m0'),
            dianteira:  this.element.getElementById('m1'),
            tampa:      this.element.getElementById('m2'),
            base:       this.element.getElementById('m3'),
            esquerda:   this.element.getElementById('m4'),
            direita:    this.element.getElementById('m5')
        };


        let _z = this.zMax/2;
        let margin = [10, 10, 10, 20];
        this.element.setAttribute('viewBox', `-${margin[0]} -${margin[1]} ${margin[2] + this.xMax+_z} ${margin[3] + this.yMax + _z}` );

        let _zV = this.zValue/2;
        
        this.fill(guia, [0,0], this.xMax, this.yMax, _z);
        this.fill(medida, [100,this.yMax-this.yValue], this.xValue, this.yValue, _zV);
    }
}

window.customElements.define('cube-svg', CubeSVG);
