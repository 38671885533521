import React from "react";

//@material-ui
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Fab from "@material-ui/core/Fab";
import TextField from "@material-ui/core/TextField";

//others components
import moment from "moment";
import { LoadingContainer } from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 300,
    minWidth: 345,
    padding: 12,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

function CiclosCompletos(props) {
  const classes = useStyles();
  let sum = (a) => a.reduce((x, y) => x + y);
  let avg = (a) => {
    let aux = 0;
    aux = a.filter((x) => x !== 0 && x !== null).length;
    let soma = sum(a);
    let media = soma/aux;
    return getTimeFromMins(media);
  }

  function getTimeFromMins(mins) {
    // do not include the first validation check if you want, for example,
    // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)
    if (mins >= 24 * 60 || mins < 0) {
        throw new RangeError("Valid input should be greater than or equal to 0 and less than 1440.");
    }
    var h = mins / 60 | 0,
        m = mins % 60 | 0;
    return moment.utc().hours(h).minutes(m).format("hh:mm");
}

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <Fab disabled style={{ backgroundColor: "#cce9fa" }}>
            <AccessTimeIcon style={{ color: "#0091e6" }} />
          </Fab>
        }
        title="Tempo médio nos ciclos"
      />

      <CardContent>
      
       
          {props.data && props.data != "NaN:NaN:NaN" ? (
             <Typography style={{ fontSize: 85, fontWeight: "bold" }} component="p">
            {props.data} 
            </Typography>
          ) : (
            <LoadingContainer>
              <CircularProgress style={{ color: "#1e824c" }} />{" "}
            </LoadingContainer>
          )}
        
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
}

export default CiclosCompletos;
