import React, { useState } from 'react';
import { Link, withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    root: {
        maxWidth: 350,
        minWidth: 300,
        minHeight: 90,

    },
    title: {
        fontSize: 14,
        fontWeight: 'bold'
    },
    buttonTitle: {
        fontSize: 10,
        color: '#5AD545'
    },
    pos: {
        textAlign: 'right',
        marginBottom: 12,
    },
});


function CardChecklist({pageLink, cardTitle}) {
    const classes = useStyles();

     return (
        <Card className={classes.root}>
            <CardContent>
                <Typography className={classes.title} variant="h4">
                    {cardTitle}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small">
                    <Link to={"/report"+pageLink} className={classes.buttonTitle}>
                        Exibir
                    </Link>
                </Button>
            </CardActions>
        </Card>)
}
export default withRouter(CardChecklist);