import React, { Component } from 'react';
import css from '../../css/loaders/bars_loader.module.css';

class BarsLoader extends Component {
    render() {
        return (
            <div {...this.props}>
                <div className={css["lds-facebook"]}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        );
    }
}

export default BarsLoader;