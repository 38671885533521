import React from "react";

//@material-ui
import NearMe from "@material-ui/icons/NearMe";
import LensIcon from "@material-ui/icons/Lens";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";

export default ({
  carro,
  navigate,
  setColor,
  selectedIndex,
  handleListItemClick,
}) => {
  return (
    <>
      <ListItem
        button
        selected={selectedIndex}
        onClick={(event) => handleListItemClick(event, 0)}
        divider
      >
        <ListItemIcon>
          <LensIcon style={{ color: setColor(carro.counter), fontSize: 12 }} />
        </ListItemIcon>

        <ListItemText primary={carro.board} />

        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete">
            <NearMe
              onClick={() => navigate(carro.latitude, carro.longitude)}
              style={{ color: "#7C8796" }}
            />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};
