import React, { useState, useEffect } from "react";
import api from "../../../../../../core/services/api";
import { useStyles } from "./styles";

//custom components
import GraphCard from "./GraphCard";

//material ui
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

//custom components
import CustomSelect from "../../../../components/CustomSelect";

function SaturationFlow(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    series: [
      {
        data: [],
      },
    ],
    legends: [],
    local_id: 37,
    local_list: [],
  });
  useEffect(() => {
    getData();
  }, [props.start_day, props.end_day, state.local_id, props.routes]);

  async function getData() {
    try {
      const data = await api.get(
        `sla-saturation-flow?start_at=${props.start_day}&end_at=${props.end_day}&local=${state.local_id}&routes=[${props.routes}]`
      );

      const data2 = await api.get(`place?perPage=1000`);
      let local = data2.data.data.filter((item) => {
        return item.id == state.local_id;
      });
      let saturation = data.data.data.data || [];
      let legends = [];
      let series = [
        {
          name: "Saturação por fluxo",
          data: [],
        },
      ];
      saturation.forEach((item, index) => {
        legends.push(local[0]?.name + " - " + item.place);
        series[0].data.push(parseFloat(item.avg));
      });

      setState((prev) => ({
        ...prev,
        series: series,
        legends: legends,
        local_list: data2.data.data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="center" alignItems="center">
          <Grid item lg={10} sm={10} md={10} xs={10}>
            <Typography color="textSecondary" gutterBottom>
              % Saturação por Fluxo
            </Typography>
          </Grid>

          <Grid item lg={2} sm={2} md={2} xs={2}>
            <CustomSelect
              id="component"
              label="Local"
              outlined="outlined"
              value={state.local_id}
              name="local_id"
              data={state.local_list}
              onChange={(event) => handleChange(event)}
              fullWidth
            />
          </Grid>
        </Grid>

        <GraphCard series={state.series} legends={state.legends} />
      </CardContent>
    </Card>
  );
}

export default SaturationFlow;
