import React, {useState, useEffect} from 'react'
import Main from '../../components/Main'
import MaterialTable, { MTableToolbar } from "material-table";
import api from '../../../../core/services/api'
import fileDownload from 'js-file-download'
import moment from 'moment';
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";
import CustomSelect from '../../components/CustomSelect'

//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// pick a date util library
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";

//material-ui
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';

function Report(){
    const [state, setState] = useState({
      start_day: moment().subtract(7, "days").day(0).format("YYYY-MM-DD"),
      end_day:  moment().subtract(7, "days").day(6).format("YYYY-MM-DD"),
      active_day: moment().format("YYYY-MM-DD"),
      route_id: 50,
      routes_list: [],
    })
    useEffect(() => {
      getData();
    }, []);

    const handleDate = (date) => {
      setState((prev) => ({
        ...prev,
        start_day: moment(date).day(0).format("YYYY-MM-DD"),
        end_day: moment(date).day(6).format("YYYY-MM-DD"),
        active_day: moment(date).format("YYYY-MM-DD")
      }));
  };
  
    async function getData() {
      try {
        const data = await api.get(`routes`);
        setState((prev) => ({
          ...prev,
          routes_list: data.data || []
        }))
      } catch (error) {
        console.warn(error)
      }
    }
    const handleChange = (event) => {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;
      setState((prev) => ({
        ...prev,
        [name]: value,
      }));
    };


    async function handleSubmit(){
      console.log('Baixando relatório')
      try {
        let url = `/report?start_at=${state.start_day}&end_at=${state.end_day}&route_id=${state.route_id}`;
        // url += state.check_shift ? "&check_shift=" + state.check_shift : "";
        api.get(url).then(blobFile => {
            //fileDownload(blobFile.data.data, 'teste.xlsx')
             const url = window.URL.createObjectURL(new Blob([blobFile.data.data]));
            const link = document.createElement('a');
            //converter base64 pra xlsx
            //passar xlsx pro href

            link.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+blobFile.data.data;
            link.setAttribute('download', `report-download.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);  

           /*  var a = document.createElement("a"); //Create <a>
            a.href = blobFile.data.data; //Image Base64 Goes here
            a.download = "report-download.xlsx"; //File name Here
            a.click(); //Downloaded file */
        })
      } catch (err) {
        console.warn(err);
      }
    }

    return(
        <Main color="#f7f7f7" title="Relatório">
          <Grid container direction="column">
            <Grid item sm={3} md={3} lg={3} xs={3}>
            <div style={{ marginTop: 10, marginBottom:  0}}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
                <ThemeProvider theme={fcaTheme}>
                  <KeyboardDatePicker
                    label="Filtro de Data"
                    disableFuture={true}
                    inputVariant="outlined"
                    disableToolbar
                    clearable="true"
                    autoOk={true}
                    style={{ paddingBottom: 12 }}
                    format="dd/MM/yyyy"
                    variant="inline"
                    value={moment(state.active_day, "YYYY-MM-DD")}
                    onChange={handleDate}
                    fullWidth
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </div>
            </Grid>
            <Grid item sm={3} md={3} lg={3} xs={3}>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
              <CustomSelect
                id="component"
                label="Rota"
                outlined="outlined"
                value={state.route_id}
                name="route_id"
                data={state.routes_list}
                onChange={(event) => handleChange(event)}
                fullWidth
              />
              </div>
            </Grid>
            <Grid item sm={3} md={3} lg={3} xs={3}>
              <Button fullWidth variant="contained" color="primary" onClick={handleSubmit}>
              Baixar Relatório
              </Button>
            </Grid>
          </Grid>
          
        </Main>
    )
}

export default Report;
