import React, {useState, useEffect} from 'react'
import moment from 'moment'
import Chart from "react-apexcharts";
import options from "./options";

function GraphCard(props){
    const [state, setState] = useState({          
        series: props.cube_shift,
        legends: [],
        target: [],
        series: [{
            name: 'Income',
            type: 'column',
            data: [1.4, 2]
          }, {
            name: 'Cashflow',
            type: 'column',
            data: [1.1, 3]
          },
          {
            name: 'Cashflow 2',
            type: 'column',
            data: [1.1, 3]
          }, 
          {
            name: 'Revenue',
            type: 'line',
            data: [20, 29]
          }],
    });

    return(
        <Chart
              options={options(props.legends)}
              series={props.cube_shift}
              type="bar"
              height={350}
            />
    )
}

export default GraphCard;