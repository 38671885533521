import React, { useEffect, useState } from "react";
import api from '../../../../../core/services/api'


import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Grid from '@material-ui/core/Grid'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import SearchIcon from '@material-ui/icons/Search'
import Checkbox from '@material-ui/core/Checkbox'

import { useSnackbar } from 'notistack';


function CreateForm(props) {
  const { enqueueSnackbar } = useSnackbar()
  const [state, setState] = useState({
    error: false,
    disabled: false,
    active: true,
    edit: null,
    driver_status: null,
  });

  const [name, setName] = useState("");
  const [matricula, setMatricula] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
   
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setState({
      disabled: false,
      disabled_field: true,
      check: false,
      error: false,
      showPassword: '',
      edit: false,
    })
    setName("");
    setMatricula("");
    setPassword("");
    setPassword2("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = (status) => {
    setState((prev) => ({
      ...prev,
      disabled: true
    }))

    if(status == 'create') {
      if(password != password2){
        setState((prev) => ({
          ...prev,
          disabled: false,
          error: true
        }))
      } else if (password === '' && password2 === '' && name === '' && matricula === ''){
        setState((prev) => ({
          ...prev,
          disabled: false,
          error: true
        }))
      } else {
      postDriver();
      setOpen(false)
      }
    } else if (status == 'active'){
      activeDriver();
      setOpen(false);
    } else {
      if(state.check){
        //SE ATIVAR CHECKBOX DE MUDANÇA DE SENHA
        if(password != password2){
          setState((prev) => ({
            ...prev,
            disabled: false,
            error: true
          }))
        } else if (password === '' && password2 === '' && name === ''){
          setState((prev) => ({
            ...prev,
            disabled: false,
            error: true
          }))
        } else {
        postDriver(state.check);
        setOpen(false)
        }
      } else {
        //SEM TROCA DE SENHA
        if (name === ''){
          setState((prev) => ({
            ...prev,
            disabled: false,
            error: true
          }))
        } else {
        updateDriver(state.check);
        setOpen(false)
        }
      } 
    }
    
    
    
  }

  async function updateDriver(check){
    try {
      if(check){
        const driver = {
          name: name,
          email: "",
          password: password,
          matricula: matricula,
          resource: {
            cpf: "",
            cnh: "",
            active: true,
          }
        }
        await api.put(`drivers/`+state.driver_select.id,driver);
        props.getData();
      } else {
        const driver = {
          name: name,
          email: "",
          matricula: matricula,
          resource: {
            cpf: "",
            cnh: "",
            active: true,
          },
          carrier_id: state.carrier_id,
        }
        await api.put(`drivers/`+state.driver_select.id,driver);
        props.getData();
      }
    } catch (error) {
      console.warn(error)  
    }
  }

  async function activeDriver(){
    try {
        await api.put(`driver-active/`+state.driver_select.driver_client_id);
        props.getData(); 
    } catch (error) {
      console.warn(error)  
    }
  }

  async function postDriver(){
    try {
    const driver = {
      name: name,
      email: "",
      password: password,
      matricula: matricula,
      resource: {
        cpf: "",
        cnh: "",
        active: true,
      },
      carrier_id: state.carrier_id || "",
    }
      
      console.log('criando motorista: ', driver)
      await api.post(`drivers`, driver);
      props.getData();
    } catch (error) {
      console.warn(error)
    }    
  }
  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  async function handleSearchMatricula(matricula){
    try {
      const data = await api.get(`driver-search?matricula=${matricula}`);
      if(data.data.data[0]){
        let driver = data.data.data[0]
        setName(driver.name)
        setMatricula(driver.matricula)
        if(!driver.status){
          enqueueSnackbar('Motorista encontrado está inativo!',{variant: 'warning'})
          setState((prev) => ({...prev, driver_status: true, edit: false, disabled_field: true}))
        } else {
          enqueueSnackbar('Motorista encontrado!',{variant: 'success'})
          setState((prev) => ({...prev, disabled_field: false, driver_status: null, edit: true}))
        }
        
      } else {
        enqueueSnackbar('Nenhum motorista em uso dessa matrícula!',{variant: 'info'})
        setState((prev) => ({...prev, disabled_field: false, edit: false, driver_status: null}))
        setName("")
      }
      setState((prev) => ({
        ...prev,
        driver_select: data.data.data[0] || []
      }));
    } catch (error) {
      console.warn(error);
    }
    
  }

  return (
    <div>
      <AddIcon onClick={handleClickOpen}  />
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm" >
        <DialogTitle>Cadastrar novo Motorista</DialogTitle>
        <DialogContent>
             <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                  id="matricula"
                  name="matricula"
                  label="Matrícula"
                  type="text"
                  value={matricula}
                  onChange={(e) => setMatricula(e.target.value)}
                  fullWidth
                  InputProps={{
                    endAdornment:
                   
                      <InputAdornment position="end">
                      <IconButton aria-label="a" onClick={() => handleSearchMatricula(matricula)} edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                    
                  }}
                  helperText="Verifique se a matrícula não está em uso"
                />{" "}
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                  disabled={state.disabled_field}
                  id="name"
                  name="name"
                  label="Nome do Motorista"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                />{" "}
              </div>
              
              <div style={{ marginTop: 12, marginBottom: 12, display: state.driver_status ? 'none' : (state.edit ? (state.check ? 'block' : 'none') : 'block') }}>
             <FormControl fullWidth>
              <InputLabel>Senha</InputLabel>
              <Input
                  disabled={state.disabled_field}
                  error={state.error ? true : false}
                  fullWidth
                  id="password"
                  name="password"
                  label="Senha"
                  type={state.showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                       
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                /></FormControl>
              </div>
              <div style={{ marginTop: 12, marginBottom: 12, display: state.driver_status ? 'none' : (state.edit ? (state.check ? 'block' : 'none') : 'block')}} >
              <FormControl fullWidth>
                <InputLabel>Confirmar senha</InputLabel>
                <Input
                  disabled={state.disabled_field}
                  error={state.error ? true : false}
                  fullWidth
                  id="password2"
                  name="password2"
                  label="Confirme sua Senha"
                  type={state.showPassword ? 'text' : 'password'}
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />{" "}</FormControl>
              </div>
              
              <div style={{ marginTop: 12, marginBottom: 12, display: state.edit ? 'block' : 'none' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.check}
                    onChange={handleChange}
                    name="check"
                    color="primary"
                  />
                }
                label="Alterar senha"
              /> 
                </div>
             
        </DialogContent>
        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          {state.driver_status ? (
            <Button
            disabled={state.disabled}
            onClick={() => handleSubmit('active')}
            variant="contained"
            style={{ backgroundColor:state.disabled ? "" : "#5ed237", color:state.disabled ? "" : "#fff" }}>
            Ativar Motorista
          </Button>
          ) : (
            <Button
              disabled={state.disabled}
              onClick={() => state.edit ? handleSubmit('edit') : handleSubmit('create')}
              variant="contained"
              style={{ backgroundColor:state.disabled ? "" : "#5ed237", color:state.disabled ? "" : "#fff" }}>
              {state.edit ? 'Atualizar' : 'Salvar'}
            </Button>
          )}
          
          
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateForm;
