import React, {useState} from 'react';
import {useStyles} from './styles'

//@material-ui
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import StopIcon from '@material-ui/icons/Stop'

//custom components
import Table from './Table'


function PresenteeismTurn(props){
    const classes = useStyles();
    const [state, setState] = useState({
        presenteeism: props.presenteeism
    })
    return (
        <Card className={classes.root}>
        <CardContent>
        <Typography>{props.title}</Typography>
        <Table presenteeism={props.presenteeism} setup_time={props.setup_time} end={props.end} />
        </CardContent>
            <CardActions></CardActions>
        </Card>
    )
}

export default PresenteeismTurn;

