import React, { useState, useEffect } from "react";
import MaterialTable, { MTableToolbar } from 'material-table'

//custom components
import Main from "../../components/Main";
import api from "../../../../core/services/api";
import CreateForm from "./CreateForm";
import EditForm from "./EditForm";

//material-ui
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import DeleteIcon from "@material-ui/icons/Delete";
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton";

function GroupsPage() {
  const [state, setState] = React.useState({
    groups: [],
    menu_pages: [],
  });
  useEffect(() => {
    getPages();
    getData();
  }, []);


  async function getData() {
    try {
      const data = await api.get(`groups`);
      setState((prev) => ({
        ...prev,
        groups: data.data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }
  async function getPages(){
      try {
          const data = await api.get(`pages`);
          let filteredPages = data.data.data.filter((item) => {return (item.resource.icon || item.resource.route.includes('report/')) && item.resource.route !== 'report'})
          let hiddenPages = data.data.data.filter((item) => {return !item.resource.icon})
          console.log('Filtered pages: ', filteredPages)
          console.log('Hidden pages: ', hiddenPages)
          setState((prev) => ({
              ...prev,
              menu_pages: filteredPages || []
          }))
      } catch (error) {
          console.warn(error)
      }
  }
  async function deleteGroup(item) {
    try {
      if (
        window.confirm(
          "Tem certeza que deseja deletar a função " + item.name
        )
      ) {
        await api.delete(`groups/` + item.id);
      } else {
      }

      getData();
    } catch (error) {
      console.warn(error);
    }
  }

  return (
    <Main title="Funções">
      <Grid
        container
        justify="flex-end"
        spacing={3}
        style={{ paddingBottom: 12 }}
      >
        <Grid item>
          <CreateForm
            //datas
           menu_pages={state.menu_pages ? state.menu_pages : []}
           getData={getData}
        /> 
        </Grid>
      </Grid>
       <MaterialTable
      title="Simple Action Preview"
      columns={[
        { title: 'Grupo',
          field: 'name',
          cellStyle: {paddingLeft: 24, textAlign: "left" },
          headerStyle: {borderBottom: '#fff',  borderRadius: '8px 0px 0px 0px'}
        },
       {
          field: "actions",
          title: "Ações",
          cellStyle: {  paddingBottom: 0, paddingTop: 0, textAlign: "right" },
          headerStyle: {paddingRight: 20, textAlign: "right", borderRadius: '0px 8px 0px 0px'},
          render: (rowData) => (
            <>
              <FormGroup row style={{justifyContent: 'flex-end'}}>
                <EditForm
                  oldGroup={rowData}
                  //datas
                  menu_pages={state.menu_pages ? state.menu_pages : []}
                  getData={getData}
               /> 
                <IconButton
                  color="secondary"
                  onClick={() => deleteGroup(rowData)}
                >
                  <DeleteIcon />
                </IconButton>{" "}
              </FormGroup>
            </>
          ),
        },
      ]}
      data={state.groups.data}        
      options={{
        pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
          },
          rowStyle: (x) => {
            if (x.tableData.id === 0){
              return { borderTop: '2px solid rgb(212, 212, 212)'}
            }
          },
        }}
      components={{
        Toolbar: (props) => (
          <div style={{ display: "none" }}>
            <MTableToolbar {...props} />
          </div>
        ),
      }}
      style={{
        borderRadius: 10,
      }}
      localization={{
        body: {
            emptyDataSourceMessage: 'Carregando dados'
        }
    }}
    />
      
    </Main>
  );
}

export default GroupsPage;
