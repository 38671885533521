import React, { useState, useEffect } from 'react'
import Main from '../../../components/Main'
import MaterialTable, { MTableToolbar } from "material-table";
import api from '../../../../../core/services/api'
import fileDownload from 'js-file-download'
import moment from 'moment';
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";
import CustomSelectCheckbox from '../../../components/CustomSelectCheckbox'
import FilterReport from '../../Report/FilterReport'
import TableReport from '../../Report/TableReport'

//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// pick a date util library
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";

//material-ui
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Grid from '@material-ui/core/Grid'
import { Button, CircularProgress} from '@material-ui/core';

function EndShiftReports() {



  const [state, setState] = useState({
    start_day: moment().format("YYYY-MM-DD"),
    shifts: [],
    report: [],
    justifications: [],
    fences: [],
    shift1: false,
    shift2: false,
    shift3: false,
  })
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, [])

  const [route_list, setRoutelist] = useState([]);
  const [shift_list, setShiftlist] = useState([])
  const handleChangeCustom = (event) => {
    if (event.target.name == "route") {
      setRoutelist(event.target.value);
    }
    if (event.target.name == "shift") {
      setShiftlist(event.target.value);
    }

  };
  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    if (event.target.name == "route") {
      setRoutelist(value);
    }
    if (event.target.name == "shift") {
      setShiftlist(value);
    }
  };

  async function getData() {
    try {
      const data = await api.get(`routes`);
      const data2 = await api.get(`shifts?active=all`);
      const data3 = await api.get(`justification?&perPage=1000`);
      const data4 = await api.get(`occurrence`);
      const data5 = await api.get(`place?perPage=1000`);
      setState((prev) => ({
        ...prev,
        routes: data.data || [],
        shifts: data2.data.data || [],
        justifications: data3.data.data || [],
        issues: data4.data || [],
        fences: data5.data.data || [],
      }))
    } catch (error) {
      console.warn(error)
    }
  }

  const handleStartDate = (date) => {
    setState((prev) => ({
      ...prev,
      start_day: moment(date).format("YYYY-MM-DD"),
    }));
  };

  const handleEndDate = (date) => {
    setState((prev) => ({
      ...prev,
      end_day: moment(date).format("YYYY-MM-DD"),
    }));
  };

  function checkJustifyOTD(id) {
    const justify = state.justifications.filter((item) => { return item.id == id })
    if (justify[0]) {
      if (justify[0].type == "otd") {
        return justify[0].description
      }
    } else {
      return "-"
    }
  }

  function checkJustify(id) {
    const justify = state.justifications.filter((item) => { return item.id == id })
    if (justify[0]) {
      if (justify[0].type == "dock_time") {
        return justify[0].description
      }
    } else {
      return "-"
    }
  }

  function checkIssue(type, id) {
    const issue = state.issues.filter((item) => { return item.id == type })
    if (issue[0]) {
      const issue_name = issue[0].occurrence.filter((item2) => { return item2.id == id })
      if (issue_name[0]) {
        return issue_name[0].description
      } else { return "-" }
    } else { return "-" }
  }

  function checkFence(id) {
    const fence = state.fences.filter((item) => { return item.id == id })
    if (fence[0]) {
      return fence[0].name
    } else { return "-" }
  }

  function checkPrevision(start, end, id) {
    if (id) {
      if (start != "" && end != "") {
        return moment(start, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss") + " - " + moment(end, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")
      } else {
        return "-"
      }
    } else { return "-" }

  }

  function checkPrevisionTransit(start, end, id) {
    if (id) {
      if (start != "" && end != "") {
        return moment(start, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss") + " - " + moment(start, "YYYY-MM-DD HH:mm:ss").add(end, 'minutes').format("HH:mm:ss")
      } else {
        return "-"
      }
    } else { return "-" }

  }

  function checkShift(id) {
    const shift = state.shifts.filter((item) => { return item.id == id })
    if (shift[0]) {
      return shift[0].resource.work_shift
    } else {
      return ""
    }
  }


  async function handleSubmitReport() {
    let routes = []
    let shifts = []
    state.routes.map((item) => {
      route_list.forEach((route) => {
        if (route == item.name) {
          routes.unshift(item.id)
        }
      })
    })
    state.shifts.map((item) => {
      shift_list.forEach((shift) => {
        if (shift == item.resource.work_shift) {
          shifts.unshift(item.id)
        }
      })
    })
    const obj = {
      start_at: state.start_day,
      end_at: state.end_day,
      routes: routes,
      shifts: shifts
    }
    console.log('Gerando relatório')
    try {
      setLoading(true)
      let url = `/endshift-report-view?day_time=${state.start_day}&shifts=[${shifts}]`;
      const { data: { data : reports } } = await api.get(url);
      const reportRows = [];

      reports.forEach((item) => {
        console.log({item})
        reportRows.push({
            shift: item[0],
            fleet: item[1],
            route: item[2],
            driver: item[3],
            product: item[4],
            saturation: item[5],
            local:item[6]
        })
      })

      setState((prev) => ({
        ...prev,
        report: reportRows || []
      }));
    } catch (err) {
      console.warn(err);
    }
    finally{
      setLoading(false)
    }
  }

  const columns = [
    {
      title: "Turno",
      field: "shift",
      cellStyle: { paddingLeft: 24, textAlign: "left" },
      headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
    },
    {
      title: "Frota",
      field: "fleet",
      cellStyle: { paddingLeft: 24, textAlign: "left" },
      headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
    },
    {
      title: "Rota",
      field: "route",
      cellStyle: { paddingLeft: 24, textAlign: "left" },
      headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
    },
    {
      title: "Motorista",
      field: "driver",
      cellStyle: { paddingLeft: 24, textAlign: "left" },
      headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
    },
    {
      title: "Produto",
      field: "product",
      cellStyle: { paddingLeft: 24, textAlign: "left" },
      headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
    },
    {
      title: "Saturação",
      field: "saturation",
      cellStyle: { paddingLeft: 24, textAlign: "left" },
      headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
    },
    {
      title: "Local",
      field: "local",
      cellStyle: { paddingLeft: 24, textAlign: "left" },
      headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
    }
  ]

  return (
    <Main color="#f7f7f7" title="Fim de turno">
      <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2} style={{ height: 120 }}>
        <Grid item sm={4} md={2} lg={2} xs={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
            <ThemeProvider theme={fcaTheme}>
              <KeyboardDatePicker
                label="Data"
                disableFuture={true}
                inputVariant="outlined"
                disableToolbar
                clearable="true"
                autoOk={true}
                format="dd/MM/yyyy"
                variant="inline"
                value={moment(state.start_day, "YYYY-MM-DD")}
                onChange={handleStartDate}
                fullWidth
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item sm={2} md={2} lg={2} xs={2}>
          <CustomSelectCheckbox
            value={shift_list}
            onChange={handleChangeCustom}
            data={state.shifts}
            list={shift_list}
            label="Turnos"
            name="shift"
          />
        </Grid>
        <Grid item sm={2} md={2} lg={2} xs={2}>
          <Button style={{ padding: 14, backgroundColor: '#5ad545', color: '#fff' }} size="large" variant="contained" color="primary" onClick={handleSubmitReport} >
          {loading && <CircularProgress size={16} />}
          {!loading && 'Gerar Relatório'}
          </Button>
        </Grid>
      </Grid>
      <TableReport
        report={state.report}
        columns={columns} />
    </Main>
  )
}

export default EndShiftReports;
