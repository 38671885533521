import moment from 'moment'
export default function(target, lesstarget, biggertarget) {
    return {
        colors: ["#2de0c2", "#26a0fc"],
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
        }
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'left',
        },

        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return value.toFixed(1)+"%";
          }
        },
        stroke: {
          curve: 'straight',
          width: 2
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories:["-"+lesstarget, target-1, target , target+1, biggertarget+"+"],
          
        },
        yaxis: [{   
          labels: {  
            show: true,
            formatter: function (value) {
              return value.toFixed(1)+"%";
            }
          },
        }
      ],
      }
  }