import React, { useState, useEffect } from "react";
import moment from "moment";
import Chart from "react-apexcharts";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "moment/locale/pt-br";
import api from "../../../../../../core/services/api";

//@material-ui
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import Grid from "@material-ui/core/Grid";

//custom components
import options from "./options";
import { toraTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";

function Grafico(props) {
  const [open, setOpen] = useState(false);
  const [data, setState] = useState({
    endDate: moment(props.date).format("YYYY-MM-DD"),
    startDate: moment(props.date).subtract(5, "d").format("YYYY-MM-DD"),
    cycles: [],
    values: [],
    cols: [],
    loading: true,
  });

  const handleClickOpen = () => {
    setOpen(true);
    getData();
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleStartDate = (date) => {
    setState((prev) => ({
      ...prev,
      startDate: moment(date).format("YYYY-MM-DD"),
    }));
  };
  const handleEndDate = (date) => {
    setState((prev) => ({
      ...prev,
      endDate: moment(date).format("YYYY-MM-DD"),
    }));
  };

  async function getData() {
    // const {data} = await api.get('/forklift-idleness?start=2020-04-01&end=2020-04-06&forklift_number=382&operation_place_id=1')
    let url = `https://flex.trackage.io/api/v1/tora_cycles_grafico?ini_date=${data.startDate}&end_date=${data.endDate}&id=${props.id}`;
    const result = await api.get(url);
    const apiCols = [];
    const apiValues = [];

    result.data.map(({ localdate, cycles, created_at }) => {
      apiCols.push(moment.utc(created_at).format("DD MMM YYYY"));
      if (cycles != null) {
        apiValues.push(cycles);
      } else {
        apiValues.push(0);
      }
    });

    setState((prev) => ({
      ...prev,
      cols: apiCols,
      values: apiValues,
      loading: false,
    }));
  }

  // Main UseEffect
  useEffect(() => {
    if (open) {
      getData();
    } else {
    }
  }, [data.startDate, data.endDate, open]);

  return (
    <div>
      <Button startIcon={<InsertChartIcon />} onClick={handleClickOpen} />
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid container direction="row">
          <Grid item lg={8}>
            <DialogTitle id="alert-dialog-title">
              Veículo de placa: {props.board}
            </DialogTitle>
          </Grid>
          <Grid item lg={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <ThemeProvider theme={toraTheme}>
                {" "}
                <KeyboardDatePicker
                  style={{ margin: 12 }}
                  autoOk={true}
                  disableToolbar
                  disableFuture={true}
                  variant="inline"
                  format="dd/MM/yyyy"
                  label="Data Inicial"
                  value={moment(data.startDate, "YYYY-MM-DD")}
                  onChange={handleStartDate}
                  KeyboardButtonProps={{
                    "aria-label": "Data Inicial",
                  }}
                />{" "}
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item lg={2}>
            {" "}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <ThemeProvider theme={toraTheme}>
                <KeyboardDatePicker
                  style={{ margin: 12 }}
                  autoOk={true}
                  disableFuture={true}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  label="Data Final"
                  value={moment(data.endDate, "YYYY-MM-DD")}
                  onChange={handleEndDate}
                  KeyboardButtonProps={{
                    "aria-label": "Data Inicial",
                  }}
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        <DialogContent>
          <Chart
            options={options(data.cols, 6)}
            series={[
              {
                name: "Ciclos",
                data: data.values,
              },
            ]}
            type="bar"
            width="1200"
            height="500"
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}

export default Grafico;
