



import moment from 'moment'
export default function(cols, goals_time){
  
    return {
      colors: ['inherit']
    ,
      chart: {
        height: 350,
        type: "bar",
      },
      
      plotOptions: {
        bar: {
          columnWidth: '80%',
          rangeBarOverlap: true,
          colors: {
            ranges: [{
                from: 0,
                to: goals_time,
                color: '#1e824c'
            },
            {
              from: goals_time,
              to: 500,
              color: '#ec273b'
            }
          ],
            backgroundBarColors: [],
            backgroundBarOpacity: 1,
            backgroundBarRadius: 0,
        },
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          if(val <= 60){
            return moment.utc().startOf('day').add({ minutes: val }).format('H:mm')+"m";
          } else {
            return moment.utc().startOf('day').add({ minutes: val }).format('H:mm')+"h";
          }
          
        
        },
       
        offsetY: -25,
        style: {
          fontSize: "12px",
          colors: ["#304758"],
        },
      },
      xaxis: {
        categories: cols,
        position: "bottom",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: false
              
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          formatter: function (val) {
            if(val <= 60){
              return moment.utc().startOf('day').add({ minutes: val }).format('H:mm')+"m";
            } else {
              return moment.utc().startOf('day').add({ minutes: val }).format('H:mm')+"h";
            }
            
          
          },
          
        },
      },
    }
     
}