import React, { useState, useEffect } from "react";
import Main from "../../components/Main";
import api from "../../../../core/services/api";
import moment from "moment";
import { Link } from "react-router-dom";
//@material-ui
import { Container } from "./styles";
import Grid from "@material-ui/core/Grid";

//custom components
import CiclosCompletos from "./CiclosCompletos";
import TempoMedioCiclos from "./TempoMedioCiclos";
import TempoMedioCarga from "./TempoMedioCarga";
import TempoMedioDescarga from "./TempoMedioDescarga";
import Veiculos from "./Veiculos";
import Carregamentos from "./Carregamentos";
import Descarregamentos from "./Descarregamentos";
import { toraTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";

//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// pick a date util library
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";

export default (params) => {
  const [state, setState] = useState({
    loading: true,
    date: moment(Date.now()).format("YYYY-MM-DD"),
  });
  const [display, setDisplay] = useState("block");
  useEffect(
    () => {
      getTotalciclos();
      getTempomediociclo();
      getTempocarregamento();
      getTempodescarregamento();
      getCarregamentos();
      getVeiculos();
      const interval = setInterval(() => {
        getTotalciclos();
        getTempomediociclo();
        getTempocarregamento();
        getTempodescarregamento();
        getCarregamentos();
        getVeiculos();
      }, 300000);
      return () => clearInterval(interval);
    },
    [state.date]
  );
  const handleDate = (date) => {
    if (
      moment(date).format("YYYY-MM-DD") ===
      moment(Date.now()).format("YYYY-MM-DD")
    ) {
      setDisplay("block");
      setState((prev) => ({
        ...prev,
        date: moment(date).format("YYYY-MM-DD"),
      }));
    } else {
      setDisplay("none");
      setState((prev) => ({
        ...prev,
        dashboard: null,
        date: moment(date).format("YYYY-MM-DD"),
      }));
    }
  };

  //time functions
  let sum = (a) => a.reduce((x, y) => x + y);
  let avg = (a) => {
    let aux = 0;
    aux = a.filter((x) => x !== 0 && x !== null).length;
    let soma = sum(a);
    let media = soma / aux;
    return getTimeFromMins(media);
  }
  let avg2 = (a) => {
    let aux = 0;
    aux = a.filter((x) => x !== '00:00:00' && x !== null).length;
    let soma = sum(a);
    let media = soma / aux;
    return moment(media, "mm").format("HH:mm")
  }
  function getTimeFromMins(mins) {
    // do not include the first validation check if you want, for example,
    // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)
    if (mins >= 24 * 60 || mins < 0) {
      throw new RangeError("Valid input should be greater than or equal to 0 and less than 1440.");
    }
    var h = mins / 60 | 0,
      m = mins % 60 | 0;
    return moment.utc().hours(h).minutes(m).format("hh:mm");
  }

  async function getTotalciclos() {
    try {

        const totalciclos = await api.get(`https://flex.trackage.io/api/v1/tora-total-cycles?date=${state.date}`);
       // localStorage.setItem('oldciclos', totalciclos.data.data);
        setState((prev) => ({
          ...prev,
          totalciclos: totalciclos.data,
          loading: false,
        }));
      
    } catch (error) {
      console.warn(error)
    }
  }
  async function getTempomediociclo() {
    try {

      // const oldtempociclo = localStorage.getItem('oldtempociclo');
      // if (oldtempociclo) {
      //   const tempomediociclo = await api.get(`https://flex.trackage.io/api/v1/tora-tempo-ciclos?date=${state.date}`);
      //   // const newtempociclo = avg(tempomediociclo.data.temposList.map((x) =>moment.duration(x, "HH:mm:ss.SSS").asMinutes()))
      //   // if(newtempociclo === "12:00"){
      //   //   newtempociclo = oldtempociclo;
      //   // }
      //   setState((prev) => ({
      //     ...prev,
      //     tempomediociclo: tempomediociclo.data,
      //     loading: false,
      //   }));
      //   localStorage.setItem('oldtempociclo', tempomediociclo);
      // } else {
      //   const tempomediociclo = await api.get(`https://flex.trackage.io/api/v1/tora-tempo-ciclos?date=${state.date}`);
      //   // const newtempociclo = avg(tempomediociclo.data.temposList.map((x) => moment.duration(x, "HH:mm:ss.SSS").asMinutes()))
      //   localStorage.setItem('oldtempociclo', tempomediociclo);
      //   setState((prev) => ({
      //     ...prev,
      //     tempomediociclo: tempomediociclo.data,
      //     loading: false,
      //   }));
      // }
      const tempomediociclo = await api.get(`https://flex.trackage.io/api/v1/tora-tempo-ciclos?date=${state.date}`);
      const newtempociclo = tempomediociclo;
      setState((prev) => ({
        ...prev,
        tempomediociclo: newtempociclo.data.data,
        loading: false,
      }));
    } catch (error) {
      console.warn(error)
    }
  }
  async function getTempocarregamento() {
    try {


        const tempocarregamento = await api.get(`https://flex.trackage.io/api/v1/tora-tempo-carregamento?date=${state.date}`);
        const newtempocarregamento = tempocarregamento.data.tempo_carregamento
        const newmediovarzea = tempocarregamento.data.tempo_varzea;
        const newmediomiguel2 = tempocarregamento.data.tempo_miguel2;
        
        setState((prev) => ({
          ...prev,
          tempocarregamento: newtempocarregamento,
          mediovarzea: newmediovarzea,
          mediomiguel2: newmediomiguel2,
          loading: false,
        }));
    } catch (error) {
      console.warn(error)
    }
  }
  async function getTempodescarregamento() {
    try {


        const tempodescarregamento = await api.get(`https://flex.trackage.io/api/v1/tora-tempo-descarregamento?date=${state.date}`);
        const newtempodescarregamento = tempodescarregamento.data.tempo_descarregamento;
        const newmedioscoff = tempodescarregamento.data.tempo_scoff;
        const newmediogerdau = tempodescarregamento.data.tempo_gerdau;
        const newmediomiguel1 = tempodescarregamento.data.tempo_miguel1;
        const newmediopatrag = tempodescarregamento.data.tempo_patrag;
        setState((prev) => ({
          ...prev,
          tempodescarregamento: newtempodescarregamento,
          medioscoff: newmedioscoff,
          mediogerdau: newmediogerdau,
          mediomiguel1: newmediomiguel1,
          mediopatrag: newmediopatrag,
          loading: false,
        }));
    } catch (error) {
      console.warn(error)
    }
  }
  async function getCarregamentos() {
    try {

        const carregamentos = await api.get(`https://flex.trackage.io/api/v1/tora-carregamentos?date=${state.date}`);
        setState((prev) => ({
          ...prev,
          carregamentos: carregamentos,
          loading: false,
        }));
    } catch (error) {
      console.warn(error)
    }
  }
  async function getVeiculos() {
    try {
      const veiculos = await api.get(`https://flex.trackage.io/api/v1/tora-total-veiculos?date=${state.date}`)
      setState((prev) => ({
        ...prev,
        veiculos: veiculos.data[0],
        loading: false,
      }));
    } catch (error) {
      console.warn(error)
    }
  }
  return (
    
    <Main>
      <Container>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
          <ThemeProvider theme={toraTheme}>
            <KeyboardDatePicker
              label="Filtro de Data"
              disableFuture={true}
              disableToolbar
              clearable="true"
              autoOk={true}
              style={{ paddingBottom: 12 }}
              format="dd/MM/yyyy"
              variant="inline"
              value={moment(state.date, "YYYY-MM-DD")}
              onChange={handleDate}
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>

        <Grid container spacing={3}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            {/* <Link to={`/indicadores`} style={{ textDecoration: "none" }}> */}
            <CiclosCompletos data={state.totalciclos} loading={state.loading} />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <TempoMedioCiclos data={state.tempomediociclo} />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            {/* <PesoCarregado /> */}
            <div style={{ display: display }}>
              <Veiculos data={state.veiculos} />
            </div>{" "}
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Link
              to={`tempo-medio-carga/${state.date}`}
              style={{ textDecoration: "none" }}
            >
              <TempoMedioCarga data={state.tempocarregamento} 
              varzea={state.mediovarzea} 
              miguel2={state.mediomiguel2} 
              loading={state.loading} />
            </Link>
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Link
              to={`/tempo-medio-descarga/${state.date}`}
              style={{ textDecoration: "none" }}
            >
              <TempoMedioDescarga
                data={state.tempodescarregamento}
                miguel1={state.mediomiguel1}
                gerdau={state.mediogerdau}
                scoff={state.medioscoff}
                patrag={state.mediopatrag}
                loading={state.loading}
              />
            </Link>
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Carregamentos data={state.carregamentos} />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Descarregamentos data={state.carregamentos} />
          </Grid>
        </Grid>
      </Container>
    </Main>
  );
};
