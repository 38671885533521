export default function (cols) {
  return {
    colors: ["#ffcd04", "#b2bec7"],
    chart: {
      type: "donut",
      width: "100%",
      height: 200,
    },
    labels: ["Tempo de trânsito", ""],
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return val.toFixed(1) + "%";
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      position: "bottom",
      //horizontalAlign: "left",
      markers: {
        fillColors: ["#ffcd04", "#FFF"],
      },
    },
  };
}
