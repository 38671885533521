import styled from "styled-components";

const white = "#fff";
const grey = "#e8ebef";
const softGrey = "#768191";
const deepGrey = "#1d2329";
const corTemperaturaTitle = "#939ca8";

export const Flex = styled.div`
  width: calc(100vw - 50px);
  height: 100vh;
  display: flex;
  justify-content: flex-end;
`;

export const MapContainer = styled.div`
  flex: 1;
  border-right: 1px solid #fff;
`;

export const Sidebar = styled.div`
  width: 334px;
  min-width: 334px;
  min-height: 500px;
  max-height: calc(100vh - 50px);
  margin-top: 50px;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const SidebarHeader = styled.div`
  width: 100%;
  height: 38px;
  background-color: #f3f5f8;
  padding: 8px;
`;

// export const Sidebar = styled.div`
//   width: 334px; // 100%, 334px
//   min-width: 334px;
//   min-height: 500px;
//   max-height: 93vh;
//   background: #fff;
//   display: flex;
//   margin-top: 50px;
//   flex: 1;
//   flex-direction: column;
//   overflow: auto;
// `;

// export const MapContainer = styled.div`
//   border-right: 1px solid #fff;
//   position: relative;
//   margin: 0;
//   top: 0;
//   bottom: 0;
// `;

// export const Flex = styled.div`
//   width: calc(100vw - 50px);
//   display: flex;
//   flex-direction: row;
// `;

// export const SidebarHeader = styled.div`
//   width: 100%;
//   height: 38px;
//   background-color: #f3f5f8;
//   padding: 8px;
// `;

// .container {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: inherit;
//   height: inherit;
// }

// .rec {
//   display: flex;
//   justify-content: space-around;
//   align-items: center;
//   width: inherit;
//   height: inherit;
//   padding: 0 3px;
//   border-radius: 4px;
// }

// .plate {
//   font-size: 0.6rem;
//   color: white;
// }

// .tip {
//   width: 0;
//   height: 0;
// }

export const Pin = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 80px;
  height: auto;
  padding-bottom: 2px;
  background: ${props => props.color} 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 9px;
  font-weight: bold;
  color: #fff;
  box-shadow: 0px 0px 2px #0000001F;
  cursor: hover;
  &:before {
    position: absolute;
    bottom: -4px;
    left: 34px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    content: "";
    border-top: 5px solid ${props => props.color};
  }
  a {
    color: #fff;
    font-size: 9px;
    text-decoration: none;
  }
`;

export const InfoContainer = styled.div`
  width: 342px;
  height: 217px;
  background-color: ${white};
  z-index: 999;
`;

export const TopInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 53px;
  background-color: ${white};
  padding: 0 20px;
`;

export const NomeMotorista = styled.h5`
  color: ${softGrey};
  font-size: 0.9rem;
  font-weight: 400;
  margin-top: 3px;
`;

export const TopInfoRight = styled.div`
  align-self: center;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
`;

export const Placa = styled.p`
  color: ${deepGrey};
  font-size: 0.8rem;
`;

export const TemperaturaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${grey};
  height: 38px;
  vertical-align: middle;
`;

export const TemperaturaTitle = styled.p`
  font-size: 0.6rem;
  color: ${corTemperaturaTitle};
  margin-right: 5px;
  margin-top: 16px;
  margin-left: 20px;
  align-self: center;
  vertical-align: middle;
`;

export const Badge = styled.div`
  width: 47px;
  height: 21px;
  background-color: #5ed237;
  border-radius: 13px;
  vertical-align: middle;
  margin-left: 5px;
  margin-right: 20px;
  align-self: center;
  font-size: 0.6rem;
  color: #fff;
  padding: 4px;
  text-align: center;
`;

export const Detalhes = styled.h5`
  color: #0091e6;
  font-size: 0.6rem;
  align-self: center;
  margin-top: 8px;
  margin-right: 20px;
  vertical-align: middle;
  cursor: pointer;
`;

export const Resumo = styled.p`
  margin: 8px 0 8px 20px;
  padding: 0;
  font-size: 0.75rem;
  color: #1d2329;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px 0 20px;
`;

export const InfoTitle = styled.h5`
  font-size: 0.65rem;
  color: #adb6c3;
`;

export const InfoSubtitle = styled.h5`
  font-size: 0.7rem;
  color: #768191;
`;

export const IconContainer = styled.div`
  margin-top: -10px;
  padding: 4px;
  width: 28px;
  height: 28px;
  border-radius: 30px;
  border: 2px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;