import React, { useState, useEffect } from "react";
import api from "../../../../core/services/api";
import { useHistory } from "react-router-dom";
import moment from 'moment';

//custom components
import Main from "../../components/Main";
import Task from "./Task";
import Alert from "../../components/Alert";

//material ui
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import LinkA from "@material-ui/core/Link";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'

//sortable Hoc
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

//custom componentes

const CssTextField = withStyles({
  root: {
    "& input.MuiInputBase-input": {
      color: "#fff",
    },
    "& label.MuiFormLabel-root": {
      color: "#fff",
    },
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& label": {
        color: "white",
      },
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
  },
})(TextField);

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#5ad545",
    border: "1px solid #fff",
    fontSize: 16,
    padding: 10,
    color: "#fff",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      color: "#fff",
      borderColor: "#fff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  SelectCustom: {
    "& .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiInputLabel-root": {
      color: "white",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "white",
    },
    "&:hover .MuiInputLabel-root": {
      color: "white",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "white",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "white",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
  },
  formControl: {
    //margin: theme.spacing(1),
    "& .MuiSelect-outlined": {
      backgroundColor: "#5ad545",
      color: "#fff",
      borderColor: "#fff",
    },
  },
  exampleWrapper: {
    position: "relative",
    marginTop: theme.spacing(3),
    height: 380,
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  select: {
    "& .MuiSelect-root": {
      borderColor: "#fff",
    },
    "&:before": {
      borderColor: "#fff",
    },
    "&:after": {
      borderColor: "#fff",
    },
  },
}));

function ManagementRoutes(props) {
  const history = useHistory();
  const classes = useStyles();
  //lista de Tasks
  const [state, setState] = useState({
    edit: false,
    disabled: false,
    tasks: [],
    tasks_fixed: [],
    items: [],
    shifts: [],
    typeactivities: [],
    locations: [],
    route_period: 1,
    shifts_list: [],
    shifts_id: 10,
  });
  const [direction, setDirection] = React.useState("up");
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = useState(false);
  //const [hidden, setHidden] = React.useState(false);
  //variaveis de rota
  const [route_name, setRoutename] = useState("");
  const [route_number, setRoutenumber] = useState("");
  const [route_transit, setRoutetransit] = useState("");
  const [route_period, setRouteperiod] = useState("");
  const [route_description, setRoutedescription] = useState("");

  useEffect(() => {
    if (props.location.pathname.match(/(\d+)/) !== null) {
      getRoute();
    } else {
      console.log("Sem rota");
    }
    getShifts();
    getActivities();
    getLocations();
  }, [state.shifts_id]);

  useEffect(() => {
    getShifts();
  }, [state.route_period]);

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  async function getRoute() {
    try {
      const id = parseInt(props.location.pathname.match(/(\d+)/)[0]);
      const route = await api.get(`routes/${id}`);
      let tasks = route.data.tasks
      /* if(state.shifts_id){
        tasks = route.data.tasks.filter((item) => {return item.shift == state.shifts_id})
      } */
      setState((prev) => ({
        ...prev,
        route: route.data || {},
        tasks: tasks || [],
        tasks_fixed: tasks || [],
        edit: true,
        route_period: parseInt(route.data.period),
      }));
      console.log("Edit route: ", route);
      setRoutename(route.data.name);
      setRoutetransit(route.data.transit);
      setRouteperiod(parseInt(route.data.period));
      setRoutedescription(route.data.description);
    } catch (error) {
      console.warn(error);
    }
  }

  async function getShifts() {
    try {
      const data = await api.get(`shifts`);
      const sunday_shifts = data.data.data.filter((item) => {
        return item.resource.week_days[0] === 0;
      });
      const week_shifts = data.data.data.filter((item) => {
        return item.resource.week_days[0] !== 0;
      });
      if (state.route_period === 2) {
        setState((prev) => ({
          ...prev,
          shifts: sunday_shifts || [],
        }));
      } else {
        setState((prev) => ({
          ...prev,
          shifts: week_shifts || [],
        }));
      }
    } catch (error) {
      console.warn(error);
    }
  }
  async function getActivities() {
    try {
      const data = await api.get(`type_activity`);
      setState((prev) => ({
        ...prev,
        typeactivities: data.data.data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }
  async function getLocations() {
    try {
      const data = await api.get(`place?perPage=1000`);
      setState((prev) => ({
        ...prev,
        locations: data.data.data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }


  function onSortEnd({ oldIndex, newIndex }) {

    let list = arrayMove(state.tasks, oldIndex, newIndex)
    let times = []
    let times2 = []
    state.tasks_fixed.forEach((item) => {
      times.push(item.start_prevision)
      times2.push(item.end_prevision)
    })
     list.forEach((item, index) => {
      
      if(index != 0){
      if(newIndex > oldIndex){
        if(index >= oldIndex && index <= newIndex){
          if(!isNaN(list[index-1].transit_time) && list[index-1].transit_time > 0){
            item.start_prevision = moment(list[index-1].end_prevision, "HH:mm:ss").add(list[index-1].transit_time, 'minutes').format("HH:mm:ss");
            item.end_prevision =  moment.utc(moment.duration(item.start_prevision, "HH:mm:ss").add(moment.duration(item.target_time, "HH:mm:ss")).as('milliseconds')).format("HH:mm:ss");    
    
          } else { 
            item.start_prevision = list[index-1].end_prevision;
            item.end_prevision =  moment.utc(moment.duration(item.start_prevision, "HH:mm:ss").add(moment.duration(item.target_time, "HH:mm:ss")).as('milliseconds')).format("HH:mm:ss");    
          }
        }
      } else {
        if(index >= newIndex && index <= oldIndex){
          if(!isNaN(list[index-1].transit_time) && list[index-1].transit_time > 0){
            item.start_prevision = moment(list[index-1].end_prevision, "HH:mm:ss").add(list[index-1].transit_time, 'minutes').format("HH:mm:ss");
            item.end_prevision =  moment.utc(moment.duration(item.start_prevision, "HH:mm:ss").add(moment.duration(item.target_time, "HH:mm:ss")).as('milliseconds')).format("HH:mm:ss");    
          } else { 
            item.start_prevision = list[index-1].end_prevision;
            item.end_prevision =  moment.utc(moment.duration(item.start_prevision, "HH:mm:ss").add(moment.duration(item.target_time, "HH:mm:ss")).as('milliseconds')).format("HH:mm:ss");    
          }
       }
      }  
      }
      
      //console.log('Data: ', moment.duration(times[index], "HH:mm:ss"), 'Adicionando target: ', moment.duration(item.target_time, "HH:mm:ss"), 'Final: ', item.end_prevision)
      item.order = index;
    }); 
    console.log('Times: ', times)
    //const final_list = orderTimes(list, ordered_list)
    setState((prev) => ({
      ...prev,
      tasks: list,
      tasks_fixed: list,
    }));
  }

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = state.tasks;
    list.splice(index, 1);
    setState((prev) => ({
      ...prev,
      tasks: list,
    }));
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    const last = state.tasks[state.tasks.length - 1];
    let max = state.tasks.reduce(function (max, next) {
      return (max.id || 0) > next.id ? max : next;
    }, {});
    if (last) {
      setState((prev) => ({
        ...prev,
        tasks: [
          ...state.tasks,
          {
            last_time: last.end_prevision,
            id: max.id + 1,
            order: last.order + 1,
            title: "",
            start_prevision: "00:00:00",
            end_prevision: "00:00:00",
            target_time: null,
            local: last.destiny,
            destiny: null,
            shift: null,
            obs: "",
            use_saturation: true,
            activity_type: null,
          },
        ],
      }));
    } else {
      setState((prev) => ({
        ...prev,
        tasks: [
          {
            id: 1,
            order: 1,
            title: "",
            start_prevision: "00:00:00",
            end_prevision: "00:00:00",
            target_time: null,
            local: null,
            destiny: null,
            shift: null,
            obs: "",
            use_saturation: true,
            activity_type: null,
          },
        ],
      }));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };


  const [expand, setExpand] = useState(false);
  const handleExpand = (panel) => (event, isExpanded) => {
    setExpand(isExpanded ? panel : false);
  };

  const handleExpand2 = (panel) => {
    if(expand === 'allpanel'){
      setExpand(false);
    } else {
      setExpand(panel);
    }
  }

  const SortableItem = SortableElement(({ item, dex }) => {
    return (
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Task
          setRoutetransit={setRoutetransit}
          period={state.route_period}
          index={dex}
          item={item}
          setState={setState}
          tasks={state.tasks}
          shifts={state.shifts}
          locations={state.locations}
          typeactivities={state.typeactivities}
          handleRemoveClick={handleRemoveClick}
          handleExpand={handleExpand}
          expand={expand}
          panel={'panel'+dex}
        />
      </Grid>
    );
  });

  const SortableList = SortableContainer(({ tasks }) => {
    return (
      <Grid container spacing={2}>
        {tasks.map((item, index) => {
          return (
            <>
              <SortableItem
                key={`item-${index}`}
                index={index}
                item={item}
                dex={index}
              />
            </>
          );
        })}
      </Grid>
    );
  });



  async function handleSubmit() {
    setState((prev) => ({
      ...prev,
      disabled: true,
    }));

    state.tasks.forEach((item, index) => {
      item.start_prevision = item.start_prevision.slice(0, -2) + "00";
      item.end_prevision = item.end_prevision.slice(0, -2) + "00"
    })
    const routeobj = {
      name: route_name,
      route_number: route_number,
      transit: route_transit,
      period: state.route_period,
      description: route_description,
      tasks: state.tasks,
    };

    //VALIDAÇÃO DE TURNO VAZIO
    const shift_empty = state.tasks.filter((item) => {
      return item.shift == null;
    });
    if (shift_empty.length > 0) {
      setState((prev) => ({
        ...prev,
        message:
          "Campo obrigatório faltando na atividade " +
          shift_empty[0].title +
          " !",
        alert: "error",
        disabled: false,
      }));
      setOpen2(true);
    } else {
      if (state.edit) {
        try {
          await api.put(`routes/${state.route.id}`, routeobj);
        } catch (error) {
          console.warn(error);
        }
      } else {
        try {
          await api.post(`routes`, routeobj);
          //getData();
        } catch (error) {
          console.warn(error);
        }
      }
      console.log("Cadastrando rota: ", routeobj);
      history.push("/routes");
      history.go();
    }
    //console.log('Cadastrando rota: ',routeobj)
  }

  return (
    <Main color="#f7f7f7" title="Cadastro de Rotas">
      <Breadcrumbs
        aria-label="breadcrumb"
        style={{ paddingBottom: 30, marginTop: "-10px" }}
      >
        <LinkA
          color="inherit"
          href="/routes"
          style={{ textDecoration: "none" }}
        >
          Lista de rotas
        </LinkA>
        <Typography color="textPrimary">
          {route_name ? route_name : "Rota"}
        </Typography>
      </Breadcrumbs>
      <div
        style={{ zIndex: 9999, position: "fixed", bottom: "5%", right: "2%" }}
      >
        <SpeedDial
          ariaLabel="SpeedDial example"
          className={classes.speedDial}
          hidden={state.disabled}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          direction={direction}
        >
          <SpeedDialAction
            key="Adicionar Atividade"
            icon={<AddIcon />}
            tooltipTitle="Adicionar Atividade"
            onClick={() => handleAddClick()}
          />

          <SpeedDialAction
            key="Salvar"
            icon={<SaveIcon />}
            tooltipTitle="Salvar"
            onClick={() => handleSubmit()}
          />
        </SpeedDial>
      </div>
      {/*  <Grid container justify="flex-end" spacing={3} style={{ paddingBottom: 12 }}>
                <Grid item>
                <Tooltip title="Voltar" placement="left">
                <Fab
                    color="primary"
                    style={{ zIndex: 9999, position: "fixed", bottom: "5%", right: "2%"  }}
                    onClick={() => handleAddClick()}
                >
                    +
                </Fab>
                </Tooltip>
                
                </Grid>
            </Grid> */}
      <div
        style={{
          marginLeft: "-20px",
          marginRight: "-20px",
          marginTop: "-20px",
          padding: 30,
          backgroundColor: "#5ad545",
        }}
      >
        <Grid container spacing={3}>
          <Grid item md={4} sm={4} lg={4} xs={4}>
            <CssTextField
              label="Nome"
              variant="outlined"
              value={route_name}
              onChange={(e) => setRoutename(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item md={4} sm={4} lg={4} xs={4}>
            <CssTextField
              label="Trânsito"
              variant="outlined"
              value={route_transit}
              onChange={(e) => setRoutetransit(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item md={4} sm={4} lg={4} xs={4}>
            <TextField
              fullWidth
              className={classes.SelectCustom}
              value={state.route_period}
              name="route_period"
              onChange={handleChange}
              variant="outlined"
              label="Periodicidade"
              select
            >
              <MenuItem value={1}>Semanal</MenuItem>
              <MenuItem value={2}>Domingo</MenuItem>
            </TextField>
            {/*  <CssTextField
                    label="Periodicidade"
                    variant="outlined"
                    value={route_period}
                    onChange={(e) => setRouteperiod(e.target.value)}
                    fullWidth
                />  */}
          </Grid>
          <Grid item md={12} sm={12} lg={12} xs={12}>
            <CssTextField
              label="Descrição"
              variant="outlined"
              value={route_description}
              onChange={(e) => setRoutedescription(e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </div>
       <Grid container spacing={3} style={{
          marginLeft: "-20px",
          marginRight: "-20px" }}>
        <Grid item md={8} sm={8} lg={8} xs={8}></Grid>
        <Grid item md={4} sm={4} lg={4} xs={4}>
        <div style={{ marginTop: 12, marginBottom: 12 }}>
        <Button fullWidth variant="contained" onClick={() => handleExpand2('allpanel')}>{expand ? (expand === 'allpanel' ? 'Recolher todas as atividades' : 'Expandir todas as atividades') : 'Expandir todas as atividades' }</Button>
        </div>
       {/*  <div style={{ marginTop: 12, marginBottom: 12 }}>
            <Select
                MenuProps={{ disableScrollLock: true }}
                 fullWidth
                 variant="outlined"
                  displayEmpty
                  value={state.shifts_id}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                     shifts_id: e.target.value,
                    }))
                  } 
                >
                  <MenuItem key="all" value={null}>
                    Todos os Turnos
                  </MenuItem>
                  {state.shifts.map((item) => {return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.resource.work_shift}
                    </MenuItem>
                  )})}
                </Select>
         
            </div> */}
        </Grid>
      </Grid> 
      <div style={{ margin: 10, marginTop: 20 }}>



        <SortableList
          tasks={state.tasks}
          onSortEnd={onSortEnd}
          useDragHandle={true}
          useWindowAsScrollContainer={true}
        />


      </div>

      <Alert
        open={open2}
        message={state.message}
        alert={state.alert}
        setOpen={() => setOpen2()}
      />
    </Main>
  );
}

export default ManagementRoutes;
