import React, { useState, useEffect, useRef } from "react";

import { easeCubic } from "d3-ease";

//@material ui
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputBase from "@material-ui/core/InputBase";
import LensIcon from "@material-ui/icons/Lens";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {
  clusterLayer,
  clusterCountLayer,
  unclusteredPointLayer,
} from "./layers";

// Mapbox Lib
import "mapbox-gl/dist/mapbox-gl.css";
import MapGL, {
  Marker,
  FlyToInterpolator,
  Popup,
  Source,
  Layer,
  NavigationControl,
} from "react-map-gl";

// Api
import api from "../../../../core/services/api";

// Custom Components
import Main from "../../components/Main";
import SidebarContainer from "./Sidebar";
import { MapContainer, Flex } from "./styles";
import { Pin } from "../../styles/Map";
import PopupMotorista from "./PopupMotorista";
import PopupCounters from "./PopupCounters";
const CustomSwitch = withStyles({
  switchBase: {
    color: "#1e824c",
    "&$checked": {
      color: "#1e824c",
    },
    "&$checked + $track": {
      backgroundColor: "#1e824c",
    },
  },
  checked: {},
  track: {},
})(Switch);

export default function () {
  const token = process.env.REACT_APP_MAPBOX_TOKEN;
  const [search, setSearch] = useState("");
  const [opensidebar, setOpensidebar] = useState(true);
  const mapRef = useRef();
  const [state, setState] = useState({
    is_open: false,
    loading: true,
    properties: [],
    fences: [],
    filteredProperties: [],
    counters: [],
    filter: "",
    selectedHotspot: null,
    viewport: {
      width: "100%",
      height: "100vh",
      latitude: -20.397267,
      longitude: -43.820764,
      zoom: 10,
    },
  });
  useEffect(() => {
    getCarros();
    getCounters();
    getFences();
    const interval = setInterval(() => {
      getCarros();
      getCounters();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  const navigateTo = (latitude, longitude) => {
    const viewport = {
      ...state.viewport,
      latitude: latitude,
      longitude: longitude,
      zoom: 18,
      transitionDuration: 5000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: easeCubic,
    };
    setState((prev) => ({ ...prev, viewport }));
  };

  async function getCarros() {
    try {
      const data = await api.get(
        `https://flex.trackage.io/api/v1/tora_index`
      );

      setState((prev) => ({
        ...prev,
        loading: false,
        properties: data.data,
        filteredProperties: data.data,
      }));
    } catch (err) {
      console.warn(err);
    }
  }

  function filterData(value) {
    const filter = state.properties.filter((carro) => {
      return carro.board.toLowerCase().includes(value.toLowerCase());
    });
    setState((prev) => ({
      ...prev,
      filteredProperties: filter,
    }));
    setSearch(filter);
  }
  function filterSelect(value) {
    if (value === "todos") {
      setState((prev) => ({
        ...prev,
        filteredProperties: state.properties,
      }));
    } else {
      const filter = state.properties.filter((carro) => {
        return carro.counter === value;
      });
      setState((prev) => ({
        ...prev,
        filteredProperties: filter,
      }));
    }
  }

  async function getCounters() {
    try {
      const data = await api.get(
        `https://flex.trackage.io/api/v1/tora_total`
      );
      setState((prev) => ({
        ...prev,
        loading: false,
        counters: data.data,
      }));
    } catch (err) {
      console.warn(err);
    }
  }

  async function getFences() {
    try {
      const data = await api.get(
        `https://flex.trackage.io/api/v1/tora_fences`
      );
      const fen = data.data.filter((fence) => {
        return fence.name !== "Saída";
      });
      setState((prev) => ({
        ...prev,
        loading: false,
        fences: fen || [],
      }));
    } catch (err) {
      console.warn(err);
    }
  }

  function formatData(data = []) {
    const collection = {
      type: "FeatureCollection",
      features: [],
    };

    data.map(({ name, st_asgeojson }) => {
      const { coordinates } = JSON.parse(st_asgeojson);
      collection.features.push({
        type: "Feature",
        properties: {
          name,
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [coordinates],
        },
      });
    });

    return collection;
  }

  function showRoute(path) {
    this.props.history.push(path);
  }
  function toggleSatelite(e) {
    setState((prev) => ({
      ...prev,
      satelite: e.target.checked,
    }));
  }

  function selectColor(counter) {
    if (counter === "carregado") {
      return "#0091e6";
    }
    if (counter === "movimento") {
      return "#5ed237";
    }
    if (counter === "desligado") {
      return "#6c7a89";
    }
    if (counter === "parado") {
      return "#ff8000";
    }
  }
  function resetPopup() {
    setState((prev) => ({
      ...prev,
      popupInfo: null,
    }));
  }

  function openPopup(carro) {
    setState((prev) => ({
      ...prev,
      popupInfo: carro,
    }));
  }
  function renderCounters() {
    let items = state.counters
      .sort((a, b) =>
        a.counter === "desligado" ? 1 : b.counter === "movimento" ? 1 : -1
      )
      .map((item) => {
        return (
          <>
            {/*  
          <PopupCounters
            counter={item.counter}
            count={item.count}
            selectColor={selectColor}
            data={state.filteredProperties}
          /> */}
            {item.counter === "desligado" ? (
              <p
                style={{
                  margin: 0,
                  padding: "6px 12px",
                  borderRadius: "20px",
                  marginLeft: 10,
                  fontSize: 14,
                }}
              >
                <LensIcon
                  style={{ color: selectColor(item.counter), fontSize: 12 }}
                />{" "}
                {capitalize(item.counter)} ({item.count})
              </p>
            ) : (
              <p
                style={{
                  margin: 0,
                  padding: "6px 12px",
                  borderRadius: "20px",
                  marginLeft: 10,
                  fontSize: 14,
                }}
              >
                <LensIcon
                  style={{ color: selectColor(item.counter), fontSize: 12 }}
                />{" "}
                Ligado {item.counter} ({item.count})
              </p>
            )}
          </>
        );
      });
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          justifyContent: "space-between",
        }}
      >
        {items}
      </div>
    );
  }
  function capitalize(string) {
    return string[0].toUpperCase() + string.slice(1);
  }

  function renderPopup() {
    return (
      state.popupInfo && (
        <Popup
          closeButton={true}
          longitude={state.popupInfo.longitude}
          latitude={state.popupInfo.latitude}
          onClose={() => resetPopup()}
          closeOnClick={true}
        >
          {" "}
          <div style={{ margin: -14, width: 300 }}>
            <PopupMotorista
              board={state.popupInfo.board}
              time_operation={state.popupInfo.tempo_operacao}
              cycles={state.popupInfo.cycles}
            />
          </div>
        </Popup>
      )
    );
  }

  return (
    <Main
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span
            style={{
              marginRight: 50,
              borderRight: "0.5px solid #fffffffc",
              paddingRight: 20,
            }}
          >
            Mapa
          </span>
          {renderCounters()}
        </div>
      }
    >
      <Flex>
        <MapContainer>
          <MapGL
            {...state.viewport}
            mapboxApiAccessToken={token}
            mapStyle={`mapbox://styles/mapbox/${
              state.satelite ? "satellite-v9" : "streets-v11"
            }`}
            onViewportChange={(viewport) =>
              setState((prev) => ({ ...prev, viewport }))
            }
          >
            <Source type="geojson" data={formatData(state.fences)}>
              <Layer
                type="fill"
                paint={{
                  "fill-color": "#62bc24",
                  "fill-opacity": 0.5,
                }}
              />
              <Layer
                type="symbol"
                layout={{
                  "text-field": [
                    "format",
                    ["get", "name"],
                    { "font-scale": 1.2 },
                  ],
                }}
              />
            </Source>

            <div
              style={{
                position: "inherit",
                margin: 25,
                bottom: "7%",
                right: opensidebar ? 330 : 0,
                transition: "all .35s ease",
              }}
            >
              {" "}
              <NavigationControl />
            </div>
            <Paper
              style={{
                position: "inherit",
                paddingLeft: 12,
                paddingRight: 12,
                margin: 25,
                top: "7%",
                left: 0,
                transition: "all .35s ease",
              }}
            >
              <FormControlLabel
                control={
                  <CustomSwitch
                    checked={state.satelite}
                    onChange={toggleSatelite}
                    name="satellite"
                  />
                }
                label="Satélite"
              />
            </Paper>

            {state.filteredProperties ? (
              <>
                {state.filteredProperties.map((carro) => (
                  <Marker
                    style={{ zIndex: 9999, display: "block" }}
                    key={carro.id}
                    longitude={carro.longitude}
                    latitude={carro.latitude}
                  >
                    <div onClick={() => openPopup(carro)}>
                      <Pin color={selectColor(carro.counter) || "#1e824c"}>
                        <span style={{ fontSize: 16 }}>{carro.board}</span>
                      </Pin>
                    </div>
                  </Marker>
                ))}{" "}
              </>
            ) : (
              ""
            )}
            {renderPopup()}
          </MapGL>
        </MapContainer>

        <SidebarContainer
          toggleSatelite={toggleSatelite}
          satelite={state.satelite}
          loading={state.loading}
          showRoute={showRoute}
          goto={navigateTo}
          data={state.filteredProperties}
          filterData={filterData}
          filter={state.filter}
          setColor={selectColor}
          changeViewport={navigateTo}
          filterSelect={filterSelect}
          setOpensidebar={setOpensidebar}
        >
          {" "}
          <InputBase
            style={{ width: "100%" }}
            placeholder="Buscar"
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
            onChange={(e) => filterData(e.target.value)}
          />
        </SidebarContainer>
      </Flex>
    </Main>
  );
}
