import React, {useState, useEffect} from 'react'
import moment from 'moment'
import { Link } from "react-router-dom";
import api from '../../../../core/services/api'

//custom Components
import Main from "../../components/Main";
import { Container } from "./styles";


// @material-ui
import TextField from '@material-ui/core/TextField'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import ShareIcon from '@material-ui/icons/Share'
import MaterialTable, { MTableToolbar, MTablePagination } from "material-table";
import Chip from '@material-ui/core/Chip'

function DriverPanel(){
  const [state, setState] = useState({
    fences: []
  })

  useEffect(
    () => {
      getFences();
      getData();
      const interval = setInterval(() => {
        getFences();
        getData();
        console.log('Atualizando...')
      }, 30000);
      return () => clearInterval(interval);
    },
    []
  );


  async function getData() {
    try {
      const { data } = await api.get(`journey-panel`);
      //const filterdata = data.filter((item) => item.excluded !=== true);
      setState((prev) => ({
        ...prev,
        journeys: data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  async function getFences(){
    try {
      const { data } = await api.get(`fences`);
      //const filterdata = data.filter((item) => item.excluded !=== true);
      setState((prev) => ({
        ...prev,
        fences: data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  const names = [
    'Destino 1',
    'Destino 2',
    'Destino 3'
  ]
   function getFenceName(id){
    const fence = state.fences.filter((item) => {return item.id === id });
    return fence[0]?.name
  } 
  function status(start, end, eta, last_update){
    const start_schedule = moment.duration(start, "HH:mm").asSeconds()
    let end_schedule = moment.duration(end, "HH:mm").asSeconds()
    if(end_schedule === 0) end_schedule = 86400;
    const aux = parseInt(eta)
    const last = moment(last_update, "HH:mm:ss").subtract(3, "hours").format("HH:mm:ss")
    const last2 = moment.duration(last, "HH:mm:ss").asSeconds()
    
    console.log('Start, end, eta, last_update inputs: ', start, end, eta, last_update)
    console.log('Eta + time : ', aux, last2, last2+aux)
    console.log('Checks in seconds : ', last2+aux, start_schedule, end_schedule)
    if(last2+aux < start_schedule){
      return  <Chip label='Em Antecipação' color="primary" />
    } else if (last2+aux < end_schedule){
      return <Chip label='No prazo' style={{backgroundColor: '#47b34d', color: '#fff'}} />
    } else {
      return <Chip label='Atrasado' color="secondary" />
    }
 
  }
    
    
    return(
        <Main title="Chegada de Motoristas em até 2 horas">
        <Container>
       

<MaterialTable
    columns={[
      {
        title: "Motorista",
        field: "drivername",
        sorting: false,
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
      },
      {
        title: "Transportadora",
        field: "name",
        sorting: false,
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
      },
      {
        title: "Destino",
        field: "destiny",
        sorting: false,
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
        render: (rowData) => getFenceName(rowData.destiny)
      },
      {
        title: "Data/Hora Previsão de Chegada",
        field: "eta_ts",
        sorting: false,
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
       render: (rowData) => moment(rowData.eta_ts, "YYYY-MM-DD HH:mm:ss.SSS").subtract(3, "hours").format("HH:mm DD/MM/YYYY")
      },
      {
        title: "Data/Hora Início Jornada",
        field: "created_at",
        sorting: false,
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
        render: (rowData) => moment(rowData.created_at, "YYYY-MM-DD HH:mm:ss").subtract(3, "hours").format("HH:mm DD/MM/YYYY")
      },
      {
        title: "Distância percorrida",
        field: "traveled_distance",
        sorting: false,
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
        render: (rowData) => parseInt(rowData.traveled_distance).toFixed(1)+"Km"
      },
      {
        title: "Distância a Percorrer",
        field: "remaining_distance",
        sorting: false,
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
        render: (rowData) => parseInt(rowData.remaining_distance).toFixed(1)+"Km"
      },
      
     
      {
        title: "Status",
        field: "status",
        sorting: false,
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
        render: (rowData) => status(rowData.schedule_begin, rowData.schedule_end, rowData.eta, rowData.last_time)
      },
      {
        title: "Última atualização",
        field: 'last_update',
        sorting: false,
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
        render: (rowData) => moment(rowData.last_update, "YYYY-MM-DD HH:mm:ss").subtract(3, "hours").format("HH:mm DD/MM/YYYY")
      }
    ]}
    data={state.journeys}        
    options={{
      pageSize: 10,
      actionsColumnIndex: -1,
      search: false,
      showTitle: false,
      headerStyle: {
        paddingLeft: 40,
        textAlign: "left",
        backgroundColor: "#1c1c1c",
        color: "#fff",
        border: "#fff",
      },
      rowStyle: (x) => {
        if (x.tableData.id % 2) {
          return { backgroundColor: "#f3f5f8" };
        }
      },
    }}
    components={{
      Toolbar: props => (
        <div style={{ display: "none" }}>
          <MTableToolbar {...props} />
        </div>
      ),
    }}
    localization={{
      pagination: {
          labelDisplayedRows: '{from}-{to} de {count}'
      },
      body: {
          emptyDataSourceMessage: 'Carregando dados',
          
      }
  }}
  
    style={{
      borderRadius: 5,
    }}
  />
        </Container>
        </Main>
    )
}

export default DriverPanel;