export default function(legends) {
    return {
        colors: ["#26a0fc", "#24dff5", "#e93093"],
        chart: {
          height: 350,
          type: "bar",
        },
        legend: {
            position: 'top'
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(0)+"m";
          },
          offsetY: 10,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        xaxis: {
          categories: legends,
          
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val.toFixed(0)+"m";
            },
          },
        },
        stroke: {
            show: true,
            width: 5,
            colors: ['transparent']
        },
      }
  }