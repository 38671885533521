import React, {useEffect} from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


function Alertt(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

function Alert(props){
    const [state, setState] = React.useState({
        open: props.open,
      });
      useEffect(() => {
        setState({ open: props.open });
      }, [props.open]);
      const { open } = state;
    
      const handleClick = (newState) => () => {
        setState({ open: true, ...newState });
      };
    
      const handleClose = () => {
        setState({ ...state, open: false });
        props.setOpen(false);
      };

      return (<div>
        <Snackbar
          style={{zIndex: 99999}}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          onClose={handleClose}
        ><Alertt onClose={handleClose} severity={props.alert}>
        {props.message}
      </Alertt>
      </Snackbar>
      </div>)

}

export default Alert;