import React, {useState, useEffect} from 'react'
import api from '../../../../core/services/api'
import PropTypes from 'prop-types';


//material ui
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper'


//custom components
import Main from '../../components/Main'
import PresenteeismPanel from './PresenteeismPanel'
import EffectivenessPanel from './EffectivenessPanel'
import HDPanel from './HDPanel'
import ReportPanel from './ReportPanel'




function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

function Kpis2(){

    const [state, setState] = useState({
        shifts_list: []
    })
    const [value, setValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    
    return(
        <Main title="KPI - Report Diário da Operação (OMS)" color="#f7f7f7">
            <Paper square style={{marginTop: '-25px', marginRight: '-30px', marginLeft: '-30px'}}>
            <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
        
            >
            <Tab label="Report Geral"  />
            <Tab label="Hora Direta"  /> 
            <Tab label="Efetividade" />
            <Tab label="Presenteísmo" />
            </Tabs>
        </Paper>
        
           <TabPanel value={value} index={0}>
            <ReportPanel />
            </TabPanel> 
            <TabPanel value={value} index={1}>
            <HDPanel />
            </TabPanel> 
            <TabPanel value={value} index={2}>
            <EffectivenessPanel />
            </TabPanel>
            <TabPanel value={value} index={3}>
            <PresenteeismPanel />
            </TabPanel>
        </Main>
    )
}

export default Kpis2;