import React, { useState, useEffect } from 'react';
import Routes from './routes';
import ConfigContext from '../core/config';
import { SnackbarProvider } from 'notistack';

function App() {
  const [config, setConfig] = useState({ loading: true, data: {
    menu: []
  }});

  const { loading, data } = config;

  useEffect(() => {
    function getConfigAsync(){
      let data = localStorage.getItem('usuario');
      data = data ? JSON.parse(data) : {menu: []};
      setConfig({ data, loading: false });
    }
    getConfigAsync();
  }
  , []);

  return (
    <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'top',horizontal: 'right'}}>
      <ConfigContext.Provider value={data}>
        <Routes routes={data.menu} />
      </ConfigContext.Provider>
    </SnackbarProvider>
  )
}

export default App;
