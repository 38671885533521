import React from "react";

//@material-ui
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Fab from "@material-ui/core/Fab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

//others components
import moment from "moment";
import { LoadingContainer } from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 400,
    minWidth: 345,
    padding: 12,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

function TempoMedioCarga(props) {
  const classes = useStyles();
  let sum = (a) => a.reduce((x, y) => x + y);
  let avg = (a) => {
    let aux = 0;
    aux = a.filter((x) => x !== 0 && x !== null).length;
    let soma = sum(a);
    let media = soma/aux;
    return moment(media, "mm").format("HH:mm")
  }


  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <Fab disabled style={{ backgroundColor: "#cce9fa" }}>
            <AccessTimeIcon style={{ color: "#0091e6" }} />
          </Fab>
        }
        title="Tempo médio de carga"
      />

      <CardContent>
        
          {props.data && props.data != '00:00:00' ? (<>
            <Typography style={{ fontSize: 45, fontWeight: "bold", marginTop: '-30px' }} component="p">
            {props.data === "Invalid date" ? "00:00" : moment(props.data, "HH:mm:ss").format("HH:mm")}
            </Typography>
            <TableContainer>
          <Table>
            <TableBody>
                      <TableRow>
                        <TableCell
                          component="th"
                          style={{ fontSize: 14 }}
                          scope="row"
                        >
                          Varzea dos Lopes
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ fontSize: 14, fontWeight: "bold" }}
                        >
                         {props.varzea ? props.varzea : "00:00"}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          component="th"
                          style={{ fontSize: 14 }}
                          scope="row"
                        >
                         Miguel Burnier
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ fontSize: 14, fontWeight: "bold" }}
                        >
                         {props.miguel2 ? props.miguel2 : "00:00"}
                        </TableCell>
                      </TableRow>
                  
            </TableBody>
          </Table>
        </TableContainer>


         </> ) : (
            <LoadingContainer>
              <CircularProgress style={{ color: "#1e824c" }} />{" "}
            </LoadingContainer>
          )}
     
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
}

export default TempoMedioCarga;
