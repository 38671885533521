import React, { useState, useEffect } from 'react';
import Main from '../../components/Main'
import MaterialTable, { MTableToolbar } from "material-table";
import api from '../../../../core/services/api'

//custom components
import CreateForm from './CreateForm'
import EditForm from './EditForm'
import Table from './Table'
import Alert from '../../components/Alert'


import Fab from "@material-ui/core/Fab";

//@material-ui
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'


//@material icons
import DeleteIcon from '@material-ui/icons/Delete'
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SaveAltIcon from '@material-ui/icons/SaveAlt'

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import AddIcon from "@material-ui/icons/Add";
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

function Occurrences() {

  
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [state, setState] = useState({
    occurrences: []
  })

  useEffect(() => {
    getData();
  }, [])

  async function deleteOccurrences(occurrence_category) {
    try {

      if (
        window.confirm(
          "Tem certeza que deseja deletar a ocorrência? " + occurrence_category.description
        )
      ) {
        await api.delete(`occurrence_category/${occurrence_category.id}`);
      }

      getData();
    } catch (error) {
      console.warn(error)
    }
  }

  async function getData() {
    try {
      const data = await api.get(`occurrence`);
      setState((prev) => ({
        ...prev,
        occurrences: data.data || []
      }))
    } catch (error) {
      console.warn(error)
    }
  }
  const useStyles = makeStyles({
    root: {
      transform: "translateZ(0px)",
      flexGrow: 1,
      color: '#69f0ae'
    },
    speedDial: {
      zIndex: 9999,
      position: "fixed",
      bottom: "12%",
      right: "0.2%",
      "& .MuiSpeedDial-fab": {
        backgroundColor: '#5ad545'
      },
    }
  }
  );

  async function getReport() {
    try {
      let url = `/occurrence_export`;
      // url += state.check_shift ? "&check_shift=" + state.check_shift : "";
      api.get(url).then(blobFile => {
          //fileDownload(blobFile.data.data, 'teste.xlsx')
           const url = window.URL.createObjectURL(new Blob([blobFile.data.data]));
          const link = document.createElement('a');
          //converter base64 pra xlsx
          //passar xlsx pro href
          link.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+blobFile.data.data;
          link.setAttribute('download', `ocorrencias.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);  

      })
    } catch (err) {
      console.warn(err);
    }
  
  };


  const classes = useStyles();
  const [direction, setDirection] = React.useState("up");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);

  };

  return (
    <Main color="#f7f7f7" title="Ocorrências">
      <Grid container justify="flex-end" spacing={3} style={{ paddingBottom: 12 }}>
        <Grid item>

        <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={direction}
            getData={getData}
            optionType={state.optionType}
          >
            <SpeedDialAction
              key="CreateForm "
              icon={<CreateForm getData={getData} occurrence={state.occurrences} />}
              tooltipTitle="Adicionar Ocorrência"
              onClick={() => handleOpen() }

            />
            <SpeedDialAction
              key="download"
              icon={<GetAppIcon />}
              tooltipTitle="Download"
              onClick={() => getReport()}
             
            />
            {/* <SpeedDialAction
              key="upload"
              icon={<PublishIcon />}
              tooltipTitle="Upload"
              onClick={() => handleOpen()}
            /> */}

          </SpeedDial>
                 </Grid>
       <Alert open={open2} message="O relatório foi baixado com sucesso!" setOpen={() => setOpen2()} /> 

              </Grid>

      <div className={classes.root}>

        {state.occurrences.map((item) => {
          return (
            <Accordion expanded={expanded === item.id} onChange={handleChange(item.id)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={classes.heading}>{item.description}</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <div style={{width: '100%'}}>
                <Table occurrence={item} getData={getData}/>
                </div>
              </AccordionDetails>

            </Accordion>
          )
        })}
      </div>


      {/* <MaterialTable
        columns={[


          {
            title: "Descrição",
            field: "name",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
          },
          {
            title: "Ocorrências",
            field: "occurrence",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
          },
          
          {
            field: "actions",
            title: "Ações",
            cellStyle: { paddingBottom: 0, paddingTop: 0, textAlign: "right" },
            headerStyle: { paddingRight: 20, textAlign: "right", borderRadius: '0px 8px 0px 0px' },
            render: (rowData) => (
              <>

                <FormGroup row style={{ justifyContent: 'flex-end' }}>
                  <EditForm occurrence_category={rowData} getData={getData} />

                  <IconButton

                    style={{ color: "#ec273b" }}
                    onClick={() => deleteOccurrences(rowData)}
                  >
                    <DeleteIcon />

                  </IconButton>{" "}

                  <div id="buttons"></div>
                </FormGroup>

              </>
            ),
          },
        ]}
        data={state.occurrences}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
          },
          rowStyle: (x) => {
            if (x.tableData.id === 0) {
              return { borderTop: '2px solid rgb(212, 212, 212)' }
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}

        style={{
          borderRadius: 10,
        }}

      /> */}
    </Main>

  )
}

export default Occurrences;


