import React, { useEffect, useState } from "react";
import api from "../../../../../../core/services/api";
import * as _ from "lodash";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import { Tooltip } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Chip from "@material-ui/core/Chip";


//custom components
import CustomSelect from "../../../../components/CustomSelect";
import {
  FormConditional,
  FormLine
} from "./style";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import FormConditions from "./FormConditions";
import FormMessages from "./FormMessages";

function CreateForm({
  onSubmit = () => {},
  id = null,
  datasets:listDatasets = [],
  segments: listSegments = [],
}) {

  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const [datasetid, setDatasetid] = useState("");
  const [dataset, setDataset] = useState(null);
  const [segmentConditionals, setSegmentConditionals] = useState([]);
  const [baseConditionals, setBaseConditionals] = useState([]);
  const [segments,setSegments] = useState([])
  const [messages,setMessages] = useState([{
    service: 'email',
    message: ''
  }])
  const [recurrence, setRecurrence] = useState('once')

  const [optionsConditionals, setOptionsConditionals] = useState({
    base_conditionals: [],
    segment_conditionals: []
  });

  const [state, setState] = useState({
    error: false,
    disabled: false,
    active: true,
  });

  useEffect(() => {
    const _dataset = _.find(listDatasets, { id: datasetid });

    const _baseConditionals =
      (_.get(_dataset, "resource.base_conditionals", []) || [])
      .filter((item) => item.type === "input");

    const _segmentsConditionals =
      (_.get(_dataset, "resource.segment_conditionals", []) || [])
      .filter((item) => item.type === "input");

    setOptionsConditionals({
      base_conditionals: _baseConditionals,
      segment_conditionals: _segmentsConditionals
    });

    setSegmentConditionals(_segmentsConditionals
    .map((item) => ({
      field: item.field,
      operator: "=",
      value: "",
    })))
    setBaseConditionals(_baseConditionals
    .map((item) => ({
      field: item.field,
      operator: "=",
      value: "",
    })))

    setDataset(_dataset || null);

  }, [datasetid]);

  const handleClickOpen = () => {
    setState({
      disabled: false,
      error: false,
      active: true,
      segments: [],
    });
    setName("");
    setOpen(true);
    setDatasetid("");
   
    setMessages([{
      service: 'email',
      message: ''
    }]);
    setSegmentConditionals([]);
    setBaseConditionals([])
    setOptionsConditionals({base_conditionals:[],segment_conditionals: []})
    setDataset(null);
    setSegments([])
    setRecurrence('once')

    if (id) {
      api
        .get(`alerts/${id}`)
        .then((data) => {
          const _baseConditionals =
          _.get(data, "data.data.resource.base_conditionals", []) || [];
          const _segmentsConditionals =
          _.get(data, "data.data.resource.segment_conditionals", []) || [];
          const messages =
            _.get(data, "data.data.resource.messages", []) || [];

          setName(_.get(data, "data.data.name"));
          setDatasetid(_.get(data, "data.data.dataset_id"));
          setMessages(messages.length > 0 ? messages : [{
            service: 'email',
            message: ''
          }]);
          setSegments(_.get(data, "data.data.resource.segments", []) || []);
          setSegmentConditionals(_segmentsConditionals)
          setBaseConditionals(_baseConditionals)
          setRecurrence(_.get(data,'data.data.resource.sending_recurrence','once'))
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar("Não foi possível carregar alerta para edição", {
            variant: "error",
          });
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function handleSubmit() {
    setState((prev) => ({
      ...prev,
      disabled: true,
    }));
    try {
      const payload = {
        name: name,
        dataset_id: datasetid,
        base_conditionals: baseConditionals,
        segment_conditionals: segmentConditionals,
        segments: segments || [],
        messages,
        recurrence
      };
      if (id) {
        await api.put(`/alerts/${id}`, payload);
        enqueueSnackbar("Alerta atualizada com sucesso!", {
          variant: "success",
        });
      } else {
        await api.post("/alerts", payload);
        enqueueSnackbar("Alerta cadastrada com sucesso!", {
          variant: "success",
        });
      }
      onSubmit();
      setOpen(false);
    } catch (error) {
      console.warn(error);
      enqueueSnackbar("Não foi possível cadastrar alerta", {
        variant: "error",
      });
      setState((prev) => ({
        ...prev,
        disabled: false,
      }));
    }
  }

  const renderVariables = (dataset) => {
    const variables = _.get(dataset, "resource.message_fields", []);
    return variables.map((item, index) => (
      <Chip
        key={item.label + "-" + index}
        style={{ marginLeft: 5 }}
        label={item.label}
      />
    ));
  };

  return (
    <div>
      {id ? (
        <Tooltip title="Editar">
          <IconButton onClick={handleClickOpen}>
            <EditIcon style={{ color: "#767676" }} className="icon-small" />
          </IconButton>
        </Tooltip>
      ) : (
        <AddIcon onClick={handleClickOpen} />
      )}
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>{id ? "Atualizar alerta" : "Novo Alerta"}</DialogTitle>
        <DialogContent>
          <FormLine>
            <TextField
              id="name"
              name="name"
              label="Nome"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />{" "}
          </FormLine>
          <FormLine>
            <CustomSelect
              label="Dataset"
              value={datasetid}
              name="dataset_id"
              data={listDatasets}
              onChange={(e) => setDatasetid(e.target.value)}
            />
          </FormLine>
          {datasetid ? (
            <>
            <FormConditions 
              conditionals={baseConditionals} 
              options={optionsConditionals.base_conditionals}
              type="base_conditionals" 
              onChange={(value) => setBaseConditionals(value)}
              onAdd={() => {
                setBaseConditionals([
                  ...baseConditionals,
                  {
                    field: "",
                    operator: "=",
                    value: "",
                  },
                ]);
              }}
            />
            <FormConditions 
              conditionals={segmentConditionals} 
              options={optionsConditionals.segment_conditionals}
              type="segment_conditionals" 
              onChange={(value) => setSegmentConditionals(value)}
              onAdd={() => {
                setSegmentConditionals([
                  ...segmentConditionals,
                  {
                    field: "",
                    operator: "=",
                    value: "",
                  },
                ]);
              }}
            />
          </>
          ):null}
          
          {_.get(dataset,'resource.type_segment','') === "local" ? (
            <FormLine>
              <CustomSelect
                label="Segmentos"
                value={segments}
                name="segments"
                data={listSegments || []}
                onChange={(e) => setSegments(e.target.value)}
                multiple={true}
              />
            </FormLine>
          ) : null}
          <FormLine>
            <CustomSelect
              label="Recorrência"
              value={recurrence}
              name="recurrence"
              data={[{id: "once",name: "Envio único"},{id: "recurrence",name: "Envio recorrênte"}]}
              onChange={(e) => setRecurrence(e.target.value)}
            />
          </FormLine>
          {dataset ? (
            <FormLine>
              Variáveis possíveis para mensagem:
              {renderVariables(dataset)}
            </FormLine>
          ) : null}
          <FormConditional>
            <FormMessages 
              fields={_.get(dataset, "resource.message_fields", [])} 
              messages={messages}
              onAdd={()=> setMessages([...messages,{
                service: 'email',
                message: ''
              }])}
              onChange={(value) => setMessages(value)}
            />
          </FormConditional>
        </DialogContent>
        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{
              backgroundColor: state.disabled ? "" : "#5ed237",
              color: state.disabled ? "" : "#fff",
            }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateForm;
