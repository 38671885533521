import React, { Component } from "react";

// Styled Components
import { Sidebar, SidebarHeader } from "./styles";

import { Link } from 'react-router-dom';

// @material-ui/core
import Fab from "@material-ui/core/Fab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

// @material-ui/icons
import MapIcon from "@material-ui/icons/Map";
import NearMe from "@material-ui/icons/NearMe";
import DirectionsCar from "@material-ui/icons/DirectionsCar";

export class SidebarContainer extends Component {
  filtraTemperatura(temp) {
    if (temp < 12) {
      return "#2196F3"; // blue
    } else if (temp > 16) {
      return "#EC273B"; // red
    } else {
      return "#5ED237"; // green
    }
  }

  render() {
    return (
      <>
        <Sidebar>
          <SidebarHeader>
              <h3 style={{textAlign: 'center'}}>Veiculos</h3>
          </SidebarHeader>
          <List
            style={{ marginTop: -10 }}
            component="nav"
            aria-label="main mailbox folders"
          >
            {this.props.children}
            {this.props.data.map(property => {
              return (
                <div key={property.id}>
                  <ListItem
                    button
                    onClick={this.props.click.bind(this, {
                      latitude: property.telemetrias.slice(-1)[0].lat,
                      longitude: property.telemetrias.slice(-1)[0].lon
                    })}
                  >
                    <ListItemIcon>
                      <Fab
                        color="primary"
                        style={{
                          background: this.filtraTemperatura(
                            property.telemetrias !== null
                              ? property.telemetrias[
                                  property.telemetrias.length - 1
                                ].temperature
                              : 10
                          )
                        }}
                        size="small"
                        aria-label="add"
                      >
                        <DirectionsCar />
                      </Fab>
                    </ListItemIcon>
                    <ListItemText primary={property.jornada_placa} secondary={property.nome_motorista} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="map_icon" onClick={() => this.props.showRoute('/routes/'+property.id)}>
                        <MapIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="near_me" onClick={() => this.props.goto(property.telemetrias.slice(-1)[0].lat, property.telemetrias.slice(-1)[0].lon)}>
                        <NearMe />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </div>
              );
            })}
            <Divider />
          </List>
        </Sidebar>
      </>
    );
  }
}

export default SidebarContainer;
