import React, { useState, useEffect } from "react";
import api from "../../../../../../core/services/api";
import moment from "moment";
import { useStyles } from "./styles";

//@material-ui
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";

//custom components
import GraphCard from "./GraphCard";

function CuboXRoute(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    start_day: moment().format("YYYY-MM-DD"),
    end_day: moment().add(1, "days").format("YYYY-MM-DD"),
    shifts_list: [],
    shifts_id: null,
    legends: [],
    cube_shift: [
      {
        name: "Presenteísmo",
        type: "column",
        data: [],
      },
      {
        name: "Hora Direta",
        type: "column",
        data: [],
      },
      {
        name: "Efetividade",
        type: "column",
        data: [],
      },
      {
        name: "Cubo",
        type: "line",
        data: [],
      },
    ],
  });

  useEffect(() => {
    getData();
  }, [state.start_day]);

  async function getData() {
    try {
      // const data = await api.get(`cube-route?start_day=${state.start_day}&end_day=${state.end_day}`);
      const data = await api.get(
        `hdtime-day?start_day=${state.start_day}&end_day=${state.end_day}`
      );
      const data2 = await api.get(`routes`);
      let legends = [];
      let pres_prev = [];
      let hd_prev = [];
      let efet_prev = [];
      let efet_real = [];
      let series = [
        {
          name: "Presenteísmo",
          type: "column",
          data: [],
        },
        {
          name: "Hora Direta",
          type: "column",
          data: [],
        },
        {
          name: "Efetividade",
          type: "column",
          data: [],
        },
        {
          name: "Cubo",
          type: "line",
          data: [],
        },
        {
          name: "Target",
          type: "line",
          data: [],
        },
      ];
      data.data.forEach((item, index) => {
        let exist = legends.includes(item.name);
        if (!exist) {
          legends.push(item.name);
          efet_prev.push(0);
          efet_real.push(0);
          pres_prev.push(0);
          hd_prev.push(0);
        }
      });

      data2.data.forEach((item, index) => {
        let idx = legends.indexOf(item.name);
        let presenteeism_plan = 0;
        let hd_program = 0;
        let t1 = 0;
        let t2 = 0;
        let t3 = 0;
        let t3d = 0;
        item.tasks.forEach((item2, idex) => {
          if (item2.shift == 10) t1 = 1;
          if (item2.shift == 16) t2 = 1;
          if (item2.shift == 12) t3 = 1;
          if (item2.shift == 13) t3d = 1;
          if (item2.activity_type == 6) {
            let idx = legends.indexOf(item.name);
            efet_prev[idx] += 1;
          }
        });
        if (t1 == 1) hd_program += 436;
        if (t2 == 1) hd_program += 413;
        if (t3 == 1) hd_program += 230;
        if (t3d == 1) hd_program += 433;
        pres_prev[idx] += t1 + t2 + t3 + t3d;
        hd_prev[idx] += hd_program;
      });

      legends.forEach((item, index) => {
        series[0].data.push(0);
        series[1].data.push(0);
        series[2].data.push(0);
        series[3].data.push(0);
        series[4].data.push(83);
      });

      //PRESENTEISMO
      data.data.forEach((item, index) => {
        let idx = legends.indexOf(item.name);
        series[0].data[idx] += 1;
      });

      //HORA DIRETA
      data.data.forEach((item, index) => {
        let idx = legends.indexOf(item.name);
        let transito = item.soma_transito ? item.soma_transito : 0;
        let doca = item.soma_doca ? item.soma_doca : 0;
        let arranque = item.soma_arranque ? item.soma_arranque : 0;
        let fechamento = item.soma_fechamento ? item.soma_fechamento : 0;
        let soma_ocorrencia = 0;
        //TEMPO OCORRENCIA
        if (item.jornada.tasks) {
          item.jornada.tasks.forEach((task, index) => {
            if (task.issues[0] && task.issues[0].finish_at) {
              let occurrence_total = 0;
              task.issues.forEach((item2, index) => {
                if(task.issues[index].finish_at){
                  const aux = moment(task.issues[index].finish_at,"YYYY-MM-DD HH:mm:ss").diff(moment(task.issues[index].created_at, "YYYY-MM-DD HH:mm:ss"),"seconds");
                  occurrence_total += aux;
                }
               
              })
              soma_ocorrencia += occurrence_total;
            }
          });
        }
        series[1].data[idx] +=
          (transito + doca + arranque + fechamento - soma_ocorrencia) ;
      });

      //EFETIVIDADE
      data.data.forEach((item, index) => {
        let idx = legends.indexOf(item.name);
        let dock_total = 0;
        if (item.jornada.tasks) {
          item.jornada.tasks.forEach((task, index2) => {
            if (task.activity_type == 6 && task.end_real != null) {
              dock_total += 1;
            }
          });
        }
        series[2].data[idx] += dock_total;
      });

      //CUBO
      series[3].data.forEach((item, index) => {
        let hd_sum = series[1].data[index];
        let percent_hd = (hd_sum * 85) / (hd_prev[index] * 60);
        let percent_efet = (series[2].data[index] * 100) / efet_prev[index];
        let percent_presenteeism =
          (series[0].data[index] * 100) / pres_prev[index];
        series[3].data[index] =
          (percent_hd * percent_efet * percent_presenteeism) / 10000;
      });

      setState((prev) => ({
        ...prev,
        cube_shift: series,
        legends: legends,
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  const handleDate = (date) => {
    setState((prev) => ({
      ...prev,
      start_day: moment(date).format("YYYY-MM-DD"),
      end_day: moment(date).add(1, "days").format("YYYY-MM-DD"),
    }));
  };
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between" alignItems="center" spacing={3}>
          <Grid item lg={10} md={10} sm={10} xs={10}>
            <Typography>Cubo X Rota</Typography>
          </Grid>
          <Grid item lg={2} sm={2} md={2} xs={2}>
            <div style={{ marginTop: 10, marginBottom: 0 }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
                <ThemeProvider theme={fcaTheme}>
                  <KeyboardDatePicker
                    label="Filtro de Data"
                    disableFuture={true}
                    inputVariant="outlined"
                    disableToolbar
                    clearable="true"
                    autoOk={true}
                    style={{ paddingBottom: 12 }}
                    format="dd/MM/yyyy"
                    variant="inline"
                    value={moment(state.start_day, "YYYY-MM-DD")}
                    onChange={handleDate}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </div>
          </Grid>
        </Grid>

        <GraphCard cube_shift={state.cube_shift} legends={state.legends} />
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
}

export default CuboXRoute;
