import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }));
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

function CustomSelectCheckbox(props) {
    const classes = useStyles();
    const theme = useTheme();
  
    return (
      <div>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="demo-mutiple-checkbox-label">{props.label}</InputLabel>
          <Select
            name={props.name}
            multiple
            value={props.value}
            onChange={props.onChange}
            label={props.label}
            //input={<Input />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {props.data.map((item) => (
              <MenuItem key={item.id} value={item.name || item.resource?.work_shift}>
                <Checkbox checked={props.list.indexOf(item.name || item.resource?.work_shift) > -1} />
                <ListItemText primary={item.name || item.resource?.work_shift} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  } 

  export default CustomSelectCheckbox;