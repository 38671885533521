import React, { useState, useEffect } from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
import Grid from '@material-ui/core/Grid'

function TableReport({ report, columns, hasChecklist }) {
  const [state, setState] = useState({
    users: []
  })

  const pneus = [
    22, 0, 0, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1]


  const renderAnswer = answer => {
    switch (typeof answer) {
      case 'boolean':
        return (answer
          ? (<div style={{ backgroundColor: 'green', height: 20, width: 20, borderRadius: 20 }} />)
          : (<div style={{ backgroundColor: 'red', height: 20, width: 20, borderRadius: 20 }} />))

      case 'number':
        return (
          <div>{answer}%</div>
        )
      case 'object':
        return (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gridTemplateRows: "repeat(6, 1fr)",
              gridAutoFlow: "row",
              marginRight: 15,
            }}
          >
            {pneus.map(pneu => (
              <div
                key={pneu.gridArea}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: 15,
                  borderWidth: 1,
                  borderColor: 'black',
                  borderStyle: 'solid',
                  borderRadius: 5,
                  width: 20,
                  height: 25,
                  margin: 5,
                  visibility: pneu === 0 ? 'hidden' : ''
                }}>
                {answer.some(position => position == pneu) ? pneu : ''}
              </div>
            ))}
          </div>
        )
      default:
        return (
          <div>{answer}KM</div>
        )
    }
  }

  return (
    hasChecklist
      ? (<MaterialTable
        columns={columns}
        data={report}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
          },
          rowStyle: (x) => {
            if (x.tableData.id === 0) {
              return { borderTop: '2px solid rgb(212, 212, 212)' }
            }
          },
        }}
        detailPanel={rowData => (
          (rowData.checklist != null && rowData.checklist.some(check => check?.question)) && (
            <Grid container style={{ display: 'grid', gridTemplateRows: "repeat(6, 1fr)", gridTemplateColumns: "repeat(4, 1fr)" }}>
              {rowData.checklist.map(({ question, value: answer }) => (
                <div
                  key={question}
                  style={{
                    margin: '0 10px',
                    borderBottomWidth: 'thin',
                    borderBottomColor: '#e4e4e4',
                    borderBottomStyle: 'solid',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start'
                  }}>
                  <Grid item sm={10} md={10} lg={10} xs={10}
                    style={{
                      margin: '5px  0px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start'
                    }}>

                    <b>{question}</b>
                  </Grid>
                  <Grid item sm={2} md={2} lg={2} xs={2}
                    style={{
                      margin: '0 55px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start'
                    }}>
                    {renderAnswer(answer)}
                  </Grid>
                </div>
              ))}
            </Grid>
          )
        )}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 10,
        }}
      />)
      : (<MaterialTable
        columns={columns}
        data={report}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
          },
          rowStyle: (x) => {
            if (x.tableData.id === 0) {
              return { borderTop: '2px solid rgb(212, 212, 212)' }
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 10,
        }}
      />)
  )
}

export default TableReport;