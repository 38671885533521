import React from "react";

//@material-ui
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { red } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import Typography from '@material-ui/core/Typography'
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'
import Grid from '@material-ui/core/Grid'

//others components
import { LoadingContainer } from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import DetailsTable from '../../../components/DetailsTable'

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 350,
    minWidth: 345,
    padding: 12,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

function Inside(props) {
  const classes = useStyles();


  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <Fab disabled style={{ backgroundColor: "#dff6d7" }}>
            <DirectionsCarIcon style={{ color: "#5ed237" }} />
          </Fab>
        }
        title="Veículos dentro da retroárea"
      />
      <CardContent>
      <Typography style={{ fontSize: 85, fontWeight: "bold" }} component="p">
          {props.data === [] ? 0 : props.data?.length || 0}
      </Typography>
      </CardContent>
      <CardActions>
      <Grid container justify="flex-end" alignItems="center"><DetailsTable title="Veículos dentro da retroárea" data={props.data}/></Grid></CardActions>
    </Card>
  );
}

export default Inside;
