import styled from "styled-components";

export const MapContainer = styled.div`
  flex: 1;
  border-right: 1px solid #fff;
`;
export const Flex = styled.div`
  width: calc(100vw - 50px);
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  margin-top: -80px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
`;
