import React, { useState, useEffect } from "react";
//import api from "../../../../../../core/services/api";
import api from "../../../../../core/services/api";
import moment from "moment";
import { useStyles } from "./styles";

//@material-ui
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from '@material-ui/core/LinearProgress'
import GraphCard from "./GraphCard";

const TASK_ACTIVITY_TYPE_DOCA = 6;

function OTDMonth() {
  const classes = useStyles();
  const [state, setState] = useState({
    series: [
      {
        name: "OTD Mensal",
        data: [],
      },
    ],
    start_day: moment().startOf("year").format("YYYY-MM-DD"),
    end_day: moment().endOf("year").format("YYYY-MM-DD"),
    filter_id: 0,
    filter: [],
    legends: [],
    loading:true
  });

  //let year = moment().startOf("year").format("YYYY");

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [state.start_day, state.end_day]);

  async function getData() {
    
    let year = parseInt(moment().startOf("year").format("YYYY"));
 
    let filter = [];
    for (let index = 0; index < 10; index++) {
      filter.push({ id: index, name: year });
      year--;
    }

    try {
      const journeys = await api.get(
        `otdweek?start_day=${state.start_day}&end_day=${state.end_day}`
      );

      let otd = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      let totalotd = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      journeys.data.map((item) => {
        if (item.resource.tasks && item.resource.tasks.length > 0) {

            let activitiesDoca = item.resource.tasks.filter((item) => {
                return item.activity_type == TASK_ACTIVITY_TYPE_DOCA;
            });

            console.log('activitiesDoca', activitiesDoca);

            activitiesDoca.map((item) => {
            const month = parseInt(
              moment(item.start_real, "YYYY-MM-DD HH:mm:ss").format("M"));

           let sec = moment(item.start_real, "YYYY-MM-DD HH:mm:ss").diff(moment(item.start_prevision != null ? item.start_prevision : 0, "YYYY-MM-DD HH:mm:ss"), 'seconds')
           
           if (sec < 59) {
                console.log(sec)
                otd[month - 1] += 1;                
            }

            totalotd[month - 1] += 1;
          });
        }
      });

      otd.forEach((item, index) => {
        otd[index] = (item / totalotd[index]) * 100 ;
        //Porcentagem = (Realizadas dentro do horário/Realizadas) x 100 = (06/12) x 100 = 50%
      });

      let year = moment(state.start_day).format("YYYY")          
      let legends = [
          `Jan ${year}`,
          `Feb ${year}`,
          `Mar ${year}`,
          `Apr ${year}`,
          `May ${year}`,
          `Jun ${year}`,
          `Jul ${year}`,
          `Aug ${year}`,
          `Sep ${year}`,
          `Oct ${year}`,
          `Nov ${year}`,
          `Dec ${year}`,
        ];
        
      setState((prev) => ({
        ...prev,
        series: [
          {
            name: "OTD Mensal",
            data: otd,
          },
        ],
        filter: filter,
        legends:legends,
        loading :false
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  const onChange = (event) => {
      
    const { value } = event.target;
    let filter = state.filter[value]

    let startDate = `${filter.name}-01-01`;
    let endDate = `${filter.name}-12-31`;

    setState((prev) => ({
      ...prev,
      start_day: startDate,
      end_day: endDate,
      filter_id: value,
      loading:true
    }));
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between" alignItems="center" spacing={3}>
          <Grid item lg={10} md={10} sm={10} xs={10}>
            <Typography>OTD Mensal</Typography>
          </Grid>
          <Grid item lg={2} sm={2} md={2} xs={2}>
            <div style={{ marginTop: 10, marginBottom: 0 }}>
              <Select
                MenuProps={{ disableScrollLock: true }}
                fullWidth
                variant="outlined"
                value={state.filter_id}
                onChange={(e) => onChange(e)}
              >
                {state.filter.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </Grid>
        </Grid>

        <GraphCard series={state.series} legends={state.legends} />
      </CardContent>
      <CardActions></CardActions>
      {state.loading &&
          <LinearProgress />
      }
    </Card>
  );
}

export default OTDMonth;
