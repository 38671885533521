import React, { useState, useEffect } from "react";
import api from "../../../../../../../core/services/api";
import moment from "moment";
import Chart from "react-apexcharts";
import options from "./options";

//material ui
import Grid from "@material-ui/core/Grid";

function GraphCard(props) {
  return (
    <Grid container justify="center" style={{ marginTop: 50 }}>
      <Grid item>
        <Chart
          options={options(props.legends)}
          series={props.series}
          type="donut"
          width={480}
        />
      </Grid>
    </Grid>
  );
}

export default GraphCard;
