export default function (legends) {
  return {
    colors: ["#ffcd04", "#ee3649", "#2de0c2", "#7d77ff", "#ff7053", "#2655d8"],
    chart: {
      type: "donut",
      height: 350,
    },
    labels: legends,
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return val.toFixed(2) + "%";
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      position: "right",
    },
  };
}
