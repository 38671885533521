import React, { Component } from "react";
import api from "../../../../core/services/api";
import Table from "@material-ui/core/Table";
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Main from "../../components/Main";
import Container from "@material-ui/core/Container";
import ReactPaginate from 'react-paginate';
import moment from "moment";
import Modal from "./modal";
import Viewer from "./viewer";

import './style.css';

export class Evidences extends Component {
  state = {
    evidences: {
      data: []
    }
  };
  
  componentDidMount() {
    this.getEvidences();
  }
  
  async getEvidences(page = 1) {
    const {data} = await api.get(`/evidence?page=${page}`);
    console.log(data);
    this.setState({ ...this.state, evidences: data });
  }
  
  handlePageClick = ({selected}) => {
    let evidences = this.state.evidences;
    evidences.data = [];
    this.setState({evidences});
    this.getEvidences(selected + 1)
  }
  
  render() {
    const {evidences} = this.state;
    return (
      <>
      <Main>
      <Container maxWidth="lg">
      <TableContainer component={Paper} style={{ marginTop: 70 }}>
      <Table aria-label="simple table">
      <TableHead>
      <TableRow>
      <TableCell>Entregue</TableCell>
      <TableCell align="left">Motorista</TableCell>
      <TableCell align="left">Visualizar</TableCell>
      </TableRow>
      </TableHead>
      <TableBody>
      {!evidences.data.length ? (
        <TableRow>
        <TableCell align="center">
        CARREGANDO...
        </TableCell>
        </TableRow>
        ) : evidences.data.map(evidence => {
          console.log('evidence', evidence)
          return (
            <TableRow key={evidence.id}>
            <TableCell align="left">
            {moment(evidence.delivered_at).format(
              "DD/MM/YYYY HH:mm"
              )}
              </TableCell>
              <TableCell align="left">{evidence.driver}</TableCell>
              <TableCell align="left">
              {evidence.path === 'Sem Imagem' ? <VisibilityOff/> : 
              <Viewer url={`https://ferrero-dev.trackage.io/api/${evidence.path}`}/>}
              </TableCell>
              </TableRow>
              );
            })}
            </TableBody>
            </Table>
            </TableContainer>
            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <ReactPaginate
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={this.state.evidences.lastPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            />
            </div>
            </Container>
            </Main>
            </>
            );
          }
        }
        
        export default Evidences;
        