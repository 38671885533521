import React, {useState, useEffect} from 'react';
import Main from '../../components/Main'
import MaterialTable, { MTableToolbar } from "material-table";
import api from '../../../../core/services/api'

//custom components
import CreateForm from './CreateForm'
import EditForm from './EditForm'

//@material-ui
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import { Fab } from '@material-ui/core';

//@material icons
import DeleteIcon from '@material-ui/icons/Delete'

function VehiclesHorse(){

    const [state, setState] = useState({
      vehiclesHorse: []
    })
    
    useEffect(() => {
      getData();
    }, [])

    async function getData(){
      try {
        const data = await api.get(`vehicles_web?type=horse`);
        setState((prev) => ({
          ...prev,
          vehiclesHorse: data.data
        }))
      } catch (error) {
        console.warn(error)
      }
    }

    async function deleteVehicleHorse(vehicleHorse) {
      try {
  
        if (
          window.confirm(
            "Tem certeza que deseja deletar o cavalo " + vehicleHorse.board
          )
        ) {
          await api.delete(`vehicles_web/${vehicleHorse.id}`);
        }
  
        getData();
      } catch (error) {
        console.warn(error)
      }
    }
 

    return (
    <Main color="#f7f7f7" title="Cavalos">
      <Grid container justify="flex-end" spacing={3} style={{ paddingBottom: 12 }}>
        <Grid item>
          <CreateForm
            //datas
            getData={getData}
          />
        </Grid>
        {/* <Grid item>
          <Fab
            color="primary"
            style={{ zIndex: 9999, position: "fixed", bottom: "20px", right: "0.2%", backgroundColor: "#f5f5f5" , color: "#0b0e10"}} */}
           {/* onClick={handleClickOpen} */} 
         {/*  <SaveAltIcon />
          </Fab>
        </Grid> */}
      </Grid>
        <MaterialTable
        columns={[
          {
            title: "#",
            field: "id",
            cellStyle: {paddingLeft: 24, textAlign: "left", width: '20%', paddingRight: 80 },
            headerStyle: {borderBottom: '#fff', borderRadius: '8px 0px 0px 0px'}
          },
          {
            title: "Frota",
            field: "board",
            cellStyle: {paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff'}
          },
          {
            field: "actions",
            title: "Ações",
            cellStyle: {  paddingBottom: 0, paddingTop: 0, textAlign: "right" },
            headerStyle: {paddingRight: 20, textAlign: "right", borderRadius: '0px 8px 0px 0px'},
            render: (rowData) => (
              <>
                <FormGroup row style={{justifyContent: 'flex-end'}}>
                  <EditForm vehicle={rowData} getData={getData} /> 
                  <IconButton
                    style={{color: "#ec273b"}}
                   onClick={() => deleteVehicleHorse(rowData)}
                  >
                    <DeleteIcon />
                  </IconButton>{" "}
                </FormGroup>
              </>
            ),
          },
        ]}
        data={state.vehiclesHorse}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
          },
          rowStyle: (x) => {
            if (x.tableData.id === 0){
              return { borderTop: '2px solid rgb(212, 212, 212)'}
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 10,
        }}
      />
    </Main>
    )
}

export default VehiclesHorse;