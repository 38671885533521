import React, { useState, useEffect} from 'react'
import api from '../../../../core/services/api'
import MaterialTable, { MTableToolbar } from "material-table";
import swal from "sweetalert";


//material-ui
import FormGroup from '@material-ui/core/FormGroup'
import SearchIcon from "@material-ui/icons/Search"
import {Grid, IconButton, Tooltip, InputBase, InputAdornment} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { makeStyles } from "@material-ui/core/styles";
import {SpeedDial, SpeedDialIcon, SpeedDialAction} from "@material-ui/lab";
import { ThemeProvider } from "@material-ui/styles";


//custom components
import Main from '../../components/Main'
import CreateForm from './CreateForm'
import EditForm from './EditForm'
import { fcaTheme } from "./styles";





const useStyles = makeStyles({
  root: {
    transform: "translateZ(0px)",
    flexGrow: 1,
    color: '#69f0ae'
  },
  speedDial: {
    zIndex: 9999,
    position: "fixed",
    bottom: "12%",
    right: "0.2%",
    "& .MuiSpeedDial-fab": {
      backgroundColor: '#5ad545'
    },
  }
}
);


function Segments(){
  const classes = useStyles();
  const [state, setState] = useState({
    segments: [],
  })
  const [open, setOpen] = useState(false);
  const [filteredData, setFilteredData] = useState('');
  const [direction, setDirection] = React.useState("up"); 
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    getData();
  }, [])

  async function getData() {
    try {
      const {data} = await api.get(`segments`);
      setState((prev) => ({
        ...prev,
        segments: data.data.data || []
      }))
    } catch (error) {
      console.warn(error)
    }
  };

  const handleSearch = (value) =>{
    const filter = state.receivers.filter((item) => { return item.name.toLowerCase().includes(value.toLowerCase()) })
    setFilteredData(filter);
  }

  async function deleteSegment(segment) {
    try {
      swal(`Tem certeza que deseja excluir o segmento ${segment.name}`, {
        buttons: {
          cancel: "Cancelar",
          confirm: {
            text: "Excluir",
            value: true,
          }
        },
      }).then(async (confirm) => {
        if(confirm){
          await api.delete(`segments/${segment.id}`);
          getData();
        }
      })

    } catch (error) {
      console.warn(error)
    }
  };
    return (
        <Main color="#f7f7f7" title="Segmentos">
           <Grid container justify="flex-start" spacing={3} style={{ paddingBottom: 12 }}>
           <Grid item sm={4} md={2} lg={2} xs={2}>
              <ThemeProvider theme={fcaTheme}>
                <InputBase
                    style={{ padding:'4px 6px 3px', width: "100%", border: '2px solid #BCBCBC', borderRadius:'4px' }}
                    placeholder="  Buscar"
                    endAdornment={
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    onChange={(e) => handleSearch(e.target.value)}
                  />
              </ThemeProvider>
            </Grid>
        <Grid item>
        <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={direction}
            getData={getData}
            optionType={state.optionType}
          >
            <SpeedDialAction
              key="CreateForm "
              icon={<CreateForm getData={getData} />}
              tooltipTitle="Novo Segmento"
              onClick={() => handleOpen()}
            />
          </SpeedDial>   
                </Grid>
      </Grid>
     
        <MaterialTable
        columns={[
          {
            title: "Nome",
            field: "name",
            cellStyle: { paddingLeft: 24, textAlign: "left", paddingRight: 80 },
            headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
          },
          {
            field: "actions",
            title: "Ações",
            cellStyle: { paddingBottom: 0, paddingTop: 0, textAlign: "right" },
            headerStyle: { paddingRight: 20, textAlign: "right", borderRadius: '0px 8px 0px 0px' },
            render: (rowData) => (
              <>
              <FormGroup row style={{ justifyContent: 'flex-end' }}>
                <EditForm segments={rowData} getData={getData} />
              <Tooltip title="Excluir">
                <IconButton
                  style={{ color: "#767676" }}
                  onClick={() => deleteSegment(rowData)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>{" "}
              </FormGroup>
            </>
            ),
          },
        ]}
        data={filteredData.length ? filteredData : state.segments}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
          },
          rowStyle: (x) => {
            if (x.tableData.id === 0) {
              return { borderTop: '2px solid rgb(212, 212, 212)' }
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 10,
        }}
      />
    </Main>
    )
}



export default Segments;