import React, {useState} from 'react'
import MaterialTable, { MTableToolbar } from "material-table";
import { Typography } from '@material-ui/core';

//custom components

// Utils
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from "@material-ui/pickers";

  //material-ui
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup';

function LocalTable(props){
const [startDate, setStartDate] = useState(moment(Date.now()).subtract(5, "d").format("YYYY-MM-DD"));
const [endDate, setEndDate] = useState( moment(Date.now()).format("YYYY-MM-DD"));
const handleStartDate = (date) => {
    setStartDate(date);
  };
const handleEndDate = (date) => {
    setEndDate(date);
}




 return (
     <> 
    <MaterialTable
    columns={[
      {
        title: "Local",
        field: "name",
        sorting: false,
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
      },
      {
        title: "Tempo Médio",
        field: "tempo_medio",
        cellStyle: { paddingLeft: 60, textAlign: "left", border: "#fff" },
        render: (rowData) => moment(rowData.tempo_medio, "HH:mm:ss.SSS").format("HH:mm:ss")
      },
      {
        title: "Tempo Maior",
        field: "maior_tempo",
        cellStyle: { paddingLeft: 60, textAlign: "left", border: "#fff" },
        render: (rowData) => moment(rowData.maior_tempo, "HH:mm:ss.SSS").format("HH:mm:ss")
      },
    ]}
    data={props.data}        
    options={{
      minBodyHeight: 580,
      pageSize: 10,
      actionsColumnIndex: -1,
      search: false,
      showTitle: false,
      headerStyle: {
        paddingLeft: 40,
        textAlign: "left",
        backgroundColor: "#e8ebef",
        border: "#fff",
      },
      rowStyle: (x) => {
        if (x.tableData.id % 2) {
          return { backgroundColor: "#f3f5f8" };
        }
      },
    }}
    components={{
      Toolbar: (props) => (
        <div style={{ display: "none" }}>
          <MTableToolbar {...props} />
        </div>
      ),
    }}
    style={{
      borderRadius: 5,
    }}
  /></>
 )
}

export default LocalTable;