import React from "react";
import { lighten, withStyles } from "@material-ui/core/styles";
import moment from "moment";
//@material-ui
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import LinearProgress from "@material-ui/core/LinearProgress";

//custom components
import MapModal from "./MapModal";
import Grafico from "./Grafico";

function CardMotorista(props) {
  const BorderLinearProgress = withStyles({
    root: {
      height: 20,
      backgroundColor: lighten(getColor(props.cycles), 0.7),
    },
    bar: {
      borderRadius: 3,
      backgroundColor: getColor(props.cycles),
    },
  })(LinearProgress);

  function getColor(counter) {
    let aux = isNaN(counter) ? 0 : (parseInt(counter) * 100) / 6;
    if (aux >= 100) {
      return "#5ed237";
    } else if (aux > 40) {
      return "#0091e6";
    } else {
      return "#f34336";
    }
  }

  function getColortext(counter) {
    let aux = isNaN(counter) ? 0 : (parseInt(counter) * 100) / 6;
    if (aux >= 60) {
      return "#fff";
    } else {
      return "#f34336";
    }
  }
  function getPercentual(counter) {
    let aux = isNaN(counter) ? 0 : (parseInt(counter) * 100) / 6;
    let response = aux > 100 ? 100 : aux;
    return isNaN(response) ? 0 : response;
  }

  function formatPercentual(items) {
    let result = getPercentual(items);
    let msg = `${parseInt(result)}%`;

    return msg;
  }

  return (
    <Card>
      <Grid
        container
        direction="row"
        alignItems="center"
        style={{ padding: 12, backgroundColor: "#e8ebef", textAlign: "center" }}
      >
        <Grid item lg={2}></Grid>
        <Grid item lg={8}>
          {props.board}
        </Grid>
        <Grid item lg={2}>
          <Grafico
            board={props.board}
            date={props.date}
            id={props.vehicle_id}
          />
        </Grid>
      </Grid>

      <CardContent style={{ textAlign: "center" }}>
        {props.date !== moment(Date.now()).format("YYYY-MM-DD") ? (
          " "
        ) : (
          <MapModal
            id={props.vehicle_id}
            board={props.board}
            latitude={props.latitude}
            longitude={props.longitude}
          />
        )}

        <Grid container>
        <Grid item lg={4} direction="column">
            <p style={{ opacity: "50%", fontSize: 10 }}>TEMPO NA FILIAL CONGONHAS</p>
            <Grid item>
              <div
                style={{
                  backgroundColor: "#f3f5f8",
                  borderRadius: 12,
                  width: "fit-content",
                  margin: "0 auto",
                  paddingRight: 12,
                  paddingLeft: 12,
                }}
              >
                <p style={{ opacity: "70%" }}>
                  <AccessTimeIcon
                    style={{
                      verticalAlign: "middle",
                      fontSize: 16,
                      color: "#1473e6",
                    }}
                  />{" "}
                  {props.tempo_filial ? (
                    <>
                      {" "}
                      {moment.duration(props.tempo_filial).asMinutes() <= 60
                        ? moment(props.tempo_filial, "HH:mm:ss.SSS").format(
                            "HH:mm"
                          ) + "m"
                        : moment(props.tempo_filial, "HH:mm:ss.SSS").format(
                            "HH:mm"
                          ) + "h"}
                    </>
                  ) : (
                    "00:00m"
                  )}
                </p>{" "}
              </div>
            </Grid>
          </Grid>
          <Grid item lg={4} direction="column">
            <p style={{ opacity: "50%", fontSize: 10 }}>TEMPO EM OPERAÇÃO</p>
            <Grid item>
              <div
                style={{
                  backgroundColor: "#f3f5f8",
                  borderRadius: 12,
                  width: "fit-content",
                  margin: "0 auto",
                  paddingRight: 12,
                  paddingLeft: 12,
                }}
              >
                <p style={{ opacity: "70%" }}>
                  <AccessTimeIcon
                    style={{
                      verticalAlign: "middle",
                      fontSize: 16,
                      color: "#1473e6",
                    }}
                  />{" "}
                  {props.time_operation ? (
                    <>
                      {" "}
                      {moment.duration(props.time_operation).asMinutes() <= 60
                        ? moment(props.time_operation, "HH:mm:ss.SSS").format(
                            "HH:mm"
                          ) + "m"
                        : moment(props.time_operation, "HH:mm:ss.SSS").format(
                            "HH:mm"
                          ) + "h"}
                    </>
                  ) : (
                    "00:00m"
                  )}
                </p>{" "}
              </div>
            </Grid>
          </Grid>
          <Grid item lg={4} direction="column">
            <p style={{ opacity: "50%", fontSize: 10 }}>CICLOS COMPLETADOS</p>
            <Grid item>
              <div
                style={{
                  backgroundColor: "#f3f5f8",
                  borderRadius: 12,
                  width: "fit-content",
                  margin: "0 auto",
                  paddingRight: 12,
                  paddingLeft: 12,
                }}
              >
                <p style={{ opacity: "70%" }}>
                  <AccessTimeIcon
                    style={{
                      verticalAlign: "middle",
                      fontSize: 16,
                      color: "#1473e6",
                    }}
                  />{" "}
                  {props.cycles ? props.cycles : "0"} ciclos
                </p>{" "}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <div style={{ marginTop: -10, position: "relative" }}>
        <p
          style={{
            fontSize: 12,
            fontWeight: "bold",
            textAlign: "center",
            margin: "0 auto",
            zIndex: 99,
            top: "20%",
            left: "48%",

            position: "absolute",
            color: getColortext(props.cycles),
          }}
        >
          {formatPercentual(props.cycles)}
        </p>
        <BorderLinearProgress
          style={{ height: 30 }}
          variant="determinate"
          value={getPercentual(props.cycles)}
        />
      </div>
    </Card>
  );
}

export default CardMotorista;
