import React from "react";
import * as _ from "lodash";
import CustomSelect from "../../../../components/CustomSelect";
import TextField from "@material-ui/core/TextField";
import {
  ConditionalAdd,
  ConditionalClose,
  FormConditional,
  FormLine,
  FormMessage,
} from "./style";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";

const FormMessages = ({ messages = [],fields = [], onChange = () => {}, onAdd = () => {} }) => {
const listServices = [{id:'email',name: 'E-mail'},{id:'sms',name:'Sms'},{id:'telegram',name:'Telegram'}]
  const serviceLabel = {
    email: 'E-mail',
    sms: 'Sms',
    telegram: 'Telegram'
  }

  const onChangeMessage = (messages, index, e) => {
    const _messages = _.cloneDeep(messages);
    return _.set(_messages, [index, e.target.name], e.target.value);
  };
  const onRemoveMessage = (messages, index) => {
    const _messages = _.cloneDeep(messages);
    return _messages.filter((value, _index) => _index !== index);
  };
  const findInvalidVariables = (template) => {
    const templateVariables = template.match(/(#[a-zA-Z0-9]{1,})/g) || [];
    const variables = fields.map((item) => item.label);
    const invalid = templateVariables.filter((item) => !variables.includes(item));
    return invalid && invalid.length > 0 ? `Variáveis não reconhecidas ${invalid.join(", ")}.` : ''
  };
  const render = () => {
    return messages.map((message, index) => (
        <FormConditional key={'message'+index}>
        <ConditionalClose>
            <CloseIcon onClick={() => onChange(onRemoveMessage(message,index))}/>
        </ConditionalClose>
        <FormMessage>
          <FormLine>
            <CustomSelect
              label="Serviço"
              value={message.service}
              name="service"
              data={listServices}
              onChange={(e) => onChange(onChangeMessage(messages,index,e))}
            />
          </FormLine>
          <FormLine>
            <TextField
              fullWidth
              rows={3}
              multiline={true}
              label={serviceLabel[message.service]}
              type="text"
              variant="filled"
              name="message"
              value={message.message}
              onChange={(e) => onChange(onChangeMessage(messages,index,e))}
            />
          </FormLine>
          <FormLine>
            <p style={{ color: "red" }}>{findInvalidVariables(message.message)}</p>
          </FormLine>
        </FormMessage>
      </FormConditional>
    ));
  };
  return (
    <>
        {render()}
        <ConditionalAdd>
            <AddIcon onClick={onAdd} />
        </ConditionalAdd>
    </>
  );
};

export default FormMessages;
