import styled from 'styled-components';

const dhlCor = '##ececec';

export const TopBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 54px;
  background: #FFFFFF;

  padding: 0;
  margin: 0;
  /* border-bottom: 1px solid #c1d3ec; */
  position: fixed;
  z-index: 3;
`;

export const Logo = styled.img`
  width: 125px;
  margin-left:22px;
`;

export const CirculoVerde = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #32CD32;
  margin-right: 5px;
  margin-left:6px;
`;

export const CirculoVermelho = styled.div`

  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: red;
  margin-right: 5px;
  margin-left:20px;
  
`;

export const Container = styled.div`
  display:flex
  height: 100%;
  width: 40%;
  justify-content: flex-end;
  align-items: center;

  
`

export const ContainerCircles = styled.div`
  display:flex
  height: 100%;
  width: 500px;
  align-items: center;
 
  
`



