import React from "react";
import Container from "@material-ui/core/Container";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { LoadingContainer } from "./styles";

import { Container as Content } from "./styles";
import Main from "../../components/Main";
import api from "../../../../core/services/api";
import moment from "moment";
import MaterialTable, { MTableToolbar, MTablePagination } from "material-table";

//material-ui
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import ShareIcon from '@material-ui/icons/Share'

export default class index extends React.Component {
  state = {
    vehicles: [],
    date: moment(Date.now()).format("YYYY-MM-DD"),
    loading: true,
    pageNumber: 1,
    totalRow: 30,
    numberRowPerPage: 10
  };

  componentDidMount() {
    this.getCount(moment(Date.now()).format("YYYY-MM-DD"));
    this.getData(moment(Date.now()).format("YYYY-MM-DD"), this.state.pageNumber, this.state.numberRowPerPage);
  }

  filterFleet(value) {
    this.setState({ ...this.state, date: value, vehicles: [], pageNumber: 1 }, () =>
      this.getData(this.state.date, this.state.pageNumber, this.state.numberRowPerPage)
    );
  }
  async getCount(date){
    const { data } = await api.get( `https://flex.trackage.io/api/v1/tora_vehicles_count?date=${date}`);
    this.setState({...this.state, totalRow: parseInt(data[0].count)})
  }
  async getData(date, page, rowPerPage ) {
    const { data } = await api.get(
      `https://flex.trackage.io/api/v1/tora_vehicles?date=${date}&pageNumber=${page}&numberRowPerPage=${rowPerPage}`
    );
    this.setState({ ...this.state, vehicles: data, loading: false });
  }
  handleChangePage(page){
    console.log('mudando de pagina', page)
    this.setState({...this.state, vehicles: [], pageNumber: page})
    this.getData(this.state.date, page, this.state.numberRowPerPage)
  }

  render() {
    return (
      <Main title="Historico dos Veículos">
        <Content>
          <Container>
            <TextField
              id="date"
              style={{ paddingBottom: 12 }}
              defaultValue={moment(Date.now()).format("YYYY-MM-DD")}
              label="Filtro de Data"
              type="date"
              onChange={e => this.filterFleet(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />

<MaterialTable
    columns={[
      {
        title: "Placa",
        field: "board",
        sorting: false,
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
      },
      {
        title: "Modelo",
        field: "model",
        sorting: false,
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
      },
      {
        title: "Última atualização",
        field: "last_update",
        sorting: false,
        cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
        render: (rowData) => moment(rowData.last_update, "YYYY-MM-DD HH:mm:ss").subtract(3, "hours").format("DD/MM/YYYY HH:mm")
      },
      {
        field: "actions",
        title: "Linha do Tempo",
        cellStyle: { padding: 0, paddingLeft: 40, textAlign: "right", border: "#fff", width: 20, maxWidth: 20 },
        render: (rowData) => (
          <>
            <FormGroup row>
            <Link
                          to={`/timeline-show/${rowData.id}/${moment.utc(
                            rowData.last_update,
                            "YYYY-MM-DD HH:mm:ss"
                          )
                            .subtract(3, "hours")
                            .format("YYYY-MM-DD")}`}
                        >
                          <IconButton>
  <ShareIcon />
</IconButton>
                        </Link>
            
            </FormGroup>
          </>
        ),
      },
    ]}
    data={this.state.vehicles}        
    options={{
      pageSize: 10,
      actionsColumnIndex: -1,
      search: false,
      showTitle: false,
      headerStyle: {
        paddingLeft: 40,
        textAlign: "left",
        backgroundColor: "#e8ebef",
        border: "#fff",
      },
      rowStyle: (x) => {
        if (x.tableData.id % 2) {
          return { backgroundColor: "#f3f5f8" };
        }
      },
    }}
    components={{
      Toolbar: props => (
        <div style={{ display: "none" }}>
          <MTableToolbar {...props} />
        </div>
      ),
      Pagination: props => (
        
              <MTablePagination
              {...props}
              rowsPerPageOptions={[5, 10, 20, 30]}
              rowsPerPage={this.state.numberRowPerPage}
              count={this.state.totalRow}
              page={
              this.firstLoad
              ? this.state.pageNumber
              : this.state.pageNumber - 1
              }
              onChangePage={(e, page) =>
                this.handleChangePage(page +1)
              }
              onChangeRowsPerPage={event => {
            props.onChangeRowsPerPage(event);
            this.handleChangeRowPerPage(event.target.value);
          }}  />
          
      ),
    }}
    localization={{
      pagination: {
          labelDisplayedRows: '{from}-{to} de {count}'
      },
      body: {
          emptyDataSourceMessage: 'Carregando dados',
          
      }
  }}
  
    style={{
      borderRadius: 5,
    }}
  />
          </Container>
        </Content>
      </Main>
    );
  }
}
