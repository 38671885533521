import React, {useState, useEffect} from 'react';
import api from '../../../../../core/services/api'
//@material-ui
import Grid from '@material-ui/core/Grid'



//custom components
import Table from './Table'
import EffectivenessRoute from './EffectivenessRoute'
import EffectivenessHour from './EffectivenessHour'
import moment from 'moment';

function EffectivenessPanel(){
    

    return (
    <Grid container spacing={3}>
        <Grid item md={12} lg={12} sm={12} xs={12}>
        <Table />
        </Grid>
        <Grid item md={12} lg={12} sm={12} xs={12}>
        <EffectivenessRoute />
        </Grid>
        <Grid item md={12} lg={12} sm={12} xs={12}>
        <EffectivenessHour />
        </Grid> 

    </Grid> 
    )
}

export default EffectivenessPanel;