import moment from 'moment'
export default function(legends, target) {
    return {
        colors: [function({value, seriesIndex, w}){
          if(value > target[seriesIndex]){
            return '#ed2238'
          } else {
            return "#21a2fc"
          }
        },
        function({value, seriesIndex, w}){
          if(value > target[seriesIndex]){
            return '#ed2238'
          } else {
            return "#21a2fc"
          }
        },
        function({value, seriesIndex, w}){
          if(value > target[seriesIndex]){
            return '#ed2238'
          } else {
            return "#21a2fc"
          }
        },
        '#7e78ff'],
        chart: {
          height: 350,
          type: "bar",
        },
        legend: {
            show: false,
            position: 'bottom',
            horizontalAlign: 'left',
        },

        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
          width: 2
        },
      
        xaxis: {
          categories: legends,
          
        },
        yaxis: {
          labels: {  
            show: false,
            formatter: function (value) {
              return moment().startOf('day').add(moment.duration({s: value})).format('mm:ss')+'m';
            }
          },
        },
      }
  }