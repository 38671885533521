import React, {useState, useEffect} from 'react'
import moment from 'moment'
import api from '../../../../core/services/api'
import { Link } from "react-router-dom";
import MaterialTable, { MTableToolbar } from "material-table";

//custom Components
import Main from "../../components/Main";
import { Container } from "./styles";
import CreateForm from './CreateForm'
import EditForm from './EditForm'


//material-ui
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Grid from '@material-ui/core/Grid'


function Driver(){

  const [state, setState] = useState({
    carriers: [],
    carriersall: []
  })
  const usuario = JSON.parse(localStorage.getItem("usuario"));
  console.log('usuario', usuario.system_client_id)
  useEffect(() => {
    getCarriers();
    getCarriersall();
    getData();
  }, []);
  async function getData() {
    try {
      const { data } = await api.get(`drivers`);
      //const filterdata = data.filter((item) => item.user_type === 2);
      setState((prev) => ({
        ...prev,
        users: data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }
  async function getCarriers() {
    try {
      const { data } = await api.get(`carriers`);
      setState((prev) => ({
        ...prev,
        carriers: data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }
  async function getCarriersall() {
    try {
      const { data } = await api.get(`carriers-all`);
      setState((prev) => ({
        ...prev,
        carriersall: data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  async function deleteDriver(item) {
    try {
      if (
        window.confirm(
          "Tem certeza que deseja deletar motorista " + item.name
        )
      ) {
        await api.delete(`drivers/` + item.driver_id);
      } else {
      }

      getData();
    } catch (error) {
      console.warn(error);
    }
  }

  function getCarrierName(id){
    const name = state.carriersall.filter((item) => {return item.id === id})
    return name[0]?.name;
  }

  
    
    return(
        <Main title="Motoristas">
        <Container>
        <Grid
        container
        justify="flex-end"
        spacing={3}
        style={{ paddingBottom: 12 }}
      >
        <Grid item>
          <CreateForm
            //datas
           
            getData={getData}
            carrier_list={state.carriers}
          />
        </Grid>
      </Grid>
        <MaterialTable
        columns={[
          {
            title: "Motorista",
            field: "name",
            cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
          },
          {
            title: "CPF",
            field: "cpf",
            cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
            render: (rowData) => rowData.resource.cpf
          },
          {
            title: "Telefone",
            field: "phone",
            cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
          },
          {
            title: "Transportadora",
            field: "carrier_id",
            cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
            render: (rowData) => getCarrierName(rowData.carrier_id)
          },
          {
            title: "CNH",
            field: "cnh",
            cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
            render: (rowData) => rowData.resource.cnh
          },
          {
            field: "actions",
            title: "Ações",
            cellStyle: { paddingLeft: 40, paddingBottom: 0, paddingTop: 0, textAlign: "left", border: "#fff" },
            render: (rowData) => (
              <>
                <FormGroup row>
                  <EditForm driver={rowData}  getData={getData} carrier_list={state.carriers} /> 
                  <IconButton
                    color="secondary"
                    onClick={() => deleteDriver(rowData)}
                  >
                    <DeleteIcon />
                  </IconButton>{" "} 
                </FormGroup>
              </>
            ),
          },
        ]}
        data={state.users}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          headerStyle: {
            paddingLeft: 40,
            textAlign: "left",
            backgroundColor: "#1c1c1c",
            color: "#fff",
            border: "#fff"
          },
          rowStyle: (x) => {
            if (x.tableData.id % 2) {
              return { backgroundColor: "#f3f5f8" };
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 5,
        }}
      />
        </Container>
        </Main>
    )
}

export default Driver;