import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { MenuContainer, Icon, MenuItem } from "./styles";
import { logout } from "../../../../core/config/auth";
import trackage from "../../assets/favicon.png";
import dashboard from "../../assets/icons/ic_tv_-1.svg";
import logoutIcon from "../../assets/icons/logout.svg";
import map from "../../assets/icons/map.svg";
import search from "../../assets/icons/search.svg";
import car from "../../assets/icons/car.svg";
import report from "./growth.png";
// import evidenceLogo from "./shipped.svg";

export class Menu extends Component {
  logout() {
    localStorage.removeItem("jwt");
    localStorage.removeItem("usuario");
    this.props.history.push("/login");
    this.props.history.go();
  }
  render() {

    const usuario = JSON.parse(localStorage.getItem("usuario"));
    const { pathname } = this.props.location;
    return (
      <MenuContainer>
        <img src={trackage} alt="logo trackage" width="24" height="24" />
       
        {usuario?.menu.map((menu) => {
            return ( <>
            {menu.resource.icon ? ( <MenuItem>
                <Link to={"/"+menu.resource.route}>
                  <Icon src={menu.resource.icon} alt={menu.name} rota={"/"+menu.resource.route} rotaAtual={pathname} width="19.69"  height="16" />
                  </Link>
                </MenuItem>) : ""}
               
            </>)
          }) }

        <MenuItem onClick={() => this.logout()}>
          <Icon
            src={logoutIcon}
            rota="/sair"
            rotaAtual={pathname}
            alt="logo trackage"
            width="19.69"
            height="16"
          />
        </MenuItem>
      </MenuContainer>
    );
  }
}
export default withRouter(Menu);
