import React, { useEffect } from "react";
import api from '../../../../../core/services/api'


import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Grid from '@material-ui/core/Grid'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';


function EditForm(props) {
  const [state, setState] = React.useState({
    error: false,
    disabled: false,
    showPassword: '',
    active: true
  });

  const [name, setName] = React.useState("");
  const [active, setActive] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setState({
      disabled: false,
      error: false,
    })
    setName(props.carrier.name)
    setActive(props.carrier.active)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setActive(value);
  };

  const handleSubmit = () => {
    setState((prev) => ({
      ...prev,
      disabled: true
    }))
    const carrier = {
      name: name,
      active: active
    }
   
    postCarrier(carrier);
    console.log('Transportadora criada',carrier)
    setOpen(false)
    
  }

  async function postCarrier(obj){
    try {
      await api.put(`carriers/`+props.carrier.id, obj);
      props.getData()
    } catch (error) {
      console.warn(error)
      
    }
  }



  return (
    <div>
       <IconButton onClick={handleClickOpen}>
        <EditIcon style={{ color: "#fcc326" }} className="icon-small" />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
      
      >
        <DialogTitle>Cadastar nova Transportadora</DialogTitle>

        <DialogContent>
         
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                  //error={state.error ? (forklift_number ? false : true) : false}
                  id="name"
                  name="name"
                  label="Nome transportadora"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                />{" "}
              </div>
             
              
              <FormControlLabel
                control={
                  <Switch
                    checked={active}
                    onChange={(event) => handleChange(event)}
                    name="active"
                    color="primary"
                  />
                }
                label={active ? "Ativo" : "Inativo"}
              />
            
        </DialogContent>

        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor:state.disabled ? "" : "#5ed237", color:state.disabled ? "" : "#fff" }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditForm;
