import React, {useState, useEffect} from 'react'
import api from '../../../../../../core/services/api'
import moment from 'moment'
import Chart from "react-apexcharts";
import options from "./options";
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";


//material ui
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import StopIcon from '@material-ui/icons/Stop'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'


//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// pick a date util library
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";


//custom components
import CustomSelect from '../../../../components/CustomSelect'

function GraphCard(props){
    const usuario = JSON.parse(localStorage.getItem("usuario"));
    const [state, setState] = useState({          
        series: [{
          name: 'Turno 1',
          data: []
        },
        {
          name: 'Turno 2',
          data: []
        },
        {
          name: 'Turno 3',
          data: []
        }
      ],
        legends: [],
        target: [],
        start_day: moment().format("YYYY-MM-DD"),
        end_day: moment().add(1, 'days').format("YYYY-MM-DD"),
        local_list: [],
        shifts_list: [],
        shifts_id: null,
        local_users: usuario.person.resource.local_id,
        local_id: usuario.person.resource.local_id[0],
        local_name: '',
    });

    useEffect(() => {
      getData();
    }, [props.local_id, props.shifts_id, props.start_day])

    useEffect(() => {
      getShift()
    }, [])
  
    async function getShift() {
      try {
        const data = await api.get(`shifts?active=all`);
        const shifts = []
        data.data.data.map((item) => shifts.push(item))
        setState((prev) => ({
          ...prev,
          shifts_list: data.data.data || []
        }))
      } catch (error) {
        console.warn(error)
      }
    }
  async function getData(){
      try {
        let shift = ``;
        if(props.shifts_id != null) shift = `&shift=${props.shifts_id}`

        console.log('props', props );
        
        const data = await api.get(`task-local?start_day=${props.start_day}&end_day=${props.end_day}&local=${props.local_id}`+shift);
        const data2 = await api.get(`place?perPage=1000`);
        let places = data2.data.data;
        if(!state.local_users.includes(60)){
          places = data2.data.data.filter((item) => {if(state.local_users.includes(item.id)){ return item}})
        }
        const data3 = await api.get(`routes`);
        let local_name = data.data[0] ? data.data[0].tasks.title : ""
        let series = [{
          name: 'Realizado',
          type: 'column',
          data: [0,0,0]
        },
        {
          name: 'Programado',
          type: 'column',
          data: [0,0,0]
        },
        {
          name: 'Efetividade',
          type: 'line',
          data: [0,0,0]
        }]
    

      //criando series
      data.data.map((item) =>{
        if(item.tasks.local == props.local_id){
          if(item.tasks.shift == 10){
           //ativdade turno 1 
           series[0].data[0] += 1;
          } else if (item.tasks.shift == 16){
             //atividade turno 2
             series[0].data[1] += 1;
          } else if (item.tasks.shift == 12){
             //atividade turno 3
             series[0].data[2] += 1;
          }
        }
      })

     //target medio 
      data3.data.map((item) => {
        if(item.tasks && item.tasks.length > 0){
          item.tasks.map((item) => {
            if(item.local == props.local_id){
              if(item.shift == 10){
                //ativdade turno 1 
                series[1].data[0] += 1;
              } else if (item.shift == 16){
                //atividade turno 2
                series[1].data[1] += 1;
              } else if (item.shift == 12){
                //atividade turno 3
                series[1].data[2] += 1;
              } 
            }
          })
        }
      })
      series[2].data.forEach((item, index) => {
        series[2].data[index] = (series[0].data[index]*100)/series[1].data[index]
      })

      setState((prev) => ({
        ...prev,
        local_list: places || [],
        series: series,
        local_name: local_name
      }))
      } catch (error) {
        console.warn(error)
      }
    }



    return(
      <Grid container justify="center" alignItems="center" spacing={3}>
        <Grid item lg={6} sm={6} md={6} xs={6}>
        <Typography style={{fontSize: 14, textAlign: 'left'}} color="textSecondary" gutterBottom>
        <b>Efetividade: {state.local_name}</b> - Período {moment(props.start_day, "YYYY-MM-DD").format("DD/MM/YYYY")} 
        </Typography>
        </Grid>
        <Grid item lg={6} sm={6} md={6} xs={6}></Grid>
        <Grid item lg={12} sm={12} md={12} xs={12}>
        <Chart
              options={options()}
              series={state.series}
              type="bar"
              height={350}
            />
        </Grid>
      </Grid>
        
    )
}

export default GraphCard;
