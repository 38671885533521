import React, { Component } from "react";
import { debounce, uniqueId } from "lodash";
import { Link } from "react-router-dom";
import api from "../../../../core/services/api";
import { easeCubic } from 'd3-ease';
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import Select from "@material-ui/core/Select";

// Material UI Icons
import Cold from "@material-ui/icons/AcUnit";
import Hot from "@material-ui/icons/Whatshot";

// Custom Components
import Main from "../../components/Main";
import Pin from "./Pin";

// Mapbox Lib
import { Marker, LinearInterpolator, FlyToInterpolator } from "react-map-gl";
import MapGL from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

// Styled Components
import { MapContainer, Flex } from "./styles";

import Sidebar from "./Sidebar";

export default class Map extends Component {
  constructor() {
    super();
    this.updatePropertiesLocalization = debounce(
      this.updatePropertiesLocalization,
      500
    );
  }

  state = {
    carrier: 'all',
    carriers: [],
    properties: [],
    viewport: {
      width: "100%",
      height: "100vh",
      latitude: -23.4439,
      longitude: -46.4994,
      zoom: 8
    }
  };

  componentDidMount() {
    this.getCarros();
    this.getCarriers();
  }

  navigateTo = (latitude, longitude) => {
    const viewport = {
        ...this.state.viewport,
        latitude: latitude,
        longitude: longitude,
        zoom: 16,
        transitionDuration: 5000,
        transitionInterpolator: new FlyToInterpolator(),
        transitionEasing: easeCubic
    };
    this.setState({viewport});
};

  updatePropertiesLocalization() {
    this.getCarros();
  }

  muda(item, index) {
    // item.telemetries = item.telemetries.slice(-1);
    console.log(item);
  }

  async getCarros() {
    try {
      const resp = await api.get(`/journeys?carrier=${this.state.carrier}`);
      this.setState({ ...this.state, properties: resp.data || [] });
    } catch (err) {
      console.warn(err);
    }
  }

  tempIcon(temperature) {  
      if (temperature < 12) {
        return (
          <Cold style={{fontSize: 12, color: 'white'}} />
          )
      } else if(temperature > 16) {
        return (
          <Hot style={{fontSize: 12, color: 'white'}} />
        )
      }
  }

  showRoute = (path) => this.props.history.push(path)

  async getCarriers() {
    const {data} = await api.get(`/carriers`);
    this.setState({ ...this.state, carriers: data.data || [] });
  }

  filterCarrier(value) {
      this.setState({...this.state, carrier: value}, () => this.getCarros())
  }

  filtraTemperatura(temp) {
    if (temp < 12) {
      return "#2196F3"; // blue
    } else if (temp > 16) {
      return "#EC273B"; // red
    } else {
      return "#5ED237"; // green
    }
  }

  atualiza(viewport) {
    this.setState({ ...this.state, viewport });
  }

  acha(val) {
    return this.state.properties.length !== 0;
  }

  mudaViewport(data) {
    this.setState({
      ...this.state,
      viewport: {
        ...this.state.viewport,
        latitude: data.latitude,
        longitude: data.longitude,
        zoom: 14
      }
    });
  }

  render() {
    const token =
      "pk.eyJ1IjoiZ2FsaWxldXUiLCJhIjoiY2syNDBka3FyMDBhazNudGNrNzM2NWU3ZyJ9.hLvLb-t9Xc9h9C4vxDL8gw";
    return (
      <Main>
        <Flex>
          <MapContainer>
            <MapGL
              {...this.state.viewport}
              mapboxApiAccessToken={token}
              mapStyle="mapbox://styles/mapbox/streets-v11"
              onViewportChange={viewport => this.setState({ viewport })}
            >
              {this.state.properties.filter(x => x.telemetrias !== null)
                .map(property => {
                  return property.telemetrias.map(telemetria => {
                      return (
                      <Marker
                        key={telemetria.journey_id}
                        longitude={telemetria.lon}
                        latitude={telemetria.lat}
                      >
                        <Pin
                          data={telemetria}
                          motorista={property.nome_motorista}
                          placa={property.jornada_placa}
                          lat={telemetria.lat}
                          lon={telemetria.lon}
                          id={telemetria.journey_id}
                          atualizacao={
                            property.telemetrias[
                              property.telemetrias.length - 1
                            ].creation
                          }
                          filtraTemperatura={this.filtraTemperatura(
                            telemetria.temperature
                          )}
                        >
                            <span> {this.tempIcon(telemetria.temperature)} {telemetria.temperature} Cº</span>
                        </Pin>
                      </Marker>
                    );
                  });
                })}
            </MapGL>
          </MapContainer>
          <Sidebar
            showRoute={this.showRoute}
            goto={this.navigateTo}
            data={this.state.properties}
            click={x => this.mudaViewport(x)}
          >
              <ListItem>
                <Select 
                    style={{minWidth: '100%', marginTop: 16, marginBottom: 17}}
                    value={this.state.carrier}
                    onChange={(e) => this.filterCarrier(e.target.value)}>
                    <MenuItem value='all'>Todas</MenuItem>
                    {this.state.carriers.map(({id, name}) => <MenuItem value={id}>{name}</MenuItem>)}
                </Select>
              </ListItem>
              <ListItem>
              </ListItem>
          </Sidebar>
        </Flex>
      </Main>
    );
  }
}
