import React, { useState, useEffect } from "react";
import api from "../../../../../../core/services/api";
import moment from "moment";
import { useStyles } from "./styles";

//@material-ui
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";

//custom components
import GraphCard from "./GraphCard";
import CustomSelect from "../../../../components/CustomSelect";

function HDHour(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    start_day: moment().format("YYYY-MM-DD"),
    end_day: moment().add(1, "days").format("YYYY-MM-DD"),
    routes_list: [],
    route_id: null,
    legends: [],
    efet_route: [],
    series: [
      {
        name: "Hora Direta Real ",
        type: "column",
        data: [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0,
        ],
      },
      {
        name: "% Hora Direta",
        type: "line",
        data: [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0,
        ],
      },
      {
        name: "Hora Direta Plan",
        type: "line",
        data: [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0,
        ],
      },
    ],
  });

  useEffect(() => {
    getData();
  }, [state.route_id, state.start_day]);

  async function getRoutes() {
    try {
      const data = await api.get(`routes`);
      setState((prev) => ({
        ...prev,
        routes_list: data.data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  async function getData() {
    try {
      let shift = ``;
      if (state.shifts_id != null) shift = `&shift=${state.shifts_id}`;
      const data = await api.get(
        `hdtime-day?start_day=${state.start_day}&end_day=${state.end_day}`
      );
      const data2 = await api.get(`routes`);
      let routes_filtered = data2.data;
      let hd_filtered = data.data;
      let qtd_routes = 0;
      let legends_routes = [];

      data.data.forEach((item, index) => {
        let exist = legends_routes.includes(item.name);
        if (!exist) {
          legends_routes.push(item.name);
        }
      });
      qtd_routes = legends_routes.length;

      if (state.route_id != null) {
        qtd_routes = 1;
        routes_filtered = data2.data.filter((item) => {
          return item.id == state.route_id;
        });
        hd_filtered = data.data.filter((item) => {
          return item.name == routes_filtered[0].name;
        });
      }
      let legends = [
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "00",
        "1",
        "2",
        "3",
        "4",
        "5",
      ];
      let series = [
        {
          name: "Hora Direta Real ",
          type: "column",
          data: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0,
          ],
        },
        {
          name: "% Hora Direta",
          type: "line",
          data: [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
            0,
          ],
        },
        {
          name: "Hora Direta Plan",
          type: "line",
          data: [
            2295, 3060, 3060, 3060, 3060, 0, 3060, 3060, 3060, 2448, 2907, 3060,
            3060, 0, 3060, 3060, 3060, 3060, 3060, 2754, 3060, 2295, 3060, 3060,
          ],
        },
      ];
      hd_filtered.forEach((item, index) => {
        if (item.jornada.tasks) {
          item.jornada.tasks.forEach((item2, idx) => {
            if (item2.end_real) {

              let start_task = moment(item2.start_real, "YYYY-MM-DD HH:mm:ss").hour();
              let end_task = moment(item2.end_real, "YYYY-MM-DD HH:mm:ss").hour();
              let end_moment = moment(item2.end_real, "YYYY-MM-DD HH:mm:ss").startOf('hour');


              let start_index = start_task == 0 ? "00" : start_task.toString();
              let end_index = end_task == 0 ? "00" : end_task.toString();

              let index_legend = legends.indexOf(start_index);
              let index_end = legends.indexOf(end_index);
              let duration1 = moment(end_moment).diff(moment(item2.start_real, "YYYY-MM-DD HH:mm:ss"), "seconds");
              let duration2 = moment(item2.end_real, "YYYY-MM-DD HH:mm:ss").diff(moment(item2.start_real, "YYYY-MM-DD HH:mm:ss"), "seconds");



              //DIVIDINDO HORÁRIOS DA ATIVIDADE
              //ADICIONANDO TEMPO DE DOCA E OUTROS
              if (item2.activity_type == 6 || item2.activity_type == 11 || item2.activity_type == 12) {
                if (start_task != end_task) {
                  series[0].data[index_legend] += duration1;
                  series[0].data[index_end] += (duration2 - duration1);
                } else {
                  series[0].data[index_legend] += duration2;
                }
              }



              //ADICIONANDO TEMPO DE TRANSITO
              if (item2.transit_time_end != "" && item2.transit_time_end != null) {

                let start_transit = moment(item2.transit_time_start, "YYYY-MM-DD HH:mm:ss").hour();
                let end_transit = moment(item2.transit_time_end, "YYYY-MM-DD HH:mm:ss").hour();

                let start_transit_index = start_transit == 0 ? "00" : start_transit.toString();
                let end_transit_index = end_transit == 0 ? "00" : end_transit.toString();
                let index_transit = legends.indexOf(start_transit_index);
                let index_transit_end = legends.indexOf(end_transit_index.toString());

                let end_moment_transit = moment(item2.transit_time_end, "YYYY-MM-DD HH:mm:ss").startOf('hour');
                let duration_transit1 = moment(end_moment_transit).diff(moment(item2.transit_time_start, "YYYY-MM-DD HH:mm:ss"), "seconds");
                let duration_transit2 = moment(item2.transit_time_end, "YYYY-MM-DD HH:mm:ss").diff(moment(item2.transit_time_start, "YYYY-MM-DD HH:mm:ss"), "seconds");

                //SUBTRAINDO OCORRÊNCIA
                if (item2.issues[0] && item2.issues[0].finish_at) {
                  let start_issue = moment(item2.issues[0].created_at, "YYYY-MM-DD HH:mm:ss").hour();
                  let end_issue = moment(item2.issues[0].finish_at, "YYYY-MM-DD HH:mm:ss").hour();

                  let index_issue = legends.indexOf(start_issue.toString());
                  let index_issue_end = legends.indexOf(end_issue.toString());

                  let end_moment_issue = moment(item2.issues[0].finish_at, "YYYY-MM-DD HH:mm:ss").startOf('hour');

                  let duration_issue1 = moment(end_moment_issue).diff(moment(item2.issues[0].created_at, "YYYY-MM-DD HH:mm:ss"), "seconds");
                  let duration_issue2 = moment(item2.issues[0].finish_at, "YYYY-MM-DD HH:mm:ss").diff(moment(item2.issues[0].created_at, "YYYY-MM-DD HH:mm:ss"), "seconds");


                  if (start_issue != end_issue) {
                    series[0].data[index_issue] -= duration_issue1;
                    series[0].data[index_issue_end] -= (duration_issue2 - duration_issue1);
                  } else {
                    series[0].data[index_issue] -= duration_issue2;
                  }
                }

                if (start_transit != end_transit) {
                  series[0].data[index_transit] += duration_transit1;
                  series[0].data[index_transit_end] += (duration_transit2 - duration_transit1);
                } else {
                  series[0].data[index_transit] += duration_transit2;
                }

              }



            }
          });
        }

      });

      series[2].data.forEach((item, index) => {
        //console.log('Debug series[2] - Index: ', index, 'Item e qtd_routes : ', item, qtd_routes)
        series[2].data[index] = item * qtd_routes;
      });
      series[1].data.forEach((item, index) => {
        //console.log('Series[0]: ', series[0].data[index], 'Serie[2]: ', series[2].data[index])
        if (series[0].data[index] > 0 && series[2].data[index] > 0) {
          series[1].data[index] = (
            (series[0].data[index] * 100) /
            series[2].data[index]
          ).toFixed(0);
        }
      });
      setState((prev) => ({
        ...prev,
        series: series,
        legends: legends,
        routes_list: data2.data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  const handleDate = (date) => {
    setState((prev) => ({
      ...prev,
      start_day: moment(date).format("YYYY-MM-DD"),
      end_day: moment(date).add(1, "days").format("YYYY-MM-DD"),
    }));
  };
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between" alignItems="center" spacing={3}>
          <Grid item lg={8} md={8} sm={8} xs={8}>
            <Typography>HD: Hora a Hora</Typography>
          </Grid>
          <Grid item lg={2} sm={2} md={2} xs={2}>
            <div style={{ marginTop: 12, marginBottom: 12 }}>
              <Select
                MenuProps={{ disableScrollLock: true }}
                fullWidth
                variant="outlined"
                displayEmpty
                value={state.route_id}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    route_id: e.target.value,
                  }))
                }
              >
                <MenuItem key="all" value={null}>
                  Todos as Rotas
                </MenuItem>
                {state.routes_list.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </Grid>
          <Grid item lg={2} sm={2} md={2} xs={2}>
            <div style={{ marginTop: 10, marginBottom: 0 }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
                <ThemeProvider theme={fcaTheme}>
                  <KeyboardDatePicker
                    label="Filtro de Data"
                    disableFuture={true}
                    inputVariant="outlined"
                    disableToolbar
                    clearable="true"
                    autoOk={true}
                    style={{ paddingBottom: 12 }}
                    format="dd/MM/yyyy"
                    variant="inline"
                    value={moment(state.start_day, "YYYY-MM-DD")}
                    onChange={handleDate}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </div>
          </Grid>
        </Grid>

        <GraphCard legends={state.legends} series={state.series} />
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
}

export default HDHour;
