import moment from 'moment'
export default function(legends, targetList) {
    return {
        colors: [
          function({value, seriesIndex}){
            if(value > targetList[seriesIndex]){
              return '#ec273b'
            } else {
              return "#2de0c2"
            }
          },
          function({value, seriesIndex}){
            if(value > targetList[seriesIndex]){
              return '#ec273b'
            } else {
              return "#2d55c2"
            }
          },
          '#ec273b'
        ],
        chart: {
          height: 350,
          type: 'bar',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight',
            width: 2
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          legend: {
            position: 'top',
            horizontalAlign: 'left', 
            offsetY: 8,
            markers: {
              width: 12,
              height: 12,
              strokeWidth: 0,
              strokeColor: '#fff',
              fillColors: ["#2de0c2","#2d55c2",'#ec273b' ],
          },
        },
        tooltip: {
          enabled: true,
          marker: {
            show: false,
        },
        },
        xaxis: {
          type: 'category',
          categories: legends,
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: true,
          }
          
        },
        yaxis: [{
          labels: {
            formatter: function (value) {
              return moment().startOf('day').add(moment.duration({s: value})).format('mm:ss')+'m';
            }
          },
        },]
      }
  }