import React, {useState, useEffect} from 'react'
import api from '../../../../../../core/services/api'
import moment from 'moment'
import Chart from "react-apexcharts";
import options from "./options.js";

function GraphCard(){
    const [state, setState] = useState({          
        series: [{
          name: 'Efet. Semana Anterior',
          type: 'column',
          data: []
        },
        {
          name: 'Efet. Semana Atual',
          type: 'column', 
          data: []
        },
        {
          name: 'Satur. Semana Anterior',
          type: 'line',
          data: []
        },
        {
          name: 'Satur. Semana Atual',
          type: 'line',
          data: []
        },
      ],
      legends: [],
      start_day: moment().day(0).format("YYYY-MM-DD"),
      end_day: moment().format("YYYY-MM-DD"),
      last_start_day: moment().subtract(7, "days").day(0).format("YYYY-MM-DD"),
      last_end_day: moment().subtract(7, "days").day(6).format("YYYY-MM-DD"),
    });


    useEffect(() => {
      getData();
    }, [])

  async function getData(){
      try {
        const data = await api.get(`otdweek?start_day=${state.start_day}&end_day=${state.end_day}`);
        const data3 = await api.get(`otdweek?start_day=${state.last_start_day}&end_day=${state.last_end_day}`);
        const data2 = await api.get(`place?perPage=1000`);
        const data4 = await api.get(`routes`);
        let legends = []
        let saturation = []
        let averagesaturation = []
        let saturation2 = []
        let averagesaturation2 = []
        let effectiveness = []
        let averageeffectiveness = []
        let effectiveness2 = []
        let series = [];
        //SEMANA ATUAL
        data.data.map((item) => {
          if(item.resource.tasks && item.resource.tasks.length > 0){
              item.resource.tasks.map((item) => {
                //Fluxo de saturação
                if(item.saturation){
                  //adicionando legends
                  if(legends.length < 1){
                    if(item.destiny != null){
                      let destiny = item.destiny || 0;
                      let destiny_name = data2.data.data.filter((item2) => {return item2.id == item.destiny})
                      legends.push(item.title+"x"+ destiny_name[0].name)  
                    }
                  } else {
                    if(item.destiny != null){
                    let destiny = item.destiny || 0;
                    let destiny_name = data2.data.data.filter((item2) => {return item2.id == item.destiny})
                    const exist = legends.includes(item.title+"x"+destiny_name[0].name)
                    if(!exist){
                      legends.push(item.title+"x"+destiny_name[0].name)
                    }
                    }
                  }
                }
          })   
        }
      }) 
      //SEMANA ANTERIOR
      data3.data.map((item) => {
        if(item.resource.tasks && item.resource.tasks.length > 0){
            item.resource.tasks.map((item) => {
              //Fluxo de saturação
              if(item.saturation){
                //adicionando legends
                if(legends.length < 1){
                  if(item.destiny != null){
                    let destiny = item.destiny || 0;
                    let destiny_name = data2.data.data.filter((item2) => {return item2.id == item.destiny})
                    legends.push(item.title+"x"+ destiny_name[0].name)  
                  }
                } else {
                  if(item.destiny != null){
                  let destiny = item.destiny || 0;
                  let destiny_name = data2.data.data.filter((item2) => {return item2.id == item.destiny})
                  const exist = legends.includes(item.title+"x"+destiny_name[0].name)
                  if(!exist){
                    legends.push(item.title+"x"+destiny_name[0].name)
                  }
                  }
                }
              }
        })   
      }
    }) 
      legends.forEach((item, index) => {
        saturation.push(0)
        averagesaturation.push(0)
        saturation2.push(0)
        averagesaturation2.push(0)
        effectiveness.push(0)
        averageeffectiveness.push(0)
        effectiveness2.push(0)
      })

      //adicionando as series de acordo com a index das legends
      data.data.map((item) => {
        if(item.resource.tasks && item.resource.tasks.length > 0){
            item.resource.tasks.map((item) => {
              //effetividade
              if(item.activity_type == 6){
                if(item.destiny != null){
                const aux = moment(item.start_real, "YYYY-MM-DD HH:mm:ss").isBefore(item.start_prevision, "YYYY-MM-DD HH:mm:ss")
                let destiny_name = data2.data.data.filter((item2) => {return item2.id == item.destiny})
                let index = legends.indexOf(item.title+"x"+destiny_name[0].name)
                if(aux == true){
                  effectiveness[index] += 1
                }
                }
              }


              //Fluxo de saturação
              if(item.saturation){
                if(item.destiny != null){
                let destiny_name = data2.data.data.filter((item2) => {return item2.id == item.destiny})
                let index = legends.indexOf(item.title+"x"+destiny_name[0].name)
                saturation[index] += item.saturation.value;
                averagesaturation[index] += 1;
              }
              }
        })   
      }
    }) 
    //SEMANA ANTERIOR
    data3.data.map((item) => {
      if(item.resource.tasks && item.resource.tasks.length > 0){
          item.resource.tasks.map((item) => {
            //effetividade
            if(item.activity_type == 6){
              if(item.destiny != null){
              const aux = moment(item.start_real, "YYYY-MM-DD HH:mm:ss").isBefore(item.start_prevision, "YYYY-MM-DD HH:mm:ss")
              let destiny_name = data2.data.data.filter((item2) => {return item2.id == item.destiny})
              let index = legends.indexOf(item.title+"x"+destiny_name[0].name)
              if(aux == true){
                effectiveness2[index] += 1
              }
              }
            }

            //Fluxo de saturação
            if(item.saturation){
              if(item.destiny != null){
              let destiny_name = data2.data.data.filter((item2) => {return item2.id == item.destiny})
              let index = legends.indexOf(item.title+"x"+destiny_name[0].name)
              saturation2[index] += item.saturation.value;
              averagesaturation2[index] += 1;
            }
            }
      })   
    }
  }) 

  data4.data.map((item) => {
    if(item.tasks && item.tasks.length > 0){
      item.tasks.map((item) => {
        if(item.activity_type == 6){
          if(item.destiny != null){
            let destiny_name = data2.data.data.filter((item2) => {return item2.id == item.destiny})
            if(destiny_name.length > 0){
              let index = legends.indexOf(item.title+"x"+destiny_name[0].name)
              averageeffectiveness[index] += 1
            }
            }
        }
      })
    }
  })



      saturation.forEach((item, index) => {
        saturation[index] = item/averagesaturation[index] || 0
      })
      saturation2.forEach((item, index) => {
        saturation2[index] = item/averagesaturation2[index] || 0
      })
      averageeffectiveness.forEach((item, index) =>{
        averageeffectiveness[index] = item*6
      })
      effectiveness2.forEach((item, index) => {
        if(item == 0 || averageeffectiveness[index] == 0){
          effectiveness2[index] = 0;
        } else {
          effectiveness2[index] = (item*100/averageeffectiveness[index]) || 0
        }
      }) 
      effectiveness.forEach((item, index) => {
        if(item == 0 || averageeffectiveness[index] == 0){
          effectiveness[index] = 0;
        } else {
          effectiveness[index] = (item*100/averageeffectiveness[index]) || 0
        }
      })  
          setState((prev) => ({
          ...prev,
          series:[{
            name: 'Efet. Semana Anterior',
            type: 'column',
            data: effectiveness2
          },
          {
            name: 'Efet. Semana Atual',
            type: 'column',
            data: effectiveness
          },
          {
            name: 'Satur. Semana Anterior',
            type: 'line',
            data: saturation2
          },
          {
            name: 'Satur. Semana Atual',
            type: 'line',
            data: saturation
          },
        ],
          legends: legends,
        }))  
      } catch (error) {
        console.warn(error)
      }
    }


    return(
        <Chart
              options={options(state.legends)}
              series={state.series}
              type="line"
              height={350}
            />
    )
}

export default GraphCard;