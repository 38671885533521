import React, { useState, useEffect} from 'react'
import MaterialTable, { MTableToolbar } from "material-table";
import * as _ from 'lodash'
import moment from 'moment'
import swal from "sweetalert";

//material-ui
import FormGroup from '@material-ui/core/FormControl'
import {Grid, IconButton, Tooltip} from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import {SpeedDial, SpeedDialIcon, SpeedDialAction} from "@material-ui/lab";
import DeleteIcon from '@material-ui/icons/Delete'


//custom components
import CreateForm from './CreateForm'
import api from '../../../../../core/services/api'
import { useSnackbar } from 'notistack';





const useStyles = makeStyles({
  root: {
    transform: "translateZ(0px)",
    flexGrow: 1,
    color: '#69f0ae'
  },
  speedDial: {
    zIndex: 9999,
    position: "fixed",
    bottom: "12%",
    right: "0.2%",
    "& .MuiSpeedDial-fab": {
      backgroundColor: '#5ad545'
    },
  }
}
);

function AlertsPanel({ filter }){
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles();
  const [state, setState] = useState({

  })
  const [open, setOpen] = useState(false);
  const [direction, setDirection] = React.useState("up"); 
  const [filteredTableData, setFilteredTableData] = useState([])
  const [tableData, setTableData] = useState([])
  const [datasets,setDatasets] = useState([])
  const [segments,setSegments]= useState([])

  useEffect(()=>{
    getData()
  },[])

  useEffect(()=>{
    handleSearch(filter)
  }, [filter])
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const formatTableData = (data) => {
    console.log(data)
    data.forEach((item) => {
      item.services = item.resource.messages.map(message => message.service).join(',');
      item.resource.sending_recurrence = item.resource.sending_recurrence = 'once' ? 'Envio único' : 'Envio recorrente'
      item.resource.segments = typeof item.resource.segments === 'object' && item.resource.segments ? item.resource.segments.map(segment => segment).join(',') : 'SEGMENTO DINÂMICO';
    })

    return data;
  }

  const handleSearch = (value) =>{
    const filter = tableData.filter((item) => { return item.name.toLowerCase().includes(value.toLowerCase()) })
    setFilteredTableData(filter);
  }
  
  async function getData(){
    try {
      const response = await api.get('alerts')
      const data = _.get(response,'data.data',[])||[]
  
      setTableData(formatTableData(data));
      setFilteredTableData(formatTableData(data));
      api.get('alerts/datasets').then(data => {
        setDatasets(_.get(data,'data.data',[]) || [])
      }).catch(err=>{
        console.log(err)
        enqueueSnackbar('Não foi possível carregar dataset',{variant: 'error'})
      })
      api.get('segments').then(data => {
        setSegments(_.get(data,'data.data.data',[]) || [])
      }).catch(err=>{
        console.log(err)
        enqueueSnackbar('Não foi possível carregar segmentos',{variant: 'error'})
      })
    }
    catch(err){
      console.log(err)
      enqueueSnackbar('Não foi possível carregar listagem de alertas',{variant: 'error'})
    }
  }
  const handlerRemove = async ({id, name}) => {
    try{
      swal(`Tem certeza que deseja excluir o ${name}`, {
        buttons: {
          cancel: "Cancelar",
          confirm: {
            text: "Excluir",
            value: true,
          }
        },
      }).then((confirm) => {
        if(confirm){
          // await api.delete(`alerts/${id}`)
          // enqueueSnackbar('Alerta removida com sucesso!',{variant: 'success'})
          // await getData()
        }
      })
    }
    catch(err){
      enqueueSnackbar('Não foi possível remover alerta',{variant: 'error'})
    }
  }
  const table = {
    columns: [
      {
        title: "Nome",
        field: "name",
        cellStyle: { paddingLeft: 24, textAlign: "left", width: '20%' },
        headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
      },
      {
        field: "actions",
        title: "",
        cellStyle: { paddingBottom: 0, paddingTop: 0, textAlign: "right", borderRight: '1px solid #BCBCBC' },
        headerStyle: { paddingRight: 20, textAlign: "right", borderRadius: '0px 4px 0px 0px' },
        render: (rowData) => (
          <>
            <FormGroup row="true" style={{ justifyContent: 'flex-end', flexDirection: 'row' }}>
                <CreateForm 
                  onSubmit={() => getData()} 
                  id={rowData.id} 
                  segments={segments}
                  datasets={datasets}
                />
                <Tooltip title="Excluir">
                  <IconButton
                    style={{ color: "#767676" }}
                    onClick={() => handlerRemove(rowData)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>{" "}
              </FormGroup>
          </>
        ),
      },
      {
        title: "Criado em",
        field: "created_at",
        cellStyle: { paddingLeft: 24, textAlign: "left" },
        headerStyle: { borderBottom: '#fff' },
        render: (rowData)=>(<>{moment(rowData.created_at).format('DD/MM/YYYY HH:mm')}</>)
      },
      {
        title: "Segmento",
        field: "resource.segments",
        cellStyle: { paddingLeft: 24, textAlign: "left" },
        headerStyle: { borderBottom: '#fff' }
      },
      {
        title: "Dataset",
        field: "dataset_name",
        cellStyle: { paddingLeft: 24, textAlign: "left" },
        headerStyle: { borderBottom: '#fff' }
      },
      {
        title: "Serviço",
        field: "services",
        cellStyle: { paddingLeft: 24, textAlign: "left", textTransform: 'capitalize' },
        headerStyle: { borderBottom: '#fff' }
      },
      {
        title: "Recorrência",
        field: "resource.sending_recurrence",
        cellStyle: { paddingLeft: 24, textAlign: "left" },
        headerStyle: { borderBottom: '#fff' }
      },
      
    ],
    options: {
      pageSize: 10,
      actionsColumnIndex: -1,
      search: false,
      showTitle: false,
      tableLayout: 'auto',
      headerStyle: {
        paddingLeft: 24,
        //textAlign: "left",
        backgroundColor: "#fff",
        color: "#777",
        border: '0px solid #777',
      },
      rowStyle: (x) => {
        if (x.tableData.id === 0) {
          return { borderTop: '2px solid rgb(212, 212, 212)' }
        }
      },
    }
  }
    return (
       <>
        <Grid container justify="flex-end" spacing={3} style={{ paddingBottom: 12 }}>
        <Grid item>
        <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={direction}
            // getData={getData}
            // optionType={state.optionType}
          >
            <SpeedDialAction
              key="CreateForm "
              icon={<CreateForm 
                onSubmit={getData} 
                segments={segments}
                datasets={datasets}
              />}
              tooltipTitle="Novo Alerta"
              onClick={() => handleOpen()}
            />
          </SpeedDial>   
                </Grid>
      </Grid>
     
        <MaterialTable
        on
        columns={table.columns}
        data={filteredTableData.length ? filteredTableData : tableData}
        options={table.options}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 10,
        }}
      />
      </>
    )
}



export default AlertsPanel;