import React, { useState, useEffect } from 'react'
import MaterialTable, { MTableToolbar } from "material-table";
import api from '../../../../core/services/api'
import { Link, withRouter } from "react-router-dom";

//custom components
import Main from '../../components/Main'
import Alert from '../../components/Alert'
import ViewForm from './ViewForm'
import { makeStyles } from "@material-ui/core/styles";

//material ui
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import EditIcon from '@material-ui/icons/Edit'
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';


function ListRoutes() {
  const [state, setState] = useState({
    routes: [],
    shifts: [],
    locations: [],
  })

  useEffect(() => {
    getData();
    getShifts();
    getLocations();
  }, [])

  async function getData() {
    try {
      const data = await api.get(`routes`);
      setState((prev) => ({
        ...prev,
        routes: data.data || []
      }))
    } catch (error) {
      console.warn(error)
    }
  }

  async function getShifts() {
    try {
      const data = await api.get(`shifts`);
      setState((prev) => ({
        ...prev,
        shifts: data.data.data || [],
      }));

    } catch (error) {
      console.warn(error);
    }
  }

  async function getLocations() {
    try {
      const data = await api.get(`place?perPage=1000`);
      setState((prev) => ({
        ...prev,
        locations: data.data.data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  async function deleteRoute(route) {
    try {
      if (
        window.confirm(
          "Tem certeza que deseja deletar a rota " + route.name
        )
      ) {
        await api.delete(`routes/${route.id}`);
      }
      getData();
    } catch (error) {
      console.warn(error)
    }
  }

  const useStyles = makeStyles({
    root: {
      transform: "translateZ(0px)",
      flexGrow: 1,
      color: '#69f0ae'
    },
    speedDial: {
      zIndex: 9999,
      position: "fixed",
      bottom: "12%",
      right: "0.2%",
      "& .MuiSpeedDial-fab": {
        backgroundColor: '#5ad545'
      },
    }
  }
  );

  async function getReport() {
    try {
      let url = `/route_export`;
      // url += state.check_shift ? "&check_shift=" + state.check_shift : "";
      api.get(url).then(blobFile => {
        //fileDownload(blobFile.data.data, 'teste.xlsx')
        const url = window.URL.createObjectURL(new Blob([blobFile.data.data]));
        const link = document.createElement('a');
        //converter base64 pra xlsx
        //passar xlsx pro href
        link.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + blobFile.data.data;
        link.setAttribute('download', `rotas.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

      })
    } catch (err) {
      console.warn(err);
    }
  };


  const classes = useStyles();
  const [direction, setDirection] = React.useState("up");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false)

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);

  };

  return (
    <Main color="#f7f7f7" title="Rotas">
      <Grid container justify="flex-end" spacing={3} style={{ paddingBottom: 12 }}>
        <Grid item>

          <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={direction}
            getData={getData}
            optionType={state.optionType}
          >

            <SpeedDialAction
              key="CreateForm "
              style={{ textDecoration: 'none' }}
              icon={<Link to={"/routes-create"} ><AddIcon getData={getData} optionType={state.optionType} /></Link>}
              tooltipTitle="Adicionar Rota"
              onClick={() => handleOpen()} />



            <SpeedDialAction
              key="download"
              icon={<GetAppIcon />}
              tooltipTitle="Download"
              onClick={() => getReport()} />

            {/* <SpeedDialAction
              key="upload"
              icon={<PublishIcon />}
              tooltipTitle="Upload"
              onClick={() => handleOpen()}
            /> */}

          </SpeedDial>

        </Grid>
        <Alert open={open2} message="O relatório foi baixado com sucesso!" setOpen={() => setOpen2()} />
      </Grid>
      <MaterialTable
        columns={[


          {
            title: "Nome",
            field: "name",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
          },
          {
            title: "Descrição",
            field: "description",
            cellStyle: { paddingLeft: 24, textAlign: "left", width: '20%' },
            headerStyle: { borderBottom: '#fff' }
          },
          {
            title: "Trânsito",
            field: "transit",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' }
          },
          {
            title: "Periodicidade",
            field: "period",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' },
            render: (rowData) => rowData.period == 2 ? 'DOMINGO' : 'SEMANAL'
          },
          {
            title: "Atividades",
            field: "tasks",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' },
            render: (rowData) => rowData.tasks.length || "-"
          },
          {
            field: "actions",
            title: "Ações",
            cellStyle: { paddingBottom: 0, paddingTop: 0, textAlign: "right" },
            headerStyle: { paddingRight: 0, textAlign: "right", borderRadius: '0px 8px 0px 0px' },
            render: (rowData) => (
              <>
                <FormGroup row style={{ justifyContent: 'flex-end' }}>
                  <ViewForm route={rowData} shifts={state.shifts} locations={state.locations} />
                  {/*  <EditForm location={rowData} getData={getData} />  */}
                  <Link to={{
                    pathname: `/routes-create/${rowData.id}`,
                    aboutProps: { item: 'test' }
                  }}>
                    <IconButton>
                      <EditIcon style={{ color: "#fcc326" }} className="icon-small" />
                    </IconButton>
                  </Link>
                  <IconButton
                    style={{ color: "#ec273b" }}
                    onClick={() => deleteRoute(rowData)}
                  >
                    <DeleteIcon />
                  </IconButton>{" "}

                </FormGroup>
              </>
            ),
          },
        ]}
        data={state.routes}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
          },
          rowStyle: (x) => {
            if (x.tableData.id === 0) {
              return { borderTop: '2px solid rgb(212, 212, 212)' }
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 10,
        }}
      />
    </Main>
  )
}

export default ListRoutes;
