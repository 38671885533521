import React, {useState, useEffect} from 'react'
import api from '../../../../../../core/services/api'
import moment from 'moment'
import Chart from "react-apexcharts";
import options from "./options";

function GraphCard(){
    const [state, setState] = useState({          
        series: [{
          data: []
        }],
        start_day: moment().day(0).format("YYYY-MM-DD"),
        end_day: moment().day(6).format("YYYY-MM-DD"),
        legends: [],
    });


    
    useEffect(() => {
      getData();
    }, [])

  async function getData(){
      try {
        const data = await api.get(`rank-occurrences?start_day=${state.start_day}&end_day=${state.end_day}`);
        let legends = []
        let occurrences = []
        let average = 0;

        data.data.map((item) => {
          legends.push(item.occurrence_name.split(" "));
          occurrences.push(item.time);
          average += parseInt(item.time);
        })   

        occurrences.forEach((item, index) => {
          occurrences[index] = (item*100)/average;
        });

          setState((prev) => ({
          ...prev,
         legends: legends,
         series:  [{
          name: 'Porcentagem de Ocorrência',
          data: occurrences
        }]
        }))  
      } catch (error) {
        console.warn(error)
      }
    }

    return(
        <Chart
              options={options(state.legends)}
              series={state.series}
              type="bar"
              height={350}
            />
    )
}

export default GraphCard;