import React from "react";
import { TopBar, Logo } from "./styles";
import Menu from "../../components/NavHeader";
import logo from "../../assets/tkg_flex.png";
import logo_dhl from '../../assets/logo_DHL.svg'

// import { Container } from './styles';

function Main({ children, title, color, filter }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        background: "whitesmoke",
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
      }}
    >
      <Menu />
      <div style={{ width: "96%", marginLeft: "50px", background: color }}>
        <TopBar style={{ zIndex: 999}}>
          <Logo src={logo} />
          {title ? (
            <p
              style={{
                margin: 0,
                borderLeft: "0.5px solid #7d7d7dfc",
                paddingLeft: 20,
                marginLeft: 20,
                fontWeight: "initial",
                color: "#1b1919",
              }}
            >
              {title}
            </p>
          ) : null}

       
        
        <div style={{ position:'absolute', right:240, zIndex: 9999, width: 500}}>
              {filter}
        </div>
          <div style={{position: 'absolute', right: 26, zIndex: 9999, width: 200}}>
          <Logo src={logo_dhl} />
        </div>
        </TopBar>
        <div style={{ margin: 30, paddingTop: 60, background: color }}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Main;
