import React, { useState, useEffect} from 'react'
import api from '../../../../core/services/api'
import MaterialTable, { MTableToolbar } from "material-table";


//material-ui
import DeleteIcon from '@material-ui/icons/Delete'
import SearchIcon from "@material-ui/icons/Search"
import {Grid, FormGroup, IconButton, Tooltip, InputBase, InputAdornment} from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import {SpeedDial, SpeedDialIcon, SpeedDialAction} from "@material-ui/lab";


//custom components
import Main from '../../components/Main'
import CreateForm from './CreateForm'
import EditForm from './EditForm'
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";





const useStyles = makeStyles({
  root: {
    transform: "translateZ(0px)",
    flexGrow: 1,
    color: '#69f0ae'
  },
  speedDial: {
    zIndex: 9999,
    position: "fixed",
    bottom: "12%",
    right: "0.2%",
    "& .MuiSpeedDial-fab": {
      backgroundColor: '#5ad545'
    },
  }
}
);


function Receivers(){
  const classes = useStyles();
  const [state, setState] = useState({
    receivers: [],
    segments: [],
  })
  const [open, setOpen] = useState(false);
  const [filteredData, setFilteredData] = useState('')
  const [direction, setDirection] = useState("up"); 
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    getSegments();
    getData();
  }, [])

  async function getSegments() {
    try {
      const {data} = await api.get(`segments`);
      setState((prev) => ({
        ...prev,
        segments: data.data.data || []
      }))
    } catch (error) {
      console.warn(error)
    }
  };

  async function getData() {
    try {
      const {data} = await api.get(`receivers`);
      setState((prev) => ({
        ...prev,
        receivers: data.data.data || []
      }))
    } catch (error) {
      console.warn(error)
    }
  };

  async function deleteReceiver(receiver) {
    try {

      if (
        window.confirm(
          "Tem certeza que deseja deletar o destinatário " + receiver.name
        )
      ) {
       
        await api.delete(`receivers/${receiver.id}`);
      }

      getData();
    } catch (error) {
      console.warn(error)
    }
  };


  const handleSearch = (value) =>{
    const filter = state.receivers.filter((item) => { return item.name.toLowerCase().includes(value.toLowerCase()) })
    setFilteredData(filter);
  }

  function checkSegment(id){
    const segment = state.segments.filter((item) => {return item.id === id})
    return segment[0] ? segment[0].name : "-"
  }

    return (
        <Main color="#f7f7f7" title="Destinatários">
          <Grid container justify="flex-start" spacing={3} style={{ paddingBottom: 12 }}>
           <Grid item sm={4} md={2} lg={2} xs={2}>
              <ThemeProvider theme={fcaTheme}>
                <InputBase
                    style={{ padding:'4px 6px 3px', width: "100%", border: '2px solid #BCBCBC', borderRadius:'4px' }}
                    placeholder="  Buscar"
                    endAdornment={
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    onChange={(e) => handleSearch(e.target.value)}
                  />
              </ThemeProvider>
            </Grid>
            <Grid item>
        <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={direction}
            getData={getData}
            optionType={state.optionType}
          >
            <SpeedDialAction
              key="CreateForm "
              icon={<CreateForm getData={getData} segments={state.segments} />}
              tooltipTitle="Novo Destinatário"
              onClick={() => handleOpen()}
            />
          </SpeedDial>   
                </Grid>
      </Grid>
     
        <MaterialTable
        columns={[
          {
            title: "Nome",
            field: "name",
            cellStyle: { paddingLeft: 24, textAlign: "left", width: '20%', paddingRight: 80 },
            headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
          },
          {
            field: "actions",
            title: "",
            cellStyle: { paddingBottom: 0, paddingTop: 0, textAlign: "right", borderRight: '1px solid #BCBCBC' },
            headerStyle: { paddingRight: 20, textAlign: "right", borderRadius: '0px 8px 0px 0px' },
            render: (rowData) => (
              <>
                <FormGroup row style={{ justifyContent: 'flex-end' }}>
                <EditForm receivers={rowData} getData={getData} segments={state.segments} />
                <Tooltip title="Excluir">
                  <IconButton
                    style={{ color: "#767676" }}
                    onClick={() => deleteReceiver(rowData)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>{" "}
              </FormGroup>
              </>
            ),
          },
          {
            title: "Segmento",
            field: "segments_id",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' },
            render: (rowData) => rowData.segments_id ? checkSegment(rowData.segments_id) : "-"
          },
          {
            title: "E-mail",
            field: "email",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' },
          },
          {
            title: "Telefone",
            field: "phone",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' },
          }
        ]}
        data={filteredData.length ? filteredData : state.receivers}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
          },
          rowStyle: (x) => {
            if (x.tableData.id === 0) {
              return { borderTop: '2px solid rgb(212, 212, 212)' }
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 10,
        }}
      />
        </Main>
    )
}



export default Receivers;