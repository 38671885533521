import React from 'react'
import {useStyles} from './styles'


//custom components
import GraphCard from './GraphCard'

//material ui 
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'

function TimeDockLocal(props){
    const classes = useStyles();
    return (
        <Card className={classes.root}>
        <CardContent>
        <GraphCard   start_day={props.start_day} end_day={props.end_day} local_id={props.local_id} shifts_id={props.shifts_id}/>
        </CardContent>
            <CardActions></CardActions>
        </Card>
    )
}


export default TimeDockLocal;