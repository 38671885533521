import moment from 'moment'
export default function(legends, target) {
    return {
        colors: ["#21a2fc"],
        chart: {
          height: 350,
          type: "bar",
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'left',
        },

        dataLabels: {
          enabled: true,
          formatter: function (value) {
            return value.toFixed(2)+"%";
          }
        },
        stroke: {
          curve: 'straight',
          width: 2
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories:['Turno 1', 'Turno 2', 'Turno 3'],
          
        },
        yaxis: [{   
          labels: {  
            show: true,
            formatter: function (value) {
              return value.toFixed(2)+"%";
            }
          },
        }
      ],
      }
  }