import React, { useState, useEffect } from 'react';
import Main from '../../components/Main'
import api from '../../../../core/services/api'
// Using an ES6 transpiler like Babel
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
//custom components
import CreateForm from './CreateForm'
import CardChecklist from './CardChecklist'
//@material-ui
import Grid from '@material-ui/core/Grid'
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import AddIcon from "@material-ui/icons/Add";
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import { makeStyles } from "@material-ui/core/styles";
import Alert from '../../components/Alert'


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function CheckLists() {

  const [state, setState] = useState({
    checkLists: [],
    items: []
  })
  const SortableItem = SortableElement(({ item, index }) => (
    <Grid item>
      <CardChecklist id={item.order} title={item.titleMessage} sizeTotal={state.checkLists.length} index={item.order} active={item.active} checklist={item} getData={getData} />
    </Grid>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <Grid container spacing={2}>
        {items.map((item, index) => {
          return (
            <SortableItem key={`item-${item.id}`} index={index} item={item} />
          )
        })}
      </Grid>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const list = arrayMove(state.checkLists, oldIndex, newIndex)
    list.map((item, index) => { return item.order = index })
    setState({ checkLists: list });
    updateChecklist(list);
  };

  function onDragEnd(result) {
    //ordenation
    if (!result.destination) {
      return;
    }
    const list = reorder(
      state.checkLists,
      result.source.index,
      result.destination.index
    );
    console.log('Index Atual: ', result.source.index);
    console.log('Index Destino: ', result.destination.index);
    list.map((item, index) => { return item.order = index })
    setState({ checkLists: list });

  }
  useEffect(() => {
    getData();
  }, [])

  async function getData() {
    try {
      const data = await api.get(`support_data/check_list`);
      const activechecklist = data.data.filter((item) => { return item.excluded === false })
      setState((prev) => ({
        ...prev,
        checkLists: activechecklist || [],
        items: data.data
      }))
    } catch (error) {
      console.warn(error)
    }
  }

  async function updateChecklist(list) {
    try {
      const checklist = {
        resource: list
      }
      await api.put(`support_data_web/check_list_order`, checklist);
      getData();
    } catch (error) {
      console.warn(error)
    }
  }

  async function deleteCheckLists(checkList) {
    try {
      if (
        window.confirm(
          "Tem certeza que deseja deletar o check list " + checkList.titleMessage
        )
      ) {
        await api.delete(`support_data_web/check_list/${checkList.id}`);
      }

      getData();
    } catch (error) {
      console.warn(error)
    }
  }

  function getName(component) {
    if (component === "radio") {
      return "Radio"
    } else if (component === "slider") {
      return "Slider"
    } else {
      return "-"
    }
  }

  const useStyles = makeStyles({
    root: {
      transform: "translateZ(0px)",
      flexGrow: 1,
      color: '#69f0ae'
    },
    speedDial: {
      zIndex: 9999,
      position: "fixed",
      bottom: "12%",
      right: "0.2%",
      "& .MuiSpeedDial-fab": {
        backgroundColor: '#5ad545'
      },
    }
  }
  );

  async function getReport() {
      try {
        let url = `/support_data_export/check_list`;
        // url += state.check_shift ? "&check_shift=" + state.check_shift : "";
        api.get(url).then(blobFile => {
            //fileDownload(blobFile.data.data, 'teste.xlsx')
             const url = window.URL.createObjectURL(new Blob([blobFile.data.data]));
            const link = document.createElement('a');
            //converter base64 pra xlsx
            //passar xlsx pro href
            link.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+blobFile.data.data;
            link.setAttribute('download', `checklist.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);  
  
        })
      } catch (err) {
        console.warn(err);
      }
    
  };

  const classes = useStyles();
  const [direction, setDirection] = React.useState("up");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false)

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    console.log('handleOpenede')

  };

  return (
    <Main color="#f7f7f7" title="Check Lists">
      {/* SORTABLE 2 */}
      <SortableList items={state.checkLists} onSortEnd={onSortEnd} useDragHandle={true} axis="xy" />

      <Grid container justify="flex-end" spacing={3} style={{ paddingBottom: 12 }}>
        <Grid item>
        <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={direction}
            getData={getData}
            optionType={state.optionType}
          >
            <SpeedDialAction
              key="CreateForm "
              icon={<CreateForm getData={getData} order={state.checkLists.length} />}
              tooltipTitle="Adicionar CheckList"
              onClick={() => handleOpen() }

            />
            <SpeedDialAction
              key="download"
              icon={<GetAppIcon />}
              tooltipTitle="Download"
              onOpen={() => <Alert message="O relatório foi baixado com sucesso!"  />}
              onClick={() => getReport()}
                           
            />
            {/* <SpeedDialAction
              key="upload"
              icon={<PublishIcon />}
              tooltipTitle="Upload"
              onClick={() => handleOpen()}
            /> */}

          </SpeedDial>
        
        </Grid>
        <Alert open={open2} message="O relatório foi baixado com sucesso!" setOpen={() => setOpen2()} />
      </Grid>
      
    </Main>
  )
}

export default CheckLists; 