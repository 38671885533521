import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import api from "../../../../../../../core/services/api";
import moment from "moment";
import options from "./options.js";

function GraphCard(props) {
  return (
    <Chart
      options={options(props.legends)}
      series={props.series}
      type="bar"
      height={350}
    />
  );
}

export default GraphCard;
