import React, { useState, useEffect } from "react";
import api from "../../../../../../../core/services/api";
import moment from "moment";
import Chart from "react-apexcharts";
import options from "./options";

//material ui
import Grid from "@material-ui/core/Grid";

function GraphCard(props) {
  const [state, setState] = useState({
    series: [],
    start_day: moment().format("YYYY-MM-DD"),
    end_day: moment().add(1, "days").format("YYYY-MM-DD"),
  });

  useEffect(() => {
    getData();
  }, [props.start_day, props.end_day,props.routes]);

  async function getData() {
    try {
      const data = await api.get(
        `/sla-report-geral?start_at=${props.start_day}&end_at=${props.end_day}&routes=[${props.routes}]`
      );
      let series = [];
      let value = data.data.data.data ? parseFloat(data.data.data.data.effectiveness.value) : 0;
      series.push(value);
      series.push(100 - value);

      setState((prev) => ({
        ...prev,
        series: series,
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  return (
    <Grid container justify="center">
      <Grid item>
        <Chart
          options={options()}
          series={state.series}
          type="donut"
          width={320}
        />
      </Grid>
    </Grid>
  );
}

export default GraphCard;
