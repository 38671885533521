import React, {useState, useEffect} from 'react'
import Chart from "react-apexcharts";
import api from '../../../../../../core/services/api'
import options from "./options";
import moment from 'moment'

function GraphCard(){
    const [state, setState] = useState({          
        series: [{
          name: 'Perdas em portaria',
          data: []
        }],
        legends: []
    });

    
    useEffect(() => {
      getData();
    }, [])

  async function getData(){
      try {
        const data = await api.get(`loss-lobby`);
        let series = []
        let legends = ['W'+moment().subtract(49,"days").week(),'W'+moment().subtract(42,"days").week(),'W'+moment().subtract(35,"days").week(),'W'+moment().subtract(28,"days").week(),'W'+moment().subtract(21,"days").week(),'W'+moment().subtract(14,"days").week(),'W'+moment().subtract(7,"days").week(),'W'+moment().week()]
        series.push(data.data[0].week8 || 0)
        series.push(data.data[0].week7 || 0)
        series.push(data.data[0].week6 || 0)
        series.push(data.data[0].week5 || 0)
        series.push(data.data[0].week4 || 0)
        series.push(data.data[0].week3 || 0)
        series.push(data.data[0].week2 || 0)
        series.push(data.data[0].week1 || 0)

          setState((prev) => ({
          ...prev,
         legends: legends,
         series:   [{
          name: 'Perdas em portaria',
          data: series
        }],
        }))  
      } catch (error) {
        console.warn(error)
      }
    }



    return(
        <Chart
              options={options(state.legends)}
              series={state.series}
              type="bar"
              height={350}
            />
    )
}

export default GraphCard;