import React, {useState} from 'react'
import MaterialTable, { MTableToolbar } from "material-table";
import moment from 'moment';

//material ui
import Tooltip from '@material-ui/core/Tooltip'

//EMOTICON ICONS
import emoji1 from '../../../../../assets/emoticon/1.png'
import emoji2 from '../../../../../assets/emoticon/2.png'
import emoji3 from '../../../../../assets/emoticon/3.png'
import emoji4 from '../../../../../assets/emoticon/4.png'
import emoji5 from '../../../../../assets/emoticon/5.png'

function Table(props){
  const [state, setState] = useState({
    presenteeism: props.presenteeism
  })
  
  function pad(num) {
    return ("0"+num).slice(-2);
  }
  function hhmmss(secs) {
    if(secs < 0) secs = Math.abs(secs)
    var minutes = Math.floor(secs/60);
    var secss = secs-(minutes*60);
    var hours = Math.floor(minutes/60)
    minutes = minutes%60;
    return `${pad(hours)}:${pad(minutes)}:${pad(secss)}`;
    // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
  }

  function checkEmoticon(emoticon) {
    if(emoticon == 1){
      return (<Tooltip title="Muito Triste" aria-label="add"><img src={emoji1} width="22"/></Tooltip>)
    } else if (emoticon == 2){
      return (<Tooltip title="Tô bem não" aria-label="add"><img src={emoji2} width="22"/></Tooltip>)
    } else if (emoticon == 3){
      return (<Tooltip title="Não quero falar" aria-label="add"><img src={emoji3} width="22"/></Tooltip>)
    } else if (emoticon == 4){
      return (<Tooltip title="Feliz" aria-label="add"><img src={emoji4} width="22"/></Tooltip>)
    } else if (emoticon == 5){
      return (<Tooltip title="Arretado de bom!" aria-label="add"><img src={emoji5} width="22"/></Tooltip>)
    }
  }

    return (
        <MaterialTable
        columns={[
          {
            title: "Rota",
            field: "route_name",
            cellStyle: {paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff',  borderRadius: '8px 0px 0px 0px'}
          },
          {
            title: "Motorista",
            field: "name",
            cellStyle: {paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff',  borderRadius: '8px 0px 0px 0px'}
          },
          {
            title: 'Emocionômetro',
            field: "emoticon",
            cellStyle: {paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff',  borderRadius: '8px 0px 0px 0px'},
            render: (rowData) => rowData.emoticon ? checkEmoticon(rowData.emoticon) : "-"
          },
          {
            title: "Start",
            field: "start",
            cellStyle: {paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff',  borderRadius: '8px 0px 0px 0px'},
            render: (rowData) => rowData.start ? moment(rowData.start, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss") : "-"
          }, 
          {
            title: "Checklist",
            field: "checklist",
            cellStyle: {paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff',  borderRadius: '8px 0px 0px 0px'},
            render: (rowData) => rowData.checklist ?  hhmmss(rowData.checklist) : "-"
          },  
          {
            title: "Setup time",
            field: "setup",
            cellStyle: {paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff',  borderRadius: '8px 0px 0px 0px'},
            render: (rowData) => rowData.setup_time ?  hhmmss(rowData.setup_time) : "-" 
          },
          {
            title: "Lanche",
            field: "route",
            cellStyle: {paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff',  borderRadius: '8px 0px 0px 0px'},
            render: (rowData) => rowData.lanche ? hhmmss(rowData.lanche) : "-"
          },
          {
            title: "End",
            field: "route",
            cellStyle: {paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff',  borderRadius: '8px 0px 0px 0px'},
            render: (rowData) => rowData.end_shift ? hhmmss(rowData.end_shift) : "-"
          },
          {
            title: "Logout",
            field: "route",
            cellStyle: {paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff',  borderRadius: '8px 0px 0px 0px'},
            render: (rowData) => rowData.logout ? moment(rowData.logout, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss") : "-"
          },
        ]}
        data={props.presenteeism || []}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
          },
          rowStyle: (x) => {
            if (x.tableData.id === 0){
              return { borderTop: '2px solid rgb(212, 212, 212)'}
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
            boxShadow: '0px 0px #fff'
          }}
      />
    )
}

export default Table;