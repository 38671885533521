import React, { useEffect, useState } from "react";
import api from '../../../../../core/services/api'
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Grid from '@material-ui/core/Grid'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from "@material-ui/core/FormControlLabel";



//custom components
import CustomSelect from '../../../components/CustomSelect'


function PhoneMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/,')', /[9]/, /\d/, /\d/, /\d/, /\d/,'-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
PhoneMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function CreateForm(props) {
  const [state, setState] = useState({
    error: false,
    disabled: false,
    active: true,
    group_id: null,
    groups: [],
  });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");




  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setState((prev) => ({
      ...prev,
      disabled: false,
      error: false,
      showPassword: '',
      group_id: null,
      groups: props.groups
    }))
    setName("");
    setEmail("");
    setPhone("");
    setPassword("");
    setPassword2("");
    setOpen(true);
    // console.log("state: ", state)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  async function handleSubmit() {
    setState((prev) => ({
      ...prev,
      disabled: true
    }))
    const user = {
      name: name,
      email: email,
      password: password,
      phone: phone.replace(/[^0-9]/g,""),
      cpf: "",
      cnh: "",
      group_id: state.group_id,
    }
    try {
      await api.post(`users`, user);
      props.getData();
    } catch (error) {
      console.warn(error)
    }
    console.log('Usuário criado', user)
    setOpen(false)

  }
  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  return (
    <div>
      <AddIcon onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm" >
        <DialogTitle>Cadastrar novo Usuário</DialogTitle>
        <DialogContent>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
              id="name"
              name="name"
              label="Nome do Usuário"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
              id="email"
              name="email"
              label="E-mail"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <FormControl fullWidth>
            <InputLabel htmlFor="formatted-text-mask-input">Celular</InputLabel>
            <Input
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              inputComponent={PhoneMask}
              name="textmask"
              id="formatted-text-mask-input"
              fullWidth
            /></FormControl>
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <FormControl fullWidth>
              <InputLabel>Senha</InputLabel>
              <Input
                error={state.error ? true : false}
                fullWidth
                id="password"
                name="password"
                label="Senha"
                type={state.showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton

                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              /></FormControl>
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <FormControl fullWidth>
              <InputLabel>Confirmar senha</InputLabel>
              <Input
                error={state.error ? true : false}
                fullWidth
                id="password2"
                name="password2"
                label="Confirme sua Senha"
                type={state.showPassword ? 'text' : 'password'}
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />{" "}</FormControl>
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <CustomSelect
              label="Grupo de Usuário"
              value={state.group_id}
              name="group_id"
              data={state.groups}
              onChange={(event) => handleChange(event)}
            />
          </div>
    
         


        </DialogContent>
        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor: state.disabled ? "" : "#5ed237", color: state.disabled ? "" : "#fff" }}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateForm;
