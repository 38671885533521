import React, { useState, useEffect } from 'react';
import Main from '../../components/Main'
import MaterialTable, { MTableToolbar } from "material-table";
import api from '../../../../core/services/api'

//custom components
import CreateForm from './CreateForm'
import EditForm from './EditForm'
import { makeStyles } from "@material-ui/core/styles";
import Alert from '../../components/Alert'

//@material-ui
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

//@material icons
import DeleteIcon from '@material-ui/icons/Delete'
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import AddIcon from "@material-ui/icons/Add";
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import SearchIcon from '@material-ui/icons/Search'


function Users() {
  const usuario = JSON.parse(localStorage.getItem("usuario"));
  const [state, setState] = useState({
    users: [],
    shifts: [],
    groups: [],
  })

  useEffect(() => {
    getGroups();
    getLocations();
    getShifts();
    getData();

  }, [])

  async function getGroups() {
    try {
      const data = await api.get(`groups`);
      setState((prev) => ({
        ...prev,
        groups: data.data.data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  async function getData() {
    try {
      const data = await api.get(`users?system_client_id=9&perPage=1000`);
      setState((prev) => ({
        ...prev,
        users: data.data.data || [],
        filteredUsers: data.data.data || []
      }))
    } catch (error) {
      console.warn(error)
    }
  }

  async function getLocations() {
    try {
      const data = await api.get(`place?perPage=1000`);
      setState((prev) => ({
        ...prev,
        locations: data.data.data || []
      }))
    } catch (error) {
      console.warn(error)
    }
  }
  async function getShifts() {
    try {
      const data = await api.get(`shifts`);
      setState((prev) => ({
        ...prev,
        shifts: data.data.data || []
      }))
    } catch (error) {
      console.warn(error)
    }
  }

  async function deleteUser(item) {
    try {
      if (
        window.confirm(
          "Tem certeza que deseja deletar usuário " + item.name
        )
      ) {
        await api.delete(`users/` + item.user_id);
      } else {
      }

      getData();
    } catch (error) {
      console.warn(error);
    }
  }

  function getName(id, type) {
    if (type === "func") {
      const name = state.occupations?.filter((item) => {
        return item.id === id
      })
      return name ? name[0]?.name : "-"
    } else if (type == "local") {
      const name = state.locations?.filter((item) => {
        return item.id === id
      })
      return name ? name[0]?.name : "-"
    } else if (type == "group") {
      const name = state.groups.filter((item) => {
        return item.id === id
      })
      return name ? name[0]?.name : "-"
    } else {
      return "-"
    }
  }
  function getShiftname(list) {
    const numberToDays = list?.map((item, index) => {
      const name = state.shifts.filter((shift) => { return shift.id === item })
      return (name[0] ? (
        <li key={index}>
          <Chip
            size="small"
            label={name[0].resource.work_shift}
          />
        </li>
      ) : ""
      )
    });

    return numberToDays;
  }
  function getLocalname(list) {

    let check = Array.isArray(list)
    if(check){

   
    const numberToDays = list?.map((item, index) => {
      const name = state.locations.filter((local) => { return local.id === item })
      return (name[0] ? (
        <li key={index}>
          <Chip
            size="small"
            label={name[0].name}
          />
        </li>
      ) : ""
      )
    });
    return numberToDays;

  } else {
    return list
  }
  }

  const useStyles = makeStyles({
    root: {
      transform: "translateZ(0px)",
      flexGrow: 1,
      color: '#69f0ae'
    },
    speedDial: {
      zIndex: 9999,
      position: "fixed",
      bottom: "12%",
      right: "0.2%",
      "& .MuiSpeedDial-fab": {
        backgroundColor: '#5ad545'
      },
    }
  }
  );

  async function getReport() {
    try {
      let url = `/user_export`;
      // url += state.check_shift ? "&check_shift=" + state.check_shift : "";
      api.get(url).then(blobFile => {
          //fileDownload(blobFile.data.data, 'teste.xlsx')
           const url = window.URL.createObjectURL(new Blob([blobFile.data.data]));
          const link = document.createElement('a');
          //converter base64 pra xlsx
          //passar xlsx pro href
          link.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+blobFile.data.data;
          link.setAttribute('download', `usuarios.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);  

      })
    } catch (err) {
      console.warn(err);
    }
  };


  const classes = useStyles();
  const [direction, setDirection] = React.useState("up");
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);

  };

  function handleSearch(value){
    console.log('State user: ', state.users)
    const filter = state.users.filter((item) => {return item.name.toLowerCase().includes(value.toLowerCase()) ||
       item.email.toLowerCase().includes(value.toLowerCase()) || 
       item.phone.toLowerCase().includes(value.toLowerCase()) || 
       item.group_id == state.groups.filter((item2) => {return item2.name.toLowerCase().includes(value.toLowerCase())})[0]?.id
      })
    setState((prev) => ({
      ...prev,
      filteredUsers: filter
    }))
  }

  return (
    <Main color="#f7f7f7" title="Usuários">
      <Grid container justify="flex-end" spacing={3} style={{ paddingBottom: 12 }}>
        <Grid item>
        <TextField id="outlined-basic" label="Busca" variant="outlined" InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={(e) => handleSearch(e.target.value)}
         />
        </Grid>
        <Grid item>
          <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={direction}
            getData={getData}
            optionType={state.optionType}
          >
            <SpeedDialAction
              key="CreateForm "
              icon={<CreateForm getData={getData}
                occupations={state.occupations}
                locations={state.locations}
                shifts={state.shifts}
                groups={state.groups}
              />}
              tooltipTitle="Adicionar Usuário"
              onClick={() => handleOpen()}

            />
            <SpeedDialAction
              key="download"
              icon={<GetAppIcon />}
              tooltipTitle="Download"
              onClick={() => getReport()}
                            
            />
            {/* <SpeedDialAction
              key="upload"
              icon={<PublishIcon />}
              tooltipTitle="Upload"
              onClick={() => handleOpen()}
            /> */}

          </SpeedDial>        
        </Grid>
      </Grid>
        {/* <Alert open={open} message="O relatório foi baixado com sucesso!" setOpen={() => setOpen()} /> */}
      

      <MaterialTable
        columns={[
          {
            title: "Nome",
            field: "name",
            cellStyle: { paddingLeft: 24, textAlign: "left", width: '20%', paddingRight: 80 },
            headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
          },

          {
            title: "Função",
            field: "group_id",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' },
            render: (rowData) => getName(rowData.group_id, "group")
          },
          {
            title: "E-mail",
            field: "email",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' }
          },
          {
            title: "Celular",
            field: "phone",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' }
          },
          {
            title: "Turno",
            field: "turn",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' },
            render: (rowData) => rowData.resource ?  <div style={{ display: 'flex', justifyContent: 'center',
             flexWrap: 'wrap', listStyle: 'none' }}>{getShiftname(rowData.resource.shift_id)}</div> : "-"
          },
          {
            title: "Local",
            field: "local",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' },
            render: (rowData) =>rowData.resource ?   <div style={{ display: 'flex', justifyContent: 'center',
            flexWrap: 'wrap', listStyle: 'none' }}>{getLocalname(rowData.resource.local_id)}</div> : "-"
          },
          {
            field: "actions",
            title: "Ações",
            cellStyle: { paddingBottom: 0, paddingTop: 0, textAlign: "right" },
            headerStyle: { paddingRight: 20, textAlign: "right", borderRadius: '0px 8px 0px 0px' },
            render: (rowData) => (
              <>
                <FormGroup row style={{ justifyContent: 'flex-end' }}>
                  <EditForm collaborator={rowData} getData={getData} occupations={state.occupations} locations={state.locations} shifts={state.shifts} groups={state.groups} />
                  <IconButton
                    style={{ color: "#ec273b" }}
                    onClick={() => deleteUser(rowData)}
                  >
                    <DeleteIcon />
                  </IconButton>{" "}
                </FormGroup>
              </>
            ),
          },
        ]}
        data={state.filteredUsers}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: true,
          showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
          },
          rowStyle: (x) => {
            if (x.tableData.id === 0) {
              return { borderTop: '2px solid rgb(212, 212, 212)' }
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 10,
        }}
      />
    </Main>
  )
}

export default Users;