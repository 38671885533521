import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { MenuContainer, Icon, MenuItem } from "./styles";
import trackage from "../../assets/favicon.png";
import dashboard from "../../assets/icons/ic_tv_-1.svg";
import orders from "../../assets/icons/ic_playlist_add_check_48px.svg";
import logoutIcon from "../../assets/icons/logout.svg";
import heat from "../../assets/icons/speed.svg";
import map from "../../assets/icons/map.svg";
import path from "../../assets/icons/path.svg";
import evidenceLogo from "./shipped.svg";
// import { logout } from '../../config/auth';

export class Menu extends Component {
  logout() {
    localStorage.removeItem("jwt");
    localStorage.removeItem("usuario");
    this.props.history.push("/login");
    this.props.history.go();
  }
  render() {
    const usuario = JSON.parse(localStorage.getItem("usuario"));
    const { pathname } = this.props.location;
    return (
      <MenuContainer>
        <img
          src={trackage}
          alt="logo trackage"
          width="24"
          height="24"
        />
         {usuario?.menu.map((menu) => {
            return ( <>
              {menu.resource.icon ? ( <MenuItem>
                <Link to={"/"+menu.resource.route}>
                  <Icon src={menu.resource.icon} alt={menu.name} rota={"/"+menu.resource.route} rotaAtual={pathname} width="19.69"  height="16" />
                  </Link>
                </MenuItem>) : ""}
            </>)
          }) }

        <MenuItem onClick={() => this.logout()}>
          <Icon
            src={logoutIcon}
            rota="/sair"
            rotaAtual={pathname}
            alt="logo trackage"
            width="19.69"
            height="16"
          />
        </MenuItem>
      </MenuContainer>
    );
  }
}
export default withRouter(Menu);
