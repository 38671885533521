import React from 'react'


//material ui
import Grid from '@material-ui/core/Grid'


//custom components
import Main from '../../components/Main'
import {Container} from './styles'
import OTDMonth from './OTDMonth'
import OTDWeekAtual from './OTDWeekAtual'
import OTDWeekAnterior from './OTDWeekAnterior'
import TimeDockMonth from './TimeDockMonth'
import TimeDockProvider from './TimeDockProvider'
import EffectivenessSaturation from './EffectivenessSaturation'
import TimeOccurrences from './TimeOccurrences'
import Occurrences from './Occurrences'
import ProviderAboveTarget from './ProviderAboveTarget'
import WindowWithoutOrder from './WindowWithoutOrder'
import LossesContact from './LossesContact'
import LossesContactLocal from './LossesContactLocal'

function Kpis(){
    return(
        <Main title="Kpis" color="#f7f7f7">
            <Grid container spacing={3}>
                <Grid item sm={12} lg={12} md={12}><OTDMonth /></Grid>
                <Grid item sm={6} lg={6} md={6}><OTDWeekAtual /></Grid>
                <Grid item sm={6} lg={6} md={6}><OTDWeekAnterior /></Grid>
                <Grid item sm={12} lg={12} md={12}><TimeDockMonth /></Grid>
                <Grid item sm={12} lg={12} md={12}><TimeDockProvider /></Grid>
                <Grid item sm={12} lg={12} md={12}><ProviderAboveTarget /></Grid>
                <Grid item sm={12} lg={12} md={12}><EffectivenessSaturation /></Grid>
                <Grid item sm={12} lg={12} md={12}><TimeOccurrences /></Grid>
                <Grid item sm={12} lg={12} md={12}><Occurrences /></Grid>
                <Grid item sm={12} lg={12} md={12}><WindowWithoutOrder /></Grid>
                <Grid item sm={12} lg={12} md={12}><LossesContact /></Grid>
                <Grid item sm={12} lg={12} md={12}><LossesContactLocal /></Grid> 
            </Grid>
                
        </Main>
    )
}

export default Kpis;