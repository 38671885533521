import moment from 'moment'
export default function(cols) {
    return {
        colors: ["#2de0c2", "#b53f3f", "#7d77ff", "#6762d4"],
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
            width: 2
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'left', 
        },
        xaxis: {
          categories:['Jan 2021', 'Feb 2021', 'Mar 2021', 'Apr 2021', 'May 2021', 'Jun 2021', 'Jul 2021', 'Aug 2021', 'Sep 2021', 'Oct 2021', 'Nov 2021', 'Dec 2021'],
          
        },
        yaxis: [
          {
            opposite: true,
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              style: {
                colors: '#FEB019',
              },
              formatter: function (value) {
                return moment().startOf('day').add(moment.duration({s: value})).format('mm:ss')+'m';
              }
            },
          },
          {
            seriesName: 'Média Tempo de Doca',
            opposite: true,
            labels: {
              show: false,
              style: {
                colors: '#FEB019',
              },
              formatter: function (value) {
                return moment().startOf('day').add(moment.duration({s: value})).format('mm:ss')+'m';
              }
             
            }, 
          },
          {
            seriesName: 'Plano de produção por dia',
            //opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: false,
              color: "#7d77ff"
            },
            labels: {
              show: false,
              style: {
                colors: "#7d77ff",
              },
              formatter: function (value) {
                return value.toFixed(0);
              }
              
            },
           /*  title: {
              text: "Plano de produção por dia",
              style: {
                color: "#7d77ff",
              }
            } */
          },
          {
            seriesName: 'Produção real',
            //opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: false,
              color: "#7d77ff"
            },
            labels: {
              show: false,
              style: {
                colors: "#7d77ff",
              },
              formatter: function (value) {
                return value.toFixed(0);
              }
              
            },
           /*  title: {
              text: "Plano de produção por dia",
              style: {
                color: "#7d77ff",
              }
            } */
          },
        ]
      }
  }