import React, { useState, useEffect } from "react";
import moment from 'moment'
import api from "../../../../core/services/api";
import PropTypes from "prop-types";

//material ui
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

//custom components
import Main from "../../components/Main";
import ReportPanel from "./ReportPanel";
import SaturationPanel from "./SaturationPanel";
import EffectivenessPanel from "./EffectivenessPanel";
import OTDPanel from "./OTDPanel";
import TransitTimePanel from "./TransitTimePanel";
import CustomSelectCheckbox from '../../components/CustomSelectCheckbox'

//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function Kpis3() {
  const [value, setValue] = useState(0);
  const [route_list, setRoutelist] = useState([]);
  const [route_list_filter, setRoutelistfilter] = useState([]);
  const [state, setState] = useState({
    start_day: moment().format("YYYY-MM-DD"),
    end_day: moment().format("YYYY-MM-DD"),
    routes: [],
    checkRoutes: true,
  });

  useEffect(() => {
    getData();
  }, [])

  async function getData() {
    try {
      const data = await api.get(`routes`);
      let routes = []
      let routes_name = []
      data.data.forEach((item) => { 
        routes.push(item.id)
        routes_name.push(item.name)
      })
      setRoutelistfilter(routes);
      setRoutelist(routes_name);
      setState((prev) => ({
        ...prev,
        routes: data.data || [],
      }))
    } catch (error) {
      console.warn(error)
    }
  }
  const handleDateStart = (date) => {
    setState((prev) => ({
      ...prev,
      start_day: moment(date).format("YYYY-MM-DD"),
    }));
  };

  const handleDateEnd = (date) => {
    setState((prev) => ({
      ...prev,
      end_day: moment(date).format("YYYY-MM-DD"),
    }));
  };

  const handleChange = (event) => {
    let routes = []
    let routes_name = []
    if(event.target.checked) {
      state.routes.forEach((item) => { 
        routes.push(item.id)
        routes_name.push(item.name)
      })
      setRoutelistfilter(routes);
      setRoutelist(routes_name);
    } else {
      setRoutelistfilter(routes);
      setRoutelist(routes_name);
    }
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeCustom = (event) => {
    setRoutelist(event.target.value);
    let routes = []
    state.routes.map((item) => {
      event.target.value.forEach((route) => {
          if(route == item.name){
              routes.push(item.id)
          }
      })
  })
  /* console.log('Event.target.value: ', event.target.value)
  console.log('Routes: ', routes) */
    setRoutelistfilter(routes)
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Main title="SLA - Performance Operacional" color="#f7f7f7">
       <Grid container spacing={3} >
        <Grid item md={2} lg={2} sm={12} xs={12}>
          <div style={{ marginTop: 10, marginBottom: 0 }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
              <ThemeProvider theme={fcaTheme}>
                <KeyboardDatePicker
                  label="Data Início"
                  disableFuture={true}
                  inputVariant="outlined"
                  disableToolbar
                  clearable="true"
                  autoOk={true}
                  style={{ paddingBottom: 12 }}
                  format="dd/MM/yyyy"
                  variant="inline"
                  value={moment(state.start_day, "YYYY-MM-DD")}
                  onChange={handleDateStart}
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </div>
        </Grid>
        <Grid item md={2} lg={2} sm={12} xs={12}>
          <div style={{ marginTop: 10, marginBottom: 0 }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
              <ThemeProvider theme={fcaTheme}>
                <KeyboardDatePicker
                  label="Data Final"
                  disableFuture={true}
                  inputVariant="outlined"
                  disableToolbar
                  clearable="true"
                  autoOk={true}
                  style={{ paddingBottom: 12 }}
                  format="dd/MM/yyyy"
                  variant="inline"
                  value={moment(state.end_day, "YYYY-MM-DD")}
                  onChange={handleDateEnd}
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </div>
        </Grid>
      <Grid item sm={3} md={3} lg={3} xs={3}>
        <div style={{ marginTop: 10, marginBottom: 0 }}>
                <CustomSelectCheckbox
                    value={route_list}
                    onChange={handleChangeCustom}
                    data={state.routes}
                    list={route_list}
                    label="Rotas"
                    name="route"
                />
                </div>
            </Grid> 
      <Grid item>
      <div style={{ marginTop: 16, marginBottom: 0 }}>
      <FormControlLabel
        control={
          <Checkbox
            style={{color: "#5ad545"}}
            checked={state.checkRoutes}
            onChange={handleChange}
            name="checkRoutes"
           
          />
        }
        label="Todas as rotas"
      />
      </div>
      </Grid>
      </Grid>
      <Paper
        square
        /* style={{
          marginTop: "-25px",
          marginRight: "-30px",
          marginLeft: "-30px",
        }} */
      >
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
        >
          <Tab label="Report Geral" />
          <Tab label="Saturação" />
          <Tab label="Efetividade" />
          <Tab label="OTD" />
          <Tab label="Transit Time" />
        </Tabs>
      </Paper>

      <TabPanel value={value} index={0}>
        <ReportPanel start_day={state.start_day} end_day={state.end_day} routes={route_list_filter} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SaturationPanel start_day={state.start_day} end_day={state.end_day} routes={route_list_filter}  />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EffectivenessPanel start_day={state.start_day} end_day={state.end_day} routes={route_list_filter}  />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <OTDPanel start_day={state.start_day} end_day={state.end_day} routes={route_list_filter}  />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <TransitTimePanel start_day={state.start_day} end_day={state.end_day} routes={route_list_filter}  />
      </TabPanel>
    </Main>
  );
}

export default Kpis3;
