import React, { useEffect, useState } from "react";
import api from '../../../../../core/services/api'
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/Add";

//custom components
import CustomSelect from "../../../components/CustomSelect";


function CreateForm(props) {
  const [state, setState] = useState({
    type_id: "",
    error: false,
    disabled: false,
    active: true,
    descriptionList: [],
    description_id: null,
    occurrenceList: [],
  });

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [occurrence, setOccurrence] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setState({
      disabled: false,
      error: false,
      type_id: '',
      description_id: null,
      descriptionList: props.occurrence || [],
      occurrenceList: props.occurrence || [],
    })
    setName("");
    setDescription("");
    setOccurrence("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.occurrence === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  async function handleSubmit() {
    setState((prev) => ({
      ...prev,
      disabled: true
    }))
    const occurrences = {
      //description: state.description_id,
      description: occurrence,
    }
    const name = props.occurrence.filter((item) => { return item.id == state.description_id })
    console.log('Imprimindo o name: ', name)
    try {
      await api.post(`occurrence/${name[0].description}`, occurrences);
      props.getData();
    } catch (error) {
      console.warn(error)
    }
    console.log('Ocorrência Criada', occurrences)
    setOpen(false)
  }

  return (
    <div>
      <AddIcon onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm" >
        <DialogTitle>Cadastrar nova Ocorrência</DialogTitle>
        <DialogContent>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <CustomSelect
              label="Grupo de Ocorrência"
              name="description_id"
              value={state.description_id}
              data={state.descriptionList}
              onChange={(event) => handleChange(event)}
            />
          </div>

          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
              id='occurrence'
              name="occurrence"
              label="Ocorrências"
              type="text"
              value={occurrence}
              onChange={(e) => setOccurrence(e.target.value)}
              fullWidth
            />{" "}
          </div>

        </DialogContent>
        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor: state.disabled ? "" : "#5ed237", color: state.disabled ? "" : "#fff" }}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateForm;
