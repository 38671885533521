import React from "react";
import { useStyles } from "./styles";

//custom components
import GraphCard from "./GraphCard";

//material ui
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";

function TransitTime(props) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Tempo de Trânsito
        </Typography>
        <GraphCard start_day={props.start_day} end_day={props.end_day} routes={props.routes} />
      </CardContent>
    </Card>
  );
}

export default TransitTime;
