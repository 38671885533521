import React, { useState, useEffect } from "react";
import api from "../../../../core/services/api";
import { ThemeProvider } from 'styled-components';
//custom components
import Main from "../../components/Main";
import CardMotorista from "./CardMotorista";
import CustomSelect from "../../components/CustomSelect";
import ChatBot from 'react-simple-chatbot';

//material ui
import Grid from "@material-ui/core/Grid";
import LensIcon from "@material-ui/icons/Lens";
import { CallTicket } from "./callTicketbot";
import { DelayIndicator } from "./delay-indicator";
import { ActiveJourneys } from "./active-journeys";
import { Temperature } from "../../../ferrero/pages/MapRoutes/styles";

const theme = {
  background: '#f5f8fb',
  fontFamily: 'Helvetica, Arial, sans-serif',
  headerBgColor: '#54D959',
  headerFontColor: '#fff',
  headerFontSize: '16px',
  botBubbleColor: '#54D959',
  botFontColor: '#fff',
  userBubbleColor: '#fff',
  userFontColor: '#4a4a4a',
};

  
function CardRoutes() {
  
  const usuario = JSON.parse(localStorage.getItem("usuario"));
  console.log()
  // const steps =[
  //   {
  //     id:'intro',
  //     message:`Boas vindas ${usuario.person.name}, eu sou o bot da trackage, em que posso te ajudar?`,
  //     trigger:'intro-user',
  //   },
  //   {
  //     id:'intro-user',
  //     options:[
  //     {value:'1', label:'📈 Indicadores', trigger:'indicator-opt'},
  //     {value:'2', label:'📄 F.A.Q', trigger:'faq'},
  //     {value:'3', label:'☎️ Abrir chamado', trigger:'support-title'},
  //     {value:'4', label:'📬 Acompanhar meus chamados', trigger:'support-title'},
  //     ]
  //   },
  //   {
  //     id:'intro-retry',
  //     message:`Ainda precisa de alguma ajuda?`,
  //     trigger:'intro-user',
  //   },
  //   {
  //     id:'intro-retry-user',
  //     options:[
  //     {value:'1', label:'📈 Indicadores', trigger:'indicator-opt'},
  //     {value:'2', label:'📄 F.A.Q', trigger:'faq'},
  //     {value:'3', label:'☎️ Abrir chamado', trigger:'support-title'},
  //     {value:'4', label:'📬 Acompanhar meus chamados', trigger:'support-title'},
  //     ]
  //   },
  //   {
  //     id:'indicator-opt',
  //     options:[
  //     {value:'1', label:'Motoristas em atraso', trigger:'indicator-delay-message'},
  //     {value:'2', label:'Jornadas ativas', trigger:'indicator-journeys-message'},
  //     ]
  //   },
  //   {
  //     id:'indicator-journeys-message',
  //     message:'Pesquisando jornadas ativas',
  //     trigger:'indicator-journeys'
  //   },
  //   {
  //     id:'indicator-journeys',
  //     component:<ActiveJourneys />,
  //     asMessage:true,
  //     trigger:'intro-retry'
  //   },
  //   {
  //     id:'indicator-delay-message',
  //     message:'Pesquisando motoristas atrasados',
  //     trigger:'indicator-delay'
  //   },
  //   {
  //     id: 'indicator-delay',
  //     component: <DelayIndicator />,
  //     asMessage:true,
  //     trigger: 'intro-retry'
  //   },
  //   {
  //     id:'support-title',
  //     message:'Qual o assunto do chamado?',
  //     trigger:'support-title-user'
  //   },
  //   {
  //     id:'support-title-user',
  //     user:true,
  //     trigger:'support-description'
  //   },
  //   {
  //     id:'support-description',
  //     message:'Descreva o problema de forma completa',
  //     trigger:'support-description-user'
  //   },
  //   {
  //     id:'support-description-user',
  //     user:true,
  //     trigger:'support-risk'
  //   },
  //   {
  //     id:'support-risk',
  //     message:'Qual a criticidade?',
  //     trigger:'support-risk-user'
  //   },
  //   {
  //     id:'support-risk-user',
  //     options:[
  //       {value:'1', label:'Recente', trigger:'submit'},
  //       {value:'2', label:'Normal', trigger:'submit'},
  //       {value:'3', label:'Crítico', trigger:'submit'},
  //       ]
  //   },
  //   {
  //     id:'submit',
  //     component: <CallTicket />,
  //     asMessage: true,
  //     trigger:'intro-retry'
  //   },
  //   {
  //     id:'indicators',
  //     message:'NAO ABRE CHAMADO PLS',
  //     end:true
  //   },
  //   {
  //     id:'faq',
  //     message:'Sorry to hear that.',
  //     end:true,
  //   },
  // ];
  console.log("usuario", usuario)
  const [routes, setRoutes] = useState([]);
  const [state, setState] = useState({
    counters: [
      { counter: "On Time" },
      { counter: "Atrasado" },
      { counter: "Atrasado / DHL"},
      { counter: "Em Ocorrência" },
      { counter: "Aguardando/Sem Pedido" },
      { counter: "Exceção" },
    ],
    telemetries: [],
    occurrences: [],
    filteredCards: [],
    filter: 0,
   // filterRoutes: [],
    local_users: usuario.person.resource.local_id,
  });

  const [stateRoutes, setStateRoutes] = useState([]);

  useEffect(() => {

  })
  useEffect(() => {    
    getOccurrences();
    getData();

    const interval = setInterval(() => {
      getData();      
    }, 30000);
    return () => clearInterval(interval);
  }, [state.filter, stateRoutes]);

  useEffect(() => {
    getRoutes()
  }, [])

  async function getOccurrences() {
    try {
      const data = await api.get(`occurrence`);
      setState((prev) => ({
        ...prev,
        occurrences: data.data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  async function getRoutes(){
    try{
      const { data } = await api.get('routes')
      const routes = data.map((item) => {
        return { id: item.name, name: item.name}
      })
      routes.unshift({id: 0, name: 'Todos'})
      setRoutes(routes)
    }
    catch(error){
      console.warn(error)
    }
  }

  async function getData() {
    try {
      //const { data } = await api.get(`scans-telemetries`);
      const data2 = await api.get(`route-tracking`);
      
      //FILTRANDO ROTAS
      const data3 = await api.get(`routes`);
      let routes = []
      data3.data.forEach((item, index) => {
        let route = null; 
        item.tasks.forEach((item2, index2) => {
          if(state.local_users.includes(item2.local)){ route = item.name}
        })
        if(route) routes.push(route)
      })


      let filteredCards = data2.data;

      //FILTRAR ROTAS USUARIOS
      if(!state.local_users.includes(60)){
      filteredCards = data2.data.filter((item) => {return routes.includes(item.route)})
      }
      filteredCards.sort((a,b) => (a.route > b.route) ? 1 : ((b.route > a.route) ? -1 : 0))

      if (state.filter != 0) {
        filteredCards = filteredCards.filter((item) => {
          return item.status == state.filter;
        });
        //console.log('Filter: ', state.filter, 'Filtered pushpins: ', filteredPushPins)
      }

      if(stateRoutes.length > 0) {
        let filter = [];
        filteredCards = filteredCards.filter((item) => {
          return stateRoutes.filter((it2) => {
            if (it2 == item.route){filter.push(item)}
          })          
        });
        filteredCards = filter;
      }
      setState((prev) => ({
        ...prev,
        telemetries: data2.data || [],
        filteredCards: filteredCards || [],
        routes: data3.data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  function renderCounters() {
    let items = state.counters.map((item) => {
      return (
        <>
          <p
            style={{
              margin: 0,
              padding: "6px 12px",
              borderRadius: "20px",
              marginLeft: 10,
              fontSize: 14,
            }}
          >
            <LensIcon
              style={{ color: selectColor(item.counter), fontSize: 12 }}
            />{" "}
            {item.counter}
          </p>
        </>
      );
    });
    return (
      <div
        style={{
          display: "flex",
          height: "100%"         
        }}
      >
        {items}
      </div>
    );
  }
  function selectColor(counter) {
    if (counter === "On Time") {
      return "#54d959";
    }
    if (counter === "Atrasado") {
      return "#f43f3c";
    }
    if (counter === "Em Ocorrência") {
      return "#FE8545";
    }
    if (counter === "Chegada Adiantada / Janela Sem Pedido") {
      return "#36414c";
    }
    if (counter === "Atrasado / DHL") {
      return "#FFC34A";
    }
    if (counter === "Exceção") {
      return "#3490fa";
    }
  }

  const filter = [
    { id: 0, name: "Todos" },
    { id: "green", name: "On Time" },
    { id: "red", name: "Atrasado" },
    { id: "yellow", name: "Atrasado/Responsabilidade DHL"},
    { id: "orange", name: "Em Ocorrência" },
    { id: "gray", name: "Chegada Adiantada / Janela Sem Pedido" },
    { id: "blue", name: "Exceção" },
  ];

  const handleChange = (event) => {

    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeRouter = (e) =>{    
      let last = [...e.target.value].pop();      
    
    if(last == 0){
      setStateRoutes([]);
    }
    else
    {
      setStateRoutes(e.target.value);  
    }
    
  }

  return (
    <>
      {/* <ThemeProvider theme={theme}>
          <ChatBot placeholder="Digite sua mensagem" headerTitle="Fale Conosco" floating={true} steps={steps} />
      </ThemeProvider> */}
      <Main
      color="#f7f7f7"
      title={<div style={{ display: "flex", justifyContent: "space-between" }}>
        <span
          style={{
            marginRight: 8,
            borderRight: "0.5px solid #fffffffc",
            paddingRight: 16,
            marginTop: 4,
          }}
        >
          Painel de Rotas
        </span>
      </div>}
      filter={<div style={{display:'flex', flexDirection:'row', gap: '24px'}}>
      <CustomSelect
        id="component"
        label="Filtro Status"
        value={state.filter}
        name="filter"
        data={filter}
        onChange={(event) => handleChange(event)}
        fullWidth />

        <CustomSelect
          id="component"
          label="Filtro Rotas"
          value={stateRoutes}
          name="stateRoutes"
          data={routes}          
          multiple={true}
          onChange={(e) => handleChangeRouter(e)}
          fullWidth />
          </div>}
    >
      <Grid>
      <div style={{ backgroundColor: 'white', left:'3vw', top: '8vh', marginBottom: '10px', borderRadius: '6px' }}  display="flex" justifyContent="space-between">
        {renderCounters()}
      </div>
      </Grid>
      
      <Grid container spacing={3}>
    
        {state.filteredCards.map(function (carro, key) {
          return (
            <Grid item lg={4} sm={12} md={6} xs={12}>
              <CardMotorista
                board={carro.cart}
                name={carro.driver_name}
                route_name={carro.route}
                description={carro.description}
                taskdone={carro.executedTasksShift}
                taskprevision={carro.previsionTaskInShift}
                effectiveness_ontime={carro.efetividade_ontime}
                item_sidebar={carro}
                status={carro.status}
                time_operation=""
                tempo_filial=""
                vehicle_id={carro.vehicle_id}
                latitude={carro.lat}
                longitude={carro.lon}
                occurrences={state.occurrences}
                routes={state.routes} />
            </Grid>
          );
        })}
      </Grid>
    </Main></>
  );
}

export default CardRoutes;
