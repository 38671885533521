import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import api from "../../../../../../../core/services/api";
import moment from "moment";
import options from "./options.js";

function GraphCard(props) {
  const [state, setState] = useState({
    series: [
      {
        name: "Tempo Médio",
        type: "column",
        data: [20, 30, 40, 35, 66],
      },
      {
        name: "Target",
        type: "line",
        data: [23, 32, 40, 55, 25],
      },
    ],
    start_day: moment().format("YYYY-MM-DD"),
    end_day: moment().add(1, "days").format("YYYY-MM-DD"),
  });

  return (
    <Chart
      options={options(props.legends)}
      series={props.series}
      type="line"
      height={350}
    />
  );
}

export default GraphCard;
