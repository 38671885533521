import React, { useState, useEffect } from "react";
import api from "../../../../../../core/services/api";
import moment from "moment";
import { useStyles } from "./styles";

//@material-ui
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";

//custom components
import GraphCard from "./GraphCard";
import CustomSelect from "../../../../components/CustomSelect";

function EffectivenessHour(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    start_day: moment().format("YYYY-MM-DD"),
    end_day: moment().add(1, "days").format("YYYY-MM-DD"),
    routes_list: [],
    route_id: null,
    legends: [],
    efet_route: [],
    series: [
      {
        name: "Efetividade Real ",
        type: "column",
        data: [],
      },
      {
        name: "% Efetividade",
        type: "line",
        data: [],
      },
      {
        name: "Target Efetividade",
        type: "line",
        data: [],
      },
    ],
  });

  useEffect(() => {
    getData();
  }, [state.route_id, state.start_day]);

  async function getRoutes() {
    try {
      const data = await api.get(`routes`);
      setState((prev) => ({
        ...prev,
        routes_list: data.data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  async function getData() {
    try {
      let shift = ``;
      if (state.shifts_id != null) shift = `&shift=${state.shifts_id}`;
      const data = await api.get(
        `effectiveness-day?start_day=${state.start_day}&end_day=${state.end_day}`
      );
      const data2 = await api.get(`routes`);
      let routes_filtered = data2.data;
      let effectiveness_filtered = data.data;
      if (state.route_id != null) {
        routes_filtered = data2.data.filter((item) => {
          return item.id == state.route_id;
        });
        effectiveness_filtered = data.data.filter((item) => {
          return item.name == routes_filtered[0].name;
        });
      }
      let legends = [
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "00",
        "1",
        "2",
        "3",
        "4",
        "5",
      ];
      let series = [
        {
          name: "Efetividade Real ",
          type: "column",
          data: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
        },
        {
          name: "% Efetividade",
          type: "line",
          data: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
        },
        {
          name: "Target Efetividade",
          type: "line",
          data: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
          ],
        },
      ];
      effectiveness_filtered.forEach((item, index) => {
        item.jornada.tasks.forEach((item2, idx) => {
          if (item2.activity_type == 6 && item2.end_real != null) {
            let hours = moment(item2.end_real, "YYYY-MM-DD HH:mm:ss").hour();
            let index_hours = hours == 0 ? "00" : hours.toString();
            let index_legend = legends.indexOf(index_hours);
            series[0].data[index_legend] += 1;
          }
        });
      });
      routes_filtered.forEach((item, index) => {
        item.tasks.forEach((item2, idx) => {
          if (item2.activity_type == 6) {
            let hours = moment(item2.end_prevision, "HH:mm:ss").hour();
            let index_hours = hours == 0 ? "00" : hours.toString();
            let index_legend = legends.indexOf(index_hours);
            series[2].data[index_legend] += 1;
          }
        });
      });
      series[1].data.forEach((item, index) => {
        if (series[0].data[index] > 0) {
          if(series[2].data[index] > 0) {
            series[1].data[index] = ((series[0].data[index] * 100) / series[2].data[index]).toFixed(0);
          } else {
            series[1].data[index] = (series[0].data[index] * 100).toFixed(0);
          }
          
        }
      });
      setState((prev) => ({
        ...prev,
        series: series || [],
        legends: legends || [],
        routes_list: data2.data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  const handleDate = (date) => {
    setState((prev) => ({
      ...prev,
      start_day: moment(date).format("YYYY-MM-DD"),
      end_day: moment(date).add(1, "days").format("YYYY-MM-DD"),
    }));
  };
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between" alignItems="center" spacing={3}>
          <Grid item lg={8} md={8} sm={8} xs={8}>
            <Typography>Efetividade Hora a Hora</Typography>
          </Grid>
          <Grid item lg={2} sm={2} md={2} xs={2}>
            <div style={{ marginTop: 12, marginBottom: 12 }}>
              <Select
                MenuProps={{ disableScrollLock: true }}
                fullWidth
                variant="outlined"
                displayEmpty
                value={state.route_id}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    route_id: e.target.value,
                  }))
                }
              >
                <MenuItem key="all" value={null}>
                  Todos as Rotas
                </MenuItem>
                {state.routes_list.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </Grid>
          <Grid item lg={2} sm={2} md={2} xs={2}>
            <div style={{ marginTop: 10, marginBottom: 0 }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
                <ThemeProvider theme={fcaTheme}>
                  <KeyboardDatePicker
                    label="Filtro de Data"
                    disableFuture={true}
                    inputVariant="outlined"
                    disableToolbar
                    clearable="true"
                    autoOk={true}
                    style={{ paddingBottom: 12 }}
                    format="dd/MM/yyyy"
                    variant="inline"
                    value={moment(state.start_day, "YYYY-MM-DD")}
                    onChange={handleDate}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </div>
          </Grid>
        </Grid>

        <GraphCard legends={state.legends} series={state.series} />
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
}

export default EffectivenessHour;
