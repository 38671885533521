import React, { useState, useEffect } from "react";
import api from "../../../../../../core/services/api";
import moment from "moment";
import { useStyles } from "./styles";

//@material-ui
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";

//custom components
import GraphCard from "./GraphCard";

function EffectivenessRoute(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    start_day: moment().format("YYYY-MM-DD"),
    end_day: moment().add(1, "days").format("YYYY-MM-DD"),
    shifts_list: [],
    shifts_id: null,
    legends: [],
    efet_route: [
      {
        name: "Turno 1",
        type: "column",
        data: [],
      },
      {
        name: "Turno 2",
        type: "column",
        data: [],
      },
      {
        name: "Turno 3",
        type: "column",
        data: [],
      },
      {
        name: "Turno 3 - D",
        type: "column",
        data: [],
      },
    ],
  });

  useEffect(() => {
    getData();
  }, [state.shifts_id, state.start_day]);

  useEffect(() => {
    getShift();
  }, []);

  async function getShift() {
    try {
      const data = await api.get(`shifts?active=all`);
      const shifts = [];
      data.data.data.map((item) => shifts.push(item));
      setState((prev) => ({
        ...prev,
        shifts_list: data.data.data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  async function getData() {
    try {
      let shift = ``;
      if (state.shifts_id != null) shift = `&shift=${state.shifts_id}`;
      const data = await api.get(
        `effectiveness-day?start_day=${state.start_day}&end_day=${state.end_day}` +
          shift
      );
      let efet_route = [
        {
          name: "Turno 1",
          type: "column",
          data: [],
        },
        {
          name: "Turno 2",
          type: "column",
          data: [],
        },
        {
          name: "Turno 3",
          type: "column",
          data: [],
        },
        {
          name: "Turno 3 - D",
          type: "column",
          data: [],
        },
      ];
      let legends = [];

      data.data.forEach((item, index) => {
        let exist = legends.includes(item.name);
        if (!exist) {
          legends.push(item.name);
        }
      });
      legends.forEach((item, index) => {
        efet_route[0].data.push(0);
        efet_route[1].data.push(0);
        efet_route[2].data.push(0);
        efet_route[3].data.push(0);
      });
   
      data.data.forEach((item, index) => {
    
        let idx = legends.indexOf(item.name);
        if (parseInt(item.programado_shift) != 0) {
          if (item.shift == "Turno 1") {
            efet_route[0].data[idx] =
              (parseInt(item.realizado) * 100) / parseInt(item.programado_shift);
          } else if (item.shift == "Turno 2") {
            efet_route[1].data[idx] =
              (parseInt(item.realizado) * 100) / parseInt(item.programado_shift);
          } else if (item.shift == "Turno 3") {
            efet_route[2].data[idx] =
              (parseInt(item.realizado) * 100) /
              parseInt(item.programado_shift);
          } else {
            efet_route[3].data[idx] =
              (parseInt(item.realizado) * 100) /
              parseInt(item.programado_shift);
          }
        }
      });
      setState((prev) => ({
        ...prev,
        efet_route: efet_route,
        legends: legends,
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  const handleDate = (date) => {
    setState((prev) => ({
      ...prev,
      start_day: moment(date).format("YYYY-MM-DD"),
      end_day: moment(date).add(1, "days").format("YYYY-MM-DD"),
    }));
  };
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between" alignItems="center" spacing={3}>
          <Grid item lg={8} md={8} sm={8} xs={8}>
            <Typography>Efetividade por Rota e Turno</Typography>
          </Grid>
          <Grid item lg={2} sm={2} md={2} xs={2}>
            <div style={{ marginTop: 12, marginBottom: 12 }}>
              <Select
                MenuProps={{ disableScrollLock: true }}
                fullWidth
                variant="outlined"
                displayEmpty
                value={state.shifts_id}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    shifts_id: e.target.value,
                  }))
                }
              >
                <MenuItem key="all" value={null}>
                  Todos os Turnos
                </MenuItem>
                {state.shifts_list.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.resource.work_shift}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </Grid>
          <Grid item lg={2} sm={2} md={2} xs={2}>
            <div style={{ marginTop: 10, marginBottom: 0 }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
                <ThemeProvider theme={fcaTheme}>
                  <KeyboardDatePicker
                    label="Filtro de Data"
                    disableFuture={true}
                    inputVariant="outlined"
                    disableToolbar
                    clearable="true"
                    autoOk={true}
                    style={{ paddingBottom: 12 }}
                    format="dd/MM/yyyy"
                    variant="inline"
                    value={moment(state.start_day, "YYYY-MM-DD")}
                    onChange={handleDate}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </div>
          </Grid>
        </Grid>

        <GraphCard efet_route={state.efet_route} legends={state.legends} />
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
}

export default EffectivenessRoute;
