import React, { useState, useEffect } from 'react';
import Main from '../../components/Main'
import MaterialTable, { MTableToolbar, MTablePagination } from "material-table";
import api from '../../../../core/services/api'

//custom components
import CreateForm from './CreateForm'
import EditForm from './EditForm'
import Alert from '../../components/Alert'


//@material-ui
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import { Fab } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

//@material icons
import DeleteIcon from '@material-ui/icons/Delete'
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import AddIcon from "@material-ui/icons/Add";
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';

function Justifications() {

  const [state, setState] = useState({
    justifications: [],
    pageNumber: 1,
    totalRow: 0,
    numberRowPerPage: 1000,
    firstLoad: false,
  })

  useEffect(() => {
    getData(state.pageNumber, state.numberRowPerPage);
    setState((prev) => ({
      ...prev,
      firstLoad: true,
    }))
  }, [])

  async function getData(page, rowPerPage ) {
    try {
      const data = await api.get(`justification?&perPage=${rowPerPage}`);
      setState((prev) => ({
        ...prev,
        justifications: data.data.data,
        //totalRow: parseInt(data.data.total)
      }))
    } catch (error) {
      console.warn(error)
    }
  }

  async function deleteJustification(justification) {
    try {

      if (
        window.confirm(
          "Tem certeza que deseja deletar a justificativa " + justification.description
        )
      ) {
        await api.delete(`justification/${justification.id}`);
      }

      getData();
    } catch (error) {
      console.warn(error)
    }
  }

  function getName(type) {
    if (type === "dock_time") {
      return "Tempo de doca"
    } else if (type === "otd") {
      return "OTD"
    } else {
      return "-"
    }
  }

  function handleChangePage(page){
    console.log('mudando de pagina', page)
    setState((prev) => ({
      ...prev, 
      justifications: [], 
      pageNumber: page == 0 ? page+1 : page}))
    getData(page == 0 ? page+1 : page, state.numberRowPerPage)
  }

  const useStyles = makeStyles({
    root: {
      transform: "translateZ(0px)",
      flexGrow: 1,
      color: '#69f0ae'
    },
    speedDial: {
      zIndex: 9999,
      position: "fixed",
      bottom: "12%",
      right: "0.2%",
      "& .MuiSpeedDial-fab": {
        backgroundColor: '#5ad545'
      },
    }
  }
  );

  async function getReport() {
    try {
      let url = `/justification_export`;
      // url += state.check_shift ? "&check_shift=" + state.check_shift : "";
      api.get(url).then(blobFile => {
          //fileDownload(blobFile.data.data, 'teste.xlsx')
           const url = window.URL.createObjectURL(new Blob([blobFile.data.data]));
          const link = document.createElement('a');
          //converter base64 pra xlsx
          //passar xlsx pro href
          link.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+blobFile.data.data;
          link.setAttribute('download', `justificativas.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);  

      })
    } catch (err) {
      console.warn(err);
    }
  
  };


  const classes = useStyles();
  const [direction, setDirection] = React.useState("up");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);

  };

  return (
    <Main color="#f7f7f7" title="Justificativas">
      <Grid container justify="flex-end" spacing={3} style={{ paddingBottom: 12 }}>
        <Grid item>
        <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={direction}
            getData={getData}
            optionType={state.optionType}
          >
            <SpeedDialAction
              key="CreateForm "
              icon={<CreateForm getData={getData} optionType={state.optionType} />}
              tooltipTitle="Adicionar Justificativa"
              onClick={() => handleOpen() }

            />
            <SpeedDialAction
              key="download"
              icon={<GetAppIcon />}
              tooltipTitle="Download"
              onClick={() => getReport()}
             
            />
            {/* <SpeedDialAction
              key="upload"
              icon={<PublishIcon />}
              tooltipTitle="Upload"
              onClick={() => handleOpen()}
            /> */}

          </SpeedDial>
        </Grid>
        <Alert open={open2} message="O relatório foi baixado com sucesso!" setOpen={() => setOpen2()} /> 
      </Grid>
      <MaterialTable
        columns={[
          {
            title: "Descrição",
            field: "description",
            cellStyle: { paddingLeft: 24, textAlign: "left", width: '50%', paddingRight: 80 },
            headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
          },
          {
            title: "Tipo",
            field: "type",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' },
            render: (rowData) => getName(rowData.type)
          },
          {
            field: "actions",
            title: "Ações",
            cellStyle: { paddingBottom: 0, paddingTop: 0, textAlign: "right" },
            headerStyle: { paddingRight: 20, textAlign: "right", borderRadius: '0px 8px 0px 0px' },
            render: (rowData) => (
              <>
                <FormGroup row style={{ justifyContent: 'flex-end' }}>
                  <EditForm justification={rowData} getData={getData} />
                  <IconButton
                    style={{ color: "#ec273b" }}
                    onClick={() => deleteJustification(rowData)}
                  >
                    <DeleteIcon />
                  </IconButton>{" "}
                </FormGroup>
              </>
            ),
          },
        ]}
        data={state.justifications}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
          },
          rowStyle: (x) => {
            if (x.tableData.id === 0) {
              return { borderTop: '2px solid rgb(212, 212, 212)' }
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
         /*  Pagination: props => (
        
            <MTablePagination
            {...props}
            rowsPerPageOptions={[5, 10, 20, 30]}
            rowsPerPage={state.numberRowPerPage}
            count={state.totalRow}
            page={
            state.firstLoad
            ? state.pageNumber
            : state.pageNumber - 1
            }
            onChangePage={(e, page) =>
              handleChangePage(page)
            }
            onChangeRowsPerPage={event => {
          props.onChangeRowsPerPage(event);
          this.handleChangeRowPerPage(event.target.value);
        }}  />
        
    ), */
        }}
        style={{
          borderRadius: 10,
        }}
      />
    </Main>
  )
}

export default Justifications;