import React, { Component } from "react";
import RouteRow from "../RouteRow";
// import DateInput from "../../components/DateInput";
// import DropDown from "../../components/DropDown";
import BarsLoader from "../../components/loaders/BarsLoader";
import moment from "moment";
import css from "./route_column.module.css";

class RouteColumn extends Component {
  constructor(props) {
    super(props);

    this.filters = {
      all: "Todos",
      on: "Ligados vazios",
      loaded: "Ligados carregados",
      still: "Ligados parados",
      off: "Desligados"
    };

    this.colors = {
      on: "#5ED237",
      loaded: "#0091E6",
      still: "#FF8000",
      off: "#9AA9AF"
    };

    this.paths = ["Rua", "Av.", "Praça", "Alameda"];
    this.pathsNames = [
      "Um",
      "Dois",
      "Três",
      "Quatro",
      "Cinco",
      "Seis",
      "Sete",
      "Oito",
      "Nove",
      "Dez",
      "Onze",
      "Doze"
    ];
    this.pathCount = -1;
    this.millisec = 1000 * 60 * 60 * 8;

    this.stops = [
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.on,
        plate: "LJA-4277",
        startDate: "2019-01-01",
        endDate: "2019-01-03"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.loaded,
        plate: "SDS-7132",
        startDate: "2019-01-10",
        endDate: "2019-03-01"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.still,
        plate: "EWS-0529",
        startDate: "2019-03-11",
        endDate: "2019-03-12"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.off,
        plate: "OJD-1276",
        startDate: "2019-03-21",
        endDate: "2019-06-07"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.loaded,
        plate: "YAN-5938",
        startDate: "2019-06-10",
        endDate: "2019-06-14"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.on,
        plate: "QOU-7163",
        startDate: "2019-06-17",
        endDate: "2019-06-26"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.loaded,
        plate: "GSI-0396",
        startDate: "2019-07-12",
        endDate: "2019-07-30"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.on,
        plate: "WER-9829",
        startDate: "2019-08-05",
        endDate: "2019-09-23"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.off,
        plate: "HSA-5865",
        startDate: "2019-10-18",
        endDate: "2019-10-22"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.on,
        plate: "LUE-9849",
        startDate: "2019-10-24",
        endDate: "2019-10-25"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.off,
        plate: "MHD-2383",
        startDate: "2019-11-28",
        endDate: "2019-12-09"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.off,
        plate: "AAA-1038",
        startDate: "2019-12-16",
        endDate: "2019-12-18"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.loaded,
        plate: "NDH-8725",
        startDate: "2019-01-10",
        endDate: "2019-01-14"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.still,
        plate: "BMJ-6127",
        startDate: "2019-01-15",
        endDate: "2019-02-19"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.off,
        plate: "EON-8798",
        startDate: "2019-03-15",
        endDate: "2019-03-18"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.on,
        plate: "WNP-1929",
        startDate: "2019-03-26",
        endDate: "2019-03-28"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.loaded,
        plate: "XVC-9832",
        startDate: "2019-04-12",
        endDate: "2019-04-29"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.on,
        plate: "FJO-6512",
        startDate: "2019-05-21",
        endDate: "2019-05-28"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.loaded,
        plate: "QPL-9889",
        startDate: "2019-06-20",
        endDate: "2019-06-24"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.still,
        plate: "SLU-1032",
        startDate: "2019-06-26",
        endDate: "2019-07-08"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.off,
        plate: "FLJ-1252",
        startDate: "2019-07-10",
        endDate: "2019-09-05"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.still,
        plate: "MFJ-7979",
        startDate: "2019-09-13",
        endDate: "2019-09-19"
      },
      {
        time: this.randomTime(),
        address: this.randomAddress(),
        status: this.colors.on,
        plate: "XLF-3452",
        startDate: "2019-10-04",
        endDate: "2019-10-16"
      }
    ];

    this.filteredStops = [...this.stops];

    this.state = {
      stops: this.stops,
      currentOption: this.filters.all,
      startDate: 0,
      endDate: 0
    };

    this.styles = {
      row: {
        width: "260px",
        height: "38px"
      }
    };

    this.matchMedia = window.matchMedia("(max-width: 576px)");
    this.matchMedia.addListener(this.mediaQueries.bind(this));
  }

  randomAddress() {
    const sort = (a, b) => 0.5 - Math.random();

    this.pathCount++;

    if (this.pathCount % this.paths.length === 0) this.paths.sort(sort);

    if (this.pathCount % this.pathsNames.length === 0)
      this.pathsNames.sort(sort);

    return (
      this.paths[this.pathCount % this.paths.length] +
      " " +
      this.pathsNames[this.pathCount % this.pathsNames.length] +
      ", " +
      Math.round(Math.random() * 10000)
    );
  }

  randomDelta(ms) {
    return Math.round(Math.random() * ms);
  }

  randomTime() {
    this.millisec += this.randomDelta(-1000 * 60 * 10);
    const hours = String(
      Math.floor((this.millisec / (1000 * 60 * 60)) % 24)
    ).padStart(2, "0");
    const minutes = String(
      Math.floor((this.millisec / (1000 * 60)) % 60)
    ).padStart(2, "0");
    return hours + ":" + minutes;
  }

  updateLineHeight() {
    document.querySelector("." + css.verticalLine).style.height =
      this.state.stops.length * 50 - 50 + "px";
  }

  componentDidMount() {
    this.mediaQueries();
    this.updateLineHeight();
  }

  componentDidUpdate() {
    this.updateLineHeight();
  }

  mediaQueries() {}

  filterStopsByStatus(e) {
    const key = Object.keys(this.filters).find(
      key => this.filters[key] === e.target.textContent
    );
    this.filteredStops = [...this.stops].filter(
      driver => !this.colors[key] || driver.status === this.colors[key]
    );

    this.setState({
      stops: this.filteredStops,
      currentOption: e.target.textContent
    });
  }

  filterByDate(e) {
    const ms = Date.parse(e.currentTarget.value);

    if (ms < 0) return;

    this[e.currentTarget.name] = ms;

    const array = [...this.stops];

    if (this.startDate && this.endDate && this.startDate < this.endDate)
      this.setState({
        stops: array.filter(
          stop =>
            Date.parse(stop.startDate) >= this.startDate &&
            Date.parse(stop.endDate) <= this.endDate
        )
      });
    else this.setState({ stops: array });
  }

  updateCount() {
    if(this.props.route.length > 0) {
      return `${this.props.route.length} ${
        this.props.route.length > 1 ? "posições" : "posição"
      }, ${moment(this.props.route[0].creation).format('DD/MM/YYYY')}, de ${moment(this.props.route[this.props.route.length - 1].creation).format('HH:mm ')} às ${
        moment(this.props.route[0].creation).format('HH:mm')
      }`;
    }
  }

  clickHandler(e) {
    if (this.prevRow) this.prevRow.className = css.row;

    if (e.currentTarget.className === css.row)
      e.currentTarget.className = css.rowActive;

    this.prevRow = e.currentTarget;
  }

  render() {
    const content = Object.keys(this.filters).map(filter => {
      return <a>{this.filters[filter]}</a>;
    });

    return (
      <div className={"stops-column-container " + css.container}>
        <div className={"list-group " + css.filterMain}>
          {/* <div className={"list-group-item " + css.filterContainer}>
                        <div className={css.filterContainer1}>
                            <DropDown className={css.dropDown} content={content} onSelect={this.filterStopsByStatus.bind(this)}></DropDown>
                        </div>
                    </div>
                    <div className={css.info + " list-group-item"}>
                        <div className={css.filterField}>
                            <DateInput onChangeDate={this.filterByDate.bind(this)} onInputDate={this.filterByDate.bind(this)}></DateInput>
                        </div>
                    </div> */}
          <div className={css.info + " list-group-item"}>
            <div className={css.infoText}>{this.updateCount()}</div>
          </div>
        </div>
        <div className={css.column + " list-group list-group-flush"}>
          {(!this.props.route || this.props.route.length === 0) && (
            <BarsLoader
              style={{
                position: "relative",
                zIndex: 3000,
                opacity: 1,
                left: "50%",
                top: "50%",
                transform: "translate(-20px, -50%)"
              }}
            />
          )}
          {this.props.route &&
            this.props.route.length > 0 &&
            this.props.route.map((path, index) => {
              const {
                status,
                time,
                address,
                temperature,
                lat,
                lon,
                created_at
              } = path;
              return (
                <RouteRow
                  latitude={lat}
                  longitude={lon}
                  goto={this.props.navigateTo}
                  className={css.row}
                  status={"#242424"}
                  temperature={temperature}
                  time={moment(created_at).format("HH:mm")}
                  address={address}
                  key={index}
                  onClick={this.clickHandler.bind(this)}
                ></RouteRow>
              );
            })}
          <div className={css.verticalLine}></div>
        </div>
      </div>
    );
  }
}

export default RouteColumn;
