import React, { useEffect } from "react";
import api from "../../../../../core/services/api";
import moment from "moment";

//@materialui
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";

//datepicker
import { KeyboardTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

function EditForm(props) {
  const [state, setState] = React.useState("");
  const [work_shift, setWorkshift] = React.useState(props.work_shift);
  const [start_at, setStarttime] = React.useState(
    "2018-01-01T" + props.start_at + ".000"
  );
  const [end_at, setEndtime] = React.useState(
    "2018-01-01T" + props.end_at + ".000"
  );
  const [week_days, setWeekdays] = React.useState({
    seg: props.week_days?.includes(1),
    ter: props.week_days?.includes(2),
    qua: props.week_days?.includes(3),
    qui: props.week_days?.includes(4),
    sex: props.week_days?.includes(5),
    sab: props.week_days?.includes(6),
    dom: props.week_days?.includes(0),
  });
  const [active, setActive] = React.useState(props.active);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setState({
      work_shift: props.work_shift,
      start_at: props.start_at,
      end_at: props.end_at,
      week_days: props.week_days,
      active: props.active,
      disabled: false,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function handleSubmit() {
    const week = [];
    if (week_days.seg) {
      week.push(1);
    }
    if (week_days.ter) {
      week.push(2);
    }
    if (week_days.qua) {
      week.push(3);
    }
    if (week_days.qui) {
      week.push(4);
    }
    if (week_days.sex) {
      week.push(5);
    }
    if (week_days.sab) {
      week.push(6);
    }
    if (week_days.dom) {
      week.push(7);
    }

    const shifts = {
      work_shift: work_shift,
      start_at: moment(start_at).format("HH:mm:ss"),
      end_at: moment(end_at).format("HH:mm:ss"),
      week_days: week,
    };
    const obj = {
      active: active,
      resource: shifts,
    };

    if (
      shifts.work_shift === state.work_shift &&
      shifts.start_at === state.start_at &&
      shifts.end_at === state.end_at &&
      JSON.stringify(shifts.week_days) === JSON.stringify(state.week_days) &&
      active === state.active
    ) {
      console.log("nomes iguais, start igual não atualizar");
    } else {
      setState((prev) => ({
        ...prev,
        disabled: true,
      }));
      await api.put(`shifts/` + props.rowData.id, obj);
      //console.log("editando object", props.rowData.id, obj);
      props.getData();
    }
    setOpen(false);
  }

  const handleChange = (event) => {
    setWeekdays((prev) => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
  };
  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <EditIcon style={{ color: "#c38148" }} className="icon-small" />
      </IconButton>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>Editar Turno {props.title}</DialogTitle>

        <DialogContent>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
              id="work_shift"
              label="Turno"
              type="text"
              value={work_shift}
              onChange={(e) => setWorkshift(e.target.value)}
              fullWidth
            />
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                fullWidth
                autoOk={true}
                ampm={false}
                variant="inline"
                label="Horário de Início"
                value={start_at}
                onChange={setStarttime}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                fullWidth
                autoOk={true}
                ampm={false}
                variant="inline"
                label="Horário de Término"
                value={end_at}
                onChange={setEndtime}
              />
            </MuiPickersUtilsProvider>
          </div>

          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <FormLabel component="legend">Dias da semana</FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<span>Seg</span>}
                  checkedIcon={<span>Seg</span>}
                  color="primary"
                />
              }
              name="seg"
              checked={week_days.seg}
              onChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  icon={<span>Ter</span>}
                  checkedIcon={<span>Ter</span>}
                  color="primary"
                />
              }
              name="ter"
              checked={week_days.ter}
              onChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  icon={<span>Qua</span>}
                  checkedIcon={<span>Qua</span>}
                  color="primary"
                />
              }
              name="qua"
              checked={week_days.qua}
              onChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  icon={<span>Qui</span>}
                  checkedIcon={<span>Qui</span>}
                  color="primary"
                />
              }
              name="qui"
              checked={week_days.qui}
              onChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  icon={<span>Sex</span>}
                  checkedIcon={<span>Sex</span>}
                  color="primary"
                />
              }
              name="sex"
              checked={week_days.sex}
              onChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  icon={<span>Sab</span>}
                  checkedIcon={<span>Sab</span>}
                  color="primary"
                />
              }
              name="sab"
              checked={week_days.sab}
              onChange={handleChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  icon={<span>Dom</span>}
                  checkedIcon={<span>Dom</span>}
                  color="primary"
                />
              }
              name="dom"
              checked={week_days.dom}
              onChange={handleChange}
            />
          </div>
          <FormControlLabel
            control={
              <Switch
                checked={active}
                onChange={(event) => setActive(event.target.checked)}
                name="active"
                color="primary"
              />
            }
            label={active ? "Ativo" : "Inativo"}
          />
        </DialogContent>

        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            variant="contained"
            onClick={handleSubmit}
            style={{
              backgroundColor: state.disabled ? "" : "#5ed237",
              color: state.disabled ? "" : "#fff",
            }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditForm;
