import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import api from '../../../../../core/services/api'
import moment from 'moment'

// Styled Components
import { Sidebar } from "../../../styles/Map";

// @material-ui/core
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import Drawer from "@material-ui/core/Drawer"; 
import IconButton from "@material-ui/core/IconButton";
import ListSubheader from "@material-ui/core/ListSubheader";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";


// @material-ui/icons
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";


const useStyles = makeStyles((theme) => ({
    modal: {
        paddingLeft: 50,
      },
      paper: {
        position: 'fixed',
        left: 50,
        zIndex: 1,
      },
  }));

function SidebarContainer(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    start_day: moment().format("YYYY-MM-DD"),
    end_day: moment().add(1, 'day').format("YYYY-MM-DD"),
    active_day: moment().format("YYYY-MM-DD"),
    route_id: null,
    shift_id: null,
    disabled: false,
  })
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [occurrences, setOcurrences] = useState(props.occurrences)
  useEffect(() => {
    setOpen(props.opensidebar)
    setOcurrences(props.occurrences)
    setRoute(props.item_sidebar.route)
    setDescription(props.item_sidebar.description)
    setName(props.item_sidebar.driver_name)
    setFleet(props.item_sidebar.fleet)
    setHorse(props.item_sidebar.cart)
    setLocal(checkLocal())
    setTime(checkTime())
    setRoutestatus(checkRoutestatus())
    setEffectiveness(checkEffectiveness())
    setEffectivenessday(checkEffectivenessDay())
    setHdontime(checkHdontime())
    setHdday(checkHdday())
    setStatus(props.item_sidebar.status)
    const route_id = props.routes.filter((item)=> {return item.name == props.item_sidebar.route})
    //console.log('Obj: ', props.item_sidebar.ongoing_task ? '' : props.item_sidebar)
    setState((prev) => ({...prev,
       route_id: route_id ? route_id[0].id : null,
      shift_id:  props.item_sidebar.ongoing_task ? props.item_sidebar.ongoing_task.shift : ''}))
  }, [props.opensidebar, props.item_sidebar])

  const [route, setRoute] = useState("");
  const [description, setDescription] = useState("")
  const [name, setName] = useState("");
  const [fleet, setFleet] = useState("");
  const [horse, setHorse] = useState("");
  const [local, setLocal] = useState("");
  const [time, setTime] = useState("");
  const [route_status, setRoutestatus] = useState("");
  const [effectiveness, setEffectiveness] = useState("");
  const [effectiveness_day, setEffectivenessday] = useState("");
  const [hdontime, setHdontime] = useState("");
  const [hdday, setHdday] = useState("");
  const [status, setStatus] = useState("");

  function checkHdday(){
    if(props.item_sidebar){
      return hhmmss(props.item_sidebar.hdTasksDayExecutedlMinutes)+"/"+hhmmss(props.item_sidebar.hd_time_prevision_on_day)+" ("+props.item_sidebar.hd_dia+"%/85%)"
    } else {
      return "-"
    }
  }

  function checkHdontime(){
    if(props.item_sidebar){
      let hdtime = props.item_sidebar
      return hhmmss(props.item_sidebar.hdTasksShiftExecutedMinutes)+"/"+hhmmss(props.item_sidebar.hd_time_prevision_on_shift)+" ("+props.item_sidebar.hd_ontime+"%/85%)"
    } else {
      return "-"
    }
  }

  function checkEffectiveness(){
    if(props.item_sidebar){
      return props.item_sidebar.efTask6ShiftExecuted+"/"+props.item_sidebar.efTask6Shift+" ("+props.item_sidebar.efetividade_ontime+"%/100%)"
    } else {
      return "-"
    }
  }
  function checkEffectivenessDay(){
    if(props.item_sidebar){
      return props.item_sidebar.efTask6DayExecuted+"/"+props.item_sidebar.efTask6Day+" ("+props.item_sidebar.efetividade_dia+"%/100%)"
    } else {
      return "-"
    }
  }
  function checkRoutestatus(){
    let task = null
    task = props.item_sidebar.ongoing_task;
    if(task){

      let program = moment(task.start_prevision, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
      let real = moment(task.start_real, "YYYY-MM-DD HH:mm:ss").format("HH:mm")
      let time = moment.utc(moment(real, "HH:mm").diff(moment(program, "HH:mm")))
      console.log('Time: ', time)
      if(time < 0){  
        if(task.start_prevision == "" || !task.start_prevision){
          return real+" (-"+hhmmss(Math.abs(props.item_sidebar.status_time_minutes))+")"
        } else {
          return real+" / "+program+" (-"+hhmmss(Math.abs(props.item_sidebar.status_time_minutes))+")"
        }
        
      } else {
        if(task.start_prevision == ""  || !task.start_prevision){
          return real+" (+"+hhmmss(props.item_sidebar.status_time_minutes)+")"
        } else {
          return real+" / "+program+" (+"+hhmmss(props.item_sidebar.status_time_minutes)+")"
        } 
        
      }
    } else {
      return "-"
    }
  }
  function checkLocal(){
    let task = null
    task = props.item_sidebar.ongoing_task;
    if(task){
     if (task.start_real != null && task.end_real != null ){
        if(task.issues[0]){
          if(task.issues[0].finish_at == null){
            const issue = props.occurrences.filter((item) => {return task.issues[0].type_issue == item.id})
            const iss = issue[0].occurrence.filter((item2) => {return task.issues[0].issue == item2.id})
            return iss[0].description
          } else {
            return "Em trânsito"
          }
        } else {
          return "Em trânsito"
        }  
      } else {
        return task.title
      }  
    } else {
      return "-"
    }
  }
  function checkTime(){
    let task = null 
    task = props.item_sidebar.ongoing_task;
    if(task){
      if(task.transit_time_start != null) {
        //ESTA EM TRANSITO
        if(task.issues[0]){
          //OCORRENCIA
          if(task.issues[0].finish_at == null){
            var now = moment();
            var then = moment(task.issues[0].created_at, "YYYY-MM-DD HH:mm:ss");
            let time = moment.utc(moment().diff(moment(then))).format("HH:mm:ss")
            return time
          } else {
            var now = moment();
            var occurrence_start = moment(task.issues[0].created_at, "YYYY-MM-DD HH:mm:ss");
            var occurrence_end = moment(task.issues[0].finish_at, "YYYY-MM-DD HH:mm:ss");
            var occurrence_time = moment.utc(moment(occurrence_end).diff(moment(occurrence_start))).format("HH:mm:ss")
            var then = moment(task.transit_time_start, "YYYY-MM-DD HH:mm:ss");
            let time = moment.utc(moment().diff(moment(then))).format("HH:mm:ss")
            let final_time = moment(moment(time, "HH:mm:ss").diff(moment(occurrence_time, "HH:mm:ss"))).format("mm:ss")
            return "00:"+final_time
          }
        } else {
          var now = moment();
          var then = moment(task.transit_time_start, "YYYY-MM-DD HH:mm:ss");
          let time = moment.utc(moment().diff(moment(then))).format("HH:mm:ss")
          return time
        }
      } else {
        //TEMPO ATIVIDADE
        var now = moment();
        var then = moment(task.start_real, "YYYY-MM-DD HH:mm:ss");
        let time = moment.utc(moment().diff(moment(then))).format("HH:mm:ss")
        return time
      }
    } else {
      return "-"
    }
  }
  function checkStatus(status){
    if (status === 'red'){
      return "#f43f3c"
    } else if (status === 'green') {
      return "#54d959"
    } else if (status === 'blue') {
      return "#3490fa"
    } else if (status === 'orange'){
      return "#FE8545"
    } else {
      return "#36414c"
    }
  }

function pad(num) {
    return ("0"+num).slice(-2);
}
function hhmmss(minutes) {
  //var minutes = Math.floor(secs / 60);
  var secs = (minutes*60)%60;
  var hours = Math.floor(minutes/60)
  minutes = minutes%60;
  return `${pad(hours)}:${pad(minutes)}`;
  // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
}


  async function handleDownloadReport(){
    console.log('Baixando relatório...')
    setState((prev) => ({...prev, disabled: true}))
    var active_day = state.active_day;
    var now = moment();
    var midnight = moment("00:00:00", "HH:mm:ss");
    var end = moment("05:59:00", "HH:mm:ss");
    if(now.isAfter(midnight) && now.isBefore(end)){
      active_day = moment(state.active_day, "YYYY-MM-DD").subtract(1, 'day').format("YYYY-MM-DD");
    }

    try {

      let url = `/report?start_at=${active_day}&end_at=${state.active_day}&routes=[${state.route_id}]&shifts=[${state.shift_id}]`;
      // url += state.check_shift ? "&check_shift=" + state.check_shift : "";
      api.get(url).then(blobFile => {
          //fileDownload(blobFile.data.data, 'teste.xlsx')
           const url = window.URL.createObjectURL(new Blob([blobFile.data.data]));
          const link = document.createElement('a');
          //converter base64 pra xlsx
          //passar xlsx pro href

          link.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+blobFile.data.data;
          link.setAttribute('download', `report-download.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);  

         /*  var a = document.createElement("a"); //Create <a>
          a.href = blobFile.data.data; //Image Base64 Goes here
          a.download = "report-download.xlsx"; //File name Here
          a.click(); //Downloaded file */
      })
    } catch (err) {
      console.warn(err);
    }
    setState((prev) => ({...prev, disabled: false}))
  }
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setSelectedValue] = React.useState(0);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [open, setOpen] = React.useState(props.opensidebar);

  const handleDrawerOpen = () => {
    setOpen(true);
    props.setOpensidebar(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    props.setOpensidebar(false);
  };

  return (
    <>
      <React.Fragment key="right">
        <Button
          style={{
            marginTop: 250,
            display: open ? 'block' : 'none',
            position: "absolute",
            float: "right",
            right: open ? 330 : 0,
            backgroundColor: "#fff",
            zIndez: open ? 9999 : 0,
            transition: "all .35s ease",
          }}
          onClick={open ? handleDrawerClose : handleDrawerOpen}
        >
          {open ? <ChevronRightIcon /> : <LocalShippingIcon />}
        </Button>
        <Drawer anchor="right" open={open} onClose={handleDrawerClose}>
          <Sidebar>
            <List
              component="nav"
              aria-label="main mailbox folders"
              subheader={
                <ListSubheader
                  component="div"
                  style={{ backgroundColor: checkStatus(status), color: "#fff", padding: 10 }}
                >   
                    <ListItem>
                    <ListItemAvatar>
                    <Avatar>
                        <ImageIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={name}/>
                    </ListItem>
                </ListSubheader>
              }
            >
              <ListItem alignItems="center" divider style={{backgroundColor: "#eeeeee", color: "#777"}}>
                <ListItemText style={{textAlign: 'center'}} primary={route} secondary={description}/>
              </ListItem> 
              <ListItem alignItems="center" divider>
                <ListItemText style={{textAlign: 'center'}} primary={fleet} secondary={horse} />
              </ListItem> 
              <ListItem alignItems="center" divider>
                <ListItemText style={{textAlign: 'center'}} primary="Local:" secondary={local} />
              </ListItem> 
              <ListItem alignItems="center" divider>
                <ListItemText style={{textAlign: 'center'}} primary="Tempo:" secondary={time} />
              </ListItem> 
              <ListItem alignItems="center" divider>
                <ListItemText style={{textAlign: 'center'}} primary="Status:" secondary={route_status} />
              </ListItem> 
              <ListItem alignItems="center" divider>
                <ListItemText style={{textAlign: 'center'}} primary="Efetividade On Time:" secondary={effectiveness} />
              </ListItem>
              <ListItem alignItems="center" divider>
                <ListItemText style={{textAlign: 'center'}} primary="Efetividade Dia:" secondary={effectiveness_day} />
              </ListItem>
              <ListItem alignItems="center" divider>
                <ListItemText style={{textAlign: 'center'}} primary="HD On Time:" secondary={hdontime} />
              </ListItem>
              <ListItem alignItems="center" divider>
                <ListItemText style={{textAlign: 'center'}} primary="HD Dia:" secondary={hdday} />
              </ListItem>
              <ListItem button alignItems="center" disabled={state.disabled} style={{backgroundColor: "#c4f2c6", color: "#3ca440"}}  onClick={(event) => handleDownloadReport()} divider>
              <ListItemText style={{textAlign: 'center'}} primary="Baixar Relatório" />
              </ListItem>
            </List>
          </Sidebar>
        </Drawer>
      </React.Fragment>
    </>
  );
}

export default SidebarContainer;
