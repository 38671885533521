import React, {useState, useEffect} from 'react'
import moment from 'moment';
import api from '../../../../../../core/services/api'
import Chart from "react-apexcharts";
import options from "./options";

function GraphCard(){
    const [state, setState] = useState({   
        legends: [],       
        series: [
          
        ],
      legends: [],
      start_day: moment().day(0).format("YYYY-MM-DD"),
      end_day: moment().format("YYYY-MM-DD"),
    });


    
    useEffect(() => {
      getData();
    }, [])

  async function getData(){
      try {
        const data = await api.get(`otdweek?start_day=${state.start_day}&end_day=${state.end_day}`);
        const data3 = await api.get(`justification?&perPage=1000`);
        let justifications_notd = data3.data.data.filter((item2) => {return item2.type == 'dock_time'})
        let legends = []
        let averagetime =   []
        let average =       []
        let target =        []
        let averagetarget = []
        let series = [];
        data.data.map((item) => {
          if(item.resource.tasks && item.resource.tasks.length > 0){
              item.resource.tasks.map((item) => {

                //tempo de doca por fornecedor
                if(item.activity_type == 6){
                  let timecheck = moment(item.target_time, "HH:mm:ss").diff(moment(item.finish_time, "DD/MM/YYYY HH:mm:ss"), 'seconds')
                  if(timecheck <= 0 ){
                    //console.log('Ultrapassou tempo target: ',moment(item.finish_time, "DD/MM/YYYY HH:mm:ss").format("HH:mm:ss"), 'Tempo target: ', item.target_time, item )
                  
                    //adicionando legends
                    if(legends.length < 1){
                      legends.push(item.title)  
                    } else {
                      const exist = legends.includes(item.title)
                      if(!exist){
                        legends.push(item.title)
                      }
                    }
                  }
                }
          })   
        }
      }) 
      legends.forEach((item, index) => {
        averagetime.push(0)
        average.push(0)
        target.push(0)
        averagetarget.push(0)
      })


        data.data.map((item) => {
          if(item.resource.tasks && item.resource.tasks.length > 0){
              item.resource.tasks.map((item) => {

                //tempo de doca por fornecedor
                if(item.activity_type == 6){
                  let timecheck = moment(item.target_time, "HH:mm:ss").diff(moment(item.finish_time, "DD/MM/YYYY HH:mm:ss"), 'seconds')
                  if(timecheck <= 0 ){
                    //console.log('Ultrapassou tempo target: ',moment(item.finish_time, "DD/MM/YYYY HH:mm:ss").format("HH:mm:ss"), 'Tempo target: ', item.target_time, item )
                    //adicionando series justifications
                    if(timecheck == timecheck){




                      if(item.justifications.length > 0){
                      
                      item.justifications.forEach((item3, index) => {
                        let aux = justifications_notd.filter((justify) => {return justify.id == item3.justify})
                        if(aux.length > 0){
                          let exist_serie = series.filter((item2) => {return item2.name == item3.justify})
                          if(exist_serie.length > 0){
                            let index = legends.indexOf(item.title)
                            exist_serie[0].data[index] == exist_serie[0].data[index] ? exist_serie[0].data[index] += 1 : exist_serie[0].data[index] += 1
                          } else {
                            let index = legends.indexOf(item.title)
                            let obj = {
                              name: item3.justify,
                              data: []
                            }
                            legends.forEach((item, index) => {
                              obj.data.push(0)
                            })
                            obj.data[index] == obj.data[index] ? obj.data[index] += 1 : obj.data[index] = 0 
                            series.push(obj)
                          }
                        }
                      })


                     /*  let justification_name = justifications_notd.filter((item2) => {return item2.id == item.justifications[0].justify})
                      let exist_serie = series.filter((item2) => {return item2.name == justification_name[0].description})
                      if(exist_serie.length > 0){                     
                        let index = legends.indexOf(item.title)
                        exist_serie[0].data[index] == exist_serie[0].data[index] ? exist_serie[0].data[index] += 1 : exist_serie[0].data[index] += 1
                      }else {
                        let index = legends.indexOf(item.title)
                        let justification_name = data3.data.data.filter((item2) => {return item2.id == item.justifications[0].justify})
                        let obj = {
                          name: justification_name[0].description,
                          data: []
                        }
                        legends.forEach((item, index) => {
                          obj.data.push(0)
                        })
                        obj.data[index] == obj.data[index] ? obj.data[index] += 1 : obj.data[index] = 0 
                        series.push(obj)
                      } */

                      }
                    }
                  }
                }
               
          })   
        }
      }) 
      series.forEach((item, index) => {
        let justify = justifications_notd.filter((item2) => {return item2.id == item.name})
        if(justify.length == 0 ){
          series.splice(index, 1);
        } else {
          series[index].name = justify[0].description;
        }
      })
          setState((prev) => ({
          ...prev,
          series: series,
          legends: legends,
        })) 
      } catch (error) {
        console.warn(error)
      }
    }


    return(
        <Chart
              options={options(state.legends)}
              series={state.series}
              type="bar"
              height={350}
            />
    )
}

export default GraphCard;