export default function(cols) {
  return {
            colors: ["#2de0c2", "#7d77ff"],
            chart: {
              type: 'donut',
              width: '100%',
              height: 200,
            },
            plotOptions: {
              pie: {
                size: 200,
                startAngle: 0,
                endAngle: 360,
                expandOnClick: true,
                offsetX: 70,
                offsetY: 0,
                customScale: 1,
                dataLabels: {
                    offset: 0,
                    minAngleToShowLabel: 10
                }, 
                donut: {
                  size: '65%',
                  background: 'transparent',
                },      
              }
            },
            labels: ["OTD", "N OTD"],
            dataLabels: {
              enabled: true,
              formatter: function (val, opts) {
                return val.toFixed(0)+"%"
            },
          
            },
            legend: {
              offsetY: -30,
              position: 'bottom',
              horizontalAlign: 'left',
            },
      
    }
}