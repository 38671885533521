import React, { useState, useEffect } from "react";
import api from "../../../../../../core/services/api";
import { useStyles } from "./styles";

//custom components
import GraphCard from "./GraphCard";

//material ui
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

//custom select
import CustomSelect from "../../../../components/CustomSelect";

function PercentTransitTimeFlow(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    series: [
      {
        data: [],
      },
    ],
    legends: [],
    route_id: null,
    route_list: [],
  });
  useEffect(() => {
    getData();
  }, [props.start_day, props.end_day, state.route_id]);

  async function getData() {
    try {
      const data = await api.get(
        `sla-transit-flow?start_at=${props.start_day}&end_at=${props.end_day}&route=${state.route_id}`
      );
      const data2 = await api.get(`routes`);
      let transit = data.data.data.data || [];
      let legends = [];
      let series = [
        {
          name: "Transit Time por Fluxo",
          data: [],
        },
      ];
      transit.forEach((item, index) => {
        legends.push(item.flow);
        series[0].data.push(parseFloat(item.percent_bellow));
      });

      setState((prev) => ({
        ...prev,
        series: series,
        legends: legends,
        route_list: data2.data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="center" alignItems="center">
          <Grid item lg={10} sm={10} md={10} xs={10}>
            <Typography color="textSecondary" gutterBottom>
              % Transit Time por Fluxo
            </Typography>
          </Grid>
          <Grid item lg={2} sm={2} md={2} xs={2}>
            <CustomSelect
              id="component"
              label="Rota"
              outlined="outlined"
              value={state.route_id}
              name="route_id"
              data={state.route_list}
              onChange={(event) => handleChange(event)}
              fullWidth
            />
          </Grid>
        </Grid>
        <GraphCard series={state.series} legends={state.legends} />
      </CardContent>
    </Card>
  );
}

export default PercentTransitTimeFlow;
