import React, { useState } from "react";
import { lighten, withStyles } from "@material-ui/core/styles";
import moment from "moment";
//@material-ui
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import LinearProgress from "@material-ui/core/LinearProgress";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

//custom components
import MapModal from "./MapModal";
import DetailsCard from "./DetailsCard";
import SidebarContainer from "./Sidebar";

function CardMotorista(props) {
  const [opensidebar, setOpensidebar] = useState(false);
  const [item_sidebar, setItemsidebar] = useState({});
  const BorderLinearProgress = withStyles({
    root: {
      height: 20,
      backgroundColor: lighten(getColor(props.effectiveness_ontime), 0.4),
    },
    bar: {
      borderRadius: 3,
      backgroundColor: getColor(props.effectiveness_ontime),
    },
  })(LinearProgress);

  function handleSidebar() {
    setOpensidebar(false);
    setItemsidebar(props.item_sidebar);
    setOpensidebar(true);
  }
  function getColor(counter) {
    return "#6d7982";
  }

  function getColortext(counter) {
    return "#fff";
  }
  function getPercentual(counter) {
    let aux = isNaN(counter) ? 0 : (parseInt(counter) * 100) / 6;
    let response = aux > 100 ? 100 : aux;
    return isNaN(response) ? 0 : response;
  }

  function formatPercentual(items) {
    let result = getPercentual(items);
    let msg = `${parseInt(result)}%`;

    return msg;
  }

  function checkLocal(){
    let task = null
    task = props.item_sidebar.ongoing_task;
    if(task){
      if(task.end_task){
        return task.title
      } else {
     if (task.start_real != null && task.end_real != null ){
        if(task.issues[0]){
          if(task.issues[0].finish_at == null){
            const issue = props.occurrences.filter((item) => {return task.issues[0].type_issue == item.id})
            const iss = issue[0].occurrence.filter((item2) => {return task.issues[0].issue == item2.id})
            return iss[0].description
          } else {
            return "Em trânsito"
          }
        } else {
          return "Em trânsito"
        }  
      } else {
        return task.title
      }
    }  
    } else {
      return "-"
    }
  }

  function checkSizeFont() {
    let local = checkLocal();
    if (local.length > 20) {
      return 12;
    } else {
      return 14;
    }
  }

  function checkTime() {
    let {ongoing_task: task, finished: finished_journey} = props.item_sidebar;
    if (task) {
      if(finished_journey){
        return "-"
      } else {
      if (task.transit_time_start != null) {
        //ESTA EM TRANSITO
        if (task.issues[0]) {
          //OCORRENCIA
          if (task.issues[0].finish_at == null) {
            var now = moment();
            var then = moment(task.issues[0].created_at, "YYYY-MM-DD HH:mm:ss");
            let time = moment
              .utc(moment().diff(moment(then)))
              .format("HH:mm:ss");
            return time;
          } else {
            var now = moment();
            var occurrence_start = moment(
              task.issues[0].created_at,
              "YYYY-MM-DD HH:mm:ss"
            );
            var occurrence_end = moment(
              task.issues[0].finish_at,
              "YYYY-MM-DD HH:mm:ss"
            );
            var occurrence_time = moment
              .utc(moment(occurrence_end).diff(moment(occurrence_start)))
              .format("HH:mm:ss");
            var then = moment(task.transit_time_start, "YYYY-MM-DD HH:mm:ss");
            let time = moment
              .utc(moment().diff(moment(then)))
              .format("HH:mm:ss");
            let final_time = moment(
              moment(time, "HH:mm:ss").diff(moment(occurrence_time, "HH:mm:ss"))
            ).format("mm:ss");
            return "00:" + final_time;
          }
        } else {
          var now = moment();
          var then = moment(task.transit_time_start, "YYYY-MM-DD HH:mm:ss");
          let time = moment.utc(moment().diff(moment(then))).format("HH:mm:ss");
          return time;
        }
      } else {
        //TEMPO ATIVIDADE
        var now = moment();
        var then = moment(task.start_real, "YYYY-MM-DD HH:mm:ss");
        let time = moment.utc(moment().diff(moment(then))).format("HH:mm:ss");
        return time;
      }
    }
    } else {
      return "-";
    }
  }
  function checkStatus(status) {
    if (status === "red") {
      return "#f43f3c";
    } else if (status === "green") {
      return "#54d959";
    } else if (status === "blue") {
      return "#3490fa";
    } else if (status === "orange") {
      return "#FE8545";
    } else if (status === "yellow") {
      return "#FFC34A";
    } else {
      return "#36414c";
    }
  }

  return (
    <Card>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
        style={{
          padding: 12,
          backgroundColor: checkStatus(props.status),
          color: "#fff",
        }}
      >
        <Grid item style={{ verticalAlign: "center" }}>
          <p style={{ fontSize: 14, marginTop: 0, marginBottom: 0 }}>
            {props.name}
          </p>
        </Grid>
        <Grid item>
          <p style={{ fontSize: 14, marginTop: 0, marginBottom: 0 }}>
            {props.route_name}/{props.description}
          </p>
        </Grid>
      </Grid>

      <CardContent style={{ textAlign: "center", minHeight: 180 }}>
        <Grid container>
          <Grid item lg={6} md={6} sm={6} xs={6}>
              <MapModal
              id={props.vehicle_id}
              isDiscarded={props.item_sidebar.isDiscarded}
              board={props.board}
              latitude={props.latitude}
              longitude={props.longitude}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Button
              variant="outlined"
              style={{ color: "#1473e6", borderColor: "#1473e6" }}
              onClick={handleSidebar}
            >
              Ver Detalhes
            </Button>
            <SidebarContainer
              //loading={state.loading}
              //setColor={selectColor}
              setOpensidebar={setOpensidebar}
              opensidebar={opensidebar}
              item_sidebar={props.item_sidebar}
              occurrences={props.occurrences}
              routes={props.routes}
            ></SidebarContainer>
          </Grid>
        </Grid>
        <Divider
          light
          style={{ marginTop: 12, marginRight: -20, marginLeft: -20 }}
        />

        <Grid container style={{ marginTop: 10 }}>
          <Grid item lg={6} sm={6} md={6} xs={6} direction="column">
            <p style={{ fontSize: 16 }}>Local: </p>
            <Grid item>
              <div
                style={{
                  marginTop: -10,
                  paddingRight: 12,
                  paddingLeft: 12,
                }}
              >
                <p style={{ opacity: "50%", fontSize: checkSizeFont() }}>
                  {checkLocal()}
                </p>
              </div>
            </Grid>
          </Grid>
          <Grid item lg={6} sm={6} md={6} xs={6} direction="column">
            <p style={{ fontSize: 16 }}>Tempo: </p>
            <Grid item>
              <div
                style={{
                  marginTop: -10,
                  paddingRight: 12,
                  paddingLeft: 12,
                }}
              >
                <p style={{ opacity: "50%", fontSize: checkSizeFont() }}>
                  {checkTime()}
                </p>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <div style={{ marginTop: -20, position: "relative", top: 0 }}>
        <p
          style={{
            fontSize: 12,
            fontWeight: "bold",
            textAlign: "center",
            margin: "0 auto",
            zIndex: 99,
            top: "20%",
            left: "48%",
            position: "absolute",
            color: getColortext(props.effectiveness_ontime),
          }}
        >
          {props.effectiveness_ontime || 0}%
        </p>
        <BorderLinearProgress
          style={{ height: 30 }}
          variant="determinate"
          value={props.effectiveness_ontime || 0}
        />
      </div>
    </Card>
  );
}

export default CardMotorista;
