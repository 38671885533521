import React, { Component } from "react";
import api from "../../../../core/services/api";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Main from "../../components/Main";
import Container from "@material-ui/core/Container";
import moment from "moment";
import Modal from "./modal";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import "moment/locale/pt-br";

export class Evidences extends Component {
  state = {
    evidences: [],
    carrier: "all",
    carriers: [],
    today: this.props.match.params.date
  };

  componentDidMount() {
    this.getEvidences();
    this.getCarriers();
  }

  async getCarriers() {
    const { data } = await api.get(`/carriers`);
    this.setState({ ...this.state, carriers: data.data || []});
  }

  async getEvidences() {
    const { data } = await api.get(`/evidence/list?day=${moment(this.state.today).format('YYYY-MM-DD')}&carrier=${this.state.carrier}`);
    this.setState({ ...this.state, evidences: data });
  }

  handleDateChange = date => {
    this.setState({ ...this.state, today: date }, () => {
      this.getEvidences();
    });
  };

  filterCarrier(value) {
    this.setState({ ...this.state, carrier: value }, () => this.getEvidences());
  }

  render() {
    return (
      <>
        <Main>
          <Container maxWidth="lg">
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '70px', alignItems: 'flex-end' }}>
              <h1 style={{ margin: 0 }}>Listagem de Entregas</h1>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <Select
                  style={{ winWidth: 200, maxWidth: 200, width: 200, marginTop: 16, marginBottom: 8 }}
                  value={this.state.carrier}
                  onChange={(e) => this.filterCarrier(e.target.value)}>
                  <MenuItem value="all">Todas</MenuItem>
                  {this.state.carriers.map(({ id, name }) => <MenuItem value={id}>{name}</MenuItem>)}
                </Select>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Escolha o dia"
                  format="DD-MM-YYYY"
                  value={this.state.today}
                  onChange={this.handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Entregue</TableCell>
                    <TableCell align="left">Motorista</TableCell>
                    <TableCell align="left">Transportadora</TableCell>
                    <TableCell align="left">Visualizar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.evidences.length === 0 ? null : this.state.evidences.map(evidence => {
                    return (
                      <TableRow key={evidence.id}>
                        <TableCell align="left">
                          {moment(evidence.delivered_at).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </TableCell>
                        <TableCell align="left">{evidence.driver}</TableCell>
                        <TableCell align="left">{evidence.carrier}</TableCell>
                        <TableCell align="left">
                          <Modal
                            img={`https://ferrero-dev.trackage.io/api/${evidence.path}`}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </Main>
      </>
    );
  }
}

export default Evidences;