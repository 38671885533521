import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  paper: {
    padding: 12,
    maxHeight: 300,
    overflowY: "scroll",
  },
  pin: {
    borderRadius: 24,
    padding: 12,
    color: "#fff",
    marginBottom: 10,
  },
});
