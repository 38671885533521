import React, {useState, useEffect} from 'react'
import api from '../../../../../../core/services/api'
import moment from 'moment'
import Chart from "react-apexcharts";
import options from "./options.js";

function GraphCard(){
    const [state, setState] = useState({          
        series: [],
        legends: [],
      start_day: moment().day(0).format("YYYY-MM-DD"),
      end_day: moment().format("YYYY-MM-DD"),
      last_start_day: moment().subtract(7, "days").day(0).format("YYYY-MM-DD"),
      last_end_day: moment().subtract(7, "days").day(6).format("YYYY-MM-DD"),
    });


    useEffect(() => {
      getData();
    }, [])

  async function getData(){
      try {
        const data = await api.get(`otdweek?start_day=${state.start_day}&end_day=${state.end_day}`);
        const data2 = await api.get(`routes`);
        const data3 = await api.get(`otdweek?start_day=${state.last_start_day}&end_day=${state.last_end_day}`);
        let legends = []
        let averagetime =   []
        let average =       []
        let averagetime2 =  []
        let average2 =      []
        let target =        []
        let averagetarget = []
        data.data.map((item) => {
          if(item.resource.tasks && item.resource.tasks.length > 0){
              item.resource.tasks.map((item) => {

                //tempo de doca por fornecedor
                if(item.activity_type == 6){
                  const aux = moment(item.finish_time, "DD/MM/YYYY HH:mm:ss").diff(moment(item.finish_time, "DD/MM/YYYY HH:mm:ss").clone().startOf('day'), 'seconds');
                  if(legends.length < 1){
                    legends.push(item.title)  
                  } else {
                    const exist = legends.includes(item.title)
                    if(!exist){
                      legends.push(item.title)
                    }
                  }
                }
               
          })   
        }
      }) 
       //media tempo de doca semana anterior 
       data3.data.map((item) => {
        if(item.resource.tasks && item.resource.tasks.length > 0){
            item.resource.tasks.map((item) => {

              //tempo de doca por fornecedor
              if(item.activity_type == 6){
                const aux = moment(item.finish_time, "DD/MM/YYYY HH:mm:ss").diff(moment(item.finish_time, "DD/MM/YYYY HH:mm:ss").clone().startOf('day'), 'seconds');
                if(legends.length < 1){
                  legends.push(item.title)  
                } else {
                  const exist = legends.includes(item.title)
                  if(!exist){
                    legends.push(item.title)
                  }
                }
              }
             
        })   
      }
    }) 
    legends.forEach((item, index) => {
      averagetime.push(0)
      average.push(0)
      averagetime2.push(0)
      average2.push(0)
      target.push(0)
      averagetarget.push(0)
    })  


      //media tempo de doca semana anterior 
      data3.data.map((item) => {
        if(item.resource.tasks && item.resource.tasks.length > 0){
            item.resource.tasks.map((item) => {

              //tempo de doca por fornecedor
              if(item.activity_type == 6){
                const aux = moment(item.finish_time, "DD/MM/YYYY HH:mm:ss").diff(moment(item.finish_time, "DD/MM/YYYY HH:mm:ss").clone().startOf('day'), 'seconds');
                if(aux == aux){
                  let index = legends.indexOf(item.title)
                  averagetime2[index] += aux;
                  average2[index] += 1;
                }
                
              }
             
        })   
      }
    }) 

    data.data.map((item) => {
      if(item.resource.tasks && item.resource.tasks.length > 0){
          item.resource.tasks.map((item) => {

             //tempo de doca por fornecedor
             if(item.activity_type == 6){
              const aux = moment(item.finish_time, "DD/MM/YYYY HH:mm:ss").diff(moment(item.finish_time, "DD/MM/YYYY HH:mm:ss").clone().startOf('day'), 'seconds');
              if(aux == aux){
                let index = legends.indexOf(item.title)
                averagetime[index] += aux;
                average[index] += 1;
              }
              
            }
           
      })   
    }
  }) 
    averagetime.forEach((item, index) => {
      averagetime[index] = item/average[index] || 0
    })
    averagetime2.forEach((item, index) => {
      averagetime2[index] = item/average2[index] || 0
    })

      //target medio 
      data2.data.map((item) => {
        if(item.tasks && item.tasks.length > 0){
          item.tasks.map((item) => {
            if(item.activity_type == 6){
              const aux2 = moment(item.target_time, "HH:mm:ss").diff(moment(item.target_time, "HH:mm:ss").clone().startOf('day'), 'seconds');
              let index = legends.indexOf(item.title)
              target[index] += aux2;
              averagetarget[index] += 1;
            }
          })
        }
      })
      target.forEach((item, index) => {
        target[index] = item/averagetarget[index] || 0
      })
         setState((prev) => ({
          ...prev,
          targetList: target,
          legends: legends,
          series: [{
            name: 'Semana Atual',
            type: 'column',
            data: averagetime
          },
          {
            name: 'Semana Anterior',
            type: 'column',
            data: averagetime2
          },
          {
            name: 'Target Medio',
            type: 'line',
            data: target
          }
        ],
      
        }))
      } catch (error) {
        console.warn(error)
      }
    }
    return(
        <Chart
              options={options(state.legends, state.targetList)}
              series={state.series}
              type="line"
              height={350}
            />
    )
}

export default GraphCard;