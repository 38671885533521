import React, { Component } from "react";
import { Link } from 'react-router-dom';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';

//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
// pick a date util library
import DateFnsUtils from "@date-io/date-fns";

import { Container, Row, Col } from "reactstrap";
import api from "../../../../core/services/api";
import moment from "moment";
import GraphicCard from "../../components/Dashboard/GraphicCard";
import ReactApexChart from 'react-apexcharts';
import css from "../../css/dashboard/dashboard_view.module.css";
import playlist from "../../assets/icons/ic_playlist_add_check_48px.svg";
import time from "../../assets/icons/ic_access_time_48px.svg";
import Main from "../../components/Main";
import Card from "@material-ui/core/Card";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import "moment/locale/pt-br";
import MomentUtils from "@date-io/moment";

import fakedata from './index.json'

moment.locale("pt-br");

class Dashboard extends Component {
    state = {
      carrier: "all",
      carriers: [],
      evidence: {},
      alerts: {},
      raw_data: [],
      current_data: 0,
      temperatures: {
        created_hours: [],
        pings: [],
        minimum: [],
        maximum: [],
        highest: [],
        lowest: [],
        pings_lowest: [],
        pings_highest: [],
        average: [],
      },
      today: new Date(),
      conferidosDia: "",
      pendentesDia: ""
    };


  componentDidMount() {
    this.getTemperatures();
    this.getConferidos();
    this.getPendentes();
    this.countEvidences();
    this.countAlerts();
    this.getCarriers();
  }

  async countAlerts() {
    const {data} = await api.get(
      `/alerts/total?day=${moment(this.state.today).format(
        "YYYY-MM-DD"
      )}&carrier=${this.state.carrier}`
    );
    this.setState({ ...this.state, alerts: data });
  }

  async countEvidences() {
    const {data} = await api.get(
      `/evidence/total?day=${moment(this.state.today).format(
        "YYYY-MM-DD"
      )}&carrier=${this.state.carrier}`
    );
    this.setState({ ...this.state, evidence: data });
  }

  async getCarriers() {
    const {data} = await api.get(`/carriers`);
    this.setState({ ...this.state, carriers: data.data || [] });
  }

  filterCarrier(value){
    this.setState({...this.state, carrier: value}, () => {
      this.getTemperatures();
      this.countAlerts();
      this.countEvidences();
    })
  }

  async getTemperatures() {
    try {
      const {data} = await api.get(
            `/telemetry/temperature?day=${moment(this.state.today).format(
              "YYYY-MM-DD"
            )}&carrier=${this.state.carrier}`
          );

      this.setTemperatures(data);
    } catch (error) {
      console.log(error)
    }
  }

  setTemperatures(data) {
    let temperatures = {
      created_hour: [],
      pings: [],
      minimum: [],
      maximum: [],
      highest: [],
      lowest: [],
      pings_lowest: [],
      pings_highest: [],
      average: [],
    }

    data.map(item => {
      temperatures.created_hour.push(item.created_hour)
      temperatures.pings.push(item.pings)
      temperatures.minimum.push(item.minimum)
      temperatures.maximum.push(item.maximum)
      temperatures.highest.push(item.highest)
      temperatures.lowest.push(item.lowest)
      temperatures.pings_lowest.push(item.pings_lowest)
      temperatures.pings_highest.push(item.pings_highest)
      temperatures.average.push(item.average)
    })

    this.setState({...this.state, temperatures, raw_data: fakedata});

    // try {
    //   const temperatures = await api.get(
    //     `/telemetry/temperature?day=${moment(this.state.today).format(
    //       "YYYY-MM-DD"
    //     )}`
    //   );
    //   const array = [];
    //   temperatures.data.forEach(item => {
    //     array.push({
    //       hora: item.hora,
    //       máximo: item.max,
    //       mínimo: item.min,
    //       média: item.avg
    //     });
    //   });
    //   this.setState({ ...this.state, temperatures: array });
    // } catch (err) {
    //   console.warn(err);
    // }
  }

  async getConferidos() {
    try {
      const conferidos = await api.get(`/conferidos`);
      // const orders = await api.get(`/orders-analytics`);
      this.setState(
        { ...this.state, conferidosDia: conferidos.data.conferidosDia },
        () => console.log(this.state)
      );
    } catch (err) {
      console.warn(err);
    }
  }

  async getPendentes() {
    try {
      const pendentes = await api.get(`/pendentes-dia`);
      // const orders = await api.get(`/orders-analytics`);
      this.setState(
        { ...this.state, pendentesDia: pendentes.data.pendentesDia },
        () => console.log(this.state)
      );
    } catch (err) {
      console.warn(err);
    }
  }

  handleDateChange = date => {
    this.setState({ ...this.state, today: date }, () => {
      this.getTemperatures()
      this.countAlerts();
      this.countEvidences();
    });
  };

  handleChange = (event, activeTab) => {
    this.setState( (state) => ({activeTab}));
  };

  render() {
    return (
      <Main>
        <div>
          <div>
            <div>
              <Container fluid={true} style={{ marginTop: 60 }}>
                <Row>
                  <Col lg="12" style={{ paddingLeft: 40, paddingRight: 40}}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils} locale={ptBRLocale}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        label="Escolha o dia"
                        format="dd-MM-yyyy"
                        value={this.state.today}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date"
                        }}
                      />
                      <Select 
                        style={{winWidth: 200, maxWidth: 200, width: 200, marginTop: 32, marginLeft: 15, marginBottom: 8}}
                        value={this.state.carrier}
                        onChange={(e) => this.filterCarrier(e.target.value)}>
                        <MenuItem value='all'>Todas</MenuItem>
                        {console.log('this carriers map', this.state.carriers)}
                      
                          {this.state.carriers.map(({id, name}) => <MenuItem value={id}>{name}</MenuItem>)}
                       
                      
                      </Select>
                    </MuiPickersUtilsProvider>
                  </Col>
                  <Col lg="6">
                    <Link style={{textDecoration: 'none'}} to={`list_evidences/${moment(this.state.today).format("YYYY-MM-DD")}`}>
                      <GraphicCard
                        className={css.card0}
                        color0="#12B0B5"
                        color1="rgb(15, 198, 204)"
                        icon={playlist}
                        info0={this.state.evidence.count}
                        info1={<div>Entregas Realizadas</div>}
                      />
                    </Link>
                  </Col>
                  <Col lg="6">
                    <Link style={{textDecoration: 'none'}} to={`list_alerts/${moment(this.state.today).format("YYYY-MM-DD")}`}>
                      <GraphicCard
                        className={css.card1}
                        color0="rgb(196, 37, 69)"
                        color1="rgb(227, 44, 81)"
                        icon={time}
                        info0={this.state.alerts.count}
                        info1={<div>Alertas Gerados</div>}
                        iconCSS={{ height: "42px" }}
                      />
                    </Link>
                  </Col>

                  {/* <Col lg="12">
                    {this.state.raw_data.length > 0 && 
                      <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.current_data}
                      onChange={e => this.setState({...this.state, current_data: e.target.value})}
                      >
                        {this.state.raw_data.map(({created_hour}, key) => <MenuItem value={key}>{moment(created_hour).format('HH:mm')}</MenuItem>)}
                        <FormHelperText>Horário</FormHelperText>
                      </Select> 
                    }
                  </Col> */}

                  <Col lg={12} style={{ padding: 40 }}>
                    <Card style={{ padding: 20 }}>
                        {/* {JSON.stringify(this.state.temperatures)} */}
                        <ReactApexChart 
                        series={[{
                          name: 'min',
                          data: this.state.temperatures.minimum || []
                        },{
                          name: 'avg',
                          type: 'area',
                          data: this.state.temperatures.average || [],
                        }, {
                          name: 'max',
                          data: this.state.temperatures.maximum || []
                        }]} 
                        options={{
                          title: {
                            text: 'Media de Temperatura Diária',
                            style: {
                              fontSize: '24px'
                            }
                          },
                          chart: {
                            height: 350,
                          },
                          dataLabels: {
                            enabled: false
                          },
                          stroke: {
                            curve: 'straight'
                          },
                          xaxis: {
                            type: 'datetime',
                            categories: this.state.temperatures.created_hour || []
                          },
                          colors:['#22a7f0', '#2ecc71', '#f15a22']
                        }} 
            height={350} />
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </Main>
    );
  }
}

export default Dashboard;
