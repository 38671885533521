import React, { useState, useEffect } from "react";
import moment from 'moment'
import api from "../../../../core/services/api";
import PropTypes from "prop-types";

//material ui
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SearchIcon from "@material-ui/icons/Search"
import { InputBase, InputAdornment, Select } from '@material-ui/core';
//custom components
import Main from "../../components/Main";
import AlertsPanel from './AlertsPanel'
import SegmentsPanel from './SegmentsPanel'
import ReceiversPanel from './ReceiversPanel'

//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function Kpis3() {
  const [value, setValue] = useState(0);
  const [route_list, setRoutelist] = useState([]);
  const [route_list_filter, setRoutelistfilter] = useState([]);
  const [alertSearch, setAlertSearch] = useState('');
  const [state, setState] = useState({
    start_day: moment().format("YYYY-MM-DD"),
    end_day: moment().format("YYYY-MM-DD"),
    routes: [],
    checkRoutes: true,
  });

  useEffect(() => {
    getData();
  }, [])

  async function getData() {
    try {
      const data = await api.get(`routes`);
      let routes = []
      let routes_name = []
      data.data.forEach((item) => { 
        routes.push(item.id)
        routes_name.push(item.name)
      })
      setRoutelistfilter(routes);
      setRoutelist(routes_name);
      setState((prev) => ({
        ...prev,
        routes: data.data || [],
      }))
    } catch (error) {
      console.warn(error)
    }
  }
  const handleDateStart = (date) => {
    setState((prev) => ({
      ...prev,
      start_day: moment(date).format("YYYY-MM-DD"),
    }));
  };

  const handleDateEnd = (date) => {
    setState((prev) => ({
      ...prev,
      end_day: moment(date).format("YYYY-MM-DD"),
    }));
  };

  const handleChange = (event) => {
    let routes = []
    let routes_name = []
    if(event.target.checked) {
      state.routes.forEach((item) => { 
        routes.push(item.id)
        routes_name.push(item.name)
      })
      setRoutelistfilter(routes);
      setRoutelist(routes_name);
    } else {
      setRoutelistfilter(routes);
      setRoutelist(routes_name);
    }
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangeCustom = (event) => {
    setRoutelist(event.target.value);
    let routes = []
    state.routes.map((item) => {
      event.target.value.forEach((route) => {
          if(route == item.name){
              routes.push(item.id)
          }
      })
  })
  /* console.log('Event.target.value: ', event.target.value)
  console.log('Routes: ', routes) */
    setRoutelistfilter(routes)
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Main title="Alertas" color="#f7f7f7">     
     <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
        <Grid item sm={4} md={2} lg={2} xs={2}>
          <ThemeProvider theme={fcaTheme}>
            <InputBase
                style={{ padding:'4px 6px 3px', width: "100%", border: '2px solid #BCBCBC', borderRadius:'4px' }}
                placeholder="  Buscar"
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
                onChange={(e) => setAlertSearch(e.target.value)}
              />
          </ThemeProvider>
        </Grid>
      </Grid>   
      <AlertsPanel filter={alertSearch}>

      </AlertsPanel>

    </Main>
  );
}

export default Kpis3;
