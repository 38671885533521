import React, { useEffect, useState } from "react";
import api from '../../../../../core/services/api'


import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Grid from '@material-ui/core/Grid'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'

//custom components
import CustomSelect from "../../../components/CustomSelect";


function CreateForm(props) {
  const [state, setState] = useState({
    error: false,
    disabled: false,
    active: true,
    typeList: [],
  });

  const [description, setDescription] = useState("");
  const [type, setType] = useState("");

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setState({
      disabled: false,
      error: false,
      typeList: [{ id: "dock_time", name: "Tempo de doca" }, { id: "otd", name: "OTD" }]
    })
    setDescription("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  async function handleSubmit() {
    setState((prev) => ({
      ...prev,
      disabled: true
    }))
    const justification = {
      description: description,
      type: state.type
    }
    try {
      await api.post(`justification`, justification);
      props.getData();
    } catch (error) {
      console.warn(error)
    }
    setOpen(false)

  }

  return (
    <div>
      <AddIcon onClick={handleClickOpen}  />
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm" >
        <DialogTitle>Cadastrar nova Justificativa</DialogTitle>
        <DialogContent>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
              id="description"
              name="description"
              label="Descrição"
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <CustomSelect
              label="Tipo"
              value={state.type}
              name="type"
              data={state.typeList}
              onChange={(event) => handleChange(event)}
            />
          </div>

        </DialogContent>
        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor: state.disabled ? "" : "#5ed237", color: state.disabled ? "" : "#fff" }}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateForm;
