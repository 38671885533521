export default function(legends) {
    return {
        colors: ["#837DFF", "#e91e63", "#2de0c2", "#26a0fc"],
        chart: {
          height: 350,
          type: 'bar',
          zoom: {
            enabled: true
          }
        },
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val.toFixed(1)+"%";
          },
          offsetY: 10,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
          },
          stroke: {
            curve: 'straight',
            width: 2
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
            offsetY: 0 
        },
        xaxis: {
          categories: legends,
          tickPlacement: 'on'
         
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val.toFixed(1)+"%";
            },
          },
        },
      }
  }