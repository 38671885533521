import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

export default function CustomSelect(props) {
  return (
    <>
      <FormControl
        variant={props.outlined ? "outlined" : "standard"}
        required
        style={{ width: "100%" }}
        error={props.error ? (props.value ? false : true) : false}
      >
        <InputLabel>{props.label}</InputLabel>
        <Select
          //variant={props.outlined ? "outlined" : "standard"}
          disabled={props.disabled}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
          fullWidth
          label={props.label}
        >
          {props.data.map((item) => {
            return <MenuItem value={item.id}>{item.name || item.description || item.resource.work_shift}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </>
  );
}
