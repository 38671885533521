import React, {useState, useEffect} from 'react'
import Chart from "react-apexcharts";
import api from '../../../../../../core/services/api'
import moment from 'moment'
import options from "./options.js";

function GraphCard(){
    const [state, setState] = useState({          
        series: [
          {
            name: 'Média Tempo de Doca',
            type: 'column',
            data: []
          },
        {
          name: 'Target Projetos',
          type: 'line',
          data: []
        },
/*         {
          name: 'Plano de produção por dia',
          type: 'line',
          data: [1000, 1002, 1000, 1001, 1002, 1000, 1000, 1000, 1002, 1005, 1010, 1300]
        },
        {
          name: 'Produção real',
          type: 'line',
          data: [1000, 1010, 1010, 1021, 1032, 1020, 1020, 1010, 1002, 1005, 1010, 1000]
        }, */
      ],
      start_day: moment().startOf("year").format("YYYY-MM-DD"),
      end_day: moment().format("YYYY-MM-DD"),
    });

    useEffect(() => {
      getData();
    }, [])


    async function getData(){
      try {
        const data = await api.get(`otdweek?start_day=${state.start_day}&end_day=${state.end_day}`);
        let dates = ['Jan', 'Fev', 'Mar']
        let media = [0,0,0,0,0,0,0,0,0,0,0,0];
        let quantidade = [0,0,0,0,0,0,0,0,0,0,0,0];
        let target = [0,0,0,0,0,0,0,0,0,0,0,0];
        let quantidade2 = [0,0,0,0,0,0,0,0,0,0,0,0];
        data.data.map((item) => {
          if(item.resource.tasks && item.resource.tasks.length > 0){
              item.resource.tasks.map((item) => {

                const month = moment(item.start_real, "YYYY-MM-DD HH:mm:ss").format("MMM")
                const aux2 = moment(item.target_time, "HH:mm:ss").diff(moment(item.target_time, "HH:mm:ss").clone().startOf('day'), 'seconds');
                //media de target time
              
                if(month == 'jan'){
                  if(aux2 == aux2){
                    target[0] += aux2;
                    quantidade2[0] += 1;
                  }
                }
                if(month == 'fev'){
                  if(aux2 == aux2){
                  target[1] += aux2;
                  quantidade2[1] += 1;
                  }
                }
                if(month == 'mar'){
                  if(aux2 == aux2){
                  target[2] += aux2;
                  quantidade2[2] += 1;
                  }
                }


                //media dos tempos de doca
                  if(item.activity_type == 6){
                  const aux = moment(item.finish_time, "DD/MM/YYYY HH:mm:ss").diff(moment(item.finish_time, "DD/MM/YYYY HH:mm:ss").clone().startOf('day'), 'seconds');
                  if(month == 'jan'){
                    if(item.finish_time != null && aux > 0){
                      media[0] += aux;
                      quantidade[0] += 1;
                    }
                  }
                  if(month == 'fev'){
                    if(item.finish_time != null && aux > 0){
                      media[1] += aux;
                      quantidade[1] += 1;
                    }
                  }
                  if(month == 'mar'){
                    if(item.finish_time != null && aux > 0){
                      media[2] += aux;
                      quantidade[2] += 1;
                    }
                  }
                  }
                  
                
                })
          }
        }) 
        target.forEach((item, index) => {
          target[index] = item/quantidade2[index] || 0
        })
        media.forEach((item, index) => {
          media[index] = item/quantidade[index] || 0
        }) 
       
          setState((prev) => ({
          ...prev,
          series: [{
            name: 'Média Tempo de Doca',
            type: 'column',
            data: media
          },
          {
            name: 'Target Projetos',
            type: 'line',
            data: target
          },
         /*  {
            name: 'Plano de produção por dia',
            type: 'line',
            data: [1000, 1002, 1000, 1001, 1002, 1000, 1000, 1000, 1002, 1005, 1010, 1300]
          },
          {
            name: 'Produção real',
            type: 'line',
            data: [1000, 1010, 1010, 1021, 1032, 1020, 1020, 1010, 1002, 1005, 1010, 1000]
          } */]
        
        })) 
      } catch (error) {
        console.warn(error)
      }
    }
    return(
        <Chart
              options={options()}
              series={state.series}
              type="line"
              height={350}
            />
    )
}

export default GraphCard;