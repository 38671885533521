import React, {useState, useEffect} from 'react'
import moment from 'moment'
import { Link } from "react-router-dom";
import api from '../../../../core/services/api'
import MaterialTable, { MTableToolbar } from "material-table";

//custom Components
import Main from "../../components/Main";
import { Container } from "./styles";
import CreateForm from './CreateForm'
import EditForm from './EditForm'




//material-ui
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Grid from '@material-ui/core/Grid'

function Driver(){

  const [state, setState] = useState("")

  useEffect(() => {
    getData();
  }, []);
  async function getData() {
    try {
      const { data } = await api.get(`carriers`);
      //const filterdata = data.filter((item) => item.excluded !== true);
      setState((prev) => ({
        ...prev,
        carriers: data,
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  async function deleteCarrier(item) {
    try {
      if (
        window.confirm(
          "Tem certeza que deseja deletar transportadora " + item.name
        )
      ) {
        await api.delete(`carriers/` + item.id);
      } else {
      }

      getData();
    } catch (error) {
      console.warn(error);
    }
  }
    
    return(
        <Main title="Transportadoras">
        <Container>
        <Grid
        container
        justify="flex-end"
        spacing={3}
        style={{ paddingBottom: 12 }}
      >
        <Grid item>
         
          <CreateForm
            //datas
           
            getData={getData}
          />
        </Grid>
      </Grid>
        <MaterialTable
        columns={[
          {
            title: "Transportadora",
            field: "name",
            cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
          },
         
          {
            field: "actions",
            title: "Ações",
            cellStyle: { paddingLeft: 40, paddingBottom: 0, paddingTop: 0, textAlign: "left", border: "#fff" },
            render: (rowData) => (
              <>
                <FormGroup row>
                  <EditForm getData={getData} carrier={rowData} />
                  <IconButton
                    color="secondary"
                   onClick={() => deleteCarrier(rowData)}
                  >
                    <DeleteIcon />
                  </IconButton>{" "}
                </FormGroup>
              </>
            ),
          },
        ]}
        data={state.carriers}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          headerStyle: {
            paddingLeft: 40,
            textAlign: "left",
            backgroundColor: "#1c1c1c",
            color: "#fff",
            border: "#fff",
          },
          rowStyle: (x) => {
            if (x.tableData.id % 2) {
              return { backgroundColor: "#f3f5f8" };
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 5,
        }}
      />
        </Container>
        </Main>
    )
}

export default Driver;