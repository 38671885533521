import React, {useState, useEffect} from 'react'
import api from '../../../../../../core/services/api'
import moment from 'moment'
import Chart from "react-apexcharts";
import options from "./options";


//material ui
import Grid from '@material-ui/core/Grid'


function GraphCard(){
    const [state, setState] = useState({          
        series: [],
        start_day: moment().subtract(7, "days").day(0).format("YYYY-MM-DD"),
        end_day: moment().subtract(7, "days").day(6).format("YYYY-MM-DD"),
        journeys: []
    });

    useEffect(() => {
        getData();
      }, [])

    async function getData(){
        try {
          const data = await api.get(`otdweek?start_day=${state.start_day}&end_day=${state.end_day}`);

          let antes = 0;
          let depois = 0;
          data.data.map((item) => {
            if(item.resource.tasks && item.resource.tasks.length > 0){
                item.resource.tasks.map((item) => {
                    const aux = moment(item.start_real, "YYYY-MM-DD HH:mm:ss").isBefore(item.start_prevision, "YYYY-MM-DD HH:mm:ss")
                    if(aux == true){
                     
                      antes += 1;
                    } else {
                      depois += 1;
                    }
                  })
            }
              
          }) 
          setState((prev) => ({
            ...prev,
            journeys: data.data,
            series: [antes, depois],
            otd: antes,
            notd: depois
          }))
        } catch (error) {
          console.warn(error)
        }
      }


    return(
        <Grid container justify="center">
            <Grid item style={{marginLeft: '-124px'}}><Chart options={options()} series={state.series} type="donut" width={380}/></Grid>
        </Grid>
        
       
        
    )
}

export default GraphCard;