import moment from 'moment'

function pad(num) {
  return ("0"+num).slice(-2);
}
function hhmmss(secs) {
  var minutes = Math.floor(secs/60);
  var hours = Math.floor(minutes/60)
  minutes = minutes%60;
  return `${hours}:${pad(minutes)}`;
  // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
}


export default function(legends, hd_route, shifts_id) {
    return {
        colors: ["#ff7053","#2655d8"],
        chart: {
          height: 350,
          type: "bar",
          stacked: true,
          //stackType: '100%'
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'left',
        },
        annotations: {
          yaxis: [
            {
              y: shifts_id,
              borderColor: '#ec273b',
              label: {
                borderColor: '#ec273b',
                style: {
                  color: '#fff',
                  background: '#ec273b'
                },
                text: 'Target Hora Direta'
              }
            }
          ]
        },
        dataLabels: {
          enabled: true,
          background: {
            enabled: false,
            foreColor:  '#777',
            borderColor: '#777',
          },
          //offsetY: 10,
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
              return hhmmss(hd_route[seriesIndex].data[dataPointIndex])+'h'
          },
        },
        stroke: {
          curve: 'straight',
          width: 2
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: legends,
          
        },
        yaxis: [{
          labels: {  
            show: false,
            formatter: function (value) {
              return hhmmss(value)+'h' 
            }
          },
        },
       
      ],
      }
  }