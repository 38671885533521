import moment from 'moment'
export default function(legends, target) {
    return {
        colors: ["#29e1c3","#21a2fc",'#7e78ff'],
        chart: {
          height: 350,
          type: "bar",
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'left',
        },

        dataLabels: {
          enabled: true,
          background: {
            enabled: true,
            foreColor:  '#777',
            borderColor: '#777',
          },
          //enabledOnSeries: [2],
          //offsetY: 10,
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            if(seriesIndex == 2){
              return value.toFixed(0)+"%";
            } else {
              return value
            }
            
          },
        },
        stroke: {
          curve: 'straight',
          width: 2
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories:['Turno 1', 'Turno 2', 'Turno 3'],
          
        },
        yaxis: [{
          labels: {  
            show: false,
            formatter: function (value) {
              return value;
            }
          },
        },
        { 
          seriesName: 'Realizado',
          labels: {  
            show: false,
            formatter: function (value) {
              return value;
            }
          },
        },
        { 
          labels: {  
            show: false,
            formatter: function (value) {
              return value.toFixed(0)+"%";
            }
          },
        },
      ],
      }
  }