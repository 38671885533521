import React, { useState } from "react";

// Styled Components
import { Sidebar } from "../../styles/Map";

// @material-ui/core
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import ListSubheader from "@material-ui/core/ListSubheader";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

// @material-ui/icons
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

//custom components
import Machine from "./Machine";

const options = ["Todos", "Movimento", "Parado", "Desligado"];

function SidebarContainer(props) {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setSelectedValue] = React.useState(0);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedValue(index);
    setAnchorEl(null);
    props.filterSelect(options[index].toLowerCase());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
    props.setOpensidebar(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    props.setOpensidebar(false);
  };

  return (
    <>
      <React.Fragment key="right">
        <Button
          style={{
            marginTop: 80,
            position: "absolute",
            float: "right",
            right: open ? 330 : 0,
            backgroundColor: "#fff",
            zIndez: open ? 9999 : 0,
            transition: "all .35s ease",
          }}
          onClick={open ? handleDrawerClose : handleDrawerOpen}
        >
          {open ? <ChevronRightIcon /> : <LocalShippingIcon />}
        </Button>
        <Drawer anchor="right" open={open} variant="persistent">
          <Sidebar>
            <List
              component="nav"
              aria-label="main mailbox folders"
              subheader={
                <ListSubheader
                  component="div"
                  style={{ backgroundColor: "#e8ebef" }}
                >
                  Veículos
                  <ListItemSecondaryAction>
                    <Button
                      endIcon={<ArrowDropDownIcon />}
                      onClick={handleClickListItem}
                    >
                      {options[value]}
                    </Button>
                    <Menu
                      id="lock-menu"
                      anchorEl={anchorEl}
                      getContentAnchorEl={null}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      {options.map((option, index) => (
                        <MenuItem
                          key={option}
                          selected={index === value}
                          onClick={(event) => handleMenuItemClick(event, index)}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Menu>
                  </ListItemSecondaryAction>
                </ListSubheader>
              }
            >
              <ListItem divider>{props.children}</ListItem>
              {props.data
                ? props.data.map((carro) => (
                    <Machine
                      navigate={props.changeViewport}
                      carro={carro}
                      setColor={props.setColor}
                      selectedIndex={selectedIndex}
                      handleListItemClick={handleListItemClick}
                    />
                  ))
                : ""}
            </List>
          </Sidebar>
        </Drawer>
      </React.Fragment>
    </>
  );
}

export default SidebarContainer;
