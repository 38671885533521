import React, {useState, useEffect} from 'react' 
import moment from 'moment'
import api from '../../../../../core/services/api'
//custom components
import CustomSelect from '../../../components/CustomSelect'


//material-ui
import { useStyles } from "./styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import DeleteIcon from '@material-ui/icons/Delete'
import InputAdornment from '@material-ui/core/InputAdornment'
import SaveIcon from '@material-ui/icons/Save'
import SearchIcon from '@material-ui/icons/Search'
import Typography from '@material-ui/core/Typography'

//datepicker
import { KeyboardTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";


//sortable hoc
import {
  sortableHandle,
} from 'react-sortable-hoc';


const DragHandle = sortableHandle(() => <IconButton><DragIndicatorIcon /></IconButton>);




function Task(props){

  const classes = useStyles();
  const [state, setState] = useState({
    typeactivities: props.item.activity_type,
    typeactivities_list: props.typeactivities,
    shift: props.item.shift,
    shift_list: props.shifts,
    local: props.item.local,
    destiny: props.item.destiny,
    local_list: props.locations,
    use_saturation: props.item.use_saturation || false,
    last_time: props.item.end_prevision || "00:00:00",
    exist_transit: null,
    calc_transit: null,
  })
  const [expand, setExpand] = useState(false);
  const [title, setTitle] = useState("");
  const [start_time, setStarttime] = useState(props.item.start_prevision ?  "2018-01-01T" + props.item.start_prevision + ".000" : "2018-01-01T00:00:00.000");
  const [end_time, setEndtime] = useState(props.item.end_prevision ?  "2018-01-01T" + props.item.end_prevision + ".000" : "2018-01-01T00:00:00.000");
  const [target_time, setTargettime] = useState(props.item.target_time || "");
  const [transit_time, setTransittime] = useState(props.item.transit_time || "");
  const [local, setLocal] = useState(props.item.local);
  const [destiny, setDestiny] = useState(props.item.destiny);
  const [shift, setShift] = useState(props.item.shift);
  const [obs, setObservation] = useState(props.item.obs);
  const [saturation, setSaturation] = useState(props.item.saturation);
  const [tasklist, setTasklist] = useState(props.tasks)

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
    if(name === 'local') setTitle(getLocalName());
    if(name === 'destiny' || name === 'local'){
    /*   if(name === 'destiny'){
        if(state.local) getTransitTime(state.local,value)
      }
      if(name === 'local'){
        if(state.destiny) getTransitTime(value, state.destiny)
      } */
    }
  };

  useEffect(() => {
    //const task = props.tasks.filter((itemm) => {return itemm.id == props.item.id})
    const taskfinal = {
      id: props.tasks[props.index].id,
      order: props.tasks[props.index].order,
      start_prevision: moment(start_time).format("HH:mm:ss"),
      end_prevision: moment(end_time).format("HH:mm:ss"),
      target_time: target_time,
      transit_time: parseInt(transit_time),
      local: state.typeactivities !== 6 ? '' : state.local,
      destiny: state.typeactivities !== 6 ? '' : state.destiny,
      shift: state.shift,
      obs: obs,
      use_saturation: state.use_saturation,
      activity_type: state.typeactivities,
      title: getActivityName() === "Doca" ? getLocalName() : getActivityName(),
      activity_name: getActivityName(),
      use_fence: true,
      end_task: state.typeactivities == 3 ? true : false,
      frequency: props.period,
      justify: true,
    }
    tasklist[props.index] = taskfinal
    
    const interval = setInterval(() => {
      const taskfinal = {
        id: props.tasks[props.index].id,
        order: props.tasks[props.index].order,
        start_prevision: moment(start_time).format("HH:mm:ss"),
        end_prevision: moment(end_time).format("HH:mm:ss"),
        target_time: target_time,
        transit_time: parseInt(transit_time),
        local: state.typeactivities !== 6 ? '' : state.local,
        destiny: state.typeactivities !== 6 ? '' : state.destiny,
        shift: state.shift,
        obs: obs,
        use_saturation: state.use_saturation,
        activity_type: state.typeactivities,
        title: getActivityName() === "Doca" ? getLocalName() : getActivityName(),
        activity_name: getActivityName(),
        use_fence: true,
        end_task: state.typeactivities == 3 ? true : false,
        frequency: props.period,
        justify: true,
      }
      tasklist[props.index] = taskfinal
    }, 5000);
    return () => clearInterval(interval);
  }, [state.local, state.destiny, state.shift, state.typeactivities, start_time, end_time, obs, state.use_saturation, transit_time])

  useEffect(() => {

    //AUTOMAÇÃO DE TEMPO DE TRÂNSITO E TEMPO TARGET ATIVIDADE
    const time = moment.utc(moment(end_time).diff(moment(start_time))).format("HH:mm:ss");
    if(state.edit){
      if(start_time !== "2018-01-01T" + props.item.start_prevision + ".000" && end_time !== "2018-01-01T" + props.item.end_prevision + ".000"){
        setTargettime(time);
        tasklist[props.index].target_time = time;
        //tasklist[props.index].transit_time = transit_time
      }
    } else {
      if(start_time !== "2018-01-01T00:00:00.000" && end_time !== "2018-01-01T00:00:00.000"){
        setTargettime(time);
        tasklist[props.index].target_time = time;
        //tasklist[props.index].transit_time = transit_time
      }
    }
    //CHECAR MAIOR TEMPO - CAMPO TRANSITO
    const bigtransit = tasklist.reduce((prev, next) => {
      return (prev?.transit_time || 0) > (next?.transit_time || 0) ? prev : next;
    }, {})
    if(bigtransit.transit_time){
      props.setRoutetransit(bigtransit.transit_time)
    }
  }, [end_time, start_time])



  function getLocalName(){
    const name = state.local_list.filter((item) => {return item.id === state.local});
    return name[0] ? name[0].name : ""
  }
  function getActivityName(){
    const name = state.typeactivities_list.filter((item) => {return item.id === state.typeactivities});
    return name[0] ? name[0].name : ""
  }
  function getShiftName(){
    const name = state.shift_list.filter((item) => {return item.id === state.shift})
    return name[0] ? name[0].resource.work_shift : ""
  }


  function handleSearchTransit(){
    if(props.tasks[props.index+1]){
      const then = moment(end_time).format("HH:mm:ss")
      const now = moment(props.tasks[props.index+1].start_prevision, "HH:mm:ss").format("HH:mm:ss")
      const dif = moment(now, "HH:mm:ss").diff(moment(then, "HH:mm:ss"), 'minutes')
      if(dif < 0){
        console.log('Dif negativo: ', dif)
      } else {
        setTransittime(dif)
      }
    } else {
      console.log('Não existe tempo em próxima atividade')
    }
  }




    return (
      <div className={classes.root}>
     {/*  <Paper style={{padding: 15, width: 200, margin: 15, marginLeft: 0, display: props.index == 0 ? 'none' : 'block'}}>
        <TextField disabled id="transit_time" label="Tempo de Trânsito" variant="outlined" InputLabelProps={{ shrink: true }} value={transit_time} fullWidth/>
    </Paper> */}
        <Accordion TransitionProps={{ unmountOnExit: true }} 
        expanded={props.expand ? (props.expand === props.panel || props.expand === 'allpanel' ) : false} onChange={props.handleExpand(props.panel)}
        >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id={"panel"+props.index}
          
        >
          <div className={classes.column}>
         <FormControlLabel
         style={{margin: '-20px'}}
            aria-label="Acknowledge"
            control={<DragHandle />}
            label={getActivityName() === "Doca" ? getLocalName() : getActivityName()}
          /></div>
           <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>{moment(start_time).format("HH:mm:ss")}</Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>{getShiftName()}</Typography>
          </div>
        </AccordionSummary>
        
        
        <AccordionDetails >
          
         <Grid container spacing={4}>
           <Grid item container lg={12} md={12} sm={12} xs={12} spacing={1} direction="row" alignItems="center">
             <Grid item sm={1}></Grid>
              {/* <Grid item sm={2} md={2}>
              <TextField id="transit_time" label="Tempo de Trânsito" variant="outlined" value={transit_time} onChange={(e) => setTransittime(e.target.value)} fullWidth/>
              </Grid> */}
              <Grid item sm={2} md={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                fullWidth
                inputVariant="outlined"
                keyboardIcon={<AccessTimeIcon />}
                autoOk={true}
                ampm={false}
                variant="inline"
                label="Hora Início"
                value={start_time}
                onChange={setStarttime}
              />
            </MuiPickersUtilsProvider>
              </Grid>
              <Grid item sm={2} md={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                fullWidth
                inputVariant="outlined"
                keyboardIcon={<AccessTimeIcon />}
                autoOk={true}
                ampm={false}
                variant="inline"
                label="Hora Fim"
                value={end_time}
                onChange={setEndtime}
              />
            </MuiPickersUtilsProvider>
              </Grid>
              <Grid item sm={2} md={2}>
              <TextField disabled id="target_time" label="Tempo Alvo" variant="outlined" InputLabelProps={{ shrink: true }} value={target_time} fullWidth/>
              </Grid>
              
              <Grid item sm={4} md={4}>
              <CustomSelect
                outlined={true}
                label="Tipos de Atividade"
                value={state.typeactivities}
                name="typeactivities"
                data={state.typeactivities_list}
                onChange={(event) => handleChange(event)}
              />
              </Grid>
              <Grid item sm={1}></Grid>
            </Grid>

          <Grid item container lg={12} md={12} sm={12} xs={12} spacing={1} direction="row" alignItems="center">
           <Grid item sm={1}></Grid>
           <Grid item sm={2} md={2}>
           <CustomSelect
                disabled={state.typeactivities !== 6 ? true : false}
                outlined={true}
                label="Local"
                value={state.local}
                name="local"
                data={state.local_list}
                onChange={(event) => handleChange(event)}
              />
           </Grid>
           <Grid item sm={2} md={2}>
           <CustomSelect
                disabled={state.typeactivities !== 6 ? true : false}
                outlined={true}
                label="Destino"
                value={state.destiny}
                name="destiny"
                data={state.local_list}
                onChange={(event) => handleChange(event)}
              />
           </Grid>
           <Grid item  sm={2} md={2}> 
              <TextField 
              style={{marginBottom: '-24px'}}
              id="transit_time" 
              label="Tempo de Trânsito" 
              variant="outlined" 
              InputLabelProps={{ shrink: true }} 
              value={transit_time} 
              onChange={(e) => setTransittime(e.target.value)} 
              helperText="minutos"
           
              fullWidth/>
          </Grid>
           <Grid item sm={2} md={2}>
    
           <CustomSelect
                outlined={true}
                label="Turno"
                value={state.shift}
                name="shift"
                data={state.shift_list}
                onChange={(event) => handleChange(event)}
              />
           </Grid>
           <Grid item sm={2} md={2}>
           <TextField id="obs" label="Observação" variant="outlined" value={obs} onChange={(e) => setObservation(e.target.value)} fullWidth/>
           </Grid>
           
           <Grid item sm={1}></Grid>
          </Grid>
          <Grid container>
          <Grid item sm={1}></Grid>
          <Grid item sm={2} md={2}>
           <FormControlLabel
          control={
            <Switch
              checked={state.use_saturation}
              onChange={handleChange}
              name="use_saturation"
              color="primary"
            />
          }
          label="Considerar Saturação"
        />
           </Grid>
          </Grid>
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Grid item>
            <IconButton onClick={() => props.handleRemoveClick(props.index)}>
              <DeleteIcon style={{color: "#ec273b"}} />
            </IconButton>
          </Grid>
        </Grid>
         </Grid>
        </AccordionDetails>
      </Accordion>
      </div>
    )
}

export default React.memo(Task);