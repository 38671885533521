import React from 'react';
import moment from 'moment'
import {useStyles} from './styles'

//@material-ui
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import StopIcon from '@material-ui/icons/Stop'

//custom components



function AverageTimes(props){
    const classes = useStyles();

    function pad(num) {
        return ("0"+num).slice(-2);
      }
      function hhmmss(secs) {
        let segundos = parseInt(secs)
        if(parseInt(secs) < 0) segundos = Math.abs(secs);
        var minutes = Math.floor(segundos/60);
        var secss = segundos-(minutes*60);
        var hours = Math.floor(minutes/60)
        minutes = minutes%60;
        return `${pad(hours)}:${pad(minutes)}:${pad(secss)}`;
        // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
      }
    return (
        <Card className={classes.root}>
        <CardContent>
        <Grid container direction="column" justify="center" alignItems="center"> 
        <Grid container spacing={3} direction="row">
            <Grid item lg={3} md={3} sm={3} xs={3}>
                <Grid container direction="column" justify="space-between" alignItems="center">
                <Grid item><Typography  variant="h6"  color="textSecondary" gutterBottom>
                Tempo Médio <b>Setup Time</b>
                </Typography><p style={{fontSize: 10, textAlign: 'center', marginTop: '-10px', opacity: 0.5}}>MINUTOS</p></Grid>
                {props.date == moment(props.date, "YYYY-MM-DD").day(0).format("YYYY-MM-DD") ? (<>
                    <Grid item><Typography variant="h3" color="textSecondary" style={{color: '#90a3b4'}} gutterBottom ><b>{props.setup4 == 0 ? "00:00:00" : hhmmss(props.setup4)}</b></Typography></Grid>
                </>) : (<>
                    <Grid item><Typography variant="h3" color="textSecondary" style={{color: '#2655d8'}} gutterBottom ><b>{props.setup1 == 0 ? "00:00:00" : hhmmss(props.setup1)}</b></Typography></Grid>
                    <Grid item><Typography variant="h3" color="textSecondary" style={{color: '#ff7053'}} gutterBottom ><b>{props.setup2 == 0 ? "00:00:00" : hhmmss(props.setup2)}</b></Typography></Grid>
                    <Grid item><Typography variant="h3" color="textSecondary" style={{color: '#90a3b4'}} gutterBottom ><b>{props.setup3 == 0 ? "00:00:00" : hhmmss(props.setup3)}</b></Typography></Grid>
                    </>)}

                </Grid>
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={3}>
                <Grid container direction="column" justify="space-between" alignItems="center">
                <Grid item><Typography  variant="h6"  color="textSecondary" gutterBottom>
                Tempo Médio <b>Check List</b>
                </Typography><p style={{fontSize: 10, textAlign: 'center', marginTop: '-10px', opacity: 0.5}}>MINUTOS</p></Grid>
                {props.date == moment(props.date, "YYYY-MM-DD").day(0).format("YYYY-MM-DD") ? (<>
                    <Grid item><Typography variant="h3" color="textSecondary" style={{color: '#90a3b4'}} gutterBottom ><b>{props.checklist4 == 0 ? "00:00:00" : hhmmss(props.checklist4)}</b></Typography></Grid>
                  
                </>) : (<>
                    <Grid item><Typography variant="h3" color="textSecondary" style={{color: '#2655d8'}} gutterBottom ><b>{props.checklist1 == 0 ? "00:00:00" : hhmmss(props.checklist1)}</b></Typography></Grid>
                    <Grid item><Typography variant="h3" color="textSecondary" style={{color: '#ff7053'}} gutterBottom ><b>{props.checklist2 == 0 ? "00:00:00" : hhmmss(props.checklist2)}</b></Typography></Grid>
                    <Grid item><Typography variant="h3" color="textSecondary" style={{color: '#90a3b4'}} gutterBottom ><b>{props.checklist3 == 0 ? "00:00:00" : hhmmss(props.checklist3)}</b></Typography></Grid>
                    
                </>)}
               </Grid>
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={3}>
                <Grid container direction="column" justify="space-between" alignItems="center">
                <Grid item><Typography  variant="h6"  color="textSecondary" gutterBottom>
                Tempo Médio <b>Refeição</b>
                </Typography><p style={{fontSize: 10, textAlign: 'center', marginTop: '-10px', opacity: 0.5}}>MINUTOS</p></Grid>
                {props.date == moment(props.date, "YYYY-MM-DD").day(0).format("YYYY-MM-DD") ? (<>
                    <Grid item><Typography variant="h3" color="textSecondary" style={{color: '#90a3b4'}} gutterBottom ><b>{props.lanche4 == 0 ? "00:00:00" : hhmmss(props.lanche4)}</b></Typography></Grid>
                   
                </>) : (<>
                    <Grid item><Typography variant="h3" color="textSecondary" style={{color: '#2655d8'}} gutterBottom ><b>{props.lanche1 == 0 ? "00:00:00" : hhmmss(props.lanche1)}</b></Typography></Grid>
                    <Grid item><Typography variant="h3" color="textSecondary" style={{color: '#ff7053'}} gutterBottom ><b>{props.lanche2 == 0 ? "00:00:00" : hhmmss(props.lanche2)}</b></Typography></Grid>
                    <Grid item><Typography variant="h3" color="textSecondary" style={{color: '#90a3b4'}} gutterBottom ><b>{props.lanche3 == 0 ? "00:00:00" : hhmmss(props.lanche3)}</b></Typography></Grid>
                    </>)}
                </Grid>
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={3}>
                <Grid container direction="column" justify="space-between" alignItems="center">
                <Grid item><Typography  variant="h6"  color="textSecondary" gutterBottom>
                Tempo Médio <b>Fechamento Turno</b>
                </Typography><p style={{fontSize: 10, textAlign: 'center', marginTop: '-10px', opacity: 0.5}}>MINUTOS</p></Grid>
                {props.date == moment(props.date, "YYYY-MM-DD").day(0).format("YYYY-MM-DD") ? (<>
                    <Grid item><Typography variant="h3" color="textSecondary" style={{color: '#90a3b4'}} gutterBottom ><b>{props.end4 == 0 ? "00:00:00" : hhmmss(props.end4)}</b></Typography></Grid>
                
                </>) : (<>
                    <Grid item><Typography variant="h3" color="textSecondary" style={{color: '#2655d8'}} gutterBottom ><b>{props.end1 == 0 ? "00:00:00" : hhmmss(props.end1)}</b></Typography></Grid>
                    <Grid item><Typography variant="h3" color="textSecondary" style={{color: '#ff7053'}} gutterBottom ><b>{props.end2 == 0 ? "00:00:00" : hhmmss(props.end2)}</b></Typography></Grid>
                    <Grid item><Typography variant="h3" color="textSecondary" style={{color: '#90a3b4'}} gutterBottom ><b>{props.end3 == 0 ? "00:00:00" : hhmmss(props.end3)}</b></Typography></Grid>
                
                </>)}
                 </Grid>
            </Grid>
        </Grid>
            <Grid container direction="row" justify="center" alignItems="center">
            {props.date == moment(props.date, "YYYY-MM-DD").day(0).format("YYYY-MM-DD") ? (<>
                <Grid item><StopIcon style={{color: '#90a3b4'}}/>Turno 3 - Domingo</Grid>
           </>
            ) : (<>
                <Grid item><StopIcon style={{color: '#2655d8'}} />Turno 1</Grid>
                <Grid item><StopIcon style={{color: '#ff7053'}}/>Turno 2</Grid>
                <Grid item><StopIcon style={{color: '#90a3b4'}}/>Turno 3</Grid>
            </>)}
            </Grid>
        </Grid>
        </CardContent>
            <CardActions></CardActions>
        </Card>
    )
}

export default AverageTimes;

