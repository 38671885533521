import React from 'react'
import Container from '@material-ui/core/Container'
import Cold from '@material-ui/icons/AcUnit'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Hot from '@material-ui/icons/Whatshot'
import Check from '@material-ui/icons/Check'
import Alarm from '@material-ui/icons/Alarm'
import DateFnsUtils from '@date-io/date-fns'
import { CircularProgressbar } from 'react-circular-progressbar'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MaterialTable, { MTableToolbar } from 'material-table'
import moment from 'moment'

import 'react-circular-progressbar/dist/styles.css';
import Main from '../../components/Main'
import api from "../../../../core/services/api";


const Cell = ({children}) => (
    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '15px'}}>{children}</div>
)


export default class index extends React.Component {
    state = { 
        journeys: [],
        carrier: 'all',
        carriers: [],
        date: null
     }
    
    componentDidMount() {
        this.getData(Date.now());
        this.getCarriers();
    }


    async getData(date) {
        try {
            this.setState({...this.state, date});
            const {data} = await api.get(`/journeys/list?date=${moment(date).format('YYYY-MM-DD')}&carrier=${this.state.carrier}`);
            this.setState({...this.state, journeys: data, date});
        } catch (error) {
            console.log(error)
        }
    }

    async getCarriers() {
        const {data} = await api.get(`/carriers`);
        this.setState({ ...this.state, carriers: data.data || [] });
    }
    
    filterCarrier(value) {
        this.setState({...this.state, carrier: value}, () => this.getData(this.state.date))
    }

    percentToColor(percent) {
        var r, g, b = 0;
        if(percent < 50) {
            r = 255;
            g = Math.round(5.1 * percent);
        }
        else {
            g = 255;
            r = Math.round(510 - 5.10 * percent);
        }
        var h = r * 0x10000 + g * 0x100 + b * 0x1;
        return '#' + ('000000' + h.toString(16)).slice(-6);
    }

    calcPerformance(total, good) {
        let resp = Math.floor((good / total) * 100)
        return (
            <div style={{width: '75px', height: '75px'}}>
                <CircularProgressbar styles={{
                    width: 50, height: 50,
                    path: {
                        strokeLinecap: 'butt',
                        stroke: this.percentToColor(resp)
                    },
                    text: {
                        fill: this.percentToColor(resp),
                        fontSize: '32px',
                    },
                }} value={resp} text={`${resp}%`} />
            </div>
        );
    }

    checkIcon(status) {
        if(status === 'hot') return <Hot />
        if(status === 'cold') return <Cold />
        return <Check />
    }

    calcTemperature(temp) {
        const temperature = parseInt(temp)
        let color = null;
        let status = null;

        if(temperature < 12){
            status = 'cold';
            color = '#2196F3'
        } else if(temperature > 18) {
            status = 'hot';
            color = '#EC273B'
        } else {
            color = '#5ED237'
        }

        return (
            <div style={{
                color: 'white', 
                background: color, 
                width: 75, 
                padding: '8px 12px', 
                borderRadius: 20,
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <span style={{fontSize: 18}}>{temp}</span> 
                &nbsp; 
                {this.checkIcon(status)}
            </div>
        )
    }


    render () {
        return(
            <Main>
                <div style={{paddingTop: '75px', paddingBottom: '100px', position: 'absolute', maxWidth: '100%', minWidth: '100%'}} >
                    <Container maxWidth='lg'>
                                <MaterialTable 
                                    actions={[
                                        {
                                            icon: 'map',
                                            tooltip: 'Rota',
                                            onClick: (event, {id}) => {
                                                this.props.history.push('/routes/'+id);
                                            }
                                        }
                                    ]}
                                    columns={[
                                        {title: 'Placa', field: 'placa', render: ({placa}) => <Cell>{placa}</Cell>},
                                        {title: 'Motorista', field: 'username', render: ({username}) => <Cell><span style={{textAlign: 'center'}}>{username}</span></Cell>},
                                        {title: 'Transportadora', field: 'carrier', render: ({carrier}) => <Cell><span style={{textAlign: 'center'}}>{carrier}</span></Cell>},
                                        {title: 'Temperatura Máxima', field: 'max', render: ({max}) => <Cell> {this.calcTemperature(max)} </Cell>},
                                        {title: 'Temperatura Minima', field: 'min', render: ({min}) => <Cell> {this.calcTemperature(min)} </Cell>},
                                        {title: 'Temperatura No Range', field: 'performace', render: ({total, good_pings}) => <Cell> {this.calcPerformance(total, good_pings)} </Cell>},
                                        {title: 'Duração', field: 'duration', render: ({duration}) => <Cell><Alarm/> <span style={{fontSize: 25}}> {moment(`${duration.hours}:${duration.minutes}`, 'HH:mm').format('HH:mm')}</span> </Cell>},
                                        {title: 'Média', field: 'media', render: ({media}) => <Cell>{this.calcTemperature(media)}</Cell>},
                                    ]}
                                    data={this.state.journeys}
                                    options={{
                                        actionsColumnIndex: -1,
                                        pageSize: 10,
                                        pageSizeOptions: [10, 20, 50],
                                        showTitle: false,
                                        detailPanelColumnAlignment: 'left',
                                        headerStyle: {
                                            textAlign: 'center',
                                            backgroundColor: '#E8EBEF',
                                            color: '#2B2B2B',
                                            fontSize: 16,
                                            fontWeight: 'bold'
                                        },
                                        cellStyle:{
                                            textAlignment: 'center',
                                            fontSize: 14
                                        }
                                    }}
                                    components={{
                                    Toolbar: props => (
                                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px 10px 0 10px'}}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                style={{margin: '0 0 0 10px'}}
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                id="date-picker-inline"
                                                value={this.state.date}
                                                onChange={(e) => this.getData(e)}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                        <Select 
                                            style={{winWidth: 200, maxWidth: 200, width: 200, marginTop: 16, marginBottom: 17}}
                                            value={this.state.carrier}
                                            onChange={(e) => this.filterCarrier(e.target.value)}>
                                            <MenuItem value='all'>Todas</MenuItem>
                                            {this.state.carriers.map(({id, name}) => <MenuItem value={id}>{name}</MenuItem>)}
                                        </Select>
                                        <MTableToolbar {...props} />
                                    </div>
                                    ),
                                }}
                                />
                    </Container>
                </div>
            </Main>
        )
    };    
}