
import { createMuiTheme } from "@material-ui/core";


export const toraTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#1e824c"
    }
  }
});
