import React from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import api from "../../../../../core/services/api";

//custom components
import EditForm from '../EditForm'



//@material-ui
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

function TableShifts(props) {
  function getHeaderColor(id) {
    const colors = [
      "#8480fc99",
      "#8480fc99",
      "#1e87ff99",
      "#4fc2b799",
      "#ffae3699",
      "#f65dcd99",
      "#9fe57099",
      "#000000",
      "#8480fc99",
      "#1e87ff99",
      "#4fc2b799",
      "#ffae3699",
      "#f65dcd99",
      "#9fe57099",
      "#79b35399",
      "#cee8a1",
      "#d3dc99",
      "#d7ea58",
      "#fba2a2",
      "#c56fff"
    ];
    return colors[id];
  }
  function getWeekendDays(list) {
    const days = [
      "Dom, ",
      "Seg, ",
      "Ter, ",
      "Qua, ",
      "Qui, ",
      "Sex, ",
      "Sab, ",
      "Dom, ",
    ];
    const numberToDays = list?.map((item) => {
      return days[item];
    });
    return numberToDays;
  }

  async function deleteOperationshift(item) {
    try {
      if (
        window.confirm(
          "Tem certeza que deseja deletar o turno " + item.resource.work_shift
        )
      ) {
        await api.delete(`shifts/` + item.id);
      } else {
      }
      props.getData();
    } catch (error) {
      console.warn(error);
    }
  }
  return (
    <MaterialTable
      title={props.title}
      columns={[
        {
          title: "Turno",
          field: "work_shift",
          cellStyle: {paddingLeft: 24 },
          headerStyle: {borderBottom: '#fff', textAlign: 'left', borderRadius: '8px 0px 0px 0px'},
          render: (rowData) => <p>{rowData.resource.work_shift}</p>
        },
        {
          title: "Início",
          field: "start_at",
          cellStyle: {textAlign: 'center' },
          headerStyle: {borderBottom: '#fff'},
          render: (rowData) => <p>{rowData.resource.start_at}</p>
        },
        {
          title: "Fim",
          field: "end_at",
          cellStyle: {textAlign: 'center' },
          headerStyle: {borderBottom: '#fff'},
          render: (rowData) => <p>{rowData.resource.end_at}</p>
        },
        {
          title: "Dias da Semana",
          field: "resource",
          cellStyle: {textAlign: 'center' },
          headerStyle: {borderBottom: '#fff'},
          render: (rowData) => (
            <p>{getWeekendDays(rowData.resource.week_days)}</p>
          )
        },

        {
          title: "Status",
          field: "active",
          render: (rowData) =>
            rowData.active ? (
              <p
                style={{
                  backgroundColor: "#c9ffc9",
                  borderRadius: 20,
                  width: "fit-content",
                  padding: 7,
                  color: "#549c74"
                }}
              >
                Ativo
              </p>
            ) : (
              <p
                style={{
                  backgroundColor: " #ffc9c9",
                  borderRadius: 20,
                  width: "fit-content",
                  padding: 5,
                  color: '#f50057'
                }}
              >
                Inativo
              </p>
            ),
            cellStyle: {textAlign: 'center'},
            headerStyle: {borderBottom: '#fff'}
        },
        {
          field: "actions",
          title: "Ações",
          cellStyle: { textAlign: "center" },
          headerStyle: {paddingRight: 20, textAlign: "right", borderRadius: '0px 8px 0px 0px'},
          render: (rowData) => (
            <>
              <FormGroup row style={{justifyContent: 'flex-end'}}>
              <EditForm
                  title={rowData.resource.work_shift}
                  //campos
                  work_shift={rowData.resource.work_shift}
                  start_at={rowData.resource.start_at}
                  end_at={rowData.resource.end_at}
                  week_days={rowData.resource.week_days}
                  active={rowData.active}
                  resource={rowData.resource}
                  rowData={rowData}
                  //methods
                  getData={() => props.getData()}
                />
                <IconButton color="secondary"  onClick={() => deleteOperationshift(rowData)}>
                  <DeleteIcon />
                </IconButton>{" "}
              </FormGroup>
            </>
          ),
         
        },
      ]}
      data={props.data}
      options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          //showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            textAlign: "center",
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
          },
        rowStyle: (x) => {
            if (x.tableData.id === 0){
              return { borderTop: '2px solid rgb(212, 212, 212)'}
            }
          },
      }}
      components={{
        Toolbar: (props) => (
          <div style={{ display: "none" }} >
            <MTableToolbar {...props} />
          </div>
        ),
      }}
     
    />
  );
}

export default TableShifts;
