import React, {useState, useEffect} from 'react'
import Main from '../../components/Main'
import api from '../../../../core/services/api'
import moment from 'moment'

//material-ui
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'

function SettingsPage(){

    const [state, setState] = useState({
        disabled: false,
    });
    const [planned_product, setPlannedProduct] = useState("");
    const [real_product, setRealProduct] = useState("")
    const [open, setOpen] = useState(false)


    useEffect(() => {
        getData();
      }, []);
    
      async function getData() {
        const settings = await api.get("settings");
        setState((prev) => ({
          ...prev,
          settings: settings.data.resource || [],
        }));

      }

    async function handleSubmit() {

        let planned = state.settings.planned_product
        const plannedA = {
            date: moment().format('YYYY-MM-DD'),
            planned_product: planned_product
        }
        planned.push(plannedA);

        let real = state.settings.real_product 
        const realA = {
            date: moment().format('YYYY-MM-DD'),
            real_product: real_product
        }
        real.push(realA)
        const obj = {
          resource: {
            planned_product: planned,
            real_product: real
          },
        };
        setState((prev) => ({
          ...prev,
          disabled: true,
        }));
        console.log('Configurações: ', obj)
        //await api.put(`/update-settings`, obj);
        setOpen(true);
        setState("");
        getData();
        //
      }


    return (
        <Main color="#f7f7f7" title="Configurações">
           <Card style={{maxWidth: 450}}>
                <CardContent>
                <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                    fullWidth
                    id="standard-basic"
                    label="Plano de Produção no Mês"
                    helperText="Janeiro"
                    value={planned_product}
                    onChange={(e) => setPlannedProduct(e.target.value)}
                /> </div>
                <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                    fullWidth
                    id="standard-basic"
                    label="Produção real no Mês"
                    helperText="Janeiro"
                    value={real_product}
                    onChange={(e) => setRealProduct(e.target.value)}
                /> </div>
                </CardContent>
                <CardActions>
                <Button
                
                    variant="contained"
                    color="primary"
                    disabled={state.disabled}
                    onClick={handleSubmit}
                >
                    Salvar
                </Button>
                </CardActions>
            </Card>
        </Main>
    )
}

export default SettingsPage;