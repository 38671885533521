import React, { useState, useEffect } from "react";
import Main from "../../components/Main";
import api from "../../../../core/services/api";
import moment from "moment";

//@material-ui
import { Container } from "./styles";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";

//custom components
import { toraTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";
import CardMotorista from "./CardMotorista";

//datepicker
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// pick a date util library
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";

function PainelVeiculos(props) {
  const [data, setState] = useState({
    carros: [],
    carros_filter: [],
    date: moment(Date.now()).format("YYYY-MM-DD"),
    loading: true,
  });
  const [time, setTime] = useState({
    then: moment(),
    now: moment(),
    minutes: "",
    seconds: "",
    days: "",
    hours: "",
  });
  const [search, setSearch] = useState("");
  useEffect(() => {
    getData();

    const interval2 = setInterval(() => {
      //CONTADOR
      const now = moment();
      const countdown = moment(now - time.then);
      const days = countdown.format("D");
      const hours = countdown.format("HH");
      const minutes = countdown.format("mm");
      const seconds = countdown.format("ss");
      setTime((prev) => ({
        ...prev,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      }));
    }, 1000);

    const interval = setInterval(() => {
      setTime({
        then: moment(),
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
      getData();
    }, 300000);
    return () => clearInterval(interval2, interval);
  }, [data.date, time.then]);

  const handleDate = (date) => {
    setState((prev) => ({
      ...prev,
      date: moment(date).format("YYYY-MM-DD"),
    }));
  };

  async function getData() {

    const oldstate = JSON.parse(localStorage.getItem('oldpainelveiculo'));   
    if(oldstate){
      let url = `https://flex.trackage.io/api/v1/tora_painel_motorista?date=${data.date}`;
      let result = await api.get(url);
      if(result.data[0].cycles == null){
        result = oldstate;
      }
      setState((prev) => ({
        ...prev,
        carros: result.data,
        carros_filter: result.data,
        loading: false,
      }));
    } else {
    let url = `https://flex.trackage.io/api/v1/tora_painel_motorista?date=${data.date}`;
    const result = await api.get(url);
    setState((prev) => ({
      ...prev,
      carros: result.data,
      carros_filter: result.data,
      loading: false,
    }));
    localStorage.setItem('oldpainelveiculo', JSON.stringify(result.data));
    }
   
  }
  function filterData(value) {
    const filter = data.carros.filter((carro) => {
      return carro.board.toLowerCase().includes(value.toLowerCase());
    });
    setState((prev) => ({
      ...prev,
      carros_filter: filter,
    }));
    setSearch(filter);
  }

  return (
    <Main
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ marginRight: 50 }}>Painel Veículos</span>
        </div>
      }
    >
      <Container>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item lg>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <ThemeProvider theme={toraTheme}>
                {" "}
                <KeyboardDatePicker
                  style={{ margin: 12 }}
                  disableFuture={true}
                  disableToolbar
                  clearable={true}
                  autoOk={true}
                  variant="inline"
                  format="dd/MM/yyyy"
                  label="Data Inicial"
                  value={moment(data.date, "YYYY-MM-DD")}
                  onChange={handleDate}
                  KeyboardButtonProps={{
                    "aria-label": "Data Inicial",
                  }}
                />{" "}
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item lg={2}>
            <div className="countdown-item">
              Atualizado há {time.minutes}:{time.seconds}
              <span>{time.minutes >= 1 ? " minutos" : " segundos"}</span>
            </div>
          </Grid>
          <Grid item lg={2}>
            <Input
              style={{ width: "80%" }}
              placeholder="Buscar"
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
              onChange={(e) => filterData(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {data.carros_filter.map(function (carro, key) {
            return (
              <Grid item lg={3}>
                <CardMotorista
                  board={carro.board}
                  cycles={carro.cycles}
                  time_operation={carro.tempo_operacao}
                  tempo_filial={carro.tempo_filial}
                  vehicle_id={carro.vehicle_id}
                  latitude={carro.latitude}
                  longitude={carro.longitude}
                  date={data.date}
                />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Main>
  );
}

export default PainelVeiculos;
