import React, {useState, useEffect} from 'react'
import moment from 'moment'
import Chart from "react-apexcharts";
import options from "./options";

function GraphCard(props){
    const [state, setState] = useState({          
        series: props.efet_route,
        legends: [],
        target: [],
    });

    return(
        <Chart
              options={options(props.legends)}
              series={props.efet_route}
              type="bar"
              height={350}
            />
    )
}

export default GraphCard;