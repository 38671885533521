import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import TrackageLogo from "../assets/logo_tkg.png";
import swal from "sweetalert";
import { login } from "../config/auth";
import api from "../services/api";
import { LoginContainer, LoginBox, Logo, Input, Label, Button } from "./styles";

export default function({ props }) {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [step, setStep] = useState("login");

  async function loginStep(event) {
    event.preventDefault();

    try {
      const payload = {
        email: email,
        password: password,
      };
      await api.post("/authweb", payload);

      setStep("twoFactor");
    } catch (err) {
      swal("Não Autorizado!", err.response.data.message, "warning");
      console.warn({ error: err });
    }
  }

  async function twoFactorStep(event) {
    event.preventDefault();

    try {
      const payload = {
        email: email,
        password: password,
        code: code,
      };
      const request = await api.post("/authweb", payload);

      login(request.data.token);
      localStorage.setItem("usuario", JSON.stringify(request.data));
      console.log("Rquest, data, menu: ", request.data.menu);
      let home = request.data.menu[0];
      history.push(home.resource.route);
      history.go();
    } catch (err) {
      swal("Não Autorizado!", err.response.data.message, "warning");
      console.warn(err);
    }
  }

  return (
    <LoginContainer>
      <Logo src={TrackageLogo} alt="Trackage logo" />
      <LoginBox>
        <h2 style={{ marginBottom: 20 }}>Entrar</h2>
        {step === "login" ? (
          <>
            <Label>E-mail</Label>
            <Input
              name="email"
              type="text"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Label>Senha</Label>
            <Input
              name="password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={loginStep.bind(this)}>ENTRAR</Button>
            </div>
          </>
        ) : (
          <>
            <Label>Código de autenticação</Label>
            <Input
              name="code"
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={twoFactorStep.bind(this)}>ENTRAR</Button>
            </div>
          </>
        )}
      </LoginBox>
    </LoginContainer>
  );
}
