import React, {useState, useEffect} from 'react';
import api from '../../../../../core/services/api'
//@material-ui
import Grid from '@material-ui/core/Grid'



//custom components
import moment from 'moment';
import ReportCard from './ReportCard';
import CuboXTurno from './CuboXTurno';
import CuboXRoute from './CuboXRoute';

function ReportPanel(){
    
    return (
    <Grid container spacing={3}>
      <Grid item md={12} lg={12} sm={12} xs={12}>
        <ReportCard />
      </Grid>
      <Grid item md={12} lg={12} sm={12} xs={12}>
        <CuboXTurno />
      </Grid>
      <Grid item md={12} lg={12} sm={12} xs={12}>
        <CuboXRoute />
      </Grid>
    </Grid> 
    )
}

export default ReportPanel;