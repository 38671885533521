import React, {useState, useEffect} from 'react'
import api from '../../../../../../core/services/api'
import moment from 'moment'
import Chart from "react-apexcharts";
import options from "./options";
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";


//material ui
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import StopIcon from '@material-ui/icons/Stop'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'


//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// pick a date util library
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";


//custom components
import CustomSelect from '../../../../components/CustomSelect'

function GraphCard(props){
    const usuario = JSON.parse(localStorage.getItem("usuario"));
    const [state, setState] = useState({          
        series: [{
          name: 'Turno 1',
          data: []
        },
        {
          name: 'Turno 2',
          data: []
        },
        {
          name: 'Turno 3',
          data: []
        }
      ],
        legends: [],
        target: [],
        start_day: moment().format("YYYY-MM-DD"),
        end_day: moment().add(1, 'days').format("YYYY-MM-DD"),
        local_list: [],
        shifts_list: [],
        shifts_id: null,
        local_users: usuario.person.resource.local_id,
        local_id: usuario.person.resource.local_id[0],
        local_name: '',
    });

    useEffect(() => {
      getData();
    }, [props.local_id, props.shifts_id, props.start_day])


  
  
  async function getData(){
      try {
        let shift = ``;
        if(props.shifts_id != null) shift = `&shift=${props.shifts_id}`
        const data = await api.get(`task-local?start_day=${props.start_day}&end_day=${props.end_day}&local=${props.local_id}`+shift);
        const data2 = await api.get(`place?perPage=1000`);
        let places = data2.data.data;
        if(!state.local_users.includes(60)){
          places = data2.data.data.filter((item) => {if(state.local_users.includes(item.id)){ return item}})
        }
        const data3 = await api.get(`routes`);
        let legends = []
        let local_name = ''
        let series = [{
          name: 'Turno 1',
          type: 'column',
          data: []
        },
        {
          name: 'Turno 2',
          type: 'column',
          data: []
        },
        {
          name: 'Turno 3',
          type: 'column',
          data: []
        }]
        let average = [{
          name: 'Turno 1',
          data: []
        },
        {
          name: 'Turno 2',
          data: []
        },
        {
          name: 'Turno 3',
          data: []
        }]
        let target = []
        let averagetarget = []
        //criando legends
        data.data.map((item) => {
          if(legends.length < 1){
            if(item.tasks.destiny != null){
              local_name = item.tasks.title
              let destiny_name = data2.data.data.filter((item2) => {return item2.id == item.tasks.destiny})
              if(destiny_name.length > 0) legends.push(item.tasks.title+"x"+ destiny_name[0].name)  
            }
          } else {
            if(item.tasks.destiny != null){
            let destiny_name = data2.data.data.filter((item2) => {return item2.id == item.tasks.destiny})
            let exist = null;
            if(destiny_name.length > 0) exist = legends.includes(item.tasks.title+"x"+destiny_name[0].name)
            if(!exist){
              if(destiny_name.length > 0) legends.push(item.tasks.title+"x"+destiny_name[0].name)
            }
            }
          }   
      }) 
      legends.forEach((item,index) => {
        series[0].data.push(0)
        series[1].data.push(0)
        series[2].data.push(0)
        average[0].data.push(0)
        average[1].data.push(0)
        average[2].data.push(0)
        target.push(0)
        averagetarget.push(0)
      })
    

      //criando series
      data.data.map((item) =>{
        if(item.tasks.destiny != null){
          const aux = moment(item.tasks.finish_time, "DD/MM/YYYY HH:mm:ss").diff(moment(item.tasks.finish_time, "DD/MM/YYYY HH:mm:ss").clone().startOf('day'), 'seconds');
          let destiny_name = data2.data.data.filter((item2) => {return item2.id == item.tasks.destiny})
          if(destiny_name.length > 0) {
          let index = legends.indexOf(item.tasks.title+"x"+destiny_name[0].name)
          if(item.tasks.shift == 10){
            series[0].data[index] += aux;
            average[0].data[index] += 1;
          } else if (item.tasks.shift == 16){
            series[1].data[index] += aux;
            average[1].data[index] += 1;
          } else if (item.tasks.shift == 12){
            series[2].data[index] += aux;
            average[2].data[index] += 1;
          }
        }
        }
      })
       series.forEach((item, index) => {
         item.data.forEach((item2, index2) => {
            if(item2 != 0){
              series[index].data[index2] = item2/average[index].data[index2] || 0
            }
         })
      }) 



     //target medio 
      data3.data.map((item) => {
        if(item.tasks && item.tasks.length > 0){
          item.tasks.map((item) => {
            if(item.destiny != null && item.destiny != ''){
              const aux2 = moment(item.target_time, "HH:mm:ss").diff(moment(item.target_time, "HH:mm:ss").clone().startOf('day'), 'seconds');
              let destiny_name = data2.data.data.filter((item2) => {return item2.id == item.destiny})
              if(destiny_name.length > 0){
              let index = legends.indexOf(item.title+"x"+destiny_name[0].name)
              target[index] += aux2;
              averagetarget[index] += 1;
              }
            }
          })
        }
      })
      target.forEach((item, index) => {
        target[index] = item/averagetarget[index] || 0
      })
      const finaltarget = {
        name: 'Tempo target',
        data: target,
        type: 'line'
      }
      series.push(finaltarget)
      
      setState((prev) => ({
        ...prev,
        local_list: places || [],
        legends: legends,
        target: target,
        series: series,
        local_name: local_name
      }))
      } catch (error) {
        console.warn(error)
      }
    }




    return(
      <Grid container justify="center" alignItems="center" spacing={3}>
        <Grid item lg={6} sm={6} md={6} xs={6}>
        <Typography style={{fontSize: 14, textAlign: 'left'}} color="textSecondary" gutterBottom>
        <b>Tempo de Doca: {state.local_name}</b> - Período {moment(props.start_day, "YYYY-MM-DD").format("DD/MM/YYYY")} 
        </Typography>
        </Grid>
        <Grid item lg={6} sm={6} md={6} xs={6}></Grid>
        <Grid item lg={12} sm={12} md={12} xs={12}>
        <Chart
              options={options(state.legends, state.target)}
              series={state.series}
              type="bar"
              height={350}
            />
        </Grid>
        <Grid item><div><StopIcon style={{color: '#ec273b'}} /> Acima do Target</div> </Grid>
        <Grid item><div><StopIcon style={{color: '#21a2fc'}}/> Dentro do Target</div> </Grid>
      </Grid>
        
    )
}

export default GraphCard;