import React, { useEffect, useState } from "react";
import api from '../../../../../core/services/api'
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
//custom components
import CustomSelect from '../../../components/CustomSelect'


function CreateForm(props) {
  const [state, setState] = useState({
    error: false,
    disabled: false,
    active: true,
    titleMessage: "",
    componentList: "",
    min: "",
    max: "",
    steps: "",
    question_id: null,
    relationship: false,
  });

  const [titleMessage, setTitleMessage] = useState("");
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [steps, setSteps] = useState(0);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setState({
      disabled: false,
      error: false,
      componentList: [
        { id: "radio", name: "Pergunta de alternativa (SIM/NÃO)" },
        { id: "slider", name: "Pergunta com indicação de nível" },
        { id: "wheel", name: "Checagem dos pneus" },
        { id: "input", name: "Perguntas abertas"}
      ]
    })
    setTitleMessage("");
    setMin("");
    setMax("");
    setSteps("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
    setMin("")
    setMax("")
    setSteps("")
  };

  async function handleSubmit() {
    setState((prev) => ({
      ...prev,
      disabled: true
    }))

    const checkList = {
      titleMessage: titleMessage,
      component: state.component,
      min: min,
      max: max,
      steps: steps,
      order: props.order,
      question_id: null,
      relationship: false,
    }
    try {
      await api.post(`support_data_web/check_list`, checkList);
      props.getData();
    } catch (error) {
      console.warn(error)
    }
    console.log('Check List criado com sucesso', checkList)
    handleClose()
  }

  return (
    <div>
      <AddIcon onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm" >
        <DialogTitle>Cadastrar nova Check List</DialogTitle>
        <DialogContent>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
              id="titleMessage"
              name="titleMessage"
              label="Mensagem"
              type="text"
              value={titleMessage}
              onChange={(e) => setTitleMessage(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <CustomSelect
              id="component"
              label="Tipo da pergunta"
              value={state.component}
              name="component"
              data={state.componentList}
              onChange={(event) => handleChange(event)}
              fullWidth
            />
          </div>
          <div style={{ marginTop: 12, marginBottom: 12, display: state.component === "slider" ? "block" : "none" }}>
            <TextField
              id="min"
              name="min"
              label="Valor Inicial"
              type="number"
              value={min}
              onChange={(e) => setMin(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12, display: state.component === "slider" ? "block" : "none" }}>
            <TextField
              id="max"
              name="max"
              label="Valor Final"
              type="number"
              value={max}
              onChange={(e) => setMax(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12, display: state.component === "slider" ? "block" : "none" }}>
            <TextField
              id="steps"
              name="steps"
              label="Divisões"
              type="number"
              value={steps}
              onChange={(e) => setSteps(e.target.value)}
              fullWidth
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor: state.disabled ? "" : "#5ed237", color: state.disabled ? "" : "#fff" }}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateForm;
