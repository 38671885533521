import moment from 'moment'
export default function(legends) {
    return {
        colors: ["#26a0fc", "#7e78ff"],
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
        }
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'left',
        },

        dataLabels: {
          enabled: true,
          enabledOnSeries: [0],
          style: {
            fontSize: '10px',
            fontFamily: 'Helvetica, Arial, sans-serif',
        },
          formatter: function (value) {
            return moment().startOf('day').add(moment.duration({s: value})).format('mm:ss')+'m';
          }
        },
        stroke: {
          curve: 'straight',
          width: 2
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: legends,
          labels: {
            show: true,
            style: {
                fontSize: '10px',
                fontFamily: 'Helvetica, Arial, sans-serif',
            }
        },
        },
        yaxis: [{   
          labels: {  
            show: true,
            formatter: function (value) {
              return moment().startOf('day').add(moment.duration({s: value})).format('mm:ss')+'m';
            }
          },
        }
      ],
      }
  }