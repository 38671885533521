import React, {useState, useEffect} from 'react'
import moment from 'moment'
import Chart from "react-apexcharts";
import options from "./options";

function GraphCard(props){
    const [state, setState] = useState({          
        series: props.hd_route,
        legends: [],
        target: [],
    });

    return(
        <Chart
              options={options(props.legends, props.hd_route, props.shifts_id)}
              series={props.hd_route}
              type="bar"
              height={350}
            />
    )
}

export default GraphCard;