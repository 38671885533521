import React, { useState } from 'react';
import api from '../../../../../core/services/api'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import FormGroup from '@material-ui/core/FormGroup'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import EditForm from '../EditForm/index'

//sortable hoc
import {
  sortableHandle,
} from 'react-sortable-hoc';


const DragHandle = sortableHandle(() => <IconButton><DragIndicatorIcon /></IconButton>);



const useStyles = makeStyles({
  root: {
    maxWidth: 250,
    minWidth: 250,
    minHeight: 170,
    userSelect: 'none',
    position: 'relative'
  },
  title: {
    fontSize: 14,
  },
  pos: {
    textAlign: 'right',
    marginBottom: 12,
  },
});

function CardChecklist(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    active: props.active
  });

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));

    updateChecklist(value);
  };

    async function updateChecklist(active) {
      try {
        const checklist = {
          titleMessage: props.checklist.titleMessage,
          component: props.checklist.component,
          min: props.checklist.min,
          max: props.checklist.max,
          steps: props.checklist.steps,
          order: props.checklist.order,
          active: active,
          question_id: null,
          relationship: false,
        }
      console.log('Atualizando checklist: ', checklist)
      await api.put(`support_data_web/check_list/${props.checklist.id}`, checklist);
      props.getData();
    } catch (error) {
      console.warn(error)
    }
  }

    async function deleteChecklist(item) {
      try {
        if (
          window.confirm(
            "Tem certeza que deseja deletar o checklist " + item.titleMessage
          )
        ) {
        await api.delete(`support_data_web/check_list/${item.id}`);
        console.log('Deletando o item: ', item)
      } else {
      }
      props.getData();
    } catch (error) {
      console.warn(error);
    }
  }

  return (
    <Card className={classes.root} >
      <CardContent>
      <Grid container justify="space-between" > 
          <Grid item style={{marginTop: '-14px', marginLeft: '-14px'}}> <DragHandle /></Grid>
          <Grid item> <Typography className={classes.pos} color="textSecondary">
            {props.index+1}/{props.sizeTotal}
          </Typography> </Grid>
        </Grid>
        <Typography variant="body2" component="p">
          {props.title}
        </Typography>
      </CardContent>
      <CardActions >
        <Grid container direction="row" justify="space-between" alignItems="center" style={{ position: 'absolute', bottom: 0, padding: 8 }}>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={state.active}
                  onChange={handleChange}
                  name="active"
                  color="primary"
                />
              }
              label="Ativo"
            /></Grid>
          <Grid item >
            <EditForm checklist={props.checklist} getData={props.getData}/>
          </Grid>
          <Grid item onClick={() => deleteChecklist(props.checklist)}>
            <IconButton
              style={{ color: "#ec273b" }}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardActions>
    </Card>)
}
export default CardChecklist;