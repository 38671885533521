import React, {useState, useEffect} from 'react'
import api from '../../../../../../core/services/api'
import moment from 'moment'
import Chart from "react-apexcharts";
import options from "./options";
import options2 from "./options2";
import options3 from "./options3";
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";
import {useStyles} from './styles'


//material ui
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import StopIcon from '@material-ui/icons/Stop'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'


//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// pick a date util library
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";


//custom components
import CustomSelect from '../../../../components/CustomSelect'

function GraphCard(props){
    const classes = useStyles();
    const usuario = JSON.parse(localStorage.getItem("usuario"));
    const [state, setState] = useState({          
        series: [{
          name: 'Tempo de Doca',
          data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
        },
        {
          name: 'Tempo Target',
          data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
        }
      ],
        series2: [0, 0],
        series3: [{
          name: 'Justificativa de atividade em atraso',
          data: []
        }],
        legendOTD: [],
        legends: [],
        target: [],
        start_day: props.start_day,
        end_day: props.end_day,
        local_list: [],
        shifts_list: [],
        shifts_id: null,
        local_users: usuario.person.resource.local_id,
        local_id: usuario.person.resource.local_id[0],
        local_name: '',
    });

    useEffect(() => {
      getData();
    }, [props.local_id, props.shifts_id, props.start_day])

    useEffect(() => {
      getShift()
    }, [])
  
    async function getShift() {
      try {
        const data = await api.get(`shifts?active=all`);
        const shifts = []
        data.data.data.map((item) => shifts.push(item))
        setState((prev) => ({
          ...prev,
          shifts_list: data.data.data || []
        }))
      } catch (error) {
        console.warn(error)
      }
    }
  async function getData(){
      try {
        let shift = ``;
        if(props.shifts_id != null) shift = `&shift=${props.shifts_id}`
        const data = await api.get(`task-local?start_day=${props.start_day}&end_day=${props.end_day}&local=${props.local_id}`+shift);
        const data2 = await api.get(`place?perPage=1000`);
        let places = data2.data.data;
        if(!state.local_users.includes(60)){
          places = data2.data.data.filter((item) => {if(state.local_users.includes(item.id)){ return item}})
        }
        const data3 = await api.get(`routes`);
        const data4 = await api.get(`justification?&perPage=1000`);
        let local_name = data.data[0] ? data.data[0].tasks.title : ''
        let series = [{
          name: 'Tempo de Doca',
          data: []
        },
        {
          name: 'Tempo Target',
          data: []
        }]
        let average = 0;
        let legends = []
        let antes = 0;
        let depois = 0;
        let legendOTD = []
        let justify_times = []
    

     //pegando inicio das atividades tipo doca
    data3.data.map((item) => {
      item.tasks.map((item) => {
        if(item.local == props.local_id){
          let exist = legends.includes(item.start_prevision)
          if(!exist){
            legends.push(item.start_prevision)
          }
        }
      })
    })
  
    legends.sort()
    legends.forEach((item, index) => {
      series[0].data.push(0)
      series[1].data.push(0)
    })

    data3.data.map((item) => {
      item.tasks.map((item) => {
        if(item.local == props.local_id){
          const aux = moment(item.target_time, "HH:mm:ss").diff(moment(item.target_time, "HH:mm:ss").clone().startOf('day'), 'seconds')
          let index = legends.indexOf(item.start_prevision)
          if(series[1].data[index] == 0){
            series[1].data[index] = aux;
          }
        }
      })
    })
    

    //duração da atividade do tipo doca
    data.data.map((item) => {
      if(item.tasks.local == props.local_id){
        const time = moment(item.tasks.finished_time, "YYYY-MM-DD HH:mm:ss").diff(moment(item.tasks.finished_time, "YYYY-MM-DD HH:mm:ss").clone().startOf('day'), 'seconds')
        let aux = moment(item.tasks.start_real, "YYYY-MM-DD HH:mm:ss").isBefore(item.tasks.start_prevision, "YYYY-MM-DD HH:mm:ss")
        if(aux == true){
          antes += 1;
        } else {
          item.tasks.justifications.forEach((item,index) => {
            let exist = legendOTD.includes(item.justify)
            if(!exist){
              legendOTD.push(item.justify)
              justify_times.push(0)
            }
            let index2 = legendOTD.indexOf(item.justify)
            justify_times[index2] += 1;
            average += 1;
          })
         
          depois += 1;
        }
        let index = legends.indexOf(moment(item.tasks.start_prevision, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss"))
        if(series[0].data[index] == 0){
          series[0].data[index] = time;
        }
      }
    })
    let justificativas = data4.data.data.filter((item2) => {return item2.type == 'otd'})
    legendOTD.forEach((item, index) => {
      let justifications = justificativas.filter((item2) => {return item2.id == item})
      if(justifications[0]) {
        legendOTD[index] = justifications[0].description.split(" ")
      } else {
        average = average - justify_times[index]
        legendOTD.splice(index,1);
        justify_times.splice(index,1);
      }
       
    })
    justify_times.forEach((item, index) => {
      justify_times[index] = ((item*100)/average).toFixed(1);
    })
      setState((prev) => ({
        ...prev,
        local_list: places || [],
        series: series,
        series2: [antes, depois],
        local_name: local_name,
        legends: legends,
        legendOTD: legendOTD,
        series3: [{
          name: 'Justificativa de atividade em atraso',
          data: justify_times
        }]
      }))
      } catch (error) {
        console.warn(error)
      }
    }



    return(
      <Grid container spacing={3}>
      <Grid item lg={12} sm={12} md={12} xs={12}>
      <Card className={classes.root}>
        <CardContent>
      <Grid container justify="center" alignItems="center" spacing={3}>
        <Grid item lg={6} sm={6} md={6} xs={6}>
        <Typography style={{fontSize: 14, textAlign: 'left'}} color="textSecondary" gutterBottom>
        <b>Dispersão ponto a ponto: {state.local_name}</b> - Período {moment(props.start_day, "YYYY-MM-DD").format("DD/MM/YYYY")} 
        </Typography>
        </Grid>
       
        <Grid item lg={12} sm={12} md={12} xs={12}>
        <Chart
              options={options(state.legends)}
              series={state.series}
              type="line"
              height={350}
            />
        </Grid>
      </Grid>
        </CardContent>
            <CardActions></CardActions>
        </Card>
        </Grid>
         <Grid item lg={6} sm={6} md={6} xs={6}>
         <Card className={classes.root} style={{minHeight: 440}}>
           <CardContent>
           <Typography className={classes.title} color="textSecondary" gutterBottom>
            <b>On Time Delivery (OTD)</b>
            </Typography>
           <Grid container justify="center"  alignItems="center">
            <Grid item style={{marginLeft: '-124px'}}><Chart options={options2()} series={state.series2} type="donut" width={480}/></Grid>
           </Grid>
           </CardContent>
            <CardActions></CardActions>
        </Card>
        </Grid>
        <Grid item lg={6} sm={6} md={6} xs={6}>
         <Card className={classes.root} style={{minHeight: 440}}>
           <CardContent>
           <Typography className={classes.title} color="textSecondary" gutterBottom>
            <b>Principais Ofensores do não atendimento do OTD</b>
            </Typography>
           <Chart
              options={options3(state.legendOTD)}
              series={state.series3}
              type="bar"
              height={350}
            />
           </CardContent>
            <CardActions></CardActions>
        </Card>
        </Grid>
      </Grid>
        
    )
}

export default GraphCard;