import React, {useState, useEffect} from 'react';
import Main from '../../components/Main'
import MaterialTable, { MTableToolbar } from "material-table";
import api from '../../../../core/services/api'

//custom components
import { makeStyles } from "@material-ui/core/styles";
import Alert from '../../components/Alert'

//@material-ui
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import { Fab } from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import AddIcon from "@material-ui/icons/Add";
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';

//@material icons
import DeleteIcon from '@material-ui/icons/Delete'

function Dashboard(){

    const [state, setState] = useState({
      dashboard: []
    })
    
    useEffect(() => {
      getData();
    }, [])

    async function deletePlace(place) {
      try {
  
        if (
          window.confirm(
            "Tem certeza que deseja deletar o local " + place.name
          )
        ) {
          await api.delete(`place/${place.id}`);
        }
  
        getData();
      } catch (error) {
        console.warn(error)
      }
    }

    async function getData(){
      try {
        const data = await api.get(`place?perPage=1000`);
        setState((prev) => ({
          ...prev,
          users: data.data.data
        }))
      } catch (error) {
        console.warn(error)
      }
    }
 
    const useStyles = makeStyles({
      root: {
        transform: "translateZ(0px)",
        flexGrow: 1,
        color: '#69f0ae'
      },
      speedDial: {
        zIndex: 9999,
        position: "fixed",
        bottom: "12%",
        right: "0.2%",
        "& .MuiSpeedDial-fab": {
          backgroundColor: '#5ad545'
        },
      }
    }
    );
  
    async function getReport() {
          api.get("/place_export", { responseType: 'blob' }).then(blobFile => {
        const url = window.URL.createObjectURL(new Blob([blobFile.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'locais.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setOpen2(true);
      });
    };
  
  
    const classes = useStyles();
    const [direction, setDirection] = React.useState("up");
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
  
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleOpen = () => {
      setOpen(true);
  
    };

    return (
    <Main color="#f7f7f7" title="Locais">
      <Grid container justify="flex-end" spacing={3} style={{ paddingBottom: 12 }}>
        <Grid item>
        <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={direction}
            getData={getData}
            optionType={state.optionType}
          >
            <SpeedDialAction
              key="CreateForm "
              icon={<AddIcon getData={getData} optionType={state.optionType} />}
              tooltipTitle="Adicionar Local"
              onClick={() => handleOpen() }

            />
            <SpeedDialAction
              key="download"
              icon={<GetAppIcon />}
              tooltipTitle="Download"
              onClick={() => getReport()}
             
            />
            {/* <SpeedDialAction
              key="upload"
              icon={<PublishIcon />}
              tooltipTitle="Upload"
              onClick={() => handleOpen()}
            /> */}

          </SpeedDial>
        </Grid>
        <Alert open={open2} message="O relatório foi baixado com sucesso!" setOpen={() => setOpen2()} />
              </Grid>
        <MaterialTable
        columns={[
          
          
          {
            title: "Descrição",
            field: "name",
            cellStyle: {paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff',  borderRadius: '8px 0px 0px 0px'}
          },
         
          {
            field: "actions",
            title: "Ações",
            cellStyle: {  paddingBottom: 0, paddingTop: 0, textAlign: "right" },
            headerStyle: {paddingRight: 20, textAlign: "right", borderRadius: '0px 8px 0px 0px'},
            render: (rowData) => (
              <>
                <FormGroup row style={{justifyContent: 'flex-end'}}>
                 {/*  <EditForm location={rowData} getData={getData} />  */}
                  <IconButton
                    style={{color: "#ec273b"}}
                   onClick={() => deletePlace(rowData)}
                  >
                    <DeleteIcon />
                  </IconButton>{" "}
                </FormGroup>
              </>
            ),
          },
        ]}
        data={state.users}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
          },
          rowStyle: (x) => {
            if (x.tableData.id === 0){
              return { borderTop: '2px solid rgb(212, 212, 212)'}
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 10,
        }}
      />
    </Main>
    )
}

export default Dashboard;