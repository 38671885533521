import moment from 'moment'
export default function(legends, target) {
    return {
        colors: ["#2ca2c7","#90a3b4",'#ff7053','#ec273b'],
        chart: {
          height: 350,
          type: "line",
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'left',
        },
      /*   annotations: {
            yaxis: [
              {
                y: 80,
                borderColor: '#ff7053',
                label: {
                  borderColor: '#ff7053',
                  style: {
                    color: '#fff',
                    background: '#ff7053'
                  },
                  text: 'Target Efetividade'
                }
              }
            ]
          }, */
        dataLabels: {
          enabled: true,
          enabledOnSeries: [1],
         
          //offsetY: 10,
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            if(seriesIndex == 1){
              return value+"%"
            } else {
              return value
            }
          },
        },
        stroke: {
          curve: 'straight',
          width: 2
        },
        xaxis: {
          type: 'category',
          categories: legends, 
        },
        yaxis: [
          {
            labels: {
              show: false,
              formatter: function(value){
                return value
              }
             },
          },
          {
            seriesName: '% Efetividade',
            labels: {
             show: false,
             formatter: function(value){
               return value+"%"
             }
            },

          },
          {
            seriesName: 'Target Efetividade',
            labels: {
              show: false,
              formatter: function(value){
                return value
              }
          },
        },
        ],
     
      }
  }