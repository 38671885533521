import React, {useState, useEffect} from 'react'
import moment from 'moment'
import api from '../../../../../../core/services/api'
import Chart from "react-apexcharts";
import options from "./options";

function GraphCard(){
    const [state, setState] = useState({          
        series: [{
          name: 'Tempo em Ocorrências',
          data: []
        }],
        legends: [],
        start_day: moment().day(0).format("YYYY-MM-DD"),
        end_day: moment().format("YYYY-MM-DD"),
        start_day2: moment().subtract(7, "days").day(0).format("YYYY-MM-DD"),
        end_day2: moment().subtract(7, "days").day(6).format("YYYY-MM-DD"),
        start_day3: moment().subtract(14, "days").day(0).format("YYYY-MM-DD"),
        end_day3: moment().subtract(14, "days").day(6).format("YYYY-MM-DD"),
        start_day4: moment().subtract(21, "days").day(0).format("YYYY-MM-DD"),
        end_day4: moment().subtract(21, "days").day(6).format("YYYY-MM-DD"),
        start_day5: moment().subtract(28, "days").day(0).format("YYYY-MM-DD"),
        end_day5: moment().subtract(28, "days").day(6).format("YYYY-MM-DD"),
        start_day6: moment().subtract(35, "days").day(0).format("YYYY-MM-DD"),
        end_day6: moment().subtract(35, "days").day(6).format("YYYY-MM-DD"),
        start_day7: moment().subtract(42, "days").day(0).format("YYYY-MM-DD"),
        end_day7: moment().subtract(42, "days").day(6).format("YYYY-MM-DD"),
        start_day8: moment().subtract(49, "days").day(0).format("YYYY-MM-DD"),
        end_day8: moment().subtract(49, "days").day(6).format("YYYY-MM-DD"),
    });


    
    useEffect(() => {
      getData();
    }, [])

  async function getData(){
      try {
        const data = await api.get(`time-occurrences?start_day=${state.start_day}&end_day=${state.end_day}`);
        const data2 = await api.get(`time-occurrences?start_day=${state.start_day2}&end_day=${state.end_day2}`); 
        const data3 = await api.get(`time-occurrences?start_day=${state.start_day3}&end_day=${state.end_day3}`); 
        const data4 = await api.get(`time-occurrences?start_day=${state.start_day4}&end_day=${state.end_day4}`); 
        const data5 = await api.get(`time-occurrences?start_day=${state.start_day5}&end_day=${state.end_day5}`); 
        const data6 = await api.get(`time-occurrences?start_day=${state.start_day6}&end_day=${state.end_day6}`); 
        const data7 = await api.get(`time-occurrences?start_day=${state.start_day7}&end_day=${state.end_day7}`); 
        const data8 = await api.get(`time-occurrences?start_day=${state.start_day8}&end_day=${state.end_day8}`); 
        let legends = ['W'+moment().subtract(49,"days").week(),'W'+moment().subtract(42,"days").week(),'W'+moment().subtract(35,"days").week(),'W'+moment().subtract(28,"days").week(),'W'+moment().subtract(21,"days").week(),'W'+moment().subtract(14,"days").week(),'W'+moment().subtract(7,"days").week(),'W'+moment().week()]
        let week = moment().week();
          setState((prev) => ({
          ...prev,
          series: [{
            name: 'Tempo em Ocorrências',
            data: [data8.data[0].minutes || 0, data7.data[0].minutes || 0, data6.data[0].minutes || 0, data5.data[0].minutes || 0 , data4.data[0].minutes || 0, data3.data[0].minutes,data2.data[0].minutes, data.data[0].minutes || 0]
          }],
          legends: legends,
        }))  
      } catch (error) {
        console.warn(error)
      }
    }
    return(
        <Chart
              options={options(state.legends)}
              series={state.series}
              type="bar"
              height={350}
            />
    )
}

export default GraphCard;