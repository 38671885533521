import React from 'react'
import {useStyles} from './styles'


//custom components
import GraphCard from "./GraphCard"

function WindowWithoutOrder(){
    const classes = useStyles();
    return (
        <GraphCard />
    )
}


export default WindowWithoutOrder;