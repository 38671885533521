export default function(legends) {
    return {
        colors: ['#7d77ff', '#2de0c2', '#26a0fc', '#fcca26', '#e91e63', '#80a4bf', '#26a0fc', '#2de0c2', '#7d77ff', '#e91e63', '#331c81', '#80a4bf', '#e4c54c','#4c5de4', '#c34ce4','#4ce480', '#f480a8','#8175ac','#5424ff','#4d9466'],
        chart: {
          height: 350,
          type: "bar",
        },
        legend: {
            position: 'top'
        },
        plotOptions: {
          bar: {
            distributed: true,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(1)+"%";
          },
          offsetY: 10,
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        xaxis: {
          categories: legends,
          
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val.toFixed(1)+"%";
            },
          },
        },
        stroke: {
            show: true,
            width: 5,
            colors: ['transparent']
        },
      }
  }