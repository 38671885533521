import styled from "styled-components";

export const MapContainer = styled.div`
  flex: 1;
  border-right: 1px solid #fff;
`;
export const Flex = styled.div`
  width: calc(100vw - 50px);
  height: 100vh;
  display: flex;
  justify-content: flex-end;
`;

export const Sidebar = styled.div`
  width: 334px;
  min-width: 334px;
  min-height: 500px;
  max-height: calc(100vh - 50px);
  margin-top: 50px;
  background-color: #eeeeee;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const OperationBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
`;
export const OperationDot = styled.div`
  width: 10px;
  height: 10px;
  background: ${(props) => props.color || "black"};
  margin-right: 10px;
  border-radius: 10px;
`;
export const OperationTitle = styled.p`
  margin: 0;
  color: #1d2329;
  font-size: 14px;
`;

export const SidebarTitle = styled.h5`
  margin: 10px 0;
  color: #7c8796;
  font-size: 14px;
`;

export const MachinesContainer = styled.div`
  overflow-y: scroll;
  width: 100%;
  max-height: 350px;
`;

export const CounterContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px 5px;
`;

export const Title = styled.h6`
  margin: 0;
  font-size: 12px;
  color: #7c8796;
`;

export const Value = styled.h1`
  margin: 0;
  color: #1d2329;
  font-weight: bolder;
  font-size: 32px;
`;

export const Vehicle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #eceff1;
  padding-right: 10px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: ${(props) => props.color || "black"};
  color: white;
  border-radius: 40px;
  margin-right: 15px;
`;

export const VehicleData = styled.div`
  display: flex;
  align-items: center;
`;

export const VehicleNumber = styled.div`
  margin: 0;
  color: #1d2329;
`;

export const VehicleIcon = styled.div``;

export const PopupContainer = styled.div`
  margin-top: 5px;
`;

export const PopupValue = styled.h3`
  color: #1d2329;
  margin: 0;
  font-size: 15px;
`;

export const PopupTitle = styled.p`
  color: #adb6c3;
  margin: 0;
  font-size: 12px;
`;

const white = "#fff";
const grey = "#e8ebef";
const softGrey = "#768191";
const deepGrey = "#1d2329";
const corTemperaturaTitle = "#939ca8";

export const SidebarHeader = styled.div`
  width: 100%;
  height: 38px;
  background-color: #f3f5f8;
  padding: 8px;
`;

export const Pin = styled.div`
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80px;
  padding-bottom: 2px;
  background: ${(props) => props.color} 0% 0% no-repeat padding-box;
  border-radius: 5px;
  font-size: 9px;
  font-weight: bold;
  color: #fff;
  box-shadow: 0px 0px 2px #0000001f;
  cursor: hover;
  &:before {
    position: absolute;
    bottom: -4px;
    left: 34px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    content: "";
    border-top: 5px solid ${(props) => props.color};
  }
  a {
    color: #fff;
    font-size: 9px;
    text-decoration: none;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 175px;
  height: auto;
  background-color: ${white};
  z-index: 9999;
`;

export const TopInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 53px;
  background-color: ${white};
  padding: 0 20px;
`;

export const NomeMotorista = styled.h5`
  color: ${softGrey};
  font-size: 0.9rem;
  font-weight: 400;
  margin-top: 3px;
`;

export const TopInfoRight = styled.div`
  align-self: center;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
`;

export const Placa = styled.p`
  color: ${deepGrey};
  font-size: 0.8rem;
`;

export const TemperaturaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${grey};
  height: 38px;
  vertical-align: middle;
`;

export const TemperaturaTitle = styled.p`
  font-size: 0.6rem;
  color: ${corTemperaturaTitle};
  margin-right: 5px;
  margin-top: 16px;
  margin-left: 20px;
  align-self: center;
  vertical-align: middle;
`;

export const Badge = styled.div`
  width: 47px;
  height: 21px;
  background-color: ${(props) => props.color || "green"};
  border-radius: 13px;
  vertical-align: middle;
  margin-left: 5px;
  margin-right: 20px;
  align-self: center;
  font-size: 0.6rem;
  color: #fff;
  padding: 4px;
  text-align: center;
`;

export const Detalhes = styled.h5`
  color: #0091e6;
  font-size: 0.6rem;
  align-self: center;
  margin-top: 8px;
  margin-right: 20px;
  vertical-align: middle;
  cursor: pointer;
`;

export const Resumo = styled.p`
  margin: 8px 0 8px 20px;
  padding: 0;
  font-size: 0.75rem;
  color: #1d2329;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const InfoItem = styled.div`
  margin: 0 20px 0 20px;
`;

export const InfoTitle = styled.h5`
  font-size: 0.65rem;
  color: #adb6c3;
`;

export const InfoSubtitle = styled.h5`
  font-size: 0.7rem;
  color: #768191;
`;

export const StyledPopup = styled.div`
  background: white;
  color: #3f618c;
  font-weight: 400;
  padding: 5px;
  border-radius: 2px;
`;
