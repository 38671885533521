import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import moment from "moment";
import api from "../../../../../../core/services/api";

//@material-ui
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

//custom components
import MaterialTable, { MTableToolbar } from "material-table";

//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";

function Table(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    hd: [],
    shifts: [],
    start_day: moment().format("YYYY-MM-DD"),
    end_day: moment().add(1, "days").format("YYYY-MM-DD"),
    shifts_list: [],
    shifts_id: null,
    shift1: false,
    shift2: false,
    shift3: false    
  });

  useEffect(() => {
    getShift();
    getData();
  }, [state.shifts_id, state.start_day]);



  async function getShift() {
    try {
      
      const data = await api.get(`shifts?active=all`);
      const shifts = [];
      data.data.data.map((item) => shifts.push(item));
      setState((prev) => ({
        ...prev,
        shifts_list: data.data.data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  async function getData() { 
    try {

      const routes = await api.get(`routes`);  
      const result = await (await api.get(`type_activity`));

      let type_actvities = result.data.data.filter(item => {
        return item.option_type_id == 1 ?? item
      })

      let type_actvities_ids = type_actvities.map(item => {
        return item.id
      })      

      let shift = ``;
      if (state.shifts_id != null) shift = `&shift=${state.shifts_id}`;
      const {data} = await api.get(`hdtime-day?start_day=${state.start_day}&end_day=${state.end_day}`+shift);
      let hd = [];

      //DADOS TABELA HD
      data.forEach((item, index) => {        

        let idx = hd.map(function(e) { return e.name; }).indexOf(item.name);
        let exist = hd.filter((item2) => {
          return item2.name == item.name;
        });
        if (exist[0]) {
          
          //final.push(item)
          hd[idx].soma_transito += item.soma_transito ? item.soma_transito : 0;
          hd[idx].soma_doca += item.soma_doca ? item.soma_doca : 0;
          hd[idx].soma_arranque += item.soma_arranque ? item.soma_arranque : 0;
          hd[idx].soma_fechamento += item.soma_fechamento ? item.soma_fechamento : 0;
          hd[idx].soma_checklist += item.soma_checklist ? item.soma_checklist : 0;
          hd[idx].soma_espera += item.soma_espera ? item.soma_espera : 0;
          hd[idx].soma_fimturno += item.soma_fimturno ? item.soma_fimturno : 0;
          hd[idx].soma_inicioturno += item.soma_inicioturno ? item.soma_inicioturno : 0;
          hd[idx].soma_refeicao += item.soma_refeicao ? item.soma_refeicao : 0;

          let soma_ocorrencia = 0;
          item.jornada.tasks.forEach((task, index) => {
            if (task.issues[0] && task.issues[0].finish_at) {
              let occurrence_total = 0;
              task.issues.forEach((item2, index) => {
                const aux = moment(task.issues[index].finish_at,"YYYY-MM-DD HH:mm:ss").diff(moment(task.issues[index].created_at, "YYYY-MM-DD HH:mm:ss"),"seconds");
                occurrence_total += aux;
              })

              soma_ocorrencia += occurrence_total;
            }
          });
          hd[idx].soma_transito -= soma_ocorrencia;
        } else {
          if (item.jornada.tasks) {
            let soma_ocorrencia = 0;
            item.jornada.tasks.forEach((task, index) => {
              if (task.issues[0] && task.issues[0].finish_at) {
                let occurrence_total = 0;
                task.issues.forEach((item2, index) => {
                  const aux = moment(task.issues[index].finish_at,"YYYY-MM-DD HH:mm:ss").diff(moment(task.issues[index].created_at, "YYYY-MM-DD HH:mm:ss"),"seconds");
                  occurrence_total += aux;
                })

                soma_ocorrencia += occurrence_total;
              }
            });           

            let router = routes.data.filter((rota) => {
              return rota.id === item.id
            })[0]            

            let soma_hd = 0
            
            router.tasks.map((task) => 
            {
              if((state.shifts_id == null) || (state.shifts_id === task.shift))
              {
                  if (type_actvities_ids.includes(task.activity_type)) 
                  {                
                    let split = task.target_time.split(':');
                    //target_time em minutos
                    let target_time = parseFloat(split[1]) + parseFloat(split[0]) * 60
                    let transit_time = parseFloat(task.transit_time != null ? task.transit_time : 0)
                    soma_hd += target_time + transit_time                
                  }
              }
            })
          

            let firstobj = {
              name: item.name,
              shift: item.shift,
              soma_transito: item.soma_transito ? item.soma_transito - soma_ocorrencia : 0,
              soma_doca: item.soma_doca ? item.soma_doca : 0,
              soma_arranque: item.soma_arranque ? item.soma_arranque : 0,
              soma_fechamento: item.soma_fechamento ? item.soma_fechamento : 0,
              soma_checklist: item.soma_checklist ? item.soma_checklist : 0,
              soma_espera: item.soma_espera ? item.soma_espera : 0,
              soma_fimturno: item.soma_fimturno ? item.soma_fimturno : 0,
              soma_inicioturno: item.soma_inicioturno ? item.soma_inicioturno : 0,
              soma_refeicao: item.soma_refeicao ? item.soma_refeicao : 0,
              soma_hd : soma_hd ? soma_hd * 60 : 0,
              soma_hd_h: soma_hd ? hhmmss(soma_hd * 60) : 0,
              jornada: item.jornada,
            };
            hd.push(firstobj);
          }

          
        }



//DEBUG CONSOLE DE CALCULO DE HORA DIRETA DAS ATIVIDADES
 /*   if(item.name == "ROTA 05"){
          console.log('Debugando rota 05: ', item)
          let hd = 0;
          item.jornada.tasks.forEach((item, index) => {
           
            if(item.activity_type == 6 || item.activity_type == 11 || item.activity_type == 12){
              console.log(item.title, 'Duração: ',  moment.utc(moment(item.end_real, "YYYY-MM-DD HH:mm:ss").diff(item.start_real, "YYYY-MM-DD HH:mm:ss")).format("HH:mm:ss"))
              let atv = moment.utc(moment(item.end_real, "YYYY-MM-DD HH:mm:ss").diff(item.start_real, "YYYY-MM-DD HH:mm:ss")).format("HH:mm:ss");
              let duration = moment.duration(atv, "HH:mm:ss").asSeconds();
              hd+= duration;    
            }
            if (item.issues[0] && item.issues[0].finish_at) {
              let occurrence_total = 0;
              item.issues.forEach((item2, index) => {
                const aux = moment(item.issues[index].finish_at,"YYYY-MM-DD HH:mm:ss").diff(moment(item.issues[index].created_at, "YYYY-MM-DD HH:mm:ss"),"seconds");
                occurrence_total += aux;
              })

              //const aux = moment(item.issues[0].finish_at,"YYYY-MM-DD HH:mm:ss").diff(moment(item.issues[0].created_at, "YYYY-MM-DD HH:mm:ss"),"seconds");
              let duration = moment.utc(moment(item.transit_time_end, "YYYY-MM-DD HH:mm:ss").diff(item.transit_time_start, "YYYY-MM-DD HH:mm:ss")).format("HH:mm:ss")
              let final = moment(duration, 'HH:mm:ss').subtract(occurrence_total, 'seconds').format("HH:mm:ss");
              let ocorrencia = moment.duration(final, "HH:mm:ss").asSeconds();
              hd+= ocorrencia;
              console.log('TRANSITO OCORRÊNCIA - ',item.title, 'Duração total: ', duration, 'Menos tempo de ocorrencia: ', final)
            } else {
              console.log('TRANSITO - ',item.title, moment.utc(moment(item.transit_time_end, "YYYY-MM-DD HH:mm:ss").diff(item.transit_time_start, "YYYY-MM-DD HH:mm:ss")).format("HH:mm:ss"))
              let transit = moment.utc(moment(item.transit_time_end, "YYYY-MM-DD HH:mm:ss").diff(item.transit_time_start, "YYYY-MM-DD HH:mm:ss")).format("HH:mm:ss");
              let transit_seconds = moment.duration(transit, "HH:mm:ss").asSeconds();
              hd+= transit_seconds;
            }
           
           
          })
          console.log('HD Rota 5: ', hd, hhmmss(hd))
        } */
      }); 

      
     
      setState((prev) => ({
        ...prev,
        hd: hd || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }
  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleDate = (date) => {
    setState((prev) => ({
      ...prev,
      start_day: moment(date).format("YYYY-MM-DD"),
      end_day: moment(date).add(1, "days").format("YYYY-MM-DD"),
    }));
  };

  function pad(num) {
    return ("0" + num).slice(-2);
  }
  function hhmmss(secs) {
    var minutes = Math.floor(secs / 60);
    var secss = secs - (minutes * 60);
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return `${pad(hours)}:${pad(minutes)}:${pad(secss)}`;
    // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
  }

  function sumHD(item) {
    let arranque = item.soma_arranque ? item.soma_arranque : 0;
    let doca = item.soma_doca ? item.soma_doca : 0;
    let transito = item.soma_transito ? item.soma_transito : 0;
    let fechamento = item.soma_fechamento ? item.soma_fechamento : 0;
    let ocorrencia = item.soma_ocorrencia ? item.soma_ocorrencia : 0;
    let seconds = arranque + doca + transito + fechamento - ocorrencia;
    return hhmmss(seconds);
  }

  function sumHDTeste(item) {
    let arranque = item.soma_arranque ? item.soma_arranque : 0;
    let doca = item.soma_doca ? item.soma_doca : 0;
    let transito = item.soma_transito ? item.soma_transito : 0;
    let fechamento = item.soma_fechamento ? item.soma_fechamento : 0;
    let ocorrencia = item.soma_ocorrencia ? item.soma_ocorrencia : 0;
    let seconds = arranque + doca + transito + fechamento - (ocorrencia*2);
    return seconds;
  }
  function percentHD(item) {    
    let arranque = item.soma_arranque ? item.soma_arranque : 0;
    let doca = item.soma_doca ? item.soma_doca : 0;
    let transito = item.soma_transito ? item.soma_transito : 0;
    let fechamento = item.soma_fechamento ? item.soma_fechamento : 0;
    let ocorrencia = item.soma_ocorrencia ? item.soma_ocorrencia : 0;
    let seconds = arranque + doca + transito + fechamento - ocorrencia;
    // let aux = 64740;
    // if (state.shifts_id == 10) aux = 26160;
    // if (state.shifts_id == 16) aux = 24780;
    // if (state.shifts_id == 12) aux = 13800;
    let percent = (seconds * 85) / item.soma_hd;
    return percent.toFixed(0) + "%";
  }
  function checkHDMeta(item) {
    return item.soma_hd_h
/*
    if (state.shifts_id == null) {
      return "17:59";
    } else if (state.shifts_id == 10) {
      return "07:16";
    } else if (state.shifts_id == 16) {
      return "06:53";
    } else if (state.shifts_id == 12) {
      return "03:50";
    } else {
      return "07:13";
    }
  */
  }
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between" alignItems="center" spacing={3}>
          <Grid item lg={8} md={8} sm={8} xs={8}>
            <Typography>Hora Direta</Typography>
          </Grid>
          <Grid item lg={2} sm={2} md={2} xs={2}>
            <div style={{ marginTop: 12, marginBottom: 12 }}>
              <Select
                MenuProps={{ disableScrollLock: true }}
                fullWidth
                variant="outlined"
                displayEmpty
                value={state.shifts_id}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    shifts_id: e.target.value,
                  }))
                }
              >
                <MenuItem key="all" value={null}>
                  Todos os Turnos
                </MenuItem>
                {state.shifts_list.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.resource.work_shift}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          </Grid>
          <Grid item lg={2} sm={2} md={2} xs={2}>
            <div style={{ marginTop: 10, marginBottom: 0 }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
                <ThemeProvider theme={fcaTheme}>
                  <KeyboardDatePicker
                    label="Filtro de Data"
                    disableFuture={true}
                    inputVariant="outlined"
                    disableToolbar
                    clearable="true"
                    autoOk={true}
                    style={{ paddingBottom: 12 }}
                    format="dd/MM/yyyy"
                    variant="inline"
                    value={moment(state.start_day, "YYYY-MM-DD")}
                    onChange={handleDate}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </div>
          </Grid>
        </Grid>

        <MaterialTable
          columns={[
            {
              title: "Rota",
              field: "name",
              cellStyle: { paddingLeft: 24, textAlign: "left" },
              headerStyle: {
                borderBottom: "#fff",
                borderRadius: "8px 0px 0px 0px",
              },
            },
            {
              title: "HD Real",
              field: "realizado",
              cellStyle: { paddingLeft: 24, textAlign: "left" },
              headerStyle: {
                borderBottom: "#fff",
                borderRadius: "8px 0px 0px 0px",
              },
              render: (rowData) => sumHD(rowData),
            },
            {
              title: "HD Meta",
              field: "programado_day",
              cellStyle: { paddingLeft: 24, textAlign: "left" },
              headerStyle: {
                borderBottom: "#fff",
                borderRadius: "8px 0px 0px 0px",
              },
              render: (rowData) => checkHDMeta(rowData),
            },
            {
              title: "% HD",
              field: "route_name",
              cellStyle: { paddingLeft: 24, textAlign: "left" },
              headerStyle: {
                borderBottom: "#fff",
                borderRadius: "8px 0px 0px 0px",
              },
              render: (rowData) => percentHD(rowData),
            },
          ]}
          data={state.hd}
          options={{
            pageSize: 10,
            actionsColumnIndex: -1,
            search: false,
            showTitle: false,
            tableLayout: "auto",
            headerStyle: {
              paddingLeft: 24,
              //textAlign: "left",
              backgroundColor: "#fff",
              color: "#777",
              border: "0px solid #777",
            },
            rowStyle: (x) => {
              if (x.tableData.id === 0) {
                return { borderTop: "2px solid rgb(212, 212, 212)" };
              }
            },
          }}
          components={{
            Toolbar: (props) => (
              <div style={{ display: "none" }}>
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          style={{
            boxShadow: "0px 0px #fff",
          }}
        />
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
}

export default Table;
