import styled from 'styled-components';

export const FormConditional = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
`
export const Conditional = styled.div`
    min-width: ${props=> props.minWidth || '100px'};
    padding: 5px;
    flex:1;
`
export const ConditionalClose = styled.div`
    width: 40px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const ConditionalAdd = styled.div`
    width: 100%;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
`


export const FormLine = styled.div`
margin: 12px 0;
`

export const FormMessage = styled.div`
    width: 100%;
    padding: 5px;
    flex:1;
`