export default function(cols) {
  return {
            colors: ["#ffcd04", "#26a0fc", "#b2bec7"],
            chart: {
              type: 'donut',
              width: '100%',
              height: 200,
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      show: true,
                    },
                    value: {
                      show: true,
                      formatter: function (val, opts) {
                        return parseFloat(val).toFixed(1)+"%"
                    },
                    }
                  }
                }
              }
            },
            labels: ["OTD", "Imp. DHL", "Imp. Polo"],
            dataLabels: {
              enabled: true,
              formatter: function (val, opts) {
                return val.toFixed(1)+"%"
            },
            
            },
            tooltip: {
              enabled: false,
            },
            legend: {
              position: 'bottom',
   
            },
      
    }
}