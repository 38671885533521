import React, { useState, useEffect } from "react";
import api from "../../../../../../core/services/api";
import { useStyles } from "./styles";

//custom components
import GraphCard from "./GraphCard";

//material ui
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";

function LossTotalPercent(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    series: [
      {
        data: [20, 30, 40, 35, 66],
      },
    ],
    legends: [],
  });
  useEffect(() => {
    getData();
  }, [props.start_day, props.end_day]);

  async function getData() {
    try {
      /*const data = await api.get(
                `/sla-saturation?start_at=${props.start_day}&end_at=${props.end_day}`
              );*/
      const data = await api.get(
        `sla-otd-type?start_at=${props.start_day}&end_at=${props.end_day}`
      );
      let saturation = data.data.data.data || [];
      let legends = [];
      let series = [
        {
          name: "Perdas por Total por Tipo",
          data: [],
        },
      ];
      saturation.forEach((item, index) => {
        legends.push(item.route);
        series[0].data.push(parseFloat(item.opportunity));
      });

      setState((prev) => ({
        ...prev,
        series: series,
        legends: legends,
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          % Perdas por Total por Tipo
        </Typography>
        <GraphCard series={state.series} legends={state.legends} />
      </CardContent>
    </Card>
  );
}

export default LossTotalPercent;
