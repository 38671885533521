import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";

//styles
import { useStyles } from "./styles";

function PopupCounters(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [id, setId] = React.useState(props.counter);
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const handleClick = (popper) => (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    {
      /*  DESCOMENTE PARA ABRIR POPPER COUNTER   setOpen((prev) => id !== popper || !prev); */
    }
    setOpen(false);
  };

  function capitalize(string) {
    return string[0].toUpperCase() + string.slice(1);
  }
  return (
    <div>
      <Button
        onClick={handleClick(props.counter)}
        style={{
          backgroundColor: props.selectColor(props.counter),
          color: "#fff",
          padding: "6px 12px",
          borderRadius: "20px",
          marginLeft: 10,
        }}
      >
        {" "}
        {capitalize(props.counter)}: {props.count}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorEl}
        id={props.counter}
        style={{ paddingTop: 12 }}
      >
        <Paper className={classes.paper}>
          {props.data
            .filter((carro) => carro.counter === props.counter)
            .map((carro) => {
              return (
                <div
                  className={classes.pin}
                  style={{ backgroundColor: props.selectColor(props.counter) }}
                >
                  {carro.board}
                </div>
              );
            })}
        </Paper>
      </Popper>
    </div>
  );
}

export default PopupCounters;
