import React, { Component } from 'react';
import { TopBar, Logo } from './styles';
import Menu from '../Menu';
import ferreroLogo from '../../assets/logo_ferrero.png';
import trackageLogo from '../../assets/logo-trackage-vertical.svg'

// import { Container } from './styles';

function Main({ children }) {
  const usuario = JSON.parse(localStorage.getItem("usuario"));
  const logo = usuario && usuario.system_client_id === 1 ? ferreroLogo : trackageLogo;

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Menu />
      <div style={{ width: '96%', marginLeft: '50px' }}>
        <TopBar>
          <Logo src={logo} />
        </TopBar>
        <div>{children}</div>
      </div>
    </div>
  );
}

export default Main;
