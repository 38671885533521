import React, { useEffect, useState } from "react";
import api from '../../../../../core/services/api'
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";


function CreateForm(props) {
  const [state, setState] = useState({
    error: false,
    disabled: false,
    active: true
  });

  const [name, setName] = useState("");

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setState({
      disabled: false,
      error: false
    })
    setName("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  async function handleSubmit() {
    setState((prev) => ({
      ...prev,
      disabled: true
    }))
    const location = {
      name: name
    }
    try {
      await api.post(`place`, location);
      props.getData();
    } catch (error) {
      console.warn(error)
    }
    console.log('Local Criado', location)
    setOpen(false)

  }

  return (
    <div>
      <AddIcon onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm" >
        <DialogTitle>Cadastrar novo Local</DialogTitle>
        <DialogContent>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
              id="name"
              name="name"
              label="Nome do Local"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />{" "}
          </div>
        </DialogContent>
        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor: state.disabled ? "" : "#5ed237", color: state.disabled ? "" : "#fff" }}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateForm;
