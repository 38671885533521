import React from 'react';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles({
    table: {
      minWidth: 800,
    },
  });

  
export default function ViewForm(props) {
    const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function getShift(id){
      let shift = props.shifts.filter((item) => {return id == item.id})
      return shift[0] ? shift[0].resource.work_shift : "-"
  }

  function getLocation(id){
    let location = props.locations.filter((item) => {return id == item.id})
    return location[0] ? location[0].name : "-"
}

function pad(num) {
  return ("0"+num).slice(-2);
}
function hhmmss(minutes) {
//var minutes = Math.floor(secs / 60);
var secs = (minutes*60)%60;
var hours = Math.floor(minutes/60)
minutes = minutes%60;
return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
// return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
}

  return (
    <div>
      <IconButton aria-label="delete" onClick={handleClickOpen}>
        <VisibilityIcon style={{color: '#26a0fc'}} />
      </IconButton>
      <Dialog
        fullWidth={true}
        maxWidth='lg'
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.route.name}</DialogTitle>
        <DialogContent>
        <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Título</TableCell>
            <TableCell align="right">Turno</TableCell>
            <TableCell align="right">Início</TableCell>
            <TableCell align="right">Fim</TableCell>
            <TableCell align="right">Duração</TableCell>
            <TableCell align="right">Local</TableCell>
            <TableCell align="right">Destino</TableCell>
            <TableCell align="right">Observação</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
           {props.route.tasks.map((row) => (
             <>
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.title}
              </TableCell>
              <TableCell align="right">{getShift(row.shift)}</TableCell>
              <TableCell align="right">{row.start_prevision}</TableCell>
              <TableCell align="right">{row.end_prevision}</TableCell>
              <TableCell align="right">{row.target_time}</TableCell>
              <TableCell align="right">{getLocation(row.local)}</TableCell>
              <TableCell align="right">{getLocation(row.destiny)}</TableCell>
              <TableCell align="right">{row.obs}</TableCell>
              
            </TableRow>
            {!row.end_task ? (
               <TableRow key={row.id}>
               <TableCell component="th" scope="row">Trânsito</TableCell>
               <TableCell align="right"></TableCell>
               <TableCell align="right">{row.transit_time ? row.end_prevision : "-"}</TableCell>
               <TableCell align="right">{row.transit_time ? moment(row.end_prevision, "HH:mm:ss").add(row.transit_time, 'minutes').format("HH:mm:ss") : "-"}</TableCell>
               <TableCell align="right">{row.transit_time ? moment(row.transit_time, "mm").format("HH:mm:ss") : "-"}</TableCell>
               <TableCell align="right"></TableCell>
               <TableCell align="right"></TableCell>
               <TableCell align="right"></TableCell>
             </TableRow>
            ) : ''}
           
            </>
          ))} 
        </TableBody>
      </Table>
    </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
           Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}