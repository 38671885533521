import React, { Component } from "react";
import api from "../../../../core/services/api";
import moment from "moment";
import { easeCubic } from 'd3-ease';

// Custom Components
import Main from "../../components/Main";
import Pin from "../Map/Pin";

// @material-ui/core
import Fab from "@material-ui/core/Fab";

// @material-ui/icons
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import Cold from "@material-ui/icons/AcUnit";
import Hot from "@material-ui/icons/Whatshot";
import Room from "@material-ui/icons/Room";
import Speed from "@material-ui/icons/Speed";

import RouteColumn from "../../components/RouteColumn";

// Styled Components
import {
  Container,
  LeftSide,
  Item,
  MapContainer,
  RightSide,
  Placa,
  Circle,
  GreyItem,
  Text,
  Title,
  TemperaturaContainer,
  Temperature,
  TemperatureValue,
  RoundedButton,
  TemperaturaAtual,
  Badge,
  BadgeTitle
} from "./styles";

import { Marker } from "react-map-gl";
import MapGL, { FlyToInterpolator } from "react-map-gl";
import { Flex } from "../Map/styles";

export default class Routes extends Component {
  state = {
    viewport: {
      width: window.innerWidth * 0.7,
      height: window.innerHeight,
      latitude: -23.4439,
      longitude: -46.4994,
      zoom: 8
    },
    nome_motorista: "",
    jornada_placa: '',
    telemetrias: [],
    formatted_address: ""
  };

  componentDidMount() {
    this.getJourney();
  }

  async getJourney() {
    const { id } = this.props.match.params;
    const {data} = await api.get(`/journey/${id}`);
    console.log(data);
    this.setState(
      {
        ...this.state,
        nome_motorista: data[0].nome_motorista,
        jornada_placa: data[0].jornada_placa,
        telemetrias: data[0].telemetrias,
        viewport: {
          ...this.state.viewport,
          latitude: data[0].telemetrias.slice(-1)[0].lat,
          longitude: data[0].telemetrias.slice(-1)[0].lon
        }
      },
      () => {
        const lat = this.state.telemetrias.slice(-1)[0].lat;
        const lon = this.state.telemetrias.slice(-1)[0].lon;
        this.geocodeAddress(lat, lon);
      }
    );
  }

  async geocodeAddress(lat, lon) {
    try {
      const resp = await api.get(`/geocoder?lat=${lat}&lon=${lon}`);
      this.setState({
        ...this.state,
        formatted_address: resp.data.results[0].formatted_address
      });
      console.log(resp.data);
    } catch (err) {
      console.warn(err);
    }
  }

  tempIcon(telemetrias) {
    if(telemetrias.length === 0) return null; 
    let {temperature} = telemetrias.slice(-1)[0]
  
      if (temperature < 12) {
        return (
          <Cold style={{fontSize: 21, color: 'white'}} />
          )
      } else if(temperature > 16) {
        return (
          <Hot style={{fontSize: 21, color: 'white'}} />
        )
      }
  }

  formataNome(nome) {
    const first = nome
      .split(" ")
      .slice(0, 1)
      .join(" ");
    const last = nome
      .split(" ")
      .slice(-1)
      .join(" ");
    const composed = first + " " + last;
    return composed;
  }

  filtraTemperatura(temp) {
    if (temp < 12) {
      return "#1f3a93";
    } else if (temp > 16) {
      return "#cf000f";
    } else {
      return "#5ed237";
    }
  }

  getCurrentPin = (target) => {
    
    if (this.currentPin && target !== this.currentPin)
      this.currentPin.closeModal();

    this.currentPin = target;
    return null;
  }

  _onViewportChange = viewport =>
    this.setState({
      viewport: {...this.state.viewport, ...viewport}
    });

    navigateTo = (latitude, longitude) => {
      
  };

  _goToViewport = ({longitude, latitude}) => {
    const viewport = {
      ...this.state.viewport,
      latitude: latitude,
      longitude: longitude,
      zoom: 16,
      transitionDuration: 5000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: easeCubic
    };
    this.setState({viewport});
  };

  render() {
    const token =
      "pk.eyJ1IjoiZ2FsaWxldXUiLCJhIjoiY2syNDBka3FyMDBhazNudGNrNzM2NWU3ZyJ9.hLvLb-t9Xc9h9C4vxDL8gw";
    const { telemetrias, nome_motorista, jornada_placa } = this.state;
    return (
      <Main>
        <Container>
          <LeftSide>
            <Item>
              <div style={{height: 50, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between'}}>
                <Circle>
                  <DirectionsCar style={{ color: "#fff", fontSize: 18 }} />
                </Circle>
                <Placa style={{color: 'darkgrey'}}>{jornada_placa}</Placa>
              </div>
            </Item>
            <GreyItem>
              <Text>{nome_motorista ? this.formataNome(nome_motorista): null}</Text>
            </GreyItem>
            <Item dir="column">
              <Title>ÚLT. ATUALIZAÇÃO</Title>
              <Text>
                {moment(
                  telemetrias.slice(-1).creation
                ).format("DD/MM/YYYY HH:mm")}
                {telemetrias.slice(-1).creation}
              </Text>
            </Item>
            <TemperaturaContainer style={{backgroundColor: telemetrias.length === 0 ? null : this.filtraTemperatura(telemetrias[0].temperature)}}>
              <TemperaturaAtual>TEMPERATURA ATUAL</TemperaturaAtual>
              <Temperature >
                <Room
                  style={{ fontSize: "1.8rem", color: "#fff", marginTop: 10 }}
                />
                <TemperatureValue>
                  {telemetrias.length === 0 ? null : telemetrias[0].temperature}°
                </TemperatureValue>
              </Temperature>
              {/* <RoundedButton>VER HISTÓRICO</RoundedButton> */}
              {this.tempIcon(telemetrias)}
            </TemperaturaContainer>
            {/* <Item
              dir="column"
              style={{
                borderBottom: "1px solid #f3f5f8",
                padding: "20px 0 20px 0"
              }}
            >
              <Title>ENTREGAS</Title>
              <Badge>
                <Speed style={{ fontSize: 17, color: "#179ae8" }} />
                <BadgeTitle>60%</BadgeTitle>
              </Badge>
            </Item> */}
            <Item dir="column">
              <Title>ENDEREÇO ATUAL</Title>
              <Text>{this.state.formatted_address}</Text>
            </Item>
          </LeftSide>
          <MapContainer>
            {telemetrias.length === 0 ? null : (
              <MapGL onClick={() => { console.log("map click") }}
                {...this.state.viewport}
                mapboxApiAccessToken={token}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                // onViewportChange={viewport => this.setState({ viewport })}
                onViewportChange={this._onViewportChange}
              >
                {telemetrias.map(telemetria => {
                  return (
                    <Marker longitude={telemetria.lon} latitude={telemetria.lat}>
                  <Pin
                  style={{width: '80px', height: '35px', textAlign: 'center'}}
                    data={telemetria}
                    motorista={
                      nome_motorista
                      }
                    placa={jornada_placa}
                    lat={telemetria.lat}
                    lon={telemetria.lon}
                    id={telemetria.journey_id}
                    atualizacao={telemetria.creation}
                    filtraTemperatura={this.filtraTemperatura(telemetria.temperature)}
                    getCurrentPin={this.getCurrentPin}
                    goToViewport={this._goToViewport}
                  >
                      {moment(telemetria.creation).format("HH:mm DD/MM/YY ")}
                  </Pin>
                </Marker>
                  )
                })}
              </MapGL>
            )}
          </MapContainer>
          <RouteColumn navigateTo={this._goToViewport} filtraTemperatura={this.filtraTemperatura} route={this.state.telemetrias} />
        </Container>
      </Main>
    );
  }
}
