import React, { useState, useEffect } from 'react';
import api from '../../../../../core/services/api'
import moment from 'moment'
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";
import CustomSelectCheckbox from '../../../components/CustomSelectCheckbox'



//@material ui
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'

//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// pick a date util library
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";

function FilterReport({ downloadUrl, hasFleet }) {
  const [open, setOpen] = useState(false);
  const [filter1, setFilter1] = useState(0);
  const [state, setState] = useState({
    start_day: moment().format("YYYY-MM-DD"),
    end_day: moment().format("YYYY-MM-DD"),
    routes: [],
    fleets: [],
    shifts: [],
    shift1: false,
    shift2: false,
    shift3: false,
  })

  useEffect(() => {
    getData();
  }, [])

  const [route_list, setRoutelist] = useState([]);
  const [shift_list, setShiftlist] = useState([])
  const [fleet_list, setFleetlist] = useState([]);
  const handleChangeCustom = (event) => {
    if (event.target.name == "route") {
      setRoutelist(event.target.value);
    }
    if (event.target.name == "shift") {
      setShiftlist(event.target.value);
    }
    if (event.target.name == "fleet") {
      setFleetlist(event.target.value);
    }

  };

  async function getData() {
    try {
      const data = await api.get(`routes`);
      const data2 = await api.get(`shifts?active=all`);
      const data3 = await api.get(`vehicles_web?perPage=1000`);
      console.log('Testando shifts: ', data2.data.data)
      setState((prev) => ({
        ...prev,
        routes: data.data || [],
        shifts: data2.data.data || [],
        fleets: data3.data.data.map(fleet => ({ ...fleet, ['name']: fleet.board })).filter(fleet => fleet.type === 'horse') || [],
      }))
    } catch (error) {
      console.warn(error)
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStartDate = (date) => {
    setState((prev) => ({
      ...prev,
      start_day: moment(date).format("YYYY-MM-DD"),
    }));
  };

  const handleEndDate = (date) => {
    setState((prev) => ({
      ...prev,
      end_day: moment(date).format("YYYY-MM-DD"),
    }));
  };

  async function handleSubmitReport() {
    let routes = []
    let shifts = []
    let fleets = []
    state.routes.map((item) => {
      route_list.forEach((route) => {
        if (route == item.name) {
          routes.unshift(item.id)
        }
      })
    })
    state.shifts.map((item) => {
      shift_list.forEach((shift) => {
        if (shift == item.resource.work_shift) {
          shifts.unshift(item.id)
        }
      })
    })
    state.fleets.map((item) => {
      fleet_list.forEach((route) => {
        if (route == item.name) {
          fleets.unshift(item.id)
        }
      })
    })

    console.log('Baixando relatório')
    try {
      let url = hasFleet
        ? `${downloadUrl}start_at=${state.start_day}&end_at=${state.end_day}&fleets=[${fleets}]&shifts=[${shifts}]`
        : `${downloadUrl}start_at=${state.start_day}&end_at=${state.end_day}&routes=[${routes}]&shifts=[${shifts}]`;
      // url += state.check_shift ? "&check_shift=" + state.check_shift : "";
      api.get(url).then(blobFile => {
        //fileDownload(blobFile.data.data, 'teste.xlsx')
        const url = window.URL.createObjectURL(new Blob([blobFile.data.data]));
        const link = document.createElement('a');
        //converter base64 pra xlsx
        //passar xlsx pro href
        link.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + blobFile.data.data;
        link.setAttribute('download', `report-download.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

      })
    } catch (err) {
      console.warn(err);
    }
  }


  return (
    <div>
      <Button color="primary" style={{ backgroundColor: '#5ad545', color: '#fff' }} onClick={handleClickOpen}>
        Baixar Relatório
      </Button>
      <Dialog fullWidth={true} maxWidth="lg" open={open} onClose={handleClose} >
        <DialogContent>
          <Typography>Selecione os parâmetros do relatório</Typography>
          <Grid container direction="row" justify="center" alignItems="center" spacing={2} style={{ height: 120 }}>
            <Grid item sm={2} md={2} lg={2} xs={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
                <ThemeProvider theme={fcaTheme}>
                  <KeyboardDatePicker
                    label="Data Inicial"
                    disableFuture={true}
                    inputVariant="outlined"
                    disableToolbar
                    clearable="true"
                    autoOk={true}

                    format="dd/MM/yyyy"
                    variant="inline"
                    value={moment(state.start_day, "YYYY-MM-DD")}
                    onChange={handleStartDate}
                    fullWidth
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item sm={2} md={2} lg={2} xs={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
                <ThemeProvider theme={fcaTheme}>
                  <KeyboardDatePicker
                    label="Data Final"
                    disableFuture={true}
                    inputVariant="outlined"
                    disableToolbar
                    clearable="true"
                    autoOk={true}

                    format="dd/MM/yyyy"
                    variant="inline"
                    value={moment(state.end_day, "YYYY-MM-DD")}
                    onChange={handleEndDate}
                    fullWidth
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item sm={3} md={3} lg={3} xs={3}>
              <CustomSelectCheckbox
                value={shift_list}
                onChange={handleChangeCustom}
                data={state.shifts}
                list={shift_list}
                label="Turnos"
                name="shift"
              />
            </Grid>

            {hasFleet
              ? (<Grid item sm={3} md={3} lg={3} xs={3}>
                <CustomSelectCheckbox
                  value={fleet_list}
                  onChange={handleChangeCustom}
                  data={state.fleets}
                  list={fleet_list}
                  label="Frotas"
                  name="fleet"
                />
              </Grid>)
              : (<Grid item sm={3} md={3} lg={3} xs={3}>
                <CustomSelectCheckbox
                  value={route_list}
                  onChange={handleChangeCustom}
                  data={state.routes}
                  list={route_list}
                  label="Rotas"
                  name="route"
                />
              </Grid>)}

            <Grid item sm={2} md={2} lg={2} xs={2}>
              <Button style={{ padding: 14, backgroundColor: '#5ad545', color: '#fff' }} size="large" variant="contained" color="primary" onClick={handleSubmitReport} >
                Baixar Relatório
              </Button>
            </Grid>
          </Grid>
        </DialogContent>

      </Dialog>
    </div>
  );
}

export default FilterReport;