import React from "react";

//@material-ui
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { red } from "@material-ui/core/colors";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import Fab from "@material-ui/core/Fab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

//others components
import { LoadingContainer } from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 375,
    minWidth: 345,
    padding: 12,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

function Descarregamentos(props) {
  const classes = useStyles();
  let sum = (a) => a.reduce((x, y) => x + y);

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <Fab disabled style={{ backgroundColor: "#dff6d7" }}>
            <MoveToInboxIcon style={{ color: "#5ed237" }} />
          </Fab>
        }
        title="Descarregamentos"
      />

      <CardContent>
        <TableContainer>
          <Table>
            <TableBody>
              {props.data ? ( <>
               
                {props.data.data.filter(item => item.id === 2).map((item, i) => (<>
                   
                   <TableRow key={i}>
                    <TableCell
                      component="th"
                      style={{ fontSize: 24 }}
                      scope="row"
                    >
                     {item.name}
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{ fontSize: 24, fontWeight: "bold" }}
                    >
                    {item.count}
                    </TableCell>
                  </TableRow>
               </> ))} </>)
               : 
               (<LoadingContainer>
                <CircularProgress style={{ color: "#1e824c" }} />
              </LoadingContainer>)}

            
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
}

export default Descarregamentos;
