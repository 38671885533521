import React, {useState, useEffect} from 'react'
import Main from '../../components/Main'
import MaterialTable, { MTableToolbar } from "material-table";
import api from '../../../../core/services/api'
import AlertConfirmation from '../../components/AlertConfirmation'

//material-ui
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Grid from '@material-ui/core/Grid'
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Chip from '@material-ui/core/Chip'
import moment from 'moment';
//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// pick a date util library
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";

function ActiveRoutes(){
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState({});
  const [answer, setAnswer] = useState(null);
    const [state, setState] = useState({
        temporary_routes: [],
        start_day: moment().format("YYYY-MM-DD"),
        end_day: moment().add(1, 'days').format("YYYY-MM-DD"),
    })
    useEffect(() => {
      getData();
    }, [state.start_day]);
  
    async function getData() {
      const data = await api.get(`temporary-routes?start_day=${state.start_day}`);
      setState((prev) => ({
        ...prev,
        temporary_routes: data.data || [],
      }));

    }
    function handleDelete(item){
      setOpen(true);
      setItem(item);
    }

    async function deleteRoute(answer){
     
      if(answer){
        console.log('Finalizando rota')
        await api.delete(`temporary-routes/${item.journey_id}`);
        getData();
      } else {
        console.log('Não deletando')
      }
     //console.log('Deletando rota, resposta: ', answer)
     setOpen(false);
     /*  try {
        if (
          window.confirm(
            "Tem certeza que deseja finalizar a rota " + item.route_name
          )
        ) {
          //await api.delete(`temporary-routes/${item.id}`);
        }
  
        getData();
      } catch (error) {
        console.warn(error)
      } */
    }

    function checkStatus(item){
      if(item.finalizada && !item.abandonada){
        return (<Chip variant="outlined" size="small" label="Finalizado" style={{color: '#5ad545', borderColor: '#5ad545'}}/>)
      } else if (!item.finalizada && item.abandonada) {
        return (<Chip variant="outlined" size="small" label="Abandonada" style={{color: '#ec273b', borderColor: '#ec273b'}}/>)
      } else if (item.finalizada && item.abandonada){
        return (<Chip variant="outlined" size="small" label="Finalizado" style={{color: '#5ad545', borderColor: '#5ad545'}}/>)
      } else {
        return (<Chip variant="outlined" size="small" label="Em Andamento" style={{color: '#26a0fc', borderColor: '#26a0fc'}} />)
      }
       
    }

    function checkJourney(item){
      if(item.finalizada && !item.abandonada){
        return "none"
      } else if (!item.finalizada && item.abandonada) {
        return "none"
      } else if (item.finalizada && item.abandonada) {
        return "none"
      } else {
        return "block"
      }
    }
    
    const handleDate = (date) => {
      setState((prev) => ({
        ...prev,
        start_day: moment(date).format("YYYY-MM-DD"),
        end_day: moment(date).add(1, 'days').format("YYYY-MM-DD")
      }));
  };
    return(
        <Main color="#f7f7f7" title="Status Rotas">
         <Grid container  spacing={3} style={{ paddingBottom: 12 }}>
         <Grid item lg={2} sm={2} md={2} xs={2}> 
            <div style={{ marginTop: 10, marginBottom:  0}}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
                <ThemeProvider theme={fcaTheme}>
                  <KeyboardDatePicker
                    label="Filtro de Data"
                    disableFuture={true}
                    inputVariant="outlined"
                    disableToolbar
                    clearable="true"
                    autoOk={true}
                    style={{ paddingBottom: 12 }}
                    format="dd/MM/yyyy"
                    variant="inline"
                    value={moment(state.start_day, "YYYY-MM-DD")}
                    onChange={handleDate}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </div>
            </Grid>
        </Grid>
        <MaterialTable
        columns={[
         /*  {
            title: "#",
            field: "id",
            cellStyle: {fontSize: 14, paddingLeft: 24, textAlign: "left", paddingRight: 80 },
            headerStyle: {borderBottom: '#fff', borderRadius: '8px 0px 0px 0px'}
          }, */
          {
            title: "Rota",
            field: "route_name",
            cellStyle: {fontSize: 14,paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff'}
          },
          {
            title: "Turno",
            field: "turno",
            cellStyle: {fontSize: 14,paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff'}
          },
          {
            title: "Motorista",
            field: "name",
            cellStyle: {fontSize: 14,paddingLeft: 24, textAlign: "left",  width: '30%'},
            headerStyle: {borderBottom: '#fff'}
          },
          {
            title: "Atividades realizadas",
            field: "#",
            cellStyle: {fontSize: 14,paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff'},
            render: (rowData) => rowData.journey_obj.tasks ? rowData.journey_obj.tasks.length : 0
          },
          {
            title: "Status",
            field: "#",
            cellStyle: {paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff'},
            render: (rowData) => checkStatus(rowData)
          },
           {
            title: "Horário de início",
            field: "#",
            cellStyle: {fontSize: 14,paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff'},
            render: (rowData) => moment(rowData.created_at).format("DD/MM/YYYY HH:mm:ss") || "-"
          },
          {
            title: "Última atividade",
            field: "#",
            cellStyle: {fontSize: 14,paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff'},
            render: (rowData) => moment(rowData.updated_at).format("DD/MM/YYYY HH:mm:ss") || "-"
          },
          {
            field: "actions",
            title: "Finalizar Turno",
            cellStyle: {  paddingBottom: 0, paddingTop: 0, textAlign: "right", width: '10%'},
            headerStyle: {paddingRight: 20, textAlign: "right", borderRadius: '0px 8px 0px 0px'},
            render: (rowData) => (
              <>
                <FormGroup row style={{justifyContent: 'center', display: checkJourney(rowData)}}> 
                  <IconButton
                    style={{color: "#5ad545"}}
                    onClick={() => handleDelete(rowData)}
                  >
                    <CheckCircleIcon />
                  </IconButton>{" "}
                </FormGroup>
              </>
            ),
          },
        ]}
        data={state.temporary_routes}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            fontSize: 14,
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
          },
          rowStyle: (x) => {
            if (x.tableData.id === 0){
              return { borderTop: '2px solid rgb(212, 212, 212)'}
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 10,
        }}
      />
       <AlertConfirmation open={open} question={"Finalizar o turno da rota "+item.route_name+" ?"} setOpen={setOpen} setAnswer={setAnswer} action={deleteRoute} />
        </Main>
    )
}

export default ActiveRoutes;