import React, {useState, useEffect} from 'react'
import moment from 'moment'
import api from '../../../../../../core/services/api'
import Chart from "react-apexcharts";
import options from "./options";

function GraphCard(){
    const [state, setState] = useState({          
        series: [{
          name: 'Perdas em portaria por local',
          data: []
        }],
        legends: [],
    });

  
    useEffect(() => {
      getData();
    }, [])

  async function getData(){
      try {
        const data = await api.get(`loss-lobby-local`);
        let series = []
        let legends = []
        data.data.forEach((item, index) => {
          legends.push(item.location)
          series.push(item.time)
        })

          setState((prev) => ({
          ...prev,
         legends: legends,
         series:   [{
          name: 'Perdas em portaria',
          data: series
        }],
        }))  
      } catch (error) {
        console.warn(error)
      }
    } 

    return(
        <Chart
              options={options(state.legends)}
              series={state.series}
              type="bar"
              height={350}
            />
    )
}

export default GraphCard;