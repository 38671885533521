import React from "react";

//@material-ui
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import RoomIcon from "@material-ui/icons/Room";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";

//others components
import Grafico from "../Grafico";
import moment from "moment";
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 275,
    minWidth: 345,
    padding: 12, //"#1e824c",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

function CardDescarga(props) {
  function selectColor() {
    if (
      moment.duration(props.accomplished_time, "HH:mm:ss").asMinutes() <=
      moment.duration(props.goals_time).asMinutes()
    )
      return "#5ed237";
    else return "#ec273b";
  }
  function selectPinColor() {
    if (
      moment.duration(props.accomplished_time, "HH:mm:ss").asMinutes() <=
      moment.duration(props.goals_time).asMinutes() 
    )
      return "#54bd31";
    else return "#d42335";
  }
  const classes = useStyles();
 

 

  return (
    <Card className={classes.root} style={{ backgroundColor: selectColor() }}>
      <CardHeader
        style={{ color: "#fff" }}
        action={
          <Fab disabled style={{ backgroundColor: selectPinColor() }}>
            <RoomIcon style={{ color: "#fff" }} />
          </Fab>
        }
        title={props.name}
      />
      <Divider variant="middle" style={{ backgroundColor: "#fff" }} />
      <CardContent style={{ color: "#fff" }}>
        <Grid container direction="row" spacing={3}>
          <Grid item lg={6}>
            Meta
            <Typography
              style={{ fontSize: 45, fontWeight: "bold" }}
              component="p"
            >
             {moment(props.goals_time, "HH:mm:ss").format("mm") === "Invalid date" ? moment.duration(props.goals_time).asMinutes() : (moment(props.goals_time, "HH:mm:ss").format("mm")) }m
            </Typography>
          </Grid>
          <Grid item lg={6}>
            Realizado
            <Typography
              style={{ fontSize: 45, fontWeight: "bold" }}
              component="p"
            >
              {moment.duration(props.accomplished_time).asMinutes() <= 60
                ? moment(props.accomplished_time, "HH:mm:ss.SSS").format(
                    "HH:mm"
                  ) + "m"
                : moment(props.accomplished_time, "HH:mm:ss.SSS").format(
                    "HH:mm"
                  ) + "h"}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions style={{ justifyContent: "center" }}>
        <Grafico
          name={props.name}
          idcerca={props.idcerca}
          goals_time={props.goals_time}
          date={props.date}
        />
      </CardActions>
    </Card>
  );
}

export default CardDescarga;
