import React from "react";
import * as _ from 'lodash'
import CustomSelect from "../../../../components/CustomSelect";
import TextField from "@material-ui/core/TextField";
import { Conditional, FormConditional } from "./style";

const FormConditions = ({
    conditionals,
    options,
    type = 'base_conditionals',
    onChange = () => {},
    onAdd = () => {}
}) => {
    const onChangeCondition= (conditions,index,e) => {
        const _conditions = _.cloneDeep(conditions);
        return _.set(_conditions, [index,e.target.name], e.target.value);
    }
    const onRemoveCondition = (conditions,index) => {
        const _conditions = _.cloneDeep(conditions);
        return _conditions.filter((value, _index) => _index !== index)
    }
    const render = () => {
        return conditionals.map((condition, index) => (
            <FormConditional key={type + '-conditional-' + index }>
                 {/* <ConditionalClose>
                    <CloseIcon onClick={() => onChange(onRemoveCondition(conditionals,index))}/>
                 </ConditionalClose> */}
                 <Conditional>
                    <CustomSelect
                      label="Critério"
                      value={condition.field}
                      name="field"
                      data={options.map(item => ({id: item.field,name: item.label}))}
                      onChange={(e) => onChange(onChangeCondition(conditionals,index,e))}
                    />
                 </Conditional>
                 <Conditional minWidth="50px">
                    <TextField
                     name="operator"
                     label="Operador"
                     type="text"
                     value={condition.operator}
                     onChange={(e) => onChange(onChangeCondition(conditionals,index,e))}
                     fullWidth
                    />
                 </Conditional>
                 <Conditional>
                    <TextField
                      name="value"
                      label="Valor"
                      type="text"
                      value={condition.value}
                      onChange={(e) => onChange(onChangeCondition(conditionals,index,e))}
                      fullWidth
                    />
                 </Conditional>
             </FormConditional>
         ));
    }
    return (
        <>
            {render()}
            {/* <ConditionalAdd>
              <AddIcon onClick={onAdd} />
            </ConditionalAdd> */}
        </>
    )
}


export default FormConditions