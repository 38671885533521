import React, { useEffect, useState } from "react";
import api from '../../../../../core/services/api'


import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Grid from '@material-ui/core/Grid'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Input from '@material-ui/core/Input'
import Checkbox from '@material-ui/core/Checkbox'


function EditForm(props) {
  const [state, setState] = React.useState({
    error: false,
    disabled: false,
    active: true
  });

  const [name, setName] = useState("");

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setState({
      disabled: false,
      check: false,
      error: false
    })
    setName(props.occupation.name);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  async function handleSubmit() {
    setState((prev) => ({
      ...prev,
      disabled: true
    }))

    try {

      const occupation = {
        name: name
      }
      await api.put(`support_data_web/occupation/${props.occupation.id}`, occupation);
      props.getData();

    } catch (error) {
      console.warn(error)
    }

    setOpen(false)

  }
 


  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <EditIcon style={{ color: "#fcc326" }} className="icon-small" />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>Editar Função</DialogTitle>
        <DialogContent>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
              id="name"
              name="name"
              label="Nome da Função"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />{" "}
          </div>
          
        </DialogContent>
        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor: state.disabled ? "" : "#5ed237", color: state.disabled ? "" : "#fff" }}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditForm;
