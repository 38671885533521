import React from "react";
import { TopBar, Logo } from "./styles";
import Menu from "../../components/NavHeader";
import logo from "../../assets/logo.png";

// import { Container } from './styles';

function Main({ children, title }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        background: "whitesmoke",
      }}
    >
      <Menu />
      <div style={{ width: "96%", marginLeft: "50px" }}>
        <TopBar>
          <Logo src={logo} style={{ marginLeft: "24px" }} />
          {title ? (
            <h2
              style={{
                margin: 0,
                borderLeft: "0.5px solid #fffffffc",
                paddingLeft: 20,
                marginLeft: 20,
                fontWeight: "initial",
                color: "white",
              }}
            >
              {title}
            </h2>
          ) : null}
        </TopBar>
        <div>{children}</div>
      </div>
    </div>
  );
}

export default Main;
