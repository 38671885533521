import React, {useState, useEffect} from 'react'
import api from '../../../../../../core/services/api'
import moment from 'moment'
import Chart from "react-apexcharts";
import options from "./options";
import options2 from "./options2";
import options3 from "./options3";
import {useStyles} from './styles'


//material ui
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'


function GraphCard(){
    const classes = useStyles();
    const [state, setState] = useState({          
        series: [2, 5, 15, 30, 15],
        start_day: moment().day(0).format("YYYY-MM-DD"),
        end_day: moment().day(6).format("YYYY-MM-DD"),
        journeys: [],
        title: ['-','-','-'],
        series1: [],
        series2: [],
        series3: [],
        legends1: [],
        legends2: [],
        legends3: [],
    });

    useEffect(() => {
        getData();
      }, [])

    async function getData(){
        try {
          const data = await api.get(`top-occurrences?start_day=${state.start_day}&end_day=${state.end_day}`);
          let title = []
          let times = []
          data.data.forEach((item,index) => {
            //adicionando titles
            if(!title.includes(item.occurrence_name)){
              title.push(item.occurrence_name)
              times.push({
                series: [],
                legends: [],
                average: 0,
              })
            }
          })


          data.data.forEach((item, index) => {
            let index2 = title.indexOf(item.occurrence_name)
            times[index2].series.push(parseInt(item.time));
            times[index2].legends.push(item.name);
            times[index2].average += item.time;
          })
          setState((prev) => ({
            ...prev,
            title: title,
            series1: times[0].series,
            series2: times[1].series,
            series3: times[2].series,
            legends1: times[0].legends,
            legends2: times[1].legends,
            legends3: times[2].legends,
            //journeys: data.data,
            //series: [antes, depois],
            //otd: antes,
            //notd: depois
          }))
        } catch (error) {
          console.warn(error)
        }
      }


    return(
      <Grid container spacing={3}>
        <Grid md={4} lg={4} sm={4} xs={4} item>
          <Card className={classes.root} style={{minHeight: 440}}>
          <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
          <b>{state.title[0]}</b>
          </Typography>
          <Grid container justify="center">
              <Grid item style={{marginLeft: '-0px'}}><Chart options={options(state.legends1)} series={state.series1} type="donut" width={380}/></Grid>
          </Grid>
          </CardContent>
              <CardActions></CardActions>
          </Card>
        </Grid>
        <Grid md={4} lg={4} sm={4} xs={4} item>
          <Card className={classes.root} style={{minHeight: 440}}>
          <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
          <b>{state.title[1]}</b>
          </Typography>
          <Grid container justify="center"  alignItems="center">
              <Grid item style={{marginLeft: '0px'}}><Chart options={options2(state.legends2)} series={state.series2} type="donut" width={380}/></Grid>
          </Grid>
          </CardContent>
              <CardActions></CardActions>
          </Card>
        </Grid>
        <Grid md={4} lg={4} sm={4} xs={4} item>
          <Card className={classes.root} style={{minHeight: 440}}>
          <CardContent>
          <Typography className={classes.title} color="textSecondary" gutterBottom>
          <b>{state.title[2]}</b>
          </Typography>
          <Grid container justify="center">
              <Grid item style={{marginLeft: '0px'}}><Chart options={options3(state.legends3)} series={state.series3} type="donut" width={380}/></Grid>
          </Grid>
          </CardContent>
              <CardActions></CardActions>
          </Card>
        </Grid>
      </Grid>
      
        
       
        
    )
}

export default GraphCard;