import React from "react";
import { RViewer, RViewerTrigger } from 'react-viewerjs';
import Visibility from "@material-ui/icons/Visibility";

export default function viewer(props) {
    const sourceUrl = props.url;
    
    const options = {
        toolbar: {
            prev: false,
            next: false
        }
    };
    
    return (
        <RViewer options={options} imageUrls={sourceUrl}>
            <RViewerTrigger>
                <Visibility style={{ cursor: "pointer" }}/>
            </RViewerTrigger>
        </RViewer>
        )
    }