import React, {useState, useEffect} from 'react';
import {useStyles} from './styles'
import moment from 'moment';
import api from '../../../../../../core/services/api'

//@material-ui
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

//custom components
import MaterialTable, { MTableToolbar } from "material-table";
import CustomSelectCheckbox from '../../../../components/CustomSelectCheckbox';


//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";


function Table(props){
    const classes = useStyles();
    const [state, setState] = useState({
        start_day: moment().format("YYYY-MM-DD"),
        end_day: moment().add(1, 'days').format("YYYY-MM-DD"),
        effectiveness: [],
        shifts_list: [],
        shifts_id: null,
        shift1: false,
        shift2: false,
        shift3: false,
    })

    useEffect(() => {
      getData();
    }, [state.shifts_id, state.start_day])

    useEffect(() => {
      getShift()
    }, [])
  
    async function getShift() {
      try {
        const data = await api.get(`shifts?active=all`);
        const shifts = []
        data.data.data.map((item) => shifts.push(item))
        setState((prev) => ({
          ...prev,
          shifts_list: data.data.data || []
        }))
      } catch (error) {
        console.warn(error)
      }
    }
    
    async function getData(){
      try {
        let shift = ``;
        if(state.shifts_id != null) shift = `&shift=${state.shifts_id}`
        const data = await api.get(`effectiveness-day?start_day=${state.start_day}&end_day=${state.end_day}`+shift);
        let efet = []
          data.data.forEach((item, index) => {
            let exist = efet.filter((item2) => {return item2.name == item.name});
            if(exist[0]){
              //final.push(item)
              exist[0].realizado += item.realizado
            } else {
              efet.push(item)
            }
          })
  

      setState((prev) => ({
        ...prev,
        effectiveness: efet || [],
      }))
      } catch (error) {
        console.warn(error)
      }
    }
    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        setState((prev) => ({
          ...prev,
          [name]: value,
        }));
      };
    const handleDate = (date) => {
      setState((prev) => ({
        ...prev,
        start_day: moment(date).format("YYYY-MM-DD"),
        end_day: moment(date).add(1, 'days').format("YYYY-MM-DD")
      }));
    };

    function checkEffectiveness(item){
      if(state.shifts_id != null){
        return ((parseInt(item.realizado)*100)/parseInt(item.programado_shift)).toFixed(0)+"%"
      } else {
        return ((parseInt(item.realizado)*100)/parseInt(item.programado_day)).toFixed(0)+"%"
      }
    }
    return (
        <Card className={classes.root}>
        <CardContent>
        <Grid container justify="space-between" alignItems="center" spacing={3}>
            <Grid item lg={8} md={8} sm={8} xs={8}><Typography>Efetividade</Typography></Grid>
            <Grid item lg={2} sm={2} md={2} xs={2}> 
            <div style={{ marginTop: 12, marginBottom: 12 }}>
            <Select
                MenuProps={{ disableScrollLock: true }}
                 fullWidth
                 variant="outlined"
                  displayEmpty
                  value={state.shifts_id}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                     shifts_id: e.target.value,
                    }))
                  } 
                >
                  <MenuItem key="all" value={null}>
                    Todos os Turnos
                  </MenuItem>
                  {state.shifts_list.map((item) => {return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.resource.work_shift}
                    </MenuItem>
                  )})}
                </Select>
         
            </div>
            </Grid>
            <Grid item lg={2} sm={2} md={2} xs={2}> 
            <div style={{ marginTop: 10, marginBottom:  0}}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
                <ThemeProvider theme={fcaTheme}>
                  <KeyboardDatePicker
                    label="Filtro de Data"
                    disableFuture={true}
                    inputVariant="outlined"
                    disableToolbar
                    clearable="true"
                    autoOk={true}
                    style={{ paddingBottom: 12 }}
                    format="dd/MM/yyyy"
                    variant="inline"
                    value={moment(state.start_day, "YYYY-MM-DD")}
                    onChange={handleDate}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </div>
            </Grid>
        </Grid>
        
        <MaterialTable
        columns={[
          {
            title: "Rota",
            field: "name",
            cellStyle: {paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff',  borderRadius: '8px 0px 0px 0px'}
          },
          {
            title: "Realizadas",
            field: "realizado",
            cellStyle: {paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff',  borderRadius: '8px 0px 0px 0px'}
          },
          {
            title: "Programadas",
            field: "programado_day",
            cellStyle: {paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff',  borderRadius: '8px 0px 0px 0px'},
            render: (rowData) => state.shifts_id != null ? rowData.programado_shift : rowData.programado_day
          },
          {
            title: "%Efetividade",
            field: "route_name",
            cellStyle: {paddingLeft: 24, textAlign: "left" },
            headerStyle: {borderBottom: '#fff',  borderRadius: '8px 0px 0px 0px'},
            render: (rowData) => checkEffectiveness(rowData)
          },
        ]}
        data={state.effectiveness}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
          },
          rowStyle: (x) => {
            if (x.tableData.id === 0){
              return { borderTop: '2px solid rgb(212, 212, 212)'}
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
            boxShadow: '0px 0px #fff'
          }}
      />
        </CardContent>
            <CardActions></CardActions>
        </Card>
    )
}

export default Table;

