import React, {useState, useEffect} from 'react';
import api from '../../../../../core/services/api'
//@material-ui
import Grid from '@material-ui/core/Grid'



//custom components
import Table from './Table'
import HDRoute from './HDRoute'
import HDHour from './HDHour'
import moment from 'moment';

function HDPanel(){

    return (
    <Grid container spacing={3}>
        <Grid item md={12} lg={12} sm={12} xs={12}>
        <Table  />
        </Grid>
        <Grid item md={12} lg={12} sm={12} xs={12}>
        <HDRoute  />
        </Grid>
        <Grid item md={12} lg={12} sm={12} xs={12}>
        <HDHour  />
        </Grid>
    </Grid> 
    )
}

export default HDPanel;