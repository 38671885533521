import React, { useEffect } from "react";
import api from '../../../../../core/services/api'

//Custom components
import CustomSelect from '../../../components/CustomSelect'


import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Grid from '@material-ui/core/Grid'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'


function CreateForm(props) {
  const [state, setState] = React.useState({
    error: false,
    disabled: false,
    showPassword: ''
  });

  const [name, setName] = React.useState("");
  const [carrier, setCarrier] = React.useState("");
  const [cnh, setCnh] = React.useState("");
  const [cpf, setCpf] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [active, setActive] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmpassword] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setState({
      disabled: false,
      error: false,
      showPassword: '',
      carrier_list: props.carrier_list || [],
    })
    setName("");
    setCnh("");
    setCpf("");
    setPhone("");
    setEmail("");
    setPassword("");
    setConfirmpassword("")
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {
    setState((prev) => ({
      ...prev,
      disabled: true
    }))
    if(password != confirmPassword){
      setState((prev) => ({
        ...prev,
        disabled: false,
        error: true
      }))
    } else if (password === '' && confirmPassword === '' && name === '' && cnh === '' && cpf === '' && phone === '' && email === ''){
      setState((prev) => ({
        ...prev,
        disabled: false,
        error: true
      }))
    } else {
    postDriver();
    setOpen(false)
    }
    
    
  }

  

  async function postDriver(){
    try {
      const driver = {
        name: name,
        email: email,
        password: password,
        phone: phone,
        resource: {
          cpf: cpf,
          cnh: cnh
        },
        carrier_id: state.carrier_id,
      }
      //console.log('criando motorista: ', driver)
      await api.post(`drivers`, driver);
      props.getData();
    } catch (error) {
      console.warn(error)
      
    }
  }



  return (
    <div>
      <Fab
        color="primary"
        style={{ zIndex: 9999, position: "fixed", bottom: "10%", right: "1%" }}
        onClick={handleClickOpen}
      >
        <AddIcon />
      </Fab>
      {/* 
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Nova Empilhadeira
      </Button> */}

      <Dialog
        open={open}
        onClose={handleClose}
      
      >
        <DialogTitle>Cadastar novo Motorista</DialogTitle>

        <DialogContent>
         
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                  //error={state.error ? (forklift_number ? false : true) : false}
                  id="name"
                  name="name"
                  label="Nome motorista"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                />{" "}
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <CustomSelect
                 // error={state.error}
                  label="Transportadora"
                  value={state.carrier_id}
                  name="carrier_id"
                  data={state.carrier_list}
                  onChange={(event) => handleChange(event)}
                />
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
               
               <TextField
                  //error={state.error ? (forklift_number ? false : true) : false}
                  id="cpf"
                  name="cpf"
                  label="CPF"
                  type="text"
                  value={cpf}
                  onChange={(e) => setCpf(e.target.value)}
                  style={{width: 238}}
                />
                 <TextField
                  //error={state.error ? (forklift_number ? false : true) : false}
                  id="cnh"
                  name="cnh"
                  label="CNH"
                  type="text"
                  value={cnh}
                  onChange={(e) => setCnh(e.target.value)}
                  style={{width: 238, marginLeft: 4}}
                />{" "}
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                  //error={state.error ? (forklift_number ? false : true) : false}
                  id="phone"
                  name="phone"
                  label="Telefone"
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  fullWidth
                />{" "}
              </div>
              <div style={{ marginTop: 12, marginBottom: 12 }}>
                <TextField
                  //error={state.error ? (forklift_number ? false : true) : false}
                  id="email"
                  name="email"
                  label="E-mail"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                />{" "}
              </div>
              
              <div style={{ marginTop: 12, marginBottom: 12 }}>
              <Input
                  error={state.error ? true : false}
                  id="password"
                  name="password"
                  label="Senha"
                  type={state.showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />{" "}
                <Input
                  error={state.error ? true : false}
                  id="password2"
                  name="password2"
                  label="Confirme sua Senha"
                  type={state.showPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={(e) => setConfirmpassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />{" "}
               </div>
        </DialogContent>

        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor:state.disabled ? "" : "#5ed237", color:state.disabled ? "" : "#fff" }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateForm;
