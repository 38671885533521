import React, { useState, useEffect } from "react";
import api from "../../../../../core/services/api";
//@material-ui
import Grid from "@material-ui/core/Grid";

//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";

//custom components
import moment from "moment";
import Saturation from "./Saturation";
import OTD from "./OTD";
import Effectiveness from "./Effectiveness";
import TransitTime from "./TransitTime";
import TimeDock from "./TimeDock";

function ReportPanel(props) {

  return (
    <Grid container spacing={3}>
     
      <Grid item md={6} lg={3} sm={12} xs={12}>
        <Saturation start_day={props.start_day} end_day={props.end_day} routes={props.routes} />
      </Grid>
      <Grid item md={6} lg={3} sm={12} xs={12}>
        <OTD start_day={props.start_day} end_day={props.end_day} routes={props.routes}/>
      </Grid>
      <Grid item md={6} lg={3} sm={12} xs={12}>
        <Effectiveness start_day={props.start_day} end_day={props.end_day} routes={props.routes}/>
      </Grid>
      <Grid item md={6} lg={3} sm={12} xs={12}>
        <TransitTime start_day={props.start_day} end_day={props.end_day} routes={props.routes}/>
      </Grid>
      <Grid item md={12} lg={12} sm={12} xs={12}>
        <TimeDock start_day={props.start_day} end_day={props.end_day} routes={props.routes}/>
      </Grid>
    </Grid>
  );
}

export default ReportPanel;
