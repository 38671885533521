import styled from 'styled-components';

const dhlCor = '#FFBE00';

export const TopBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  background: ${dhlCor};
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #c1d3ec;
  position: fixed;
  z-index: 9999;
`;

export const Logo = styled.img`
  width: 75px;
  margin-left: 22px;
`;
