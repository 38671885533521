import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  height: 40px;
  background-color: #fff;
  width: 16%;
  min-height: 100vh;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: ${props => (props.dir ? props.dir : "row")};
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  min-height: 58px;
  padding: 15px 0 15px 0;
`;

export const GreyItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  height: 58px;
  background-color: #f3f5f8;
`;

export const Text = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 0.7rem;
  color: #768191;
`;

export const Title = styled.p`
  margin-bottom: 0;
  font-family: Roboto, sans-serif;
  font-size: 0.5rem;
  text-align: center;
  color: #adb6c3;
`;

export const Circle = styled.div`
  justify-content: flex;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  width: 26px;
  height: 26px;
  background-color: #6d7982;
  border-radius: 50%;
  margin: 0 5px;
`;

export const Placa = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 0.8rem;
  color: #1d2329;
  vertical-align: middle;
  margin: 0 5px;
`;

export const MapContainer = styled.div`
  min-height: 100vh;
  background-color: #242424;
`;

export const RightSide = styled.div`
  width: 24%;
  min-height: 100vh;
  background-color: #fff;
`;

export const TemperaturaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 141px;
`;

export const Temperature = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const TemperatureValue = styled.h5`
  font-family: "Roboto";
  color: #fff;
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
`;

export const RoundedButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 97px;
    font-size: .6em;
    color: #fff;
    text-align: center;
    vertical-align:middle;
    border-radius: 20px;
    height: 27px;
    border: 1px solid #fff;
`;

export const TemperaturaAtual = styled.p`
  color: #fff;
  font-size: 0.8em;
  width: 100px;
  text-align: center;
  margin-bottom: 0;
`;

export const BadgeTitle = styled.h5`
  color: #179ae8;
  font-size: 0.9em;
  margin-bottom: 0;
  margin-left: 20px;
`;

export const Badge = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 97px;
  height: 27px;
  border-radius: 20px;
  color: #768191;
  background-color: #f3f5f8;
`;
