import moment from 'moment'
import React from 'react'
export default function(legends) {
    return {
        colors: ["#2655d8","#ff7053",'#90a3b4','#ec273b'],
        chart: {
          height: 350,
          width: '100%',
          type: "bar",
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'left',
        },
        dataLabels: {
          enabled: true,
          background: {
            enabled: true,
            foreColor:  '#777',
            borderColor: '#777', 
          },
          style: {
            fontSize: '8px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: ['#fff']
        },
          enabledOnSeries: [0,1,2,3],
          //offsetY: 10,
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
              return value.toFixed(0)+"%"
          },

        },
        stroke: {
          curve: 'straight',
          width: 2
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: legends,
          
        },
        yaxis: [{
          labels: {  
            show: false,
            formatter: function (value) {
              return value.toFixed(0)+"%" 
            }
          },
        },
       
      ],
      }
  }