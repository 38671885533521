import styled from "styled-components";
import { createMuiTheme } from "@material-ui/core";

export const Container = styled.div`
  margin-top: 40px;
  padding: 30px;
`;

export const fcaTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#5ad545"
    }
  }
});
