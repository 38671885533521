import React, { Component } from "react";
import api from "../../../../core/services/api";
import moment from "moment";
import { Link } from "react-router-dom";

// Styled Components
import {
  Pin,
  InfoContainer,
  TopInfo,
  NomeMotorista,
  TopInfoRight,
  Placa,
  TemperaturaContainer,
  TemperaturaTitle,
  Badge,
  Detalhes,
  Info,
  InfoItem,
  InfoTitle,
  IconContainer,
  InfoSubtitle,
  Resumo
} from "./styles";

// @material-ui icons
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import Room from "@material-ui/icons/Room";

export class PinMap extends Component {
  state = { opened: false, formatted_address: "" };

  componentDidMount() {}

  async geocodeAddress(lat, lon) {
    try {
      const resp = await api.get(`/geocoder?lat=${lat}&lon=${lon}`);
      this.setState({
        ...this.state,
        formatted_address: resp.data.results[0].formatted_address
      });
      console.log(resp.data);
    } catch (err) {
      console.warn(err);
    }
  }

  closeModal() {
    this.setState({ opened: false });
  }

  async openModal() {

    if (this.props.getCurrentPin)
      this.props.getCurrentPin(this);

    if (this.props.goToViewport)
      this.props.goToViewport({latitude:this.props.lat, longitude:this.props.lon});
      
    await this.setState({ opened: !this.state.opened }, () =>
      this.geocodeAddress(this.props.lat, this.props.lon)
    );
  }
  
  render() {
    return (
      <>
        <Pin
          color={this.props.filtraTemperatura}
          onClick={() => this.openModal()}
        >
          <IconContainer style={{borderColor: this.props.filtraTemperatura, background: 'white'}}>
            <DirectionsCar style={{fontSize: 17, color: this.props.filtraTemperatura}}/>
          </IconContainer>
          <span>{this.props.placa}</span>
          {this.props.children}
        </Pin>
        {this.state.opened ? (
          <InfoContainer>
            <TopInfo>
              <NomeMotorista>{this.props.motorista}</NomeMotorista>
              <TopInfoRight>
                <DirectionsCar
                  style={{
                    fontSize: 17,
                    // margingRight: 8,
                    // marginBottom: 5
                  }}
                />
                <Placa>{this.props.placa}</Placa>
              </TopInfoRight>
            </TopInfo>
            <TemperaturaContainer>
              <TemperaturaTitle>TEMPERATURA ATUAL</TemperaturaTitle>
              <Badge>
                <Room style={{ fontSize: 12, marginBottom: 2 }} />{" "}
                {this.props.data.temperature}º C
              </Badge>
              <Link to={`/routes/${this.props.id}`}>
                <Detalhes>VER DETALHES</Detalhes>
              </Link>
            </TemperaturaContainer>
            <Resumo>Resumo</Resumo>
            <Info>
              <InfoItem>
                <InfoTitle>ÚLT. ATUALIZAÇÃO</InfoTitle>
                <InfoSubtitle>
                  {moment(this.props.atualizacao).format("DD/MM/YYYY HH:mm")}
                </InfoSubtitle>
              </InfoItem>
              {/* <InfoItem>
                <InfoTitle>STATUS</InfoTitle>
                <InfoSubtitle></InfoSubtitle>
              </InfoItem> */}
              {/* <InfoItem>
                <InfoTitle>ENTREGAS</InfoTitle>
                <InfoSubtitle>60%</InfoSubtitle>
              </InfoItem> */}
            </Info>
            <Info>
              <InfoItem>
                <InfoTitle>ENDEREÇO ATUAL</InfoTitle>
                <InfoSubtitle>{this.state.formatted_address}</InfoSubtitle>
              </InfoItem>
            </Info>
          </InfoContainer>
        ) : null}
      </>
    );
  }
}

export default PinMap;
