export default function(legends1) {
  return {
            colors: ["#26a0fc", "#7d77ff", "#2de0c2", "#fcca26", "#e91e63"],
            chart: {
              type: 'donut',
              width: '100%',
              height: 200,
            },
            plotOptions: {
              pie: {
                size: 200,
                startAngle: 0,
                endAngle: 360,
                expandOnClick: true,
                offsetX: 0,
                offsetY: 0,
                customScale: 1,
                dataLabels: {
                    offset: 0,
                    minAngleToShowLabel: 10
                }, 
                donut: {
                  size: '65%',
                  background: 'transparent',
                },      
              }
            },
            labels: legends1,
            dataLabels: {
              enabled: true,
              formatter: function (val, opts) {
                return val.toFixed(0)+"%"
            },
          
            },
            legend: {
              show: false,
              floating: false,
              offsetX: 30,
              position: 'bottom',
              horizontalAlign: 'left',
            },
      
    }
}