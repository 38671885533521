import React, { useState, useEffect } from 'react'
import api from '../../../../core/services/api'
//custom components
import EditForm from './EditForm'
//import CardShifts from './CardShifts'
//@material-ui
import Grid from '@material-ui/core/Grid'
//import FormGroup from '@material-ui/core/FormGroup'
//import IconButton from '@material-ui/core/IconButton'
//import Paper from '@material-ui/core/Paper'
//import SaveAltIcon from '@material-ui/icons/SaveAlt'
//import { Fab } from '@material-ui/core';
//@material icons
//import DeleteIcon from '@material-ui/icons/Delete'
import Main from '../../components/Main'
import TableShifts from './TableShifts'
import CreateForm from './CreateForm'
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import AddIcon from "@material-ui/icons/Add";
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import { makeStyles } from "@material-ui/core/styles";
import Alert from '../../components/Alert'



function Shifts(props) {
  const [state, setState] = useState({
    shifts: []
  })
  useEffect(() => {
    getData()

  }, [])

  async function getData() {
    try {
      const data = await api.get(`shifts?active=all`);
      const shifts = []

      data.data.data.map((item) => shifts.push(item))
      setState((prev) => ({
        ...prev,
        shifts: shifts || []
      }))
      console.log(shifts)
    } catch (error) {
      console.warn(error)
    }
  }

  const useStyles = makeStyles({
    root: {
      transform: "translateZ(0px)",
      flexGrow: 1,
      color: '#69f0ae'
    },
    speedDial: {
      zIndex: 9999,
      position: "fixed",
      bottom: "12%",
      right: "0.2%",
      "& .MuiSpeedDial-fab": {
        backgroundColor: '#5ad545'
      },
    }
  }
  );

  async function getReport() {
    try {
      let url = `/shift_export`;
      // url += state.check_shift ? "&check_shift=" + state.check_shift : "";
      api.get(url).then(blobFile => {
          //fileDownload(blobFile.data.data, 'teste.xlsx')
           const url = window.URL.createObjectURL(new Blob([blobFile.data.data]));
          const link = document.createElement('a');
          //converter base64 pra xlsx
          //passar xlsx pro href
          link.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+blobFile.data.data;
          link.setAttribute('download', `turnos.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);  

      })
    } catch (err) {
      console.warn(err);
    }
  
  };


  const classes = useStyles();
  const [direction, setDirection] = React.useState("up");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);

  };

  return (
    <Main color="#f7f7f7" title="Turnos">
      <Grid container >

        <Grid item>
          <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={direction}
            getData={getData}
            optionType={state.optionType}
          >
            <SpeedDialAction
              key="CreateForm "
              icon={<CreateForm getData={getData} optionType={state.optionType} />}
              tooltipTitle="Adicionar Turno"
              onClick={() => handleOpen()}

            />
            <SpeedDialAction
              key="download"
              icon={<GetAppIcon />}
              tooltipTitle="Download"
              onClick={() => getReport()}

            />
            {/* <SpeedDialAction
              key="upload"
              icon={<PublishIcon />}
              tooltipTitle="Upload"
              onClick={() => handleOpen()}
            /> */}

          </SpeedDial>
        </Grid>
       <Alert open={open2} message="O relatório foi baixado com sucesso!" setOpen={() => setOpen2()} /> 

        <Grid item lg={12} md={12} sm={12} xs={12}>

          <TableShifts
            data={state.shifts}
            title="Turnos"
            //methods
            getData={() => getData()}
          />
        </Grid>
      </Grid>
    </Main>
  )
}

export default Shifts;