import React from "react";
import Container from "@material-ui/core/Container";
import { Container as Content } from "./styles";
import VerticalAlignBottom from "@material-ui/icons/VerticalAlignBottom";
import DoneIcon from "@material-ui/icons/Done";
import moment from "moment";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Main from "../../components/Main";
import api from "../../../../core/services/api";

export default class show extends React.Component {
  state = {
    historic: [],
    board: null,
    date: null,
  };

  componentDidMount() {
    this.getData();
  }

  /* setStatus(description) {
    let status = description.toString().toLowerCase();
    return status === "área de carregamento" ? "Carregado" : "Descarregado";
  }*/

  getDeslocamentoColor(cerca1, cerca2, deslocamento){

    //VARZEA - MIGUEL 
    if (cerca1 === 16){
      //SAINDO DE VARZEA
      if(cerca2 === 18){
        if (deslocamento < 90){
          return "#3ccc5b"
        } else return "#cc3c3c"
      }
    }
    //FILIAL - VARZEA
    if ( cerca1 === 15){
      //SAINDO DA FILIAL
      if(cerca2 === 16){
        if (deslocamento < 35){
          return "#3ccc5b"
        } else return "#cc3c3c"
      }
    }

    //MIGUEL - OURO BRANCO
    if (cerca1 === 19){
      //SAINDO DE MIGUEL
      if(cerca2 === 6){
        if (deslocamento < 50){
          return "#3ccc5b"
        } else return "#cc3c3c"
      }
    }

    //OURO BRANCO - VARZEA
    if (cerca1 === 6){
      //SAINDO DE OURO BRANCO
      if(cerca2 === 16){
        if (deslocamento < 50){
          return "#3ccc5b"
        } else return "#cc3c3c"
      }
    }
    return "#3ccc5b"
  }
  renderTimelineItem(item, nextitem) {
    // let status = this.setStatus(item.description);
    let time_perm = 0;
    let time_deslocamento = 0;
    let tempo_deslocamento = 0;
    const then =
      moment.utc(item.entry_day).format("DD/MM/YYYY") + " " + item.entry_time;
    const now =
      moment.utc(item.exit_day).format("DD/MM/YYYY") + " " + item.exit_time;
    const result = moment
      .utc(
        moment(now, "DD/MM/YYYY HH:mm:ss")
          .subtract(3, "hours")
          .diff(moment(then, "DD/MM/YYYY HH:mm:ss").subtract(3, "hours"))
      )
      .format("HH:mm:ss");

    const minutes = moment.duration(result).asMinutes();
    if (minutes <= 60) {
      time_perm = moment(result, "HH:mm:ss").format("mm") + " minutos";
    }
    if (minutes > 60) {
      time_perm = moment(result, "HH:mm:ss").format("HH:mm") + " horas";
    }

    if (nextitem) {
      time_deslocamento = moment
        .utc(
          moment(
            moment.utc(nextitem.entry_day).format("DD/MM/YYYY") +
              nextitem.entry_time,
            "DD/MM/YYYY HH:mm:ss"
          )
            .subtract(3, "hours")
            .diff(
              moment(
                moment.utc(item.exit_day).format("DD/MM/YYYY") + item.exit_time,
                "DD/MM/YYYY HH:mm:ss"
              ).subtract(3, "hours")
            )
        )
        .format("HH:mm:ss");

      const minutes = moment.duration(time_deslocamento).asMinutes();
      if (minutes <= 60) {
        tempo_deslocamento =
          moment(time_deslocamento, "HH:mm:ss").format("mm") + " minutos";
      } else {
        tempo_deslocamento =
          moment(time_deslocamento, "HH:mm:ss").format("HH:mm") + " horas";
      }
    }
    return (
      <>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{
            background: "rgb(30, 130, 76)",
            color: "#fff",
          }}
          contentArrowStyle={{
            borderRight: "7px solid rgb(30, 130, 76)",
          }}
          date={
            <h4
              style={{
                color: "rgb(30, 130, 76)",
              }}
            ></h4>
          }
          iconStyle={{
            background: "rgb(30, 130, 76)",
            color: "#fff",
          }}
          icon={<VerticalAlignBottom />}
        >
          <h3 className="vertical-timeline-element-title">{item.name}</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Entrada:{" "}
            {moment
              .utc(then, "DD/MM/YYYY HH:mm:ss")
              .subtract(3, "hours")
              .format("DD/MM/YYYY")}{" "}
            às{" "}
            {moment(item.entry_time, "HH:mm:ss")
              .subtract(3, "hours")

              .format("HH:mm")}
          </h4>
          <h4 className="vertical-timeline-element-subtitle">
            Saída:{" "}
            {moment
              .utc(now, "DD/MM/YYYY HH:mm:ss")
              .subtract(3, "hours")
              .format("DD/MM/YYYY")}{" "}
            às{" "}
            {moment(item.exit_time, "HH:mm:ss")
              .subtract(3, "hours")
              .format("HH:mm")}
          </h4>
          <h5 className="vertical-timeline-element-subtitle">
            Tempo de Permanência: {time_perm}{" "}
          </h5>

          <p>{item.end_cycle && "Fim De Ciclo"}</p>
        </VerticalTimelineElement>
        {nextitem ? (
         
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            iconStyle={{
              background: this.getDeslocamentoColor(item.id,nextitem.id, moment.duration(time_deslocamento).asMinutes()), //funcao de alterar cor com base na meta 
              width: 10,
              height: 10,
              padding: 10,
              margin: "25px 0px 0px -10px",
            }}
            contentStyle={{
              background: this.getDeslocamentoColor(item.id,nextitem.id, moment.duration(time_deslocamento).asMinutes()), // função pra alterar a cor
              color: "#fff",
              width: "35%",
              marginRight: 100,
              paddingRight: 0,
            }}
          >
            <h3 className="vertical-timeline-element-title">
              Tempo de deslocamento
            </h3>
            <p style={{ marginTop: 0 }}>
              De {item.name} para {nextitem.name}
            </p>
            <p style={{ marginTop: 0 }}> {tempo_deslocamento}</p>
          </VerticalTimelineElement>
        ) : (
          " "
        )}
      </>
    );
  }

  async getData() {
    const { id, date } = this.props.match.params;

    const { data } = await api.get(
      `https://flex.trackage.io/api/v1/tora_historic?id=${id}&date=${date}`
    );

    this.setState({
      ...this.state,
      historic: data || null,
      board: data[0]?.board || null,
      date: moment.utc(date, "YYYY-MM-DD").format("DD/MM/YYYY"),
    });
  }

  render() {
    const { board, date, historic } = this.state;
    const historic_filtered = historic.filter((item) => item.name != null);
    return (
      <Main
        style={{ background: "grey" }}
        title={`Linha do Tempo. ${board ? board : ""} ${date ? date : ""}`}
      >
        <Content>
          <Container>
            <VerticalTimeline layout={"2-columns"}>
              {historic_filtered.map((item, index) =>
                this.renderTimelineItem(item, historic_filtered[index + 1])
              )}
              <VerticalTimelineElement
                iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
                icon={<DoneIcon />}
              />
            </VerticalTimeline>
          </Container>
        </Content>
      </Main>
    );
  }
}
