import React, { useState, useEffect } from 'react'
import Main from '../../../components/Main'
import MaterialTable, { MTableToolbar } from "material-table";
import api from '../../../../../core/services/api'
import fileDownload from 'js-file-download'
import moment from 'moment';
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";
import CustomSelectCheckbox from '../../../components/CustomSelectCheckbox'
import FilterReport from '../../Report/FilterReport'
import TableReport from '../../Report/TableReport'

//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// pick a date util library
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";

//material-ui
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';

function ChecklistReports() {

  const [state, setState] = useState({
    start_day: moment().format("YYYY-MM-DD"),
    end_day: moment().format("YYYY-MM-DD"),
    fleets: [],
    shifts: [],
    report: [],   
  })

  useEffect(() => {
    getData();
  }, [])

  const [fleet_list, setFleetlist] = useState([]);
  const [shift_list, setShiftlist] = useState([])
  const handleChangeCustom = (event) => {
    if (event.target.name == "fleet") {
      setFleetlist(event.target.value);
    }
    if (event.target.name == "shift") {
      setShiftlist(event.target.value);
    }

  };


  async function getData() {
    try {
      const data = await api.get(`vehicles_web?perPage=1000`);
      const data2 = await api.get(`shifts?active=all`);
      setState((prev) => ({
        ...prev,
        fleets: data.data.data.map(fleet => ({ ...fleet, ['name']: fleet.board })).filter(fleet => fleet.type === 'horse') || [],
        shifts: data2.data.data || [],      
      }))

    } catch (error) {
      console.warn(error)
    }
  }

  const handleStartDate = (date) => {
    setState((prev) => ({
      ...prev,
      start_day: moment(date).format("YYYY-MM-DD"),
    }));
  };

  const handleEndDate = (date) => {
    setState((prev) => ({
      ...prev,
      end_day: moment(date).format("YYYY-MM-DD"),
    }));
  };

  async function handleSubmitReport() {
    let fleets = []
    let shifts = []
    state.fleets.map((item) => {
      fleet_list.forEach((route) => {
        if (route == item.name) {
          fleets.unshift(item.id)
        }
      })
    })
    state.shifts.map((item) => {
      shift_list.forEach((shift) => {
        if (shift == item.resource.work_shift) {
          shifts.unshift(item.id)
        }
      })
    })

    console.log('Gerando relatório')
    try {
      let url = `/checklist-report-view?start_at=${state.start_day}&end_at=${state.end_day}&fleets=[${fleets}]&shifts=[${shifts}]`;
      const data = await api.get(url);
      let reports = data.data.data.data
      //GERAR DADOS
      let reportRows = [];
      
      Object.keys(reports).forEach((fleet, index) => {
        Object.keys(reports[fleet]).forEach((date, index2) => {
          Object.keys(reports[fleet][date]).forEach((obj, index3) => {        
            reportRows.push({
              date: Object.keys(reports[fleet])[index2] + "-" + reports[fleet][date][obj].shift,
              fleet: reports[fleet][date][obj].fleet_board,
              horse: reports[fleet][date][obj].horse_board,
              driver: reports[fleet][date][obj].driver_name,
              checklist: reports[fleet][date][obj].checklist,       
            })
          })
        })
      })

      console.log("reportRows", reportRows)
      setState((prev) => ({
        ...prev,
        report: reportRows || []
      }));
    } catch (err) {
      console.warn(err);
    }
  }

  const columns = [
    {
      title: "Data/Turno",
      field: "date",
      cellStyle: { paddingLeft: 24, textAlign: "left" },
      headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
    },
    {
      title: "Frota",
      field: "fleet",
      cellStyle: { paddingLeft: 24, textAlign: "left" },
      headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
    },
    {
      title: "Placa da carreta",
      field: "horse",
      cellStyle: { paddingLeft: 24, textAlign: "left" },
      headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
    },
    {
      title: "Motorista",
      field: "driver",
      cellStyle: { paddingLeft: 24, textAlign: "left" },
      headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
    },
  ]

  return (
    <Main color="#f7f7f7" title="Relatório de Checklist" filter={<FilterReport downloadUrl={ `/checklist-report?`} hasFleet/>}>
      <Grid container direction="row" justify="center" alignItems="center" spacing={2} style={{ height: 120 }}>
        <Grid item sm={2} md={2} lg={2} xs={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
            <ThemeProvider theme={fcaTheme}>
              <KeyboardDatePicker
                label="Data Inicial"
                disableFuture={true}
                inputVariant="outlined"
                disableToolbar
                clearable="true"
                autoOk={true}

                format="dd/MM/yyyy"
                variant="inline"
                value={moment(state.start_day, "YYYY-MM-DD")}
                onChange={handleStartDate}
                fullWidth
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item sm={2} md={2} lg={2} xs={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
            <ThemeProvider theme={fcaTheme}>
              <KeyboardDatePicker
                label="Data Final"
                disableFuture={true}
                inputVariant="outlined"
                disableToolbar
                clearable="true"
                autoOk={true}

                format="dd/MM/yyyy"
                variant="inline"
                value={moment(state.end_day, "YYYY-MM-DD")}
                onChange={handleEndDate}
                fullWidth
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item sm={3} md={3} lg={3} xs={3}>
          <CustomSelectCheckbox
            value={shift_list}
            onChange={handleChangeCustom}
            data={state.shifts}
            list={shift_list}
            label="Turnos"
            name="shift"
          />
        </Grid>
        <Grid item sm={3} md={3} lg={3} xs={3}>
          <CustomSelectCheckbox
            value={fleet_list}
            onChange={handleChangeCustom}
            data={state.fleets}
            list={fleet_list}
            label="Frotas"
            name="fleet"
          />
        </Grid>
        <Grid item sm={2} md={2} lg={2} xs={2}>
          <Button style={{ padding: 14, backgroundColor: '#5ad545', color: '#fff' }} size="large" variant="contained" color="primary" onClick={handleSubmitReport} >
            Gerar Relatório
          </Button>
        </Grid>
      </Grid>
      <TableReport report={state.report} columns={columns} hasChecklist />
    </Main>
  )
}

export default ChecklistReports;
