import React, {useState, useEffect} from 'react';
import api from '../../../../../../core/services/api'
import {useStyles} from './styles'
import moment from 'moment'

//@material-ui
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";

//custom components
import GraphCard from './GraphCard'



function HDRoute(props){
    const classes = useStyles();
    const [state, setState] = useState({
        start_day: moment().format("YYYY-MM-DD"),
        end_day: moment().add(1, 'days').format("YYYY-MM-DD"),
        shifts_list: [],
        shifts_id: null,
        legends: [],
        hd_route: [{
            name: 'Hora Direta',
            data: []
          }, {
            name: 'Hora Indireta',
            data: []
          }],
    })

    useEffect(() => {
        getData();
      }, [state.shifts_id, state.start_day])
  
    useEffect(() => {
    getShift()
    }, [])

    async function getShift() {
        try {
            const data = await api.get(`shifts?active=all`);
            const shifts = []
            data.data.data.map((item) => shifts.push(item))
            setState((prev) => ({
            ...prev,
            shifts_list: data.data.data || []
            }))
        } catch (error) {
            console.warn(error)
        }
    }
      
    async function getData(){
        try {
            let shift = ``;
            if(state.shifts_id != null) shift = `&shift=${state.shifts_id}`
            const data = await api.get(`hdtime-day?start_day=${state.start_day}&end_day=${state.end_day}`+shift);
            let hd = [];
            let legends = [];
          data.data.forEach((item, index) => {
            let exist = legends.includes(item.name)
            if(!exist){
              legends.push(item.name)
            }
          })


            //DADOS TABELA HD
            data.data.forEach((item, index) => {
            let idx = hd.map(function(e) { return e.name; }).indexOf(item.name);
            let exist = hd.filter((item2) => {return item2.name == item.name});
            if(exist[0]){
                hd[idx].soma_transito += item.soma_transito ? item.soma_transito : 0;
                hd[idx].soma_doca += item.soma_doca ? item.soma_doca : 0;
                hd[idx].soma_arranque += item.soma_arranque ? item.soma_arranque : 0;
                hd[idx].soma_fechamento += item.soma_fechamento ? item.soma_fechamento : 0;
                hd[idx].soma_checklist += item.soma_checklist ? item.soma_checklist : 0;
                hd[idx].soma_espera += item.soma_espera ? item.soma_espera : 0;
                hd[idx].soma_fimturno += item.soma_fimturno ? item.soma_fimturno : 0;
                hd[idx].soma_inicioturno += item.soma_inicioturno ? item.soma_inicioturno : 0;
                hd[idx].soma_refeicao += item.soma_refeicao ? item.soma_refeicao : 0;

                let soma_ocorrencia = 0;
                item.jornada.tasks.forEach((task, index) => {
                  if (task.issues[0] && task.issues[0].finish_at) {
                    let occurrence_total = 0;
                    task.issues.forEach((item2, index) => {
                      const aux = moment(task.issues[index].finish_at,"YYYY-MM-DD HH:mm:ss").diff(moment(task.issues[index].created_at, "YYYY-MM-DD HH:mm:ss"),"seconds");
                      occurrence_total += aux;
                    })

                    soma_ocorrencia += occurrence_total;
                  }
                });
                hd[idx].soma_transito -= soma_ocorrencia;
            } else {


              if(item.jornada.tasks) {
                let soma_ocorrencia = 0;
                item.jornada.tasks.forEach((task, index) => {
                  if (task.issues[0] && task.issues[0].finish_at) {
                    let occurrence_total = 0;
                    task.issues.forEach((item2, index) => {
                      const aux = moment(task.issues[index].finish_at,"YYYY-MM-DD HH:mm:ss").diff(moment(task.issues[index].created_at, "YYYY-MM-DD HH:mm:ss"),"seconds");
                      occurrence_total += aux;
                    })
      
                    soma_ocorrencia += occurrence_total;
                  }
                })
                let firstobj = item;
                firstobj.soma_ocorrencia = soma_ocorrencia;
                hd.push(firstobj)
              }

              
            }
            })
            //DADOS GRAFICO HD ROTA
            let hd_route = [{
                name: 'Hora Direta',
                data: []
            }, {
                name: 'Hora Indireta',
                data: []
            }]
            legends.forEach((item, index) => {
                hd_route[0].data.push(0)
                hd_route[1].data.push(0)
            })
            hd.forEach((item, index) => {
                hd_route[0].data[index] = item.soma_arranque + item.soma_doca + item.soma_transito + item.soma_fechamento - item.soma_ocorrencia;
                hd_route[1].data[index] = item.soma_ocorrencia + item.soma_checklist + item.soma_espera + item.soma_fimturno + item.soma_inicioturno + item.soma_refeicao
            })

            setState((prev) => ({
                ...prev,
                hd_route: hd_route,
                legends: legends
            }))
        } catch (error) {
            console.warn(error)
        }
    }
    function checkTargetHD(){
      let aux = 64740;
      if(state.shifts_id == 10) aux = 26160;
      if(state.shifts_id == 16) aux = 24780;
      if(state.shifts_id == 12) aux = 13800;
      return aux;
    }

    const handleDate = (date) => {
        setState((prev) => ({
          ...prev,
          start_day: moment(date).format("YYYY-MM-DD"),
          end_day: moment(date).add(1, 'days').format("YYYY-MM-DD")
        }));
      };

    return (
        <Card className={classes.root}>
        <CardContent>
        <Grid container justify="space-between" alignItems="center" spacing={3}>
            <Grid item lg={8} md={8} sm={8} xs={8}><Typography>Hora Direta e Indireta por Rota</Typography></Grid>
            <Grid item lg={2} sm={2} md={2} xs={2}> 
            <div style={{ marginTop: 12, marginBottom: 12 }}>
            <Select
                MenuProps={{ disableScrollLock: true }}
                 fullWidth
                 variant="outlined"
                  displayEmpty
                  value={state.shifts_id}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                     shifts_id: e.target.value,
                    }))
                  } 
                >
                  <MenuItem key="all" value={null}>
                    Todos os Turnos
                  </MenuItem>
                  {state.shifts_list.map((item) => {return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.resource.work_shift}
                    </MenuItem>
                  )})}
                </Select>
         
            </div>
            </Grid>
            <Grid item lg={2} sm={2} md={2} xs={2}> 
            <div style={{ marginTop: 10, marginBottom:  0}}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
                <ThemeProvider theme={fcaTheme}>
                  <KeyboardDatePicker
                    label="Filtro de Data"
                    disableFuture={true}
                    inputVariant="outlined"
                    disableToolbar
                    clearable="true"
                    autoOk={true}
                    style={{ paddingBottom: 12 }}
                    format="dd/MM/yyyy"
                    variant="inline"
                    value={moment(state.start_day, "YYYY-MM-DD")}
                    onChange={handleDate}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </div>
            </Grid>
        </Grid>
       
        <GraphCard legends={state.legends} hd_route={state.hd_route} shifts_id={checkTargetHD()}/>
        </CardContent>
            <CardActions></CardActions>
        </Card>
    )
}

export default HDRoute;

