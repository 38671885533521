import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import api from '../../../../../core/services/api';


//material-ui
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader'
import Fab from '@material-ui/core/Fab'
import TimelineIcon from '@material-ui/icons/Timeline'

//custom components
import LocalTable from './LocalTable'

const useStyles = makeStyles({
  root: {
    minHeight: 750,
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

function LocalRanking() {
  const classes = useStyles();
  const [data, setState] = useState({
    resultBy: 'day',
    endDate: moment(Date.now()).format("YYYY-MM-DD"),
    startDate: moment(Date.now()).subtract(7, "d").format("YYYY-MM-DD"),
    dateRangeID: 1,
    dateRange: [
      { id: 1, name: "Últimos 7 dias" },
      { id: 2, name: "Últimos 14 dias" },
      { id: 3, name: "Últimos 20 dias" },
      { id: 4, name: "Últimos 30 dias" },
      { id: 5, name: "Hoje" },
      { id: 6, name: "Ontem" },
      { id: 7, name: "Essa Semana" },
      { id: 8, name: "Última Semana" },
      { id: 9, name: "Esse Mês" },
      { id: 10, name: "Último Mês" },
      { id: 11, name: "Este Ano" },
    ],
  })
  useEffect(
    () => {
      getData();
    },
    [data.dateRangeID]
  );

  async function getData(){
    try {
      const placess = await api.get(`https://flex.trackage.io/api/v1/places_ranking?ini_date="${data.startDate}"&end_date="${data.endDate}"`) 
      setState((prev) => ({
          ...prev,
          places: placess.data
        }));
    } catch (error) {
      console.warn(error)
    }
  }

  
  const changeDateRange = (e) => {
    const { value } = e.target;
    if (value === 1) {
      setState((prev) => ({
        ...prev,
        startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: 'day',
        dateRangeID: value
      }));
    }
    if (value === 2) {
      setState((prev) => ({
        ...prev,
        startDate: moment().subtract(14, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: 'day',
        dateRangeID: value
      }));
    }
    if (value === 3) {
      setState((prev) => ({
        ...prev,
        startDate: moment().subtract(20, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: 'day',
        dateRangeID: value
      }));
    }
    if (value === 4) {
      setState((prev) => ({
        ...prev,
        startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: 'day',
        dateRangeID: value
      }));
    }
    if (value === 5) {
      setState((prev) => ({
        ...prev,
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: 'day',
        dateRangeID: value
      }));
    }
    if (value === 6) {
      setState((prev) => ({
        ...prev,
        startDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
        resultBy: 'day',
        dateRangeID: value
      }));
    }
    if (value === 7) {
      setState((prev) => ({
        ...prev,
        startDate: moment().startOf("isoWeek").format("YYYY-MM-DD"),
        endDate: moment().endOf("isoWeek").format("YYYY-MM-DD"),
        resultBy: 'day',
        dateRangeID: value
      }));
    }
    if (value === 8) {
      const firsDayWeek = moment().startOf("isoWeek");
      const lastWeek = firsDayWeek.subtract(1, "days");
      setState((prev) => ({
        ...prev,
        startDate: lastWeek.startOf("isoWeek").format("YYYY-MM-DD"),
        endDate: lastWeek.endOf("isoWeek").format("YYYY-MM-DD"),
        resultBy: 'day',
        dateRangeID: value
      }));
    }
    if (value === 9) {
      setState((prev) => ({
        ...prev,
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: moment().endOf("month").format("YYYY-MM-DD"),
        resultBy: 'day',
        dateRangeID: value
      }));
    }
    if (value === 10) {
      const firstDayMonth = moment().startOf("month");
      const lastMonth = firstDayMonth.subtract(1, "days");
      setState((prev) => ({
        ...prev,
        startDate: lastMonth.startOf("month").format("YYYY-MM-DD"),
        endDate: lastMonth.endOf("month").format("YYYY-MM-DD"),
        resultBy: 'day',
        dateRangeID: value
      }));
    }
    if (value === 11) {
      setState((prev) => ({
        ...prev,
        startDate: moment().startOf("year").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
        resultBy: 'month',
        dateRangeID: value
      }));
    }}

  return (
    <Card className={classes.root}>
        <CardHeader
        action={
          <Select
          displayEmpty
          value={data.dateRangeID}
          onChange={changeDateRange}
          style={{ width: "223px", height: "48px" }}
          
        >
         
          {data.dateRange.length > 0 &&
            data.dateRange.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
        </Select>
        }
        title="Ranking de Locais"
      />
      <CardContent>
       
        <LocalTable data={data.places} />
      </CardContent>
      
    </Card>
  );
}
export default LocalRanking;