import styled from 'styled-components';

export const LoginContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
`;

export const LoginBox = styled.div`
  width: 482px;
  min-height: 262px;
  background-color: #fff;
  border-radius: 5px;
  padding: 32px 16px 16px 16px;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.3);
`;

export const Logo = styled.img`
  width: 10;
  height: 60px;
  margin: 100px 0;
`;

export const Input = styled.input`
  border: none;
  width: 100%;
  padding-bottom: 4px;
  font-size: 14px;
  border-bottom: 1px solid rgb(153, 153, 153);
  margin-bottom: 20px;
`;

export const Label = styled.div`
  font-size: 0.7rem;
  color: rgb(153, 153, 153);
`;

export const Button = styled.button`
  width: 85px;
  height: 36px;
  /* color: #a6a6a6; */
  color: #fff;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 500;
  background-color: #4caf50;
  border: none;
  border-radius: 4px;
  box-shadow: none;
`;
