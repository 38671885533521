import React, {useState} from "react";

//@material-ui
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import TimelineIcon from "@material-ui/icons/Timeline";
import Fab from "@material-ui/core/Fab";

//others components
import { LoadingContainer } from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 300,
    minWidth: 345,
    padding: 12,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

function CiclosCompletos(props) {
  const classes = useStyles();
  const [state,setState] = useState("");
  


  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <Fab disabled style={{ backgroundColor: "#dff6d7" }}>
            <TimelineIcon style={{ color: "#5ed237" }} />
          </Fab>
        }
        title="Ciclos Completos"
      />

      <CardContent>
       
          {props.data && props.data.data != 0 && props.data.data != null ? 
          (<> <Typography style={{ fontSize: 85, fontWeight: "bold" }} component="p">
            {props.data.data}
            </Typography>
            </>)
         
           : (
            <LoadingContainer>
              <CircularProgress style={{ color: "#1e824c" }} />{" "}
            </LoadingContainer>
          )}
       
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
}

export default CiclosCompletos;
