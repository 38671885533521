import React from 'react'
import {useStyles} from './styles'


//custom components
import GraphCard from './GraphCard'

//material ui 
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Typography from '@material-ui/core/Typography'

function EffectivenessSaturation(){
    const classes = useStyles();
    return (
        <Card className={classes.root}>
        <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
        <b>Efetividade e Saturação</b>
        </Typography>
        <GraphCard />
        </CardContent>
            <CardActions></CardActions>
        </Card>
    )
}


export default EffectivenessSaturation;