import styled from 'styled-components';

const main_color = "#1e824c";

console.log(main_color)

export const TopBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  background: ${main_color || '#2e3131'};
  padding: 0;
  margin: 0;
  position: fixed;
  z-index: 9999;
`;

export const Logo = styled.img`
  height: 20px;
  margin: 7px;
`;
