import React, { useEffect, useState } from "react";
import api from '../../../../../core/services/api'
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Grid from '@material-ui/core/Grid'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from "@material-ui/core/FormControlLabel";



//custom components
import CustomSelect from '../../../components/CustomSelect'
import swal from "sweetalert";


function PhoneMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /[1-9]/, /\d/,')', /[9]/, /\d/, /\d/, /\d/, /\d/,'-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
PhoneMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function CreateForm(props) {
  const [state, setState] = useState({
    error: false,
    disabled: false,
    active: true,
    occupation_id: null,
    occupation_list: [],
    location_id: null,
    location_list: [],
    shift_id: [],
    shift_list: [],

    receive_sms: false,
    operator_list: [{ name: "<", id: "<" }, { name: "<=", id: "<=" }, { name: "=", id: "=" },
    { name: ">=", id: ">=" }, { name: ">", id: ">" }],
    saturation_alert: false,
    saturation_operator: null,
    saturation_value: null,
    dock_time_alert: false,
    dock_time_operator: null,
    dock_time_value: null,
    arrival_prevision: false

  });
  const [shift_ids, setShiftslist] = useState([])
  const [location_ids, setLocalslist] = useState([])
  const [name, setName] = useState("");
  const [func, setFunction] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [turn, setTurn] = useState("");
  const [local, setLocal] = useState("");

  const [receive_sms, setReceiveSms] = useState("");
  const [operator_list, setOperatorList] = useState([]);
  const [saturation_alert, setSaturationAlert] = useState("");
  const [saturation_operator, setSaturationOperator] = useState("");
  const [saturation_value, setSaturationValue] = useState("");
  const [dock_time_alert, setDockTimeAlert] = useState("");
  const [dock_time_operator, setDockTimeOperator] = useState("");
  const [dock_time_value, setDockTimeValue] = useState("");
  const [arrival_prevision, setArrivalPrevision] = useState("");
  const [errors, setErros] = useState({});

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setState((prev) => ({
      ...prev,
      disabled: false,
      error: false,
      showPassword: '',
      occupation_id: null,
      occupation_list: props.groups,
      location_id: null,
      location_list: props.locations,
      shift_id: [],
      shift_list: props.shifts,

      receive_sms: false,
      saturation_alert: false,
      saturation_operator: null,
      saturation_value: null,
      dock_time_alert: false,
      dock_time_operator: null,
      dock_time_value: null,
      arrival_prevision: false
    }))
    setName("");
    setFunction("");
    setEmail("");
    setPhone("");
    setPassword("");
    setPassword2("");
    setTurn("");
    setLocal("");

    setReceiveSms("");
    setOperatorList("");
    setSaturationAlert("");
    setSaturationOperator("");
    setSaturationValue("");
    setDockTimeAlert("");
    setDockTimeOperator("");
    setDockTimeValue("");
    setArrivalPrevision("");
    setShiftslist([]);
    setLocalslist([]);

    setOpen(true);
    // console.log("state: ", state)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  async function handleSubmit() {
   if(validateFields()){
      setState((prev) => ({
        ...prev,
        disabled: true
      }))
      const user = {
        name: name,
        email: email,
        password: password,
        phone: phone.replace(/[^0-9]/g,""),
        func_id: state.occupation_id,
        shift_id: shift_ids,
        local_id: location_ids,
        cpf: "",
        cnh: "",
        group_id: state.occupation_id,

        receive_sms: state.receive_sms,
        saturation_alert: state.saturation_alert,
        saturation_operator: state.saturation_operator,
        saturation_value: saturation_value,
        dock_time_alert: state.dock_time_alert,
        dock_time_operator: state.dock_time_operator,
        dock_time_value: dock_time_value,
        arrival_prevision: state.arrival_prevision
      }
      try {
        await api.post(`users`, user).then(({data: { message }}) => {
          if(message){
            swal("ERRO", message, "warning")
            setState((prev) => ({
              ...prev,
              disabled: false
            }))
          }
          else{
            console.log('Usuário criado', user)
            props.getData();
            setOpen(false)
          }
        });
      } catch (error) {
        console.warn(error)
      }
   }

  }
  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleDelete = (id) => () => {
    setShiftslist((shifts) => shift_ids.filter((shift) => shift !== id));
  };
  const handleDeleteLocal = (id) => () => {
    setLocalslist((locals) => location_ids.filter((local) => local !== id));
  };

  const validateFields = () =>{
      let temp = {};
      temp.name = name === "" ? "Esse campo é obrigatório" : "";
      temp.phone = phone.length <= 13 ? "Esse campo é obrigatório" : "";
      temp.email = email === "" ? "Esse campo é obrigatório" : "";
      temp.password = password === "" ? "Esse campo é obrigatório" : "";
      temp.password2 = password2 === "" ? "Esse campo é obrigatório" : "";

      setErros({...temp});
      return Object.values(temp).every(x => x === "");
  }

  function getShiftname(id) {
    const name = state.shift_list.filter((shift) => { return shift.id === id })
    return name[0] ? name[0].resource.work_shift : ""
  }

  function getLocalname(id) {
    const name = state.location_list.filter((local) => { return local.id === id })
    return name[0] ? name[0].name : ""
  }

  const onAddLocal = () => {
    if (!location_ids.some(local => local === state.location_id))
    setLocalslist([...location_ids, state.location_id])
  }

  const onAddShifts = () => {
    if (!shift_ids.some(shift => shift === state.shift_id))
    setShiftslist([...shift_ids, state.shift_id])
  }

  return (
    <div>
      <AddIcon onClick={handleClickOpen} />
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm" >
        <DialogTitle>Cadastrar novo Usuário</DialogTitle>
        <DialogContent>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
              id="name"
              name="name"
              label="Nome do Usuário"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              {...(errors.name && { error: true } )}
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <TextField
              id="email"
              name="email"
              label="E-mail"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              {...(errors.email && { error: true } )}
            />{" "}
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            {/* <TextField
              id="phone"
              name="phone"
              label="Celular"
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              fullWidth
            />{" "} */}
            <FormControl fullWidth>
            <InputLabel htmlFor="formatted-text-mask-input">Celular</InputLabel>
            <Input
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              inputComponent={PhoneMask}
              name="textmask"
              id="formatted-text-mask-input"
              fullWidth
              {...(errors.phone && { error: true } )}
            /></FormControl>
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <FormControl fullWidth>
              <InputLabel>Senha</InputLabel>
              <Input
                error={state.error ? true : false}
                fullWidth
                id="password"
                name="password"
                label="Senha"
                type={state.showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                {...(errors.password && { error: true } )}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton

                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              /></FormControl>
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }} >
            <FormControl fullWidth>
              <InputLabel>Confirmar senha</InputLabel>
              <Input
                error={state.error ? true : false}
                fullWidth
                id="password2"
                name="password2"
                label="Confirme sua Senha"
                type={state.showPassword ? 'text' : 'password'}
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                {...(errors.password2 && { error: true } )}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />{" "}</FormControl>
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <Grid container alignItems="flex-end" justify="space-between" spacing={2}>
              <Grid item xs={8}>
                <CustomSelect
                  label="Locais"
                  value={state.location_id}
                  name="location_id"
                  data={state.location_list}
                  onChange={(event) => handleChange(event)}
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={() => onAddLocal()}>Adicionar</Button>
              </Grid>
            </Grid>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', listStyle: 'none' }}>
            {location_ids.map((data) => {
              return (
                <li key={data}>
                  <Chip
                    label={state.location_list ? getLocalname(data) : ""}
                    onDelete={handleDeleteLocal(data)}
                  />
                </li>
              );
            })}</div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <CustomSelect
              label="Função"
              value={state.occupation_id}
              name="occupation_id"
              data={state.occupation_list}
              onChange={(event) => handleChange(event)}
            />
          </div>
          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <Grid container alignItems="flex-end" justify="space-between" spacing={2}>
              <Grid item xs={8}>
                <CustomSelect
                  label="Turnos"
                  value={state.shift_id}
                  name="shift_id"
                  data={state.shift_list}
                  onChange={(event) => handleChange(event)}
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={() => onAddShifts()}>Adicionar</Button>
              </Grid>
            </Grid>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', listStyle: 'none' }}>
            {shift_ids.map((data) => {
              return (
                <li key={data}>
                  <Chip
                    label={state.shift_list ? getShiftname(data) : ""}
                    onDelete={handleDelete(data)}
                  />
                </li>
              );
            })}</div>

          <div style={{ marginTop: 12, marginBottom: 12 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.receive_sms}
                  onChange={handleChange}
                  name="receive_sms"
                  color="primary"
                />
              }
              label="Receber SMS"
            />
          </div>

          <div style={{ marginTop: 12, marginBottom: 12, display: state.receive_sms ? 'block' : 'none' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.saturation_alert}
                  onChange={handleChange}
                  name="saturation_alert"
                  color="primary"
                />
              }
              label="Alerta de saturação"
            />
          </div>

          <div style={{ marginTop: 12, marginBottom: 12, display: state.saturation_alert ? 'block' : 'none' }}>
            <CustomSelect
              label="Operador de saturação"
              value={state.saturation_operator}
              name="saturation_operator"
              data={state.operator_list}
              onChange={(event) => handleChange(event)}
            />
            {/* {console.log(state)} */}
            <TextField
              id="saturation_value"
              name="saturation_value"
              label="Valor de saturação (porcentagem)"
              type="number"
              value={saturation_value}
              onChange={(e) => setSaturationValue(e.target.value)}
              fullWidth
            />{" "}
          </div>

          <div style={{ marginTop: 12, marginBottom: 12, display: state.receive_sms ? 'block' : 'none' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.dock_time_alert}
                  onChange={handleChange}
                  name="dock_time_alert"
                  color="primary"
                />
              }
              label="Alerta de tempo de doca"
            />
          </div>

          <div style={{ marginTop: 12, marginBottom: 12, display: state.dock_time_alert ? 'block' : 'none' }}>
            <CustomSelect
              label="Operador de tempo de doca"
              value={state.dock_time_operator}
              name="dock_time_operator"
              data={state.operator_list}
              onChange={(event) => handleChange(event)}
            />

            <TextField
              id="dock_time_value"
              name="dock_time_value"
              label="Valor de tempo de doca (porcentagem)"
              type="number"
              value={dock_time_value}
              onChange={(e) => setDockTimeValue(e.target.value)}
              fullWidth
            />{" "}
          </div>

          <div style={{ marginTop: 12, marginBottom: 12, display: state.receive_sms ? 'block' : 'none' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.arrival_prevision}
                  onChange={handleChange}
                  name="arrival_prevision"
                  color="primary"
                />
              }
              label="Alerta de previsão de chegada"
            />
          </div>



        </DialogContent>
        <DialogActions style={{ padding: 12 }}>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            disabled={state.disabled}
            onClick={handleSubmit}
            variant="contained"
            style={{ backgroundColor: state.disabled ? "" : "#5ed237", color: state.disabled ? "" : "#fff" }}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateForm;
