import React, { Component } from "react";
import Cold from "@material-ui/icons/AcUnit";
import Hot from "@material-ui/icons/Whatshot";
import CheckCircle from "@material-ui/icons/CheckCircle";
import LocationIcon from "./ic_near_me_24px.svg";
import css from "./route_row.module.css";

class RouteRow extends Component {
  constructor(props) {
    super(props);

    this.styles = {
      bullet: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "22px",
        height: "22px",
        backgroundColor: props.status,
        borderRadius: "50%",
        marginRight: "15px",
        zIndex: 2010
      }
    };

    this.matchMedia = window.matchMedia("(max-width: 576px)");
    this.matchMedia.addListener(this.mediaQueries.bind(this));
  }

  filtraTemperatura(temp) {
    if (temp < 12) {
      return "#2196F3"; // blue
    } else if (temp > 16) {
      return "#EC273B"; // red
    } else {
      return "#5ED237"; // green
    }
  }

  tempIcon(temperature) {  
    if (temperature < 12) {
      return (
        <Cold style={{fontSize: 17, color: 'white'}} />
        )
    } else if(temperature > 16) {
      return (
        <Hot style={{fontSize: 17, color: 'white'}} />
      )
    }
    return (
        <CheckCircle style={{fontSize: 17, color: 'white'}} />
    )
}


  componentDidMount() {
    this.mediaQueries();
  }

  mediaQueries() {}

  mouseEnterHandler(e) {
    e.currentTarget.style.cursor = "pointer";
  }

  mouseLeaveHandler(e) {
    e.currentTarget.style.cursor = "initial";
  }

  render() {
    return (
      <div {...this.props}>
        <div onClick={() => this.props.goto({latitude: this.props.latitude, longitude: this.props.longitude})} className={css.container}>
          <div  className={css.row}>
            <div style={{
                        zIndex: 999,
                                margin: '0 10px 0 0 ',
                                borderRadius: '30px',
                                color: 'white',
                                width: 30,
                                maxWidth: 55,
                                padding: '2px 4px',
                                display: 'flex',
                                justifyContent: 'space-around',
                                alignItems: 'center',
                                background: this.filtraTemperatura(this.props.temperature)
                            }}>
                            <p style={{margin: 0}}>{this.props.temperature}</p>
                            </div>
            <div className={css.text}>
              <div className={css.time}>{this.props.time}</div>
              <div className={css.address}>{this.props.address}</div>
            </div>
          </div>
          <div
            className={css.row}
            onMouseEnter={this.mouseEnterHandler}
            onMouseLeave={this.mouseLeaveHandler}
          >
            <img
              className={css.icon}
              src={LocationIcon}
              alt="location icon"
            ></img>
          </div>
        </div>
      </div>
    );
  }
}

export default RouteRow;
