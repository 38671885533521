import React, { useState, useEffect } from "react";
import Main from "../../components/Main";
import api from "../../../../core/services/api";
import moment from "moment";
import { Link } from "react-router-dom";
//@material-ui
import { Container } from "./styles";
import Grid from "@material-ui/core/Grid";

//Custom Components
import Delay from './Delay'
import OnCourse from './OnCourse'
import Inside from './Inside'
import Anticipation from './Anticipation'
import Active from './Active'

//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// pick a date util library
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import  Typography  from "@material-ui/core/Typography";

function Dashboard(){
  const [state, setState] = useState("")
  const usuario = JSON.parse(localStorage.getItem("usuario"));
  useEffect(
    () => {
      getActive();
      getInside();
      getAnticipation();
      getDelay();
      getOnCourse();
      const interval = setInterval(() => {
        getActive();
        getInside();
        getAnticipation();
        getDelay();
        getOnCourse();
        console.log('Atualizando...')
      }, 30000);
      return () => clearInterval(interval);
    },
    []
  );

  async function getInside() {
    try {
      const { data } = await api.get(`inside-card`);
      //const filterdata = data.filter((item) => item.excluded !=== true);
      setState((prev) => ({
        ...prev,
        inside: data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  async function getActive() {
    try {
      const { data } = await api.get(`active-card`);
      //const filterdata = data.filter((item) => item.excluded !=== true);
      setState((prev) => ({
        ...prev,
        active: data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }
  async function getDelay() {
    try {
      const { data } = await api.get(`delay-card`);
      //const filterdata = data.filter((item) => item.excluded !=== true);
  
      setState((prev) => ({
        ...prev,
        delaycard: data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }
  async function getOnCourse() {
    try {
      const { data } = await api.get(`onroute-card`);
      //const filterdata = data.filter((item) => item.excluded !=== true);
      setState((prev) => ({
        ...prev,
        onroute: data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }
  async function getAnticipation() {
    try {
      const { data } = await api.get(`anticipation-card`);
      //const filterdata = data.filter((item) => item.excluded !=== true);
      setState((prev) => ({
        ...prev,
        anticipation: data || [],
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  function getClientName(){
    const sys = usuario.system_client_id
    if(sys === 5){
      return 'Porto de Itapoá'
    }
    if(sys === 6){
      return 'Terminal ATM'
    }
    if(sys === 7){
      return 'Cliff'
    }
    if(sys === 8){
      return 'Terminal APM'
    }
  }

  return (
    
    <Main title={getClientName()}>
      <Container>
     
          {/*   <Typography variant="h4" gutterBottom>
            Número de Veículos
            </Typography> */}
          
        <Grid container spacing={3}>
        <Grid item lg={4} md={6} sm={12} xs={12}>
            <Active data={state.active} />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Inside data={state.inside} />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <OnCourse data={state.onroute} />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
           <Delay data={state.delaycard} />
          </Grid>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Anticipation data={state.anticipation} />
          </Grid>
        </Grid>
      </Container>
    </Main>
  );
};


export default Dashboard;