import React, { useState, useEffect } from "react";

//@material-ui
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import Fab from "@material-ui/core/Fab";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

//others components
import moment from "moment";
import { LoadingContainer } from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 300,
    minWidth: 345,
    padding: 12,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

function Veiculos(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  let sum = (a) =>  a.reduce((x, y) => isNaN(parseFloat(y)) ? x+0 : x + y);
  
  return (
    <Card className={classes.root}>
      <Grid container direction="row">
        <Grid item lg={4}>
          {" "}
          <CardHeader title="Veículos Vazios" />
        </Grid>
        <Grid item lg={4}>
          {" "}
          <CardHeader title="Veículos Carregados" />
        </Grid>
        <Grid item lg={4}>
          <CardHeader
            action={
              <Fab disabled style={{ backgroundColor: "#fff2cd" }}>
                <LocalShippingIcon style={{ color: "#fec61f" }} />
              </Fab>
            }
          />
        </Grid>
      </Grid>

      <CardContent>
        <Grid spacing={3} container direction="row">
          {props.data && (props.data.carregado != 0 && props.data.vazio != 0) ? (
            <>
              <Grid item lg={5}>
                <Typography
                  style={{ fontSize: 85, fontWeight: "bold" }}
                  component="p"
                >
                  
                  {props.data.vazio }
                </Typography>
              </Grid>
              <Grid item lg={5}>
                <Typography
                  style={{ fontSize: 85, fontWeight: "bold" }}
                  component="p"
                >
                  
                   { props.data.carregado }
                </Typography>
              </Grid>
            </>
          ) : (
            <LoadingContainer>
              <CircularProgress style={{ color: "#1e824c" }} />{" "}
            </LoadingContainer>
          )}
        </Grid>
      </CardContent>
      <CardActions style={{ marginTop: -20 }}>
        {" "}
        <Typography
          style={{ fontSize: 14, fontStyle: "italic", opacity: "30%" }}
        >
          Atualmente
        </Typography>{" "}
      </CardActions>
    </Card>
  );
}

export default Veiculos;
