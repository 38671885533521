import moment from 'moment'

function pad(num) {
  return ("0"+num).slice(-2);
}
function hhmmss(secs) {
  var minutes = Math.floor(secs/60);
  var hours = Math.floor(minutes/60)
  minutes = minutes%60;
  return `${hours}:${pad(minutes)}`;
  // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
}

export default function(legends) {
    return {
        colors: ["#26a0fc","#7d77ff",'#2de0c2','#ffc504', '#ec273b'],
        chart: {
          height: 400,
          type: "bar",
        },
        annotations: {
          yaxis: [
            {
              y: 80,
              borderColor: '#ec273b',
              label: {
                borderColor: '#ec273b',
                style: {
                  color: '#fff',
                  background: '#ec273b'
                },
                text: 'Target Hora Direta'
              }
            }
          ]
        },
        legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'left',
        },
        dataLabels: {
          enabled: true,
          //enabledOnSeries: [0,1,2],
          background: {
            enabled: true,
            foreColor:  '#777',
            borderColor: '#777',
          },
          //offsetY: 10,
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            if(seriesIndex == 1){
              return hhmmss(value)
            } else if(seriesIndex == 3 || seriesIndex == 4) {
              return value.toFixed(0)+"%";
            } else {
              return value
            }
            
          },
        },
        stroke: {
          curve: 'straight',
          width: 2
        },

        xaxis: {
          categories: legends,
          
        },
        yaxis: [{
          labels: {  
            show: false,
            formatter: function (value) {
              return value
            }
          },
        },
        {
          labels: {  
            show: false,
            formatter: function (value) {
              return hhmmss(value)
            }
          },
        },
        {
          labels: {  
            show: false,
            formatter: function (value) {
              return value 
            }
          },
        },
        {
          labels: {  
            show: false,
            formatter: function (value) {
              return value 
            }
          },
        },
        {
          labels: {  
            show: false,
            formatter: function (value) {
              return value 
            }
          },
        },
       
      ],
      }
  }