import React, { useState, useEffect } from 'react';
import Main from '../../components/Main'
import MaterialTable, { MTableToolbar, MTablePagination } from "material-table";
import api from '../../../../core/services/api'

//custom components
import CreateForm from './CreateForm'
import EditForm from './EditForm'
import { makeStyles } from "@material-ui/core/styles";
import Alert from '../../components/Alert'

//@material-ui
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

//@material icons
import DeleteIcon from '@material-ui/icons/Delete'
import { Fab } from '@material-ui/core';
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import AddIcon from "@material-ui/icons/Add";
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import SearchIcon from '@material-ui/icons/Search'

function Drivers() {

  const [state, setState] = useState({
    drivers: [],
    numberRowPerPage: 10,
    totalRow: 50,
    firstLoad: true,
    pageNumber: 1,
    search: false,
  })
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    if(state.search){
      handleSearch(search);
    } else {
      getData();
    }
  }, [page])

  async function getData() {
    try {
      const { data } = await api.get(`drivers?perPage=${state.numberRowPerPage}&page=${page+1}`);
      //const filterdata = data.filter((item) => item.user_type === 2);
      setState((prev) => ({
        ...prev,
        drivers: data.data || [],
        filteredDrivers: data.data || [],
        totalRow: parseInt(data.total),
        search: false,
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  async function deleteDriver(item) {
    try {
      if (
        window.confirm(
          "Tem certeza que deseja deletar motorista " + item.name
        )
      ) {
        await api.delete(`drivers/` + item.driver_id);
      } else {
      }

      getData();
    } catch (error) {
      console.warn(error);
    }
  }


  const useStyles = makeStyles({
    root: {
      transform: "translateZ(0px)",
      flexGrow: 1,
      color: '#69f0ae'
    },
    speedDial: {
      zIndex: 9999,
      position: "fixed",
      bottom: "12%",
      right: "0.2%",
      "& .MuiSpeedDial-fab": {
        backgroundColor: '#5ad545'
      },
    }
  }
  );

  async function getReport() {
    try {
      let url = `/driver_export`;
      // url += state.check_shift ? "&check_shift=" + state.check_shift : "";
      api.get(url).then(blobFile => {
          //fileDownload(blobFile.data.data, 'teste.xlsx')
           const url = window.URL.createObjectURL(new Blob([blobFile.data.data]));
          const link = document.createElement('a');
          //converter base64 pra xlsx
          //passar xlsx pro href
          link.href = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"+blobFile.data.data;
          link.setAttribute('download', `motoristas.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);  

      })
    } catch (err) {
      console.warn(err);
    }
  };


  const classes = useStyles();
  const [direction, setDirection] = React.useState("up");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChangePage = (page) => {
    setPage(page);
  }

  async function handleSearch(value){
    let data = []
    if(state.search){
      data  = await api.get(`search-driver?perPage=${state.numberRowPerPage}&page=${page+1}&term=${value}`);  
    } else {
      setPage(0)
      data = await api.get(`search-driver?perPage=${state.numberRowPerPage}&page=${1}&term=${value}`);
    }
    
    //const filterdata = data.filter((item) => item.user_type === 2);
    setState((prev) => ({
      ...prev,
      drivers: data.data.data || [],
      filteredDrivers: data.data.data || [],
      totalRow: parseInt(data.data.total),
      search: true,
    }));
  }

  function handleEmpty(value){
    setSearch(value);
    getData();
  }
  return (
    <Main color="#f7f7f7" title="Motoristas">
      <Grid container justify="flex-end" spacing={3} style={{ paddingBottom: 12 }}>
      <Grid item>
        <TextField id="outlined-basic" label="Busca" variant="outlined" value={search} InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                  aria-label="toggle password visibility"
                  onClick={(e) => handleSearch(search)}
                  edge="end"
                >
                  <SearchIcon />
                </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={(e) => e.target.value == '' ?  handleEmpty(e.target.value) : setSearch(e.target.value)}
         />
        </Grid>
        <Grid item>
          <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            direction={direction}
            getData={getData}
            optionType={state.optionType}
          >
            <SpeedDialAction
              key="CreateForm "
              icon={<CreateForm getData={getData} />}
              tooltipTitle="Adicionar Motorista"
              onClick={() => handleOpen()}

            />
            <SpeedDialAction
              key="download"
              icon={<GetAppIcon />}
              tooltipTitle="Download"
              onClick={() => getReport()}
            />
            {/* <SpeedDialAction
              key="upload"
              icon={<PublishIcon />}
              tooltipTitle="Upload"
              onClick={() => handleOpen()}
            /> */}

          </SpeedDial>

        </Grid>
        <Alert open={open2} message="O relatório foi baixado com sucesso!" setOpen={() => setOpen2()} /> 

      </Grid>
      <MaterialTable
        columns={[
          {
            title: "Nome",
            field: "name",
            cellStyle: { paddingLeft: 24, textAlign: "left", width: '20%', paddingRight: 80 },
            headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
          },
          {
            title: "Login",
            field: "matricula",
            cellStyle: { paddingLeft: 24, textAlign: "left" },
            headerStyle: { borderBottom: '#fff' }
          },
          {
            field: "actions",
            title: "Ações",
            cellStyle: { paddingBottom: 0, paddingTop: 0, textAlign: "right" },
            headerStyle: { paddingRight: 20, textAlign: "right", borderRadius: '0px 8px 0px 0px' },
            render: (rowData) => (
              <>
                <FormGroup row style={{ justifyContent: 'flex-end' }}>
                  <EditForm collaborator={rowData} getData={getData} />
                  <IconButton
                    style={{ color: "#ec273b" }}
                    onClick={() => deleteDriver(rowData)}
                  >
                    <DeleteIcon />
                  </IconButton>{" "}
                </FormGroup>
              </>
            ),
          },
        ]}
        data={state.filteredDrivers}
        options={{
          pageSize: 10,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          tableLayout: 'auto',
          headerStyle: {
            paddingLeft: 24,
            //textAlign: "left",
            backgroundColor: "#fff",
            color: "#777",
            border: '0px solid #777',
          },
          rowStyle: (x) => {
            if (x.tableData.id === 0) {
              return { borderTop: '2px solid rgb(212, 212, 212)' }
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
          Pagination: props => (
            <MTablePagination
            {...props}
            rowsPerPageOptions={[5, 10, 20, 30]}
            rowsPerPage={state.numberRowPerPage}
            count={state.totalRow}
            page={
              state.firstLoad
                ? page
                : page - 1
            }
            onChangePage={(e, page) =>
              handleChangePage(page)
            }
          /*   onChangeRowsPerPage={event => {
              props.onChangeRowsPerPage(event);
              handleChangeRowPerPage(event.target.value);
            }} */
     />
   ),
        }}
        style={{
          borderRadius: 10,
        }}
      />
    </Main>
  )
}

export default Drivers;