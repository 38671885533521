import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Main from '../../components/Main'
import CardReportType from './CardReportType'

const useStyles = makeStyles({
    root: {
        margin: '0 auto',
        width: '75%',
        display: 'flex',
        displayDirection: 'row',
        alignItems : 'center',
        justifyContent: 'space-between'
    }, 
});

function Report(){
    const classes = useStyles();
 
    const [userConfig, setUserConfig] = useState({ loading: true, data: {
        menu: []
      }});

    useEffect(() => {
        function getConfigAsync(){
          let data = localStorage.getItem('usuario');
          data = data ? JSON.parse(data) : {menu: []};
          setUserConfig({ data, loading: false });
        }
        getConfigAsync();
      }
      , []);

      

    return(
        <Main color="#f7f7f7" title="Relatório">
           <div className={classes.root}>
               {userConfig.data.menu.map(({name, resource : { route }})=>{
                  return route.includes('report/') && <CardReportType pageLink={route.slice(6)} cardTitle={name} />
               })
               }
           </div>
        </Main>
    )
}

export default Report;
