import React from 'react'

//custom components
import LocalRanking from './LocalRanking'
import VehiclesRanking from './VehiclesRanking'
import Main from "../../components/Main";
import { Container } from "./styles";


//material-ui
import Grid from '@material-ui/core/Grid'


function Ranking(){
    return (
        <Main
        title="Ranking"
      >
        <Container>
        <Grid container spacing={3}>
            <Grid item lg={6} xs={12}>
                <LocalRanking />
            </Grid>
            <Grid item lg={6} xs={12}>
                <VehiclesRanking />
            </Grid>
        </Grid>
        </Container>
        </ Main>
    )
}

export default Ranking;