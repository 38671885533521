import React, { useEffect, useState } from "react";
import Main from "../../components/Main";
import moment from "moment";
import api from "../../../../core/services/api";
//custom components
import { Link } from "react-router-dom";
import { Container } from "./styles";
import CardDescarga from "./CardDescarga";

//@material-ui
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import InsertChartIcon from "@material-ui/icons/InsertChart";

function TempoMedioDescarga(props) {
  const [state, setState] = useState({
    date: props.match.params.date,
    goals: [],
    loading: true,
  });

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    // const {data} = await api.get('/forklift-idleness?start=2020-04-01&end=2020-04-06&forklift_number=382&operation_place_id=1')
    const data = await api.get(
      `https://flex.trackage.io/api/v1/tora_goals?date=${props.match.params.date}`
    );

    const result = data.data.filter((value, index, array) => {
      return (
        value.task_id === 2 &&
        value.goals_time != null 
      );
    });


    setState((prev) => ({
      ...prev,
      goals: result,
      loading: false,
    }));
  }


  return (
    <Main
      title={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span style={{ marginRight: 50 }}>
            Tempo Médio de Descarga do dia{" "}
            {moment(props.match.params.date, "YYYY-MM-DD").format("DD/MM/YYYY")}
          </span>
        </div>
      }
    >
      <Container>
        <Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: 12 }}>
          <Link
            to="/dashboard"
            style={{ textDecoration: "none", opacity: "50%", color: "#000" }}
          >
            Dashboard
          </Link>
          <Typography color="textPrimary">Tempo médio de Descarga</Typography>
        </Breadcrumbs>

        <Grid container spacing={3}>
        {state.goals.map(function (item, key) {
          return (
            <>
              <Grid item lg={4} md={6} sm={12} xs={12}>
                <CardDescarga
                  key={key}
                  idcerca={item.id}
                  name={item.name}
                  accomplished_time={item.tempo_medio}
                  date={props.match.params.date}
                  goals_time={item.goals_time}
                  date={props.match.params.date}
                />
              </Grid>
            </>
          )})}
        </Grid>
      </Container>
    </Main>
  );
}

export default TempoMedioDescarga;
