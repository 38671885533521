import React, { useState, useEffect } from 'react';
import MaterialTable, { MTableToolbar } from "material-table";
import api from '../../../../../core/services/api'


//custom components
import EditForm from '../EditForm'

//@material-ui
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import IconButton from '@material-ui/core/IconButton'


//@material icons
import DeleteIcon from '@material-ui/icons/Delete'

function Table(props) {

    async function deleteOccurrences(occurrence_category) {
        try {
            if (
              window.confirm(
                "Tem certeza que deseja deletar a occorência " + occurrence_category.description
              )
            ) {
                await api.delete(`occurrence/${props.occurrence.description}/${occurrence_category.id}`);
            }
            props.getData();
          } catch (error) {
            console.warn(error)
          }
    }

    return (
        <MaterialTable
            columns={[
                {
                    title: "Ocorrências",
                    field: "description",
                    cellStyle: { paddingLeft: 24, textAlign: "left" },
                    headerStyle: { borderBottom: '#fff', borderRadius: '8px 0px 0px 0px' }
                },
                {
                    field: "actions",
                    title: "Ações",
                    cellStyle: { paddingBottom: 0, paddingTop: 0, textAlign: "right" },
                    headerStyle: { paddingRight: 20, textAlign: "right", borderRadius: '0px 8px 0px 0px' },
                    render: (rowData) => (
                        <>

                            <FormGroup row style={{ justifyContent: 'flex-end' }}>
                                <EditForm occurrence_category={rowData} getData={props.getData} group={props.occurrence.description} />

                                <IconButton

                                    style={{ color: "#ec273b" }}
                                    onClick={() => deleteOccurrences(rowData)}
                                >
                                    <DeleteIcon />

                                </IconButton>{" "}

                                <div id="buttons"></div>
                            </FormGroup>

                        </>
                    ),
                },
            ]}
            data={props.occurrence.occurrence.filter((item) => {return item.active == true}) || []}
            options={{
                pageSize: 10,
                actionsColumnIndex: -1,
                search: false,
                showTitle: false,
                tableLayout: 'auto',
                headerStyle: {
                    paddingLeft: 24,
                    //textAlign: "left",
                    backgroundColor: "#fff",
                    color: "#777",
                    border: '0px solid #777',
                },
                rowStyle: (x) => {
                    if (x.tableData.id === 0) {
                        return { borderTop: '2px solid rgb(212, 212, 212)' }
                    }
                },
            }}
            components={{
                Toolbar: (props) => (
                    <div style={{ display: "none" }}>
                        <MTableToolbar {...props} />
                    </div>
                ),
            }}

            style={{
                borderRadius: 10
            }}
        />
    )
}

export default Table;