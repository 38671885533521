import moment from "moment";
export default function (legends) {
  return {
    colors: ["#ffc504", "#df1d1d", "#7d77ff", "#6762d4"],
    chart: {
      height: 350,
      type: "bar",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#000"],
      },
      formatter: function (val, opts) {
        return val + "%";
      },
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "left",
    },
    xaxis: {
      categories: legends,
    },
    yaxis: [
      {
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,

          formatter: function (value) {
            return value + "%";
          },
        },
      },
    ],
  };
}
