import styled from 'styled-components';
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

export  const useStyles = makeStyles((theme) => ({
    root: {
      minHeight: 330,
      minWidth: 250,
      padding: 12,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    title: {
        fontSize: 14,
        textAlign: 'center',
        marginBottom: 24
      },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      backgroundColor: red[500],
    },
  }));