import React, { useState, useEffect } from "react";
import moment from "moment";
import api from "../../../../../../core/services/api";

//custom components
import CubeSVG from '../Cube'

//material ui
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";

function ReportCard(props) {
  const [state, setState] = useState({
    start_day: moment().format("YYYY-MM-DD"),
    end_day: moment().add(1, "days").format("YYYY-MM-DD"),
    dock_program: 0,
    hd_percent: 0,
    presenteeism_percent: 0,
    dock_percent: 0,
  });

  useEffect(() => {
    getData();
  }, [state.start_day]);

  async function getData() {
    try {

      const result = await (await api.get(`type_activity`));

      let type_actvities = result.data.data.filter(item => {
        return item.option_type_id == 1 ?? item
      })

      let type_actvities_ids = type_actvities.map(item => {
        return item.id
      })

      const data = await api.get(
        `hdtime-day?start_day=${state.start_day}&end_day=${state.end_day}`
      );
      const data3 = await api.get(`routes`);


      let routes = [];
      if (state.start_day == moment().day(0).format("YYYY-MM-DD")) {
        routes = data3.data.filter((item) => {
          return item.period === "2";
        });
      } else {
        routes = data3.data.filter((item) => {
          return item.period === "1";
        });
      }

      //let rt = [{ rota: null, task: null }]

      let tot_previsto = 0.0;
      routes.forEach(rota => {

        rota.tasks.forEach(task => {
          if (type_actvities_ids.includes(task.activity_type)) {

            //rt.push({ rota: rota.name, task: task })
            let split = task.target_time.split(':');
            //target_time em minutos
            let target_time = parseFloat(split[1]) + parseFloat(split[0]) * 60
            let transit_time = parseFloat(task.transit_time != null ? task.transit_time : 0)
            tot_previsto += target_time + transit_time

          }
        })
      })

      // console.log('rt', rt)

      let hd_total = 0;
      let dock_total = 0;
      let routes_t1 = 0;
      let routes_t2 = 0;
      let routes_t3 = 0;
      let routes_t3d = 0;

      //contagem Hora direta programada
      let hd_program = 0; //em minutos
      let presenteeism_plan = 0;
      routes.forEach((item, index) => {
        let t1 = 0;
        let t2 = 0;
        let t3 = 0;
        let t3d = 0;
        item.tasks.forEach((item2, index2) => {
          if (item2.shift == 10) t1 = 1;
          if (item2.shift == 16) t2 = 1;
          if (item2.shift == 12) t3 = 1;
          if (item2.shift == 13) t3d = 1;
        });
        if (t1 == 1) hd_program += 436;
        if (t2 == 1) hd_program += 413;
        if (t3 == 1) hd_program += 230;
        if (t3d == 1) hd_program += 433;
        presenteeism_plan += t1 + t2 + t3 + t3d;
      });

      let dock_program = 0;
      data.data.forEach((item, index) => {
        let soma_ocorrencia = 0;
        let soma_doca = 0;
        let soma_transito = 0;
        let soma_arranque = 0;
        let soma_fechamento = 0;
        //TEMPO OCORRENCIA
        if (item.jornada.tasks) {
          item.jornada.tasks.forEach((task, index) => {



            //arranque
            if (task.activity_type == 11) {
              if (task.end_real != null && task.end_real != '') {
                const duration_arranque = moment(task.end_real, "YYYY-MM-DD HH:mm:ss").diff(moment(task.start_real, "YYYY-MM-DD HH:mm:ss"), "seconds");
                soma_arranque += duration_arranque;
                // if(item.name == "ROTA 09" && item.shift == "Turno 1"){ console.log('Atividade: ', task.title, 'Duration', moment("00:00:00", "HH:mm:ss").add(duration_arranque, 'seconds').format("HH:mm:ss"))}
              }
            }
            //arranque
            if (task.activity_type == 12) {
              if (task.end_real != null && task.end_real != '') {
                const duration_fechamento = moment(task.end_real, "YYYY-MM-DD HH:mm:ss").diff(moment(task.start_real, "YYYY-MM-DD HH:mm:ss"), "seconds");
                soma_fechamento += duration_fechamento;
                //if(item.name == "ROTA 09" && item.shift == "Turno 1"){ console.log('Atividade: ', task.title, 'Duration', moment("00:00:00", "HH:mm:ss").add(duration_fechamento, 'seconds').format("HH:mm:ss"))}
              }
            }
            //doca
            if (task.activity_type == 6) {
              if (task.end_real != null && task.end_real != '') {
                const duration_doca = moment(task.end_real, "YYYY-MM-DD HH:mm:ss").diff(moment(task.start_real, "YYYY-MM-DD HH:mm:ss"), "seconds");
                soma_doca += duration_doca;
                //if(item.name == "ROTA 09" && item.shift == "Turno 1"){ console.log('Atividade: ', task.title, 'Duration', moment("00:00:00", "HH:mm:ss").add(duration_doca, 'seconds').format("HH:mm:ss"))}
              }
            }



            if (task.issues.length > 0) {
              let occurrence_total = 0;
              task.issues.forEach((item2, index) => {
                if (task.issues[index].finish_at) {
                  const aux = moment(task.issues[index].finish_at, "YYYY-MM-DD HH:mm:ss").diff(moment(task.issues[index].created_at, "YYYY-MM-DD HH:mm:ss"), "seconds");
                  occurrence_total += aux;
                }

              })
              //transit
              if (task.transit_time_end != null && task.transit_time_end != '' && task.transit_time_start != null) {
                const duration_transit = moment(task.transit_time_end, "YYYY-MM-DD HH:mm:ss").diff(moment(task.transit_time_start, "YYYY-MM-DD HH:mm:ss"), "seconds");
                let transit = duration_transit - occurrence_total;
                soma_transito += duration_transit - occurrence_total;
                //if(item.name == "ROTA 09"  && item.shift == "Turno 1"){ console.log('Atividade: ', task.title, 'Transit OCORRENCIA: ', moment("00:00:00", "HH:mm:ss").add(transit, 'seconds').format("HH:mm:ss"))}
              }
              //if(item.name == "ROTA 09"  && item.shift == "Turno 1"){ console.log('Atividade: ', task.title, 'Duration Ocorrência', moment("00:00:00", "HH:mm:ss").add(occurrence_total, 'seconds').format("HH:mm:ss"))}
              soma_ocorrencia += occurrence_total;
            }
            else {
              //transit
              if (task.transit_time_end != null && task.transit_time_end != '' && task.transit_time_start != null) {
                const duration_transit = moment(task.transit_time_end, "YYYY-MM-DD HH:mm:ss").diff(moment(task.transit_time_start, "YYYY-MM-DD HH:mm:ss"), "seconds");
                soma_transito += duration_transit;
                //if(item.name == "ROTA 09"  && item.shift == "Turno 1"){ console.log('Atividade: ', task.title, 'Transit: ', moment("00:00:00", "HH:mm:ss").add(duration_transit, 'seconds').format("HH:mm:ss"))}
              }
            }


          });
        }

        let arranque = soma_arranque;
        let doca = soma_doca;
        let transito = soma_transito;
        let fechamento = soma_fechamento;
        hd_total += arranque + doca + transito + fechamento;

        //DEBUG HD
        /*   if(item.name == "ROTA 09" && item.shift == "Turno 1"){ console.log('Hora direta: ', moment("00:00:00", "HH:mm:ss").add(arranque + doca + transito + fechamento, 'seconds').format("HH:mm:ss"))}
          if(item.name == "ROTA 09" && item.shift == "Turno 1"){ console.log('Arranque: ', moment("00:00:00", "HH:mm:ss").add(arranque, 'seconds').format("HH:mm:ss"))}
          if(item.name == "ROTA 09" && item.shift == "Turno 1"){ console.log('Doca: ', moment("00:00:00", "HH:mm:ss").add(doca, 'seconds').format("HH:mm:ss"))}
          if(item.name == "ROTA 09" && item.shift == "Turno 1"){ console.log('Fechamento: ', moment("00:00:00", "HH:mm:ss").add(fechamento, 'seconds').format("HH:mm:ss"))}
          if(item.name == "ROTA 09" && item.shift == "Turno 1"){ console.log('Transit: ', moment("00:00:00", "HH:mm:ss").add(transito, 'seconds').format("HH:mm:ss"))} */

        if (item.shift == "Turno 1") {
          routes_t1 += 1;
        }
        if (item.shift == "Turno 2") {
          routes_t2 += 1;
        }
        if (item.shift == "Turno 3") {
          routes_t3 += 1;
        }
        if (item.shift == "Turno 3 - D") {
          routes_t3d += 1;
        }

        if (item.jornada.tasks) {
          item.jornada.tasks.forEach((item2, idx) => {
            if (item2.activity_type == 6 && item2.end_real != null) {
              dock_total += 1;
            }
          });
        }
      });

      //contagem de atividades do tipo doca
      routes.forEach((item, index) => {
        item.tasks.forEach((item2, idx) => {
          if (item2.activity_type == 6) {
            dock_program += 1;
          }
        });
      });

      let hd_percent = ((hd_total * 85) / (tot_previsto * 60)).toFixed(0)

      for (let i = 0; i <= hd_percent; i++) {
        setState((prev) => ({
          ...prev,
          hd_percent: i,
        }));
      }


      setState((prev) => ({
        ...prev,
        hd_program: tot_previsto,
        presenteeism_plan: presenteeism_plan,
        hd_total: hd_total,
        hd_percent: hd_percent,
        dock_total: dock_total,
        dock_percent: ((dock_total * 100) / dock_program).toFixed(0),
        dock_program: dock_program,
        presenteeism_total: routes_t1 + routes_t2 + routes_t3 + routes_t3d,
        presenteeism_percent: (
          ((routes_t1 + routes_t2 + routes_t3 + routes_t3d) * 98) /
          presenteeism_plan
        ).toFixed(0),
      }));
    } catch (error) {
      console.warn(error);
    }
  }

  const handleDate = (date) => {
    setState((prev) => ({
      ...prev,
      start_day: moment(date).format("YYYY-MM-DD"),
      end_day: moment(date).add(1, "days").format("YYYY-MM-DD"),
    }));
  };

  function pad(num) {
    return ("0" + num).slice(-2);
  }
  function hhmmss(secs) {
    var minutes = Math.floor(secs / 60);
    var secss = secs - minutes * 60;
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return `${hours}:${pad(minutes)}`; //:${pad(secss)}
    // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
  }

  return (
    <Grid container spacing={3}>
      <Grid item md={6} lg={8} sm={12} xs={12}>
        <Card style={{ height: 280 }}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            style={{
              padding: 12,
              backgroundColor: "#eaecee",
              color: "#919aa7",
              textAlign: "center",
            }}
          >
            <Grid item lg={4} sm={4} md={4} xs={4}>
              <div style={{ marginTop: 10, marginBottom: 0 }}>
                <MuiPickersUtilsProvider
                  utils={DateFnsUtils}
                  locale={ptBRLocale}
                >
                  <ThemeProvider theme={fcaTheme}>
                    <KeyboardDatePicker
                      label="Filtro de Data"
                      disableFuture={true}
                      inputVariant="outlined"
                      disableToolbar
                      clearable="true"
                      autoOk={true}
                      style={{ paddingBottom: 12 }}
                      format="dd/MM/yyyy"
                      variant="inline"
                      value={moment(state.start_day, "YYYY-MM-DD")}
                      onChange={handleDate}
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              </div>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2}>
              <p style={{ fontSize: 14, marginTop: 0, marginBottom: 0 }}>
                Hora Direta
              </p>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2}>
              <p style={{ fontSize: 14, marginTop: 0, marginBottom: 0 }}>
                Efetividade
              </p>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2}>
              <p style={{ fontSize: 14, marginTop: 0, marginBottom: 0 }}>
                Presenteísmo
              </p>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2}>
              <p style={{ fontSize: 14, marginTop: 0, marginBottom: 0 }}>
                Capacidade Site
              </p>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            style={{
              backgroundColor: "#7d77ff",
              color: "#fff",
              textAlign: "center",
              height: "30%",
            }}
          >
            <Grid item lg={4} md={4} sm={4} xs={4} style={{ fontSize: 28 }}>
              Previsto
            </Grid>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justify="space-between"
              style={{ padding: 12 }}
              lg={6}
              md={6}
              sm={6}
              xs={6}
            >
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <p style={{ fontSize: 24, marginTop: 0, marginBottom: 0 }}>
                  {hhmmss(state.hd_program * 60)}
                </p>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <p style={{ fontSize: 24, marginTop: 0, marginBottom: 0 }}>
                  {state.dock_program}
                </p>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <p style={{ fontSize: 24, marginTop: 0, marginBottom: 0 }}>
                  {state.presenteeism_plan}
                </p>
              </Grid>

              <Grid item lg={4} md={4} sm={4} xs={4}>
                <p style={{ fontSize: 24, marginTop: 0, marginBottom: 0 }}>
                  85%
                </p>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <p style={{ fontSize: 24, marginTop: 0, marginBottom: 0 }}>
                  100%
                </p>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <p style={{ fontSize: 24, marginTop: 0, marginBottom: 0 }}>
                  98%
                </p>
              </Grid>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} style={{ fontSize: 36 }}>
              83%
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            style={{
              backgroundColor: "#fff",
              color: "#919aa7",
              textAlign: "center",
              height: "40%",
            }}
          >
            <Grid item lg={4} md={4} sm={4} xs={4} style={{ fontSize: 28 }}>
              Real
            </Grid>
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justify="space-between"
              style={{ padding: 12 }}
              lg={6}
              md={6}
              sm={6}
              xs={6}
            >
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <p style={{ fontSize: 24, marginTop: 0, marginBottom: 0 }}>
                  {hhmmss(state.hd_total)}
                </p>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <p style={{ fontSize: 24, marginTop: 0, marginBottom: 0 }}>
                  {state.dock_total}
                </p>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <p style={{ fontSize: 24, marginTop: 0, marginBottom: 0 }}>
                  {state.presenteeism_total}
                </p>
              </Grid>

              <Grid item lg={4} md={4} sm={4} xs={4}>
                <p style={{ fontSize: 24, marginTop: 0, marginBottom: 0 }}>
                  {state.hd_percent}%
                </p>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <p style={{ fontSize: 24, marginTop: 0, marginBottom: 0 }}>
                  {state.dock_percent}%
                </p>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <p style={{ fontSize: 24, marginTop: 0, marginBottom: 0 }}>
                  {state.presenteeism_percent}%
                </p>
              </Grid>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} style={{ fontSize: 36 }}>
              {(
                (parseInt(state.hd_percent) *
                  parseInt(state.dock_percent) *
                  parseInt(state.presenteeism_percent)) /
                10000
              ).toFixed(0)}
              %
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item md={6} lg={4} sm={12} xs={12}>
        <Card style={{ height: 280 }}>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            style={{
              padding: 12,
              textAlign: "center",
              color: "#919aa7",
              position: "relative",
              right: '5%',

            }}
          >
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <p style={{ fontSize: 14, marginTop: 0, marginBottom: 0 }}>
                HD
              </p>
              <p style={{ fontSize: 26, marginTop: 0, marginBottom: 0 }}>
                {state.hd_percent}%
              </p>

            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={4}>
              <cube-svg id="teste" x-max="100" y-max="100" z-max="100" x-value={-Math.abs(state.presenteeism_percent)} y-value={state.hd_percent} z-value={state.dock_percent}></cube-svg>
            </Grid >
            <Grid item lg={4} md={4} sm={4} xs={4} >
              <p style={{ fontSize: 14, marginTop: 0, marginBottom: 0, marginLeft: 50 }}>
                EFETIVIDADE
              </p>
              <p style={{ fontSize: 26, marginTop: 0, marginBottom: 0, marginLeft: 50 }}>
                {state.dock_percent}%
              </p>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <p style={{ fontSize: 14, marginTop: -10, marginBottom: 0 }}>
                PRESENTEÍSMO
              </p>
              <p style={{ fontSize: 26, marginTop: 0, marginBottom: 0 }}>

                {state.presenteeism_percent}%
              </p>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ReportCard;
