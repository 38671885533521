import React, { useState, useEffect } from "react";
import api from "../../../../core/services/api";
import moment from 'moment'

//material ui
import Grid from "@material-ui/core/Grid";
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { fcaTheme } from "./styles";
import { ThemeProvider } from "@material-ui/styles";

//custom components
import Main from "../../components/Main";
import TimeDockLocal from "./TimeDockLocal";
import OperationLocal from "./OperationLocal";
import SaturationLocal from "./SaturationLocal";
import DispersionShift from "./DispersionShift";
import DispersionShiftPoint from "./DispersionShiftPoint";

//datepicker
import ptBRLocale from "date-fns/locale/pt-BR";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// pick a date util library
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";


//custom components
import CustomSelect from '../../components/CustomSelect'


function Kpis1() {
  const usuario = JSON.parse(localStorage.getItem("usuario"));
  const [state, setState] = useState({
    shifts_list: [],
    start_day: moment().format("YYYY-MM-DD"),
    end_day: moment().add(1, 'days').format("YYYY-MM-DD"),
    local_list: [],
    shifts_list: [],
    shifts_list_all: [],
    shifts_id: usuario.person.resource.shift_id[0],
    local_users: usuario.person.resource.local_id,
    local_id: usuario.person.resource.local_id[0],
  });

  useEffect(() => {
    getShift()
    getLocation()
  }, [state.start_day])

  async function getShift() {
    try {
      const data = await api.get(`shifts?active=all`);
      let all_shifts_ids = []
      const date = moment(state.start_day).day();
      const shifts = data.data.data.filter((item) => usuario.person.resource.shift_id.includes(item.id))
      const all_shifts = data.data.data.filter((item) => item.resource.week_days.includes(date))
      all_shifts.map((item) => all_shifts_ids.push(item.id))
      const filtered_shifts = shifts.filter((item) => all_shifts_ids.includes(item.id))
      filtered_shifts.sort((a,b) => (a.resource.work_shift > b.resource.work_shift) ? 1 : ((b.resource.work_shift > a.resource.work_shift) ? -1 : 0))    
      //data.data.data.map((item) => shifts.push(item))
      setState((prev) => ({
        ...prev,
        shifts_list: filtered_shifts || [],
        shifts_list_all: all_shifts || []
      }))
    } catch (error) {
      console.warn(error)
    }
  }

  async function getLocation() {
    try {
      const data = await api.get(`place?perPage=1000`);
      setState((prev) => ({
        ...prev,
        local_list: data.data.data || []
      }))
    } catch (error) {
      console.warn(error)
    }
  }

  const handleDate = (date) => {
    setState((prev) => ({
      ...prev,
      start_day: moment(date).format("YYYY-MM-DD"),
      end_day: moment(date).add(1, 'days').format("YYYY-MM-DD")
    }));
};
const handleChange = (event) => {
  const target = event.target;
  const value = target.type === "checkbox" ? target.checked : target.value;
  const name = target.name;
  setState((prev) => ({
    ...prev,
    [name]: value,
  }));
};

const allShifts = () => {
    if(state.shifts_list_all != state.shifts_list){
      return  (<MenuItem key="all" value={null}>
      Todos os Turnos
      </MenuItem>) 
    } 
}

  return (
    <Main title="Report Diário por Local" color="#f7f7f7">
      <Grid container spacing={3}>
        
      <Grid item lg={2} sm={2} md={2} xs={2}> 
          <div style={{ marginTop: 12, marginBottom: 12}}>
          <CustomSelect
            id="component"
            label="Local"
            outlined="outlined"
            value={state.local_id}
            name="local_id"
            data={state.local_list}
            onChange={(event) => handleChange(event)}
            fullWidth
          />
          </div>
          </Grid>
            <Grid item lg={2} sm={2} md={2} xs={2}> 
            <div style={{ marginTop: 12, marginBottom: 12 }}>
            <Select
                MenuProps={{ disableScrollLock: true }}
                 fullWidth
                 variant="outlined"
                  displayEmpty
                  value={state.shifts_id}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                     shifts_id: e.target.value,
                    }))
                  } 
                >
                  {state.shifts_list.map((item) => {return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.resource.work_shift}
                    </MenuItem>
                  )})}
                </Select>
           
            </div>
            </Grid>
            <Grid item lg={2} sm={2} md={2} xs={2}> 
            <div style={{ marginTop: 10, marginBottom:  0}}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
                <ThemeProvider theme={fcaTheme}>
                  <KeyboardDatePicker
                    label="Filtro de Data"
                    disableFuture={true}
                    inputVariant="outlined"
                    disableToolbar
                    clearable="true"
                    autoOk={true}
                    style={{ paddingBottom: 12 }}
                    format="dd/MM/yyyy"
                    variant="inline"
                    value={moment(state.start_day, "YYYY-MM-DD")}
                    onChange={handleDate}
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </div>
            </Grid>

        
        <Grid item sm={12} lg={12} md={12}>
          <TimeDockLocal start_day={state.start_day} end_day={state.end_day} local_id={state.local_id} shifts_id={state.shifts_id}/>
        </Grid>
        <Grid item sm={12} lg={12} md={12}>
          <OperationLocal start_day={state.start_day} end_day={state.end_day} local_id={state.local_id} shifts_id={state.shifts_id}/>
        </Grid>
        <Grid item sm={12} lg={12} md={12}>
          <SaturationLocal start_day={state.start_day} end_day={state.end_day} local_id={state.local_id} shifts_id={state.shifts_id}/>
        </Grid>
        <Grid item sm={12} lg={12} md={12}>
          <DispersionShift start_day={state.start_day} end_day={state.end_day} local_id={state.local_id} shifts_id={state.shifts_id}/>
        </Grid>
        <Grid item sm={12} lg={12} md={12}>
          <DispersionShiftPoint start_day={state.start_day} end_day={state.end_day} local_id={state.local_id} shifts_id={state.shifts_id}/>
        </Grid>
      </Grid>
    </Main>
  );
}

export default Kpis1;
