import loginPage from './login';

//TORA
import toraDashboard from '../../client/tora/pages/Dashboard'
import toraTimeline from '../../client/tora/pages/Timeline'
import toraTimelineShow from '../../client/tora/pages/Timeline/show'
import toraMap from '../../client/tora/pages/Map'
import toraVehicles from '../../client/tora/pages/PainelVeiculos'
import toraAverageDischarge from '../../client/tora/pages/TempoMedioDescarga'
import toraAverageLoad from '../../client/tora/pages/TempoMedioDescarga'
import toraRanking from '../../client/tora/pages/Ranking'


//FERREIRO
import ferreroDashboard from '../../client/ferrero/pages/Dashboard'
import ferreroMap from '../../client/ferrero/pages/Map'
import ferreroJourneys from '../../client/ferrero/pages/Journeys'
import ferreroEvidences from '../../client/ferrero/pages/Evidences'
import ferreroListEvidences from '../../client/ferrero/pages/Dashboard/evidences'
import ferreroListAlerts from '../../client/ferrero/pages/Dashboard/alerts'
import ferreroRoutes from '../../client/ferrero/pages/MapRoutes'


//PORTO ITAPOA
import itapoaDashboard from '../../client/portoitapoa/pages/Dashboard'
import itapoaDriverPanel from '../../client/portoitapoa/pages/DriverPanel'
import itapoaDriver from '../../client/portoitapoa/pages/Driver'
import itapoaCarrier from '../../client/portoitapoa/pages/Carrier'


//FCA GOIANA
import fcagoianaKpis from '../../client/FCAGoiana/pages/Kpis'
import fcagoianaKpis1 from '../../client/FCAGoiana/pages/Kpis1'
import fcagoianaKpis2 from '../../client/FCAGoiana/pages/Kpis2'
import fcagoianaKpis3 from '../../client/FCAGoiana/pages/Kpis3'
import fcagoianaUsers from '../../client/FCAGoiana/pages/Users'
import fcagoianaDrivers from '../../client/FCAGoiana/pages/Drivers'
import fcagoianaOccupations from '../../client/FCAGoiana/pages/Occupations'
import fcagoianaTypeActivities from '../../client/FCAGoiana/pages/TypeActivities'
import fcagoianaVehiclesCart from '../../client/FCAGoiana/pages/VehiclesCart'
import fcagoianaVehiclesHorse from '../../client/FCAGoiana/pages/VehiclesHorse'
import fcagoianaVehicles from '../../client/FCAGoiana/pages/Vehicles'
import fcagoianaJustifications from '../../client/FCAGoiana/pages/Justifications'
import fcagoianaLocations from '../../client/FCAGoiana/pages/Locations'
import fcagoianaShifts from '../../client/FCAGoiana/pages/Shifts'
import fcagoianaOccurrences from '../../client/FCAGoiana/pages/Occurrences'
import fcagoianaCheckLists from '../../client/FCAGoiana/pages/CheckList'
import fcagoianaRoutesCreate from '../../client/FCAGoiana/pages/ManagementRoutes'
import fcagoianaRoutesList from '../../client/FCAGoiana/pages/ListRoutes'
import fcagoianaSettings from '../../client/FCAGoiana/pages/SettingsPage'
import fcagoianaShiftReport from '../../client/FCAGoiana/pages/Shifts/ShiftReports'
import fcagoianaEndShiftReport from '../../client/FCAGoiana/pages/Shifts/EndShiftReports'
import fcagoianaChecklistReport from '../../client/FCAGoiana/pages/CheckList/ChecklistReports'
import fcagoianaReport from '../../client/FCAGoiana/pages/Report'
import fcagoianaGroups from '../../client/FCAGoiana/pages/GroupsPage'

import fcagoianaActiveRoutes from '../../client/FCAGoiana/pages/ActiveRoutes'

//alertas FCA GOIANA
import fcagoianaAlerts from '../../client/FCAGoiana/pages/Alerts'
import fcagoianaSegments from '../../client/FCAGoiana/pages/Segments'
import fcagoianaReceivers from '../../client/FCAGoiana/pages/Receivers'
import fcagoianaDatasets from '../../client/FCAGoiana/pages/Datasets'



//AcelorMittal
import ArcelorMittalDashboard from '../../client/ArcelorMittal/pages/Dashboard'
import ArcelorMittalReport from '../../client/ArcelorMittal/pages/Report'
import ArcelorMittalGroups from '../../client/ArcelorMittal/pages/GroupsPage'
import ArcelorMittalUsers from '../../client/ArcelorMittal/pages/Users'



// Mapa Operacional
import MapaOperacional from '../../client/FCAGoiana/pages/MapaOperacional'

// Cards Tela Principal
import CardRoutes from '../../client/FCAGoiana/pages/CardRoutes'

export default {
    'LoginPage': loginPage,

    //tora
    'ToraDashboard': toraDashboard,
    'ToraTimeline': toraTimeline,
    'ToraTimelineShow': toraTimelineShow,
    'ToraMap': toraMap,
    'ToraVehicles': toraVehicles,
    'ToraAverageDischarge': toraAverageDischarge,
    'ToraAverageLoad': toraAverageLoad,
    'ToraRanking': toraRanking,

    //ferrero - Polar
    'FerreroDashboard': ferreroDashboard,
    'FerreroMap': ferreroMap,
    'FerreroJourneys': ferreroJourneys,
    'FerreroEvidences': ferreroEvidences,
    'FerreroListEvidences': ferreroListEvidences,
    'FerreroListAlerts': ferreroListAlerts,
    'FerreroRoutes': ferreroRoutes,

    //Porto itapoa
    'ItapoaDashboard': itapoaDashboard,
    'ItapoaDriverPanel' : itapoaDriverPanel,
    'ItapoaDriver': itapoaDriver,
    'ItapoaCarrier': itapoaCarrier,


    //FCA Goiana
    'FCAGoianaKpis' : fcagoianaKpis,
    'FCAGoianaKpis1' : fcagoianaKpis1,
    'FCAGoianaKpis2' : fcagoianaKpis2,
    'FCAGoianaKpis3' : fcagoianaKpis3,
    'FCAGoianaUsers': fcagoianaUsers,
    'FCAGoianaDrivers': fcagoianaDrivers,
    'FCAGoianaOccupations': fcagoianaOccupations,
    'FCAGoianaTypeActivities': fcagoianaTypeActivities,
    'FCAGoianaVehiclesCart': fcagoianaVehiclesCart,
    'FCAGoianaVehiclesHorse': fcagoianaVehiclesHorse,
    'FCAGoianaLocations': fcagoianaLocations,
    'FCAGoianaVehicles': fcagoianaVehicles,
    'FCAGoianaJustifications': fcagoianaJustifications,
    'FCAGoianaOccurrences': fcagoianaOccurrences,
    'FCAGoianaCheckLists': fcagoianaCheckLists,
    'FCAGoianaShifts': fcagoianaShifts,
    'FCAGoianaRoutes': fcagoianaRoutesCreate,
    'FCAGoianaRoutesList': fcagoianaRoutesList,
    'FCAGoianaMap': MapaOperacional,
    'FCAGoianaSettings': fcagoianaSettings,
    'FCAGoianaCardRoutes': CardRoutes,
    'FCAGoianaActiveRoutes': fcagoianaActiveRoutes,
    'FCAGoianaReport': fcagoianaReport,
    'FCAGoianaShiftReport': fcagoianaShiftReport,
    'FCAGoianaEndShiftReport': fcagoianaEndShiftReport,
    'FCAGoianaChecklistReport': fcagoianaChecklistReport,
    'FCAGoianaGroups': fcagoianaGroups,
    //ALERTS FCA GOIANA
    'FCAGoianaAlerts': fcagoianaAlerts,
    'FCAGoianaSegments': fcagoianaSegments,
    'FCAGoianaReceivers': fcagoianaReceivers,
    'FCAGoianaDatasets': fcagoianaDatasets,



    //ArcelorMittal
    'ArcelorMittalDashboard': ArcelorMittalDashboard,
    'ArcelorMittalReport': ArcelorMittalReport,
    'ArcelorMittalGroups': ArcelorMittalGroups,
    'ArcelorMittalUsers': ArcelorMittalUsers,
}