import React from 'react';
import moment from 'moment'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MaterialTable, {MTableToolbar} from 'material-table'

export default function DetailsTable(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Detalhes
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth='md'
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
        <MaterialTable
        columns={[
          {
            title: "Motorista",
            field: "name",
            cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
          },
          {
            title: "Início Janela",
            field: "schedulewindowbegin_ts",
            cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
            render: (rowData) => moment(rowData.schedulewindowbegin_ts, "YYYY-MM-DD HH:mm:ss.SSS").format("HH:mm DD/MM/YYYY")
          },
          {
            title: "Fim Janela",
            field: "schedulewindowend_ts",
            cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
            render: (rowData) => moment(rowData.schedulewindowend_ts, "YYYY-MM-DD HH:mm:ss.SSS").format("HH:mm DD/MM/YYYY")
          },
          {
            title: "Previsão de chegada",
            field: "eta_ts",
            cellStyle: { paddingLeft: 40, textAlign: "left", border: "#fff" },
            render: (rowData) => moment(rowData.eta_ts, "YYYY-MM-DD HH:mm:ss.SSS").subtract(3, "hours").format("HH:mm DD/MM/YYYY")
          },
        ]}
        data={props.data || []}
        options={{
          pageSize: 5,
          actionsColumnIndex: -1,
          
          search: false,
          showTitle: false,
          headerStyle: {
            paddingLeft: 40,
            textAlign: "left",
            backgroundColor: "#1c1c1c",
            color: "#fff",
            border: "#fff"
          },
          rowStyle: (x) => {
            if (x.tableData.id % 2) {
              return { backgroundColor: "#f3f5f8" };
            }
          },
        }}
        components={{
          Toolbar: (props) => (
            <div style={{ display: "none" }}>
              <MTableToolbar {...props} />
            </div>
          ),
        }}
        style={{
          borderRadius: 5,
        }}
      />
        </DialogContent>
        <DialogActions>
     
          <Button onClick={handleClose} color="secondary" >
           Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}