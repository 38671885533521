import moment from "moment";


export default function (legends) {


  function pad(num) {
    return ("0" + num).slice(-2);
  }
  function hhmmss(secs) {
    var minutes = Math.floor(secs / 60);
    var secss = secs - (minutes * 60);
    var hours = Math.floor(minutes / 60);
    //minutes = minutes % 60;
    return `${pad(minutes)}:${pad(secss)}`;
    // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
  }


  return {
    colors: ["#ffc504", "#df1d1d", "#7d77ff", "#6762d4"],
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (value, opts) {
        return (value/60).toFixed(1);
      },
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "left",
    },
    xaxis: {
      categories: legends,
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: true,
      }
    },
    yaxis: [
      {
        opposite: true,
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          style: {
            colors: "#FEB019",
          },
          formatter: function (value) {
            return hhmmss(parseFloat(value));
          },
        },
      },
    ],
  };
}
