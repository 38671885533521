import React, {useEffect} from 'react';
import api from '../../../../../../../core/services/api'
import moment from 'moment'


import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye'

//material-ui
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CallReceivedIcon from '@material-ui/icons/CallReceived'
import CallMadeIcon from '@material-ui/icons/CallMade'
import HomeWorkIcon from '@material-ui/icons/HomeWork'

const useStyles = makeStyles((theme) => ({
    paper: {
      padding: '6px 16px',
    },
    secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
    },
    timer: {
      paddingTop: 20
    }
  }));
  

function ViewItem(props) {
  const [open, setOpen] = React.useState(false);
  const [data, setState] = React.useState({
    startDate: props.startDate,
    endDate: props.endDate,
    vehicle_id: props.vehicle_id
  })
  const classes = useStyles();
  
  // Main UseEffect
  useEffect(() => {
    if (open) {
      getData();
    } else {
    }
  }, [open]);

  async function getData(){
    try {
      const detail = await api.get(`https://flex.trackage.io/api/v1/vehicle_detail?ini_date="${data.startDate}"&end_date="${data.endDate}"&vehicle_id=${data.vehicle_id}`) 
      setState((prev) => ({
          ...prev,
          detail: detail.data,
          rowLen: detail.data.length
        }));
    } catch (error) {
      console.warn(error)
    }
  }

  function getIcon(id){
    const icons = {
      1: <CallReceivedIcon />,
      2: <CallMadeIcon />,
      5: <HomeWorkIcon />
    }
    return icons[id]
  }
  function getColor(id){
    const colors = {
      1: "#1e824c",
      2: "#3eb24f",
      5: "#3e7fb2"
    }
    return colors[id]
  }
  const handleClickOpen = () => {
    setOpen(true);
    getData();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
        <IconButton onClick={handleClickOpen}>
            <RemoveRedEyeIcon />
        </IconButton>
      <Dialog
       fullWidth={true}
       maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Tempo médio de permanência do Veículo {props.board} de {moment(props.startDate, "YYYY-MM-DD").format("DD/MM/YYYY")} a {moment(props.endDate, "YYYY-MM-DD").format("DD/MM/YYYY")}</DialogTitle>
        <DialogContent>
        <Timeline align="alternate">
          {data.detail ? (
            
            data.detail.map((item, i) => {
              return(
                <TimelineItem>
                <TimelineOppositeContent className={classes.timer}>
                  <Typography variant="body2" color="textSecondary">
                    {moment(item.tempo_medio, "HH:mm:ss.SSS").format("HH:mm:ss")}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot style={{backgroundColor: getColor(item.task_id)}}>
                    {getIcon(item.task_id)}
                  </TimelineDot>
                  {data.rowLen === i+1 ? "" : (<TimelineConnector />) }
                  
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Typography variant="h6" component="h1">
                      {item.name}
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
              )
            })
          ) : (<p>carregando</p>)}
      
    
        
    </Timeline>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ViewItem;