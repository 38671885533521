import styled from 'styled-components';

const verde =
  'invert(66%) sepia(90%) saturate(387%) hue-rotate(54deg) brightness(90%) contrast(94%)';
const cinza =
  'invert(50%) sepia(17%) saturate(320%) hue-rotate(162deg) brightness(90%) contrast(90%)';

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
  height: 100vh;
  background-color: #101417;
  position: fixed;
  max-width: 20%;
  padding-top: 10px;
  z-index: 99;
  overflow: auto;
`;

export const Icon = styled.img`
  filter: ${props => (props.rota === props.rotaAtual ? verde : cinza)};
`;

export const MenuItem = styled.div`
  padding-top: 30px;
  cursor: pointer;
`;
