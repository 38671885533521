import React, { useState, useEffect } from "react";

import api from "../../../../../../core/services/api";
import Chart from "react-apexcharts";
import options from "./options";
import { filter, forEach } from "lodash";

//material ui
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

function GraphCard(props) {

  return(
    <Chart
          options={options(props.legends)}
          series={props.series}
          type="bar"
          height={350}
        />
)
}

export default GraphCard;
