import React, {useState, useEffect} from 'react'
import moment from 'moment'
import Chart from "react-apexcharts";
import options from "./options";

function GraphCard(props){
    const [state, setState] = useState({          
        series: [{
          name: 'Efetividade Real',
          type: 'column',
          data: [10,80,90]
        },
        {
          name: '% Efetividade',
          type: 'line',
          data: [80,10,25]
        },
        {
          name: 'Target Efetividade',
          type: 'line',
          data: [80,10,25]
        },
      ],
        legends: [],
        target: [],
    });

    return(
        <Chart
              options={options(props.legends)}
              series={props.series}
              type="line"
              height={350}
            />
    )
}

export default GraphCard;